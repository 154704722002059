import React from "react";
import numeral from "numeral";
import moment from "moment";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import { Grid, GridCell } from "components/Grid";
import Button from "components/Button/Button";
import RadioGroupField from "components/FormFields/RadioGroupField";
import TextField from "components/FormFields/TextField";
import Alert from "components/Alert";
import RadioGroupPack from "components/RadioGroupPack";
import CreditCards from "components/CreditCards";
import LoaderSection from "components/LoaderSection";
import ZendeskLink from "components/Help/ZendeskLink";
import * as icons from "common/icons";
import { useEmailTracking } from "common/effects";
import { logEmailTracking, clearEmailTracking } from "common/actions";
import { useDispatch } from "react-redux";
import { useMyOrgBalance, useMySaveCards, useUnitPriceExclGst, getPaymentStateAsync, payByInvoiceAsync, payByPaystationCardAsync, payByPaystationTokenAsync, setRecurringFarmSubscriptionsAsync } from "./_queries";
import { useAuthContext, useOnline, useModal } from "common/hooks";
import { useOnPaymentComplete } from "../hooks";

export default function PaymentModal({ farms, onPaid, close }) {
    const online = useOnline();
    const isOrgAdmin = useAuthContext()?.isAdmin;
    const { data: unitPriceExclGst } = useUnitPriceExclGst();
    const { data: savedCards } = useMySaveCards();
    const { data: orgBalance } = useMyOrgBalance();
    const handlePaymentComplete = useOnPaymentComplete();

    const [paymentTokenPaymentInProgress, setPaymentTokenPaymentInProgress] = React.useState();
    const [selectedCard, setSeletedCard] = React.useState();
    const [recurringBaseFarmIds, setRecurringBaseFarmIds] = React.useState(farms.map((f) => f.id));
    const [selectedFarmIds, setSelectedFarmIds] = React.useState(farms.map((f) => f.id));
    const [payment, setPayment] = React.useState();
    const [windowState, setWindowState] = React.useState(WINDOW_STATE.PaymentType);
    const farmIdentifier = farms.length > 1 ? "Multi" : farms.length === 1 ? farms[0].name : "";
    const emailId = useEmailTracking("Payment flow - make payment clicked", farmIdentifier);
    const dispatch = useDispatch();

    // Poll Paystation for payment state.
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (online && payment && payment.paymentMethod !== "Invoice" && payment.paystationResultCode === undefined && !payment.error && !payment.alreadyPaid && !payment.inProgress) {
                getPaymentStateAsync(payment.id)
                    .then((paymentState) => {
                        if (paymentState.paystationResultCode === 0) {
                            // Succeeded!
                            clearInterval(interval);
                            setPayment({ ...payment, ...paymentState, completed: true });
                            if (payment.paymentMethod === "CreditCard" && payment.paymentTokenId) {
                                setWindowState(WINDOW_STATE.RecurringPayments);
                                setSelectedFarmIds([]);
                            } else {
                                setWindowState(WINDOW_STATE.PaymentResult);
                            }
                        } else if (paymentState.paystationResultCode > 0) {
                            let error = "";
                            switch (paymentState.paystationResultCode) {
                                case 3:
                                    error = DECLINE_CODES.find((d) => d.code === "TIME_OUT").message;
                                    break;
                                case 4:
                                    error = DECLINE_CODES.find((d) => d.code === "EXPIRED_CARD").message;
                                    break;
                                case 5:
                                    error = DECLINE_CODES.find((d) => d.code === "INSUFFICIENT_FUNDS").message;
                                    break;
                                case 6:
                                    error = DECLINE_CODES.find((d) => d.code === "COMMUNICATION_ERROR").message;
                                    break;
                                case 143:
                                case 8:
                                    error = DECLINE_CODES.find((d) => d.code === "INVALID_CARD").message;
                                    break;
                                default:
                                    error = DECLINE_CODES.find((d) => d.code === "BANK_DECLINE").message;
                                    break;
                            }
                            clearInterval(interval);
                            setPayment({ ...payment, ...paymentState, error, completed: true });
                            setWindowState(WINDOW_STATE.PaymentResult);
                        }
                    })
                    .catch((error) => {
                        if (error.status === 409) {
                            // HTTP 409 means there is an existing payment is in progress.
                            clearInterval(interval);
                            setPayment({ ...payment, inProgress: true, completed: true });
                            setWindowState(WINDOW_STATE.PaymentResult);
                        } else if (error.status === 426) {
                            // HTTP 426 means one or more of the selected farms is already paid for.
                            clearInterval(interval);
                            setPayment({ ...payment, alreadyPaid: true, completed: true });
                            setWindowState(WINDOW_STATE.PaymentResult);
                        } else {
                            setPayment({ ...payment, error: error.message, completed: true });
                            setWindowState(WINDOW_STATE.PaymentResult);
                        }
                    });
            } else {
                clearInterval(interval);
            }
        }, 1500);
        return () => clearInterval(interval);
    }, [online, payment]);

    React.useEffect(() => {
        if (payment && payment.completed) {
            const success = payment.status === "Paid";
            let paymentStatus;
            paymentStatus = success ? "payment success" : paymentStatus;
            paymentStatus = payment.error ? "payment failed" : paymentStatus;
            paymentStatus = payment.alreadyPaid ? "already paid" : paymentStatus;
            paymentStatus = payment.inProgress ? "payment duplicate" : paymentStatus;
            const id = emailId;
            paymentStatus && dispatch(logEmailTracking("Payment flow - " + paymentStatus, id, farmIdentifier));
            success && dispatch(clearEmailTracking());
        }
    }, [dispatch, emailId, farmIdentifier, payment]);

    const toggleSelect = (farmId) => (e) => {
        //if (farms.length === 1) return;

        let selectedIds = [];
        if (selectedFarmIds.includes(farmId)) {
            selectedIds = selectedFarmIds.filter((id) => id !== farmId);
        } else {
            selectedIds = [...selectedFarmIds, farmId];
        }
        setSelectedFarmIds(selectedIds);
        if (windowState === WINDOW_STATE.PaymentType) {
            setRecurringBaseFarmIds(selectedIds);
        }
    };

    const toggleSelectAll = (e) => {
        const selectedIds = [];
        if (selectedFarmIds.length < farms.length) {
            farms.forEach((farm) => selectedIds.push(farm.id));
        }
        setSelectedFarmIds(selectedIds);
        if (windowState === WINDOW_STATE.PaymentType) {
            setRecurringBaseFarmIds(selectedIds);
        }
    };

    const onClose = () => {
        if (payment && (payment.status === "Paid" || payment.alreadyPaid || payment.inProgress)) {
            if (onPaid) {
                onPaid();
            }
            handlePaymentComplete();
        }

        close();
    };

    const payByInvoice = async (reference) => {
        const submitResult = await payByInvoiceAsync(selectedFarmIds, reference)
            .then((response) => {
                setPayment({ ...response, completed: true });
            })
            .catch((error) => {
                if (error.status === 409) {
                    // HTTP 409 means there is an existing payment is in progress.
                    setPayment({ inProgress: true, completed: true });
                } else if (error.status === 426) {
                    // HTTP 426 means one or more of the selected farms is already paid for.
                    setPayment({ alreadyPaid: true, completed: true });
                } else {
                    setPayment({ error: error.message, completed: true });
                }
                setWindowState(WINDOW_STATE.PaymentResult);
            });
        return submitResult;
    };

    const payByPaystationCard = async (reference, paymentMethod, saveCard) => {
        const submitResult = await payByPaystationCardAsync(selectedFarmIds, reference, paymentMethod, saveCard)
            .then((response) => {
                setPayment({ ...response, completed: true });
            })
            .catch((error) => {
                if (error.status === 409) {
                    // HTTP 409 means there is an existing payment is in progress.
                    setPayment({ inProgress: true, completed: true });
                } else if (error.status === 426) {
                    // HTTP 426 means one or more of the selected farms is already paid for.
                    setPayment({ alreadyPaid: true, completed: true });
                } else {
                    setPayment({ error: error.message, completed: true });
                }
                setWindowState(WINDOW_STATE.PaymentResult);
            });
        return submitResult;
    };

    const payByPaystationToken = async (reference) => {
        setPaymentTokenPaymentInProgress(true);
        const submitResult = await payByPaystationTokenAsync(selectedFarmIds, reference, selectedCard.id)
            .then((response) => {
                if (response.status === "Paid") {
                    setPayment({ ...response, completed: true });
                } else {
                    setPayment({ error: (response && response.paystationResultMessage) || "There was an error processing your payment. If this problem persists please contact the help desk.", completed: true });
                    setWindowState(WINDOW_STATE.PaymentResult);
                }
            })
            .catch((error) => {
                if (error.status === 409) {
                    // HTTP 409 means there is an existing payment is in progress.
                    setPayment({ inProgress: true, completed: true });
                } else if (error.status === 426) {
                    // HTTP 426 means one or more of the selected farms is already paid for.
                    setPayment({ alreadyPaid: true, completed: true });
                } else {
                    setPayment({ error: error.message, completed: true });
                }
                setWindowState(WINDOW_STATE.PaymentResult);
            })
            .finally(() => setPaymentTokenPaymentInProgress(false));
        return submitResult;
    };

    const setRecurringFarmSubscriptions = async () => {
        const submitResult = await setRecurringFarmSubscriptionsAsync(selectedFarmIds, payment.paymentTokenId);
        return submitResult;
    };

    const selectSavedCardChanged = (e) => {
        const useCardOption = e.target.value;
        if (useCardOption === "False") {
            setSeletedCard(false);
        }
    };

    const retryPayment = () => {
        setPayment(undefined);
        setWindowState(WINDOW_STATE.PaymentType);
    };

    const validate = (formValues) => {
        if (windowState === WINDOW_STATE.PaymentType) {
            const { reference } = formValues;
            const validation = {};
            validation.reference = FormUtils.validators.maxLength(20)(reference);
            return validation;
        }
    };

    const submitAsync = async ({ paymentMethod, reference, saveCardDetails, selectSavedCard }) => {
        const isInvoice = paymentMethod === PAYMENT_METHODS[0].value;
        const isCreditCard = paymentMethod === PAYMENT_METHODS[1].value;
        const isPoli = paymentMethod === PAYMENT_METHODS[2].value;
        const notUsingSavedCard = windowState === WINDOW_STATE.UseSavedCard && selectSavedCard === "False";
        const noSavedCards = isCreditCard && (!savedCards || savedCards.length === 0) && windowState === WINDOW_STATE.PaymentType;
        const sendToPayByInvoice = isInvoice && windowState === WINDOW_STATE.PaymentType;
        const sendToPayByPaystationCard = windowState === WINDOW_STATE.SaveCard || isPoli;
        const sendToSaveCard = notUsingSavedCard || noSavedCards;
        const sendToUseSavedCard = savedCards && savedCards.length > 0 && windowState === WINDOW_STATE.PaymentType;
        const sendToPayByPaystationToken = windowState === WINDOW_STATE.UseSavedCard && selectedCard;
        const sendToSummary = windowState === WINDOW_STATE.RecurringPayments && payment && payment.paymentTokenId;
        const genToken = saveCardDetails === "True" && isCreditCard;

        if (sendToPayByInvoice) {
            payByInvoice(reference);
            setWindowState(WINDOW_STATE.RecurringPayments);
            setSelectedFarmIds([]);
            dispatch(logEmailTracking("Payment flow - pay by invoice selected", emailId, farmIdentifier));
        } else if (sendToPayByPaystationCard) {
            payByPaystationCard(reference, paymentMethod, genToken);
            setWindowState(WINDOW_STATE.PayByPaystation);
            dispatch(logEmailTracking(`Payment flow - pay by ${isPoli ? "poli" : "credit card"} selected`, emailId, farmIdentifier));
        } else if (sendToPayByPaystationToken) {
            payByPaystationToken(reference);
            setWindowState(WINDOW_STATE.RecurringPayments);
            setSelectedFarmIds([]);
        } else if (sendToSaveCard) {
            setWindowState(WINDOW_STATE.SaveCard);
            dispatch(logEmailTracking("Payment flow - User offered to store card", emailId, farmIdentifier));
        } else if (sendToUseSavedCard) {
            setWindowState(WINDOW_STATE.UseSavedCard);
            dispatch(logEmailTracking(`Payment flow - pay by ${isPoli ? "poli" : "credit card"} selected`, emailId, farmIdentifier));
        } else if (sendToSummary) {
            setRecurringFarmSubscriptions();
            setWindowState(WINDOW_STATE.PaymentResult);
            dispatch(logEmailTracking("Payment flow - processing payment", emailId, farmIdentifier));
        }
    };

    return (
        <Form initialValues={{ saveCardDetails: "True", selectSavedCard: "True" }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting }) => {
                const subTotal = selectedFarmIds.length * (unitPriceExclGst || 0);
                const gst = subTotal * 0.15;
                const total = subTotal + gst;
                const creditLimit = orgBalance ? orgBalance.creidtLimit : 0;
                const currentBalance = orgBalance ? orgBalance.currentBalance : 0;
                const availableBalance = orgBalance ? orgBalance.availableBalance : 0;
                const remainingBalance = availableBalance - total;
                const showOrgBalance = isOrgAdmin && (creditLimit > 0 || currentBalance !== 0 || availableBalance !== 0);
                const canPayByInvoice = availableBalance > (unitPriceExclGst || 0) && availableBalance >= total;
                const showPaymentMethod = canPayByInvoice || showOrgBalance;
                const paymentMethodIsRequired = showPaymentMethod && !values.paymentMethod;
                const isPayingByInvoice = values.paymentMethod === PAYMENT_METHODS[0].value;
                const isPayingByPaystation = !showPaymentMethod || !isPayingByInvoice;
                const hasMultipleFarmsToChooseFrom = farms.length > 1;
                const showPaymentSuccess = payment && payment.status === "Paid" && windowState === WINDOW_STATE.PaymentResult;
                const showPaymentError = payment && payment.error;
                const showPleaseTryAgain = payment && payment.inProgress;
                const showPaymentNotRequired = payment && payment.alreadyPaid;
                const paymentStarted = payment && payment.paystationUrl && windowState === WINDOW_STATE.PayByPaystation && !showPaymentSuccess && !showPaymentError && !showPaymentNotRequired && !showPleaseTryAgain;
                const showViewInvoice = payment && payment.status === "Paid" && payment.invoiceNumber && windowState === WINDOW_STATE.PaymentResult;
                var exceededPaystationMaxError = isPayingByPaystation && total > process.env.REACT_APP_PAYSTATION_LIMIT ? `The maximum spend for payment by credit card or POLi is ${numeral(process.env.REACT_APP_PAYSTATION_LIMIT).format("$0,0")}. Please reduce the number of selected farms.` : undefined;
                let info = !showPaymentMethod && windowState === WINDOW_STATE.PaymentType && "Your payment will be handled by our secure payment server";

                const zendeskLink = (id) => <ZendeskLink title="Page help" url={`https://support.overseer.org.nz/hc/en-us/articles/${id}`} className="IconLink--new-tab u-font-green" />;
                const paymentMethods = canPayByInvoice ? PAYMENT_METHODS : PAYMENT_METHODS.filter((p) => p.value !== "Invoice");

                if (paymentStarted) {
                    info = `You are paying the annual subscription fee for ${selectedFarmIds.length} ${selectedFarmIds.length > 1 ? "farms" : "farm"}`;
                } else if (exceededPaystationMaxError || showPaymentSuccess || showPaymentError || showPaymentNotRequired || showPleaseTryAgain) {
                    info = undefined;
                }

                const loading = !unitPriceExclGst || !orgBalance || paymentTokenPaymentInProgress || (payment && payment.paymentMethod !== "Invoice" && !payment.paystationUrl && !payment.error && !payment.alreadyPaid && payment.status !== "Paid" && !payment.inProgress);
                const isSubmitting = submitting && !showPaymentSuccess;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={selectedFarmIds.length === 1 || windowState === WINDOW_STATE.RecurringPayments ? "Subscription payment" : `Subscription payment for ${selectedFarmIds.length} farms`} close={onClose} submitting={isSubmitting}>
                            <ModalBody loading={loading} info={info} error={exceededPaystationMaxError} moreInfo={zendeskLink("900002985586")}>
                                {windowState === WINDOW_STATE.PaymentType && (
                                    <>
                                        <Grid>
                                            {showOrgBalance && (
                                                <GridCell className="u-textRight u-mt-md">
                                                    {creditLimit > 0 && (
                                                        <span>
                                                            <b>Postpaid spending limit</b> {numeral(creditLimit).format("$0,0.00")}
                                                        </span>
                                                    )}
                                                    {creditLimit > 0 && currentBalance !== 0 && (
                                                        <span>
                                                            <b>Postpaid purchases</b> {numeral(currentBalance).format("$0,0.00")}
                                                        </span>
                                                    )}
                                                    <span>
                                                        <b>Available balance:</b> <span className={availableBalance < 0 ? "u-textError" : ""}>{numeral(availableBalance).format("$0,0.00")}</span>
                                                    </span>
                                                </GridCell>
                                            )}

                                            <GridCell className="u-xl-width1of2">
                                                <Field name="paymentMethod" label="How would you like to pay?" options={paymentMethods} inline type="radio" component={RadioGroupField} />
                                            </GridCell>

                                            <GridCell className="u-md-width1of3">
                                                <Field name="reference" label="Reference" placeholder="Your reference for this payment (if any)" type="text" component={TextField} />
                                            </GridCell>
                                            {showPaymentMethod && (
                                                <GridCell className="u-mt-md">
                                                    {isPayingByInvoice && availableBalance >= total && <Alert type="info" text="The selected farms will be added to your organisation's monthly invoice" moreInfo={zendeskLink("900002985586")} />}
                                                    {isPayingByInvoice && availableBalance < total && <Alert type="error" text="Your organisation has insufficient available balance to pay by monthly invoice" moreInfo={zendeskLink("900002985586")} />}
                                                    {isPayingByPaystation && !exceededPaystationMaxError && <Alert type="info" text="Your payment will be handled by our secure payment server" moreInfo={zendeskLink("900002985586")} />}
                                                    {isPayingByPaystation && exceededPaystationMaxError && <Alert type="error" text={exceededPaystationMaxError} moreInfo={zendeskLink("900002985586")} />}
                                                </GridCell>
                                            )}
                                        </Grid>
                                        <div className={`Table ${canPayByInvoice || showOrgBalance ? "u-mt-sm" : "u-mt-lg"}`}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        {hasMultipleFarmsToChooseFrom && (
                                                            <th className="th--shrink">
                                                                <label className="Checkbox" htmlFor="select-all">
                                                                    <input className="Checkbox-input" type="checkbox" id="select-all" checked={selectedFarmIds.length === farms.length} onChange={toggleSelectAll} />
                                                                    <span className="Checkbox-label"></span>
                                                                </label>
                                                            </th>
                                                        )}
                                                        <th>Farm</th>
                                                        <th>Subscription</th>
                                                        <th data-width="15" className="u-textRight">
                                                            Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {farms.map((farm) => {
                                                        const newStartDate = moment.max(moment(), moment(farm.expirationDate));
                                                        const newExpiryDate = moment(newStartDate).add(1, "years");
                                                        return (
                                                            <tr key={farm.id} className="hover">
                                                                {hasMultipleFarmsToChooseFrom && (
                                                                    <td valign="top">
                                                                        <label className="Checkbox" htmlFor={`select-farm-for-payment-${farm.id}`}>
                                                                            <input className="Checkbox-input" type="checkbox" id={`select-farm-for-payment-${farm.id}`} checked={selectedFarmIds.includes(farm.id)} onChange={toggleSelect(farm.id)} />
                                                                            <span className="Checkbox-label u-textError u-textBold"></span>
                                                                        </label>
                                                                    </td>
                                                                )}
                                                                <td onClick={toggleSelect(farm.id)} valign="top">
                                                                    <div className="u-textBold">{farm.name}</div>
                                                                    <div>{farm.address}</div>
                                                                </td>
                                                                <td onClick={toggleSelect(farm.id)} valign="top">
                                                                    <div className="u-textBold">1 Year</div>
                                                                    <div>
                                                                        {newStartDate.format("DD MMM YYYY")} - {newExpiryDate.format("DD MMM YYYY")}
                                                                    </div>
                                                                </td>
                                                                <td className="u-textRight" onClick={toggleSelect(farm.id)} valign="top">
                                                                    {numeral(unitPriceExclGst).format("$0,0.00")}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th className="u-textRight" colSpan={hasMultipleFarmsToChooseFrom ? 3 : 2}>
                                                            Sub total:
                                                        </th>
                                                        <th id="subTotal" className="u-textRight u-text-md">
                                                            {numeral(subTotal).format("$0,0.00")}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="u-textRight" colSpan={hasMultipleFarmsToChooseFrom ? 3 : 2}>
                                                            GST:
                                                        </th>
                                                        <th id="gst" className="u-textRight u-text-md">
                                                            {numeral(gst).format("$0,0.00")}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="u-textRight h3 u-text-md" colSpan={hasMultipleFarmsToChooseFrom ? 3 : 2}>
                                                            Total to pay:
                                                        </th>
                                                        <th id="total" className="u-textRight h3 u-text-md">
                                                            {numeral(total).format("$0,0.00")}
                                                        </th>
                                                    </tr>
                                                    {showOrgBalance && isPayingByInvoice && (
                                                        <>
                                                            <tr>
                                                                <th className="u-textRight" colSpan={hasMultipleFarmsToChooseFrom ? 3 : 2}>
                                                                    Available balance:
                                                                </th>
                                                                <th id="availableBalance" className="u-textRight">
                                                                    {numeral(availableBalance).format("$0,0.00")}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th className="u-textRight" colSpan={hasMultipleFarmsToChooseFrom ? 3 : 2}>
                                                                    Balance after this purchase:
                                                                </th>
                                                                <th id="remainingBalance" className="u-textRight">
                                                                    {numeral(remainingBalance).format("$0,0.00")}
                                                                </th>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </>
                                )}
                                {windowState === WINDOW_STATE.UseSavedCard && (
                                    <>
                                        <Alert type="Info" text="Please select a previously saved card or select 'No' to enter a new card. Saved cards can be managed on the saved cards screen." moreInfo={zendeskLink("4406329378713")} />
                                        <div className="h2 u-mb-md u-mt-md">Would you like to use a previously saved card?</div>
                                        <div>
                                            <Field name="selectSavedCard" options={SELECT_CARD_OPTIONS} inline={false} component={RadioGroupPack} onChange={selectSavedCardChanged} />
                                        </div>
                                        {values.selectSavedCard === "True" && (
                                            <div className="u-pt-lg">
                                                <div className="h2 u-pb-md">Saved credit cards</div>
                                                <CreditCards cards={savedCards} cardId={selectedCard && selectedCard.id} onCardSelected={(c) => setSeletedCard(c)} />
                                            </div>
                                        )}
                                    </>
                                )}
                                {windowState === WINDOW_STATE.SaveCard && (
                                    <>
                                        <Alert type="Warning" text="Card details will be securely held with Paystation, our 3rd party payment provider. Saving your card details will enable quicker payments and optionally give you the ability to auto renew subscriptions" moreInfo={zendeskLink("4406329378713")} />
                                        <div className="h2 u-mt-xl u-textCenter">Would you like to save your credit card details for next time?</div>
                                        <div className="u-flex u-flexJustifyCenter u-mt-lg">
                                            <div>
                                                <Field name="saveCardDetails" options={SAVE_CARD_OPTIONS} inline={false} component={RadioGroupPack} />
                                            </div>
                                        </div>
                                        <div className="u-flex u-flexJustifyCenter u-mt-lg">
                                            <PaystationInfo />
                                        </div>
                                    </>
                                )}
                                {windowState === WINDOW_STATE.PayByPaystation && (
                                    <>
                                        {payment && payment.paystationUrl ? (
                                            <iframe id="paymentFrame" src={`${payment.paystationUrl}&b=FFFFFF`} title="Paystation" className="paystation-payment-frame" />
                                        ) : (
                                            <div className="u-mt-xl">
                                                <LoaderSection message="Contacting our 3rd party payment provider..." dark />
                                            </div>
                                        )}
                                    </>
                                )}
                                {windowState === WINDOW_STATE.RecurringPayments && (
                                    <>
                                        {payment ? (
                                            <>
                                                <Alert type="success" bold="Thank you for your payment" text={payment.invoiceNumber ? `Your invoice is available under account details or by clicking the 'View invoice' button on the next screen.` : `The selected farm${selectedFarmIds.length > 1 ? "s have" : " has"} been added to your organisation's monthly invoice.`} moreInfo={zendeskLink("900002985586")} />
                                                <Alert type="info" text="Select the farms that you would like to auto renew their annual subscription in a years time with the payment method you just used. You can manage your auto renewal farms within the 'Billing' screen" />
                                                <div className="h2 u-mb-md u-mt-lg">Select the farms you would like to automatically renew.</div>
                                                <div className="Table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th className="th--shrink">
                                                                    <label className="Checkbox" htmlFor="select-all">
                                                                        <input className="Checkbox-input" type="checkbox" id="select-all" checked={selectedFarmIds.length === farms.length} onChange={toggleSelectAll} />
                                                                        <span className="Checkbox-label"></span>
                                                                    </label>
                                                                </th>
                                                                <th>Farm</th>
                                                                <th>Renewal date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {farms
                                                                .filter((f) => recurringBaseFarmIds.includes(f.id))
                                                                .map((farm) => {
                                                                    const newExpiryDate = farm.isUnpaid ? moment().add(1, "years") : moment(farm.expirationDate).add(1, "years");
                                                                    return (
                                                                        <tr key={farm.id} className="hover">
                                                                            <td valign="top">
                                                                                <label className="Checkbox" htmlFor={`select-farm-for-payment-${farm.id}`}>
                                                                                    <input className="Checkbox-input" type="checkbox" id={`select-farm-for-payment-${farm.id}`} checked={selectedFarmIds.includes(farm.id)} onChange={toggleSelect(farm.id)} />
                                                                                    <span className="Checkbox-label u-textError u-textBold"></span>
                                                                                </label>
                                                                            </td>
                                                                            <td onClick={toggleSelect(farm.id)} valign="top">
                                                                                <div className="u-textBold">{farm.name}</div>
                                                                                <div>{farm.address}</div>
                                                                            </td>
                                                                            <td onClick={toggleSelect(farm.id)} valign="top">
                                                                                <div className="u-textBold">1 Year</div>
                                                                                <div>{newExpiryDate.format("DD MMM YYYY")}</div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="u-mt-xl">
                                                <LoaderSection message="Contacting our 3rd party payment provider..." dark />
                                            </div>
                                        )}
                                    </>
                                )}
                                {windowState === WINDOW_STATE.PaymentResult && (
                                    <>
                                        {showPaymentSuccess && (
                                            <>
                                                <div className="Tile-body-message">
                                                    <i className="icon icon--md icon-tick-circle u-textSuccess" />
                                                    <h3>Thank you for your payment.</h3>
                                                    <div className="u-pl-lg u-mt-lg">
                                                        {payment.invoiceNumber && <div className="u-mt-sm">Your invoice is available under account details or by clicking the 'View invoice' button below.</div>}
                                                        {!payment.invoiceNumber && selectedFarmIds.length === 1 && <div className="u-mt-sm">The selected farm has been added to your organisation's monthly invoice.</div>}
                                                        {!payment.invoiceNumber && selectedFarmIds.length > 1 && <div className="u-mt-sm">The selected farms have been added to your organisation's monthly invoice.</div>}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {showPaymentError && (
                                            <div className="Tile-body-message">
                                                <i className="icon icon--md icon-alert u-textError" />
                                                <h2 className="u-textError">Payment failed.</h2>
                                                <div className="u-mt-sm">{payment.error}</div>
                                            </div>
                                        )}
                                        {showPaymentNotRequired && (
                                            <div className="Tile-body-message">
                                                <i className="icon icon--md icon-tick-circle u-textSuccess" />
                                                <h3>Payment not required.</h3>
                                                <div className="u-pl-lg u-mt-lg">
                                                    {selectedFarmIds.length === 1 && <div className="u-mt-sm">The subscription for the selected farm has already been paid for.</div>}
                                                    {selectedFarmIds.length > 1 && <div className="u-mt-sm">The subscriptions for one or more of the selected farms have already been paid for.</div>}
                                                </div>
                                            </div>
                                        )}
                                        {showPleaseTryAgain && (
                                            <div className="Tile-body-message">
                                                <i className="icon icon--md icon-tick-circle u-textError" />
                                                <h3 className="u-textError">Please try again soon.</h3>
                                                <div className="u-pl-lg u-mt-lg">
                                                    {selectedFarmIds.length === 1 && <div className="u-mt-sm">There is an existing payment in progress for the selected farm. Please try again soon.</div>}
                                                    {selectedFarmIds.length > 1 && <div className="u-mt-sm">The is an existing payment in progress for one or more of the selected farms. Please try again soon.</div>}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={onClose} secondary disabled={isSubmitting}>
                                        {showPaymentSuccess || showPaymentNotRequired ? "Close" : "Cancel"}
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {windowState === WINDOW_STATE.PaymentType && (
                                        <Button id="submit" submit primary disabled={paymentMethodIsRequired || total === 0 || isSubmitting || (isPayingByInvoice && availableBalance < total) || exceededPaystationMaxError}>
                                            {isPayingByInvoice ? `Invoice ${numeral(total).format("$0,0.00")}` : values.paymentMethod === PAYMENT_METHODS[2] ? `Pay ${numeral(total).format("$0,0.00")}` : "Next"}
                                        </Button>
                                    )}
                                    {(showPaymentError || showPleaseTryAgain) && (
                                        <Button id="retry" onClick={() => retryPayment()}>
                                            Retry
                                        </Button>
                                    )}
                                    {showViewInvoice && (
                                        <Link id="view-invoice-button" to={`/invoice/${payment.id}`} target="_blank" className="Button Button--secondary">
                                            View invoice
                                        </Link>
                                    )}
                                    {windowState === WINDOW_STATE.RecurringPayments && (
                                        <Button
                                            id="submit"
                                            submit
                                            primary
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                form.change("submittedBy", "showReocurringPayments");
                                            }}
                                        >
                                            Next
                                        </Button>
                                    )}
                                    {[WINDOW_STATE.SaveCard, WINDOW_STATE.UseSavedCard].includes(windowState) && (
                                        <Button id="submit" submit primary disabled={paymentMethodIsRequired || total === 0 || isSubmitting || (isPayingByInvoice && availableBalance < total) || exceededPaystationMaxError || (windowState === WINDOW_STATE.UseSavedCard && !selectedCard && values.selectSavedCard === "True")}>
                                            {values.selectSavedCard === "True" ? `Pay ${numeral(total).format("$0,0.00")}` : "Next"}
                                        </Button>
                                    )}
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function usePaymentModal() {
    const [modal, openModal] = useModal(PaymentModal);

    const openPaymentModal = (farms, onPaid) => {
        const modalProps = {
            farms,
            onPaid,
        };
        openModal(modalProps);
    };

    return [modal, openPaymentModal];
}

function PaystationInfo() {
    return (
        <div style={{ width: "620px" }}>
            <div className="u-flex">
                <div className="u-pr-lg u-pt-xl">
                    <img src={icons.paystation} style={{ width: "130px" }} alt="Paystation" />
                </div>
                <div>
                    <h2>Card security</h2>
                    <div>Your payment details have the security offered by Paystation, a fully PCI DSS compliant certified Solutions Provider. </div>
                    {false && (
                        <div>
                            <h3>PCI DSS</h3>
                            PCI-DSS (Payment Card Industry Data Security Standard) is a set of rules created by the{" "}
                            <a href="https://www.pcisecuritystandards.org/" target="_blank" rel="noreferrer">
                                Payment Card Industry Security Standards Council
                            </a>{" "}
                            to encourage the broad adoption of consistent data security measures around the world. These rules apply to anyone who is storing, processing or transmitting credit card data. We are externally audited every year by Confide to ensure the highest level of data protection available for our merchants.&nbsp;
                            <a href="https://prod-www-site-artifacts.s3.ap-southeast-2.amazonaws.com/public/Paystation-2020-Certificate-of-Compliance.pdf" target="_blank" rel="noreferrer">
                                View our PCI-DSS compliance certificate
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const WINDOW_STATE = {
    PaymentType: "PaymentType",
    UseSavedCard: "UseSavedCard",
    SaveCard: "SaveCard",
    PayByPaystation: "PayByPaystation",
    RecurringPayments: "RecurringPayments",
    PaymentResult: "PaymentResult",
};

const PAYMENT_METHODS = [
    { value: "Invoice", text: "Invoice my organisation" },
    { value: "CreditCard", text: "Credit card" },
    { value: "POLi", text: "Poli" },
];

const SAVE_CARD_OPTIONS = [
    { value: "True", text: `Yes, save my card details` },
    { value: "False", text: `No, don't save my card details` },
];

const SELECT_CARD_OPTIONS = [
    { value: "True", text: `Yes, use the card selected below` },
    { value: "False", text: `No, I want to enter a new card` },
];

const DECLINE_CODES = [
    {
        code: "TIME_OUT",
        reasons: ["Timed out at acquirer"],
        message: (
            <div className="u-pl-lg u-mt-lg">
                <div>We’re sorry, but your transaction has timed out. Please try again.</div>
                <div className="u-mt-md">
                    If you continue to experience issues, please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },

    {
        code: "AUTH_FAIL",
        reasons: ["Card authentication failed"],
        message: (
            <div className="u-pl-lg u-mt-lg">
                <div>Oops! It looks like there was an issue with the payment details provided. Please check your payment details and try again.</div>
                <div className="u-mt-md">
                    If you continue to experience issues, please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },
    {
        code: "BANK_DECLINE",
        reasons: ["Stolen card, pick up", "Pick up card (no fraud)", "Pick up card (fraud suspected)", "Refer to issuer", "Do not honour"],
        message: (
            <div className="u-pl-lg u-mt-lg">
                <div>We’re sorry, but your transaction was declined.</div>
                <div className="u-mt-md">
                    Please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },
    {
        code: "INVALID_CARD",
        reasons: ["Invalid card number"],
        message: (
            <div className="u-pl-lg u-mt-lg">
                <div>Oops! It looks like there was an issue with the payment details provided. Please check your payment details and try again.</div>
                <div className="u-mt-md">
                    If you continue to experience issues, please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },

    {
        code: "EXPIRED_CARD",
        reasons: ["Expired card"],
        message: (
            <div className="u-pl-lg u-mt-lg">
                <div>We’re sorry, but your transaction was declined due to an expired card.</div>
                <div className="u-mt-md">
                    Please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },
    {
        code: "INSUFFICIENT_FUNDS",
        reasons: ["Insufficient funds"],
        message: (
            <div className="u-textLeft u-pl-lg u-mt-lg">
                <div>Oops! Payment has been declined due to insufficient funds. Please check your payment method or try another one.</div>
                <div>
                    If you continue to experience issues, please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },
    {
        code: "COMMUNICATION_ERROR",
        reasons: ["Error communicating with bank"],
        message: (
            <div className="u-textLeft u-pl-lg u-mt-lg">
                <div>Oops! We encountered an error while processing your request. Please try again.</div>
                <div className="u-mt-md">
                    If you continue to experience issues, please contact your bank or reach out to our support team at <a href="mailto:helpdesk@overseer.org.nz">helpdesk@overseer.org.nz</a> for assistance.
                </div>
            </div>
        ),
    },
];
