import "containers/BudgetHome/Irrigation/IrrigatorMap.css";
import "components/Map/map.css";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import Spinner from "components/Spinner/Spinner";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import MapZoomButton from "components/Map/MapZoomButton";
import MapBlockLabels from "components/Map/MapBlockLabels";
import MapErrorLabels from "components/Map/MapErrorLabels";
import TestGeoJsonLoader from "components/Map/TestGeoJsonLoader";
import Button from "components/Button/Button";
import { useOnline } from "common/hooks";

const MIN_DRAWN_AREA = 0.1;
const MIN_BLOCK_INTERSECTION_PERCENT = 5;
const MAX_IRRIGATORS_PER_BLOCK = 2;

export default function IrrigatorMap({ farm, analysis, irrigator, onChange }) {
    const online = useOnline();
    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12 });
    const [geoJson, syncMapDataToGeoJson] = useAnalysisGeoJson(analysis);
    const [toggleDrawing, setToggleDrawing] = useState(false);

    const validateFeature = useCallback(
        (mapData, featureId) => {
            if (!featureId) return;

            mapData.toGeoJson((mapGeoJson) => {
                let error;

                // Run the rules that validate the feature that triggered this validation...
                const triggerFeature = mapGeoJson.features.find((f) => f.id === featureId);

                // RULE 1 - feature that triggered this validation must not overlap itself.
                if (triggerFeature) {
                    error = geoJsonUtils.selfIntersects(triggerFeature) ? "Must not overlap itself" : undefined;
                }

                // RULE 2 - feature that triggered this validation must not overlap other irrigator features.
                if (!error) {
                    const featuresForOtherIrrigators = mapGeoJson.features.filter((f) => f.properties.irrigatorId && f.properties.irrigatorId !== irrigator.id);
                    featuresForOtherIrrigators.forEach((featureForOtherIrrigator) => {
                        if (geoJsonUtils.intersects(triggerFeature, featureForOtherIrrigator)) error = "Must not overlap other drawn irrigated areas";
                    });
                }

                // RULE 3 - feature that triggered this validation must be at least a minimum size.
                if (!error) {
                    const drawnArea = geoJsonUtils.area(triggerFeature);
                    if (drawnArea < MIN_DRAWN_AREA) error = "Must be greater than 0.1 ha";
                }

                // Run the rules that validate all the features of the current irrigator...
                const allFeaturesForThisIrrigtor = mapGeoJson.features.filter((f) => f.properties.irrigatorId === irrigator.id);
                const unionedIrrigatorFeature = geoJsonUtils.union(allFeaturesForThisIrrigtor);

                if (!error) {
                    let intersectsWithAProductiveBlock = false;
                    analysis.blocks
                        .filter((b) => b.isProductive && b.type !== "ProductiveOutdoorPigs")
                        .forEach((block) => {
                            if (!error) {
                                const blockFeatures = mapGeoJson.features.filter((f) => f.properties.blockId === block.id);
                                if (blockFeatures.length > 0) {
                                    const unionedBlockFeature = geoJsonUtils.union(blockFeatures);
                                    const intersection = geoJsonUtils.intersection(unionedIrrigatorFeature, unionedBlockFeature);
                                    if (intersection) {
                                        intersectsWithAProductiveBlock = true;

                                        // RULE 4 - block must not have more than the max number of irrigators.
                                        const numberOfOtherIrrigatorsAlreadyOnThisBlock = (analysis.irrigators || [])
                                            .filter((irr) => irr.id !== irrigator.id)
                                            .reduce((count, irr) => {
                                                if (irr.applications.some((app) => app.blockIds.includes(block.id))) count++;
                                                return count;
                                            }, 0);
                                        if (numberOfOtherIrrigatorsAlreadyOnThisBlock >= MAX_IRRIGATORS_PER_BLOCK) {
                                            error = `The "${block.name}" block is already irrigated by the maximum number of irrigation systems`;
                                        }

                                        if (!error) {
                                            const drawnBlockArea = geoJsonUtils.area(unionedBlockFeature);
                                            const drawnIrrigatedArea = utils.round((intersection.area / drawnBlockArea) * block.areaInHectares, 1);
                                            const drawnIrrigatedPercentage = utils.round((drawnIrrigatedArea / block.areaInHectares) * 100, 1);

                                            // RULE 5 - drawn irrigator must intersect by a minimim percentage of any block it intersects.
                                            if (drawnIrrigatedPercentage < MIN_BLOCK_INTERSECTION_PERCENT) {
                                                error = "Must intersect at least 5% of any block it intersects";
                                            }

                                            // RULE 6 - a drawn irrigator must not cover so much of the block that any non-drawn irrigator that is also on
                                            //          the block no longer has at least 5% over coverage.
                                            if (!error) {
                                                const nonDrawnArea = (analysis.irrigators || []).reduce((result, irr) => {
                                                    if (!result) {
                                                        result = (irr.nonDrawnArea || []).find((nda) => nda.blockId === block.id);
                                                    }
                                                    return result;
                                                }, null);
                                                if (nonDrawnArea) {
                                                    const unIrrigatedArea = block.areaInHectares - drawnIrrigatedArea;
                                                    const nonDrawnIrrigatedArea = utils.round((unIrrigatedArea * nonDrawnArea.percentageOfNonDrawnArea) / 100, 1);
                                                    const nonDrawnIrrigatedPercentage = utils.round((nonDrawnIrrigatedArea / block.areaInHectares) * 100, 1);
                                                    if (nonDrawnIrrigatedPercentage < 5) {
                                                        error = `The "${block.name}" block has too much coverage.`;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        });

                    // RULE 7 - irrigator must intersect at least one productive block.
                    if (!error && !intersectsWithAProductiveBlock) {
                        error = "Must intersect at least one productive block";
                    }
                }

                mapData.getFeatureById(featureId).setProperty("error", error);
                syncMapDataToGeoJson(mapData);
            });
        },
        [analysis.blocks, analysis.irrigators, irrigator.id, syncMapDataToGeoJson]
    );

    const mapData = useMapData(map, geoJson);
    useMapStyle(mapData, irrigator.id);
    useDrawingManager(mapData, irrigator, validateFeature);
    useValidateOnLoad(mapData, irrigator.id, validateFeature);
    useOnPolygonHover(mapData, irrigator.id);
    useOnPolygonClick(mapData, irrigator.id);
    useOnPolygonAdd(mapData, validateFeature);
    useOnPolygonEdit(mapData, validateFeature);

    const mapError = !online && "Overseer is currently offline. Mapping is unavailable.";
    const mapLoading = online && !geoJson && !mapError;
    const isIrrigatorDrawn = geoJson && geoJson.features.some((f) => f.properties.irrigatorId === irrigator.id);
    const hasDrawnProductiveBlocks =
        geoJson &&
        geoJson.features
            .filter((f) => f.properties.blockId)
            .some((f) => {
                const block = analysis.blocks.find((b) => b.id === f.properties.blockId);
                if (!block) return false;
                return block.isProductive && block.type !== "ProductiveOutdoorPigs";
            });
    const showMap = !mapLoading && !mapError && (isIrrigatorDrawn || toggleDrawing);

    useEffect(() => {
        if (onChange && geoJson) {
            onChange(geoJson.features);
        }
    }, [onChange, geoJson]);

    if (!hasDrawnProductiveBlocks && !isIrrigatorDrawn) return null;

    return (
        <Panel title="Irrigation map" waiting={mapLoading} info={toggleDrawing ? "Draw the area that is irrigated by this irrigation system." : undefined} actions={showMap ? <IrrigationMapPanelActions mapData={mapData} onMapDataChange={syncMapDataToGeoJson} /> : undefined} className="u-mt-md" midBlue>
            <PanelBody>
                {mapLoading && (
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead u-text-lg u-mt-md">Loading map...</p>
                    </div>
                )}
                {!mapLoading && mapError && (
                    <div className="Tile-body-message u-textError">
                        <i className="icon icon--md icon-alert" />
                        <p className="lead">{mapError}</p>
                    </div>
                )}
                {geoJson && !showMap && (
                    <div className="Tile-body-message u-mt-md u-mb-md">
                        <p className="h4">Click to draw the irrigated area on a map of the farm</p>
                        <Button className="IconLink--search Button Button--secondary u-mt-md" id="show-map-button" onClick={() => setToggleDrawing((prevState) => !prevState)}>
                            Draw irrigation system on farm map
                        </Button>
                    </div>
                )}
                <div className={`irrigation_map ${showMap ? "" : "u-hidden"}`}>
                    <div className={`map ${showMap ? "" : "u-hidden"}`} ref={setMapRef} />
                </div>
                {showMap && (
                    <>
                        <MapBlockLabels mapData={mapData} mapId={mapId} geoJson={geoJson} blocks={analysis.blocks} />
                        <MapZoomButton mapData={mapData} mapId={mapId} where={(f) => f.properties.irrigatorId === irrigator.id} padding={100} />
                        <MapErrorLabels map={map} geoJson={geoJson} />
                        <TestGeoJsonLoader mapData={mapData} onFeatureAdded={validateFeature} />
                    </>
                )}
            </PanelBody>
        </Panel>
    );
}

function IrrigationMapPanelActions({ mapData, onMapDataChange }) {
    const deleteSelectedPolygons = () => {
        mapData.forEach((feature) => {
            if (feature.getProperty("selected")) {
                mapData.remove(feature);
            }
        });
        mapData.setDrawingMode(null);
        onMapDataChange(mapData);
    };

    return (
        <ul className="ActionsBar-links">
            <li>
                <ActionLink id="delete-selected-polygons" className="IconLink--trash u-textWhite" onClick={deleteSelectedPolygons}>
                    Delete selected areas
                </ActionLink>
            </li>
        </ul>
    );
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    const syncMapDataToGeoJson = (mapData) => mapData.toGeoJson((geoJson) => setGeoJson(geoJson));

    useEffect(() => {
        if (!geoJson) {
            const analysisGeoJson = { type: "FeatureCollection", features: [] };
            if (analysis.features && analysis.features.length > 0) {
                analysisGeoJson.features = analysis.features.reduce((results, feature) => {
                    // Flatten and hide unassigned land.
                    const flattenedFeatures = geoJsonUtils.flatten(feature, ["UnassignedLand"]);
                    return [...results, ...flattenedFeatures];
                }, []);
            }
            setGeoJson(analysisGeoJson);
        }
    }, [analysis.features, geoJson]);

    return [geoJson, syncMapDataToGeoJson];
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    return mapData;
}

function useMapStyle(mapData, irrigatorId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && irrigatorId && !runOnce) {
            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const selected = feature.getProperty("selected");
                const visible = feature.getProperty("visible");
                const error = feature.getProperty("error");
                const layer = feature.getProperty("layer") || "Irrigator";
                const layerColor = geoJsonUtils.getMapLayerColor(layer);

                const isCurrentIrrigator = feature.getProperty("irrigatorId") === irrigatorId;

                let zIndex = 0;
                if (hovered || selected) {
                    zIndex = 3;
                } else if (isCurrentIrrigator) {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                return {
                    strokeColor: error ? "red" : layerColor,
                    strokeWeight: hovered || error ? 4 : 3,
                    fillColor: isCurrentIrrigator ? layerColor : "white",
                    fillOpacity: 0.3,
                    editable: selected,
                    zIndex,
                    visible,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, irrigatorId, runOnce]);
}

function useDrawingManager(mapData, irrigator, onMapDataChange) {
    const google = useGoogleApi();
    const [drawingManager, setDrawingManager] = useState();

    useEffect(() => {
        if (google && mapData && onMapDataChange && irrigator && !drawingManager) {
            const layerColor = geoJsonUtils.getMapLayerColor("Irrigator");
            const style = {
                strokeColor: layerColor,
                strokeWeight: 4,
                fillColor: layerColor,
                fillOpacity: 0.3,
            };

            const dm = new google.maps.drawing.DrawingManager({
                drawingMode: irrigator.isNew ? google.maps.drawing.OverlayType.POLYGON : undefined,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.CIRCLE],
                },
                circleOptions: style,
                polygonOptions: style,
                map: mapData.getMap(),
            });

            // Converts google.maps.Circle to google.maps.Data.Feature
            google.maps.event.addListener(dm, "circlecomplete", function (circle) {
                const center = circle.getCenter();
                const radius = circle.getRadius();
                circle.setMap(null);

                const circleGeoJsonFeature = geoJsonUtils.circle(center.lat(), center.lng(), radius);
                circleGeoJsonFeature.id = uuidv4();
                circleGeoJsonFeature.properties.layer = "Irrigator";
                circleGeoJsonFeature.properties.irrigatorId = irrigator.id;
                circleGeoJsonFeature.properties.selected = true;
                circleGeoJsonFeature.properties.visible = true;
                mapData.addGeoJson(circleGeoJsonFeature);
                onMapDataChange(mapData, circleGeoJsonFeature.id);
            });

            // Converts google.maps.Polygon to google.maps.Data.Feature
            google.maps.event.addListener(dm, "polygoncomplete", function (polygon) {
                const points = polygon.getPath().getArray();
                polygon.setMap(null);

                const feature = new google.maps.Data.Feature({
                    id: uuidv4(),
                    properties: {
                        layer: "Irrigator",
                        irrigatorId: irrigator.id,
                        selected: true,
                        visible: true,
                    },
                    geometry: new google.maps.Data.Polygon([points]),
                });
                mapData.add(feature);
                onMapDataChange(mapData, feature.id);
            });

            setDrawingManager(dm);
        }
    }, [google, mapData, onMapDataChange, irrigator, drawingManager]);
}

function useValidateOnLoad(mapData, irrigatorId, validateFeature) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && irrigatorId && validateFeature && !runOnce) {
            mapData.forEach((mapFeature) => {
                if (mapFeature.getProperty("irrigatorId") === irrigatorId) {
                    validateFeature(mapData, mapFeature.getId());
                }
            });
            setRunOnce(true);
        }
    }, [mapData, irrigatorId, validateFeature, runOnce]);
}

function useOnPolygonHover(mapData, irrigatorId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && irrigatorId && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("irrigatorId") === irrigatorId) {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("irrigatorId") === irrigatorId) {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, irrigatorId, runOnce]);
}

function useOnPolygonClick(mapData, irrigatorId) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && irrigatorId && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                if (feature.getProperty("irrigatorId") === irrigatorId) {
                    feature.setProperty("selected", !feature.getProperty("selected"));
                }
            });
            setRunOnce(true);
        }
    }, [mapData, irrigatorId, runOnce]);
}

function useOnPolygonAdd(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("addfeature", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    onMapDataChange(mapData, geoJsonFeature.id);

                    // De-select all other features.
                    mapData.forEach((mapFeature) => {
                        if (mapFeature.getId() !== feature.getId()) {
                            mapFeature.setProperty("selected", false);
                        }
                    });
                });
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}

function useOnPolygonEdit(mapData, onMapDataChange) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onMapDataChange && !runOnce) {
            mapData.addListener("setgeometry", ({ feature }) => {
                feature.toGeoJson((geoJsonFeature) => {
                    const drawnArea = geoJsonUtils.area(geoJsonFeature);
                    feature.setProperty("drawnArea", drawnArea);
                    onMapDataChange(mapData, geoJsonFeature.id);
                });
            });
            setRunOnce(true);
        }
    }, [mapData, onMapDataChange, runOnce]);
}
