import { useRef, useState } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import Alert from "components/Alert";
import DownloadFarms from "components/DownloadFarms";
import { GridCell } from "components/Grid";
import { useAuthContext } from "common/hooks";

export default function BarChart({ className, data = {}, options, alert, height = "auto", icon, legend = false, farmLookup, children }) {
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const chartTopRef = useRef();
    const [dataSection, setDataSection] =useState();
    const { datasets = [] } = data;

    const chartClicked = (event) => {
        if (!isSystemAdmin || datasets.length < 1) return;
        const chartElement = getElementAtEvent(chartTopRef.current, event);
        if (!chartElement || !Array.isArray(chartElement) || chartElement.length !== 1 || isNaN(chartElement[0].index)) return;
        const { farmIds = [] } = datasets[0];
        const { labels = [] } = data;
        const label = labels[chartElement[0].index];
        setDataSection({ farmIds: farmIds[chartElement[0].index], label });
    };

    return (
        <>
            {dataSection && <DownloadFarms close={() => setDataSection(undefined)} farmIds={dataSection.farmIds} farmLookup={farmLookup} fileName={`Distributions-${data.title}.csv`} />}
            <GridCell className={`${className} FarmDashboard-cell`}>
                <div className="Widget" style={{ height }}>
                    <div className="Widget-header">
                        <div className={`Widget-title-nolink ${icon}`}>
                            <span>{data.title}</span>
                            {data.filteredDataItem && <sup>*</sup>}
                        </div>
                    </div>
                    {alert && <Alert type="error" text={alert} />}
                    <div className="Widget-content">
                        <Bar data={data} height={100} legend={{ display: legend }} options={options} onClick={farmLookup ? chartClicked : undefined} ref={chartTopRef} />
                        {children}
                        {data.filteredDataItem && data.filteredDataItem.frequency > 0 && (
                            <div>
                                <sub>{`* There are ${data.filteredDataItem.frequency.toLocaleString()} farms with ${data.filteredDataItem.from}-${data.filteredDataItem.to} ${data.units} that are not shown on the chart`}</sub>
                            </div>
                        )}
                    </div>
                </div>
            </GridCell>
        </>
    );
};
