import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { valueToText } from "common/utils";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import ConfirmationField from "components/FormFields/ConfirmationField";
import AnalysisComparisonById from "../AnalysisComparisonById";
import { getAnalysisOptions } from "../_utils";
import ZendeskLink from "components/Help/ZendeskLink";
import { useAnalysis, useReplaceAnalysisAsync } from "containers/hooks";
import { useRefData, useModal } from "common/hooks";

export default function ReplaceAnalysisModal({ farm, analysisId, close}) {
    const { data: analysis } = useAnalysis(farm.id, analysisId);
    const replaceAnalysisAsync = useReplaceAnalysisAsync();
    const refData = useRefData();

    const handleSourceIdChange = (form) => () => {
        form.change("confirmationText", null);
    };

    const validate = (formValues) => {
        const validation = {};
        validation.sourceId = FormUtils.validators.required(formValues.sourceId);
        return validation;
    }

    const submitAsync = async (viewModel) => {
        const submitResult = await replaceAnalysisAsync(farm.id, viewModel.targetId, viewModel.sourceId)
            .then(close)
            .catch((ex) => ({ [FORM_ERROR]: ex.message }));
        return submitResult;
    }

    return (
        <Form initialValues={{ targetId: analysisId }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = (values && values.error) || (!dirtySinceLastSubmit && submitError);

                const targetOptions = getAnalysisOptions(farm, [values.sourceId]);
                const targetOption = targetOptions.find((o) => o.value === values.targetId);
                const targetIdLabel = !targetOption ? "Replace" : targetOption.type === "Publication" ? "Replace publication" : `Replace ${valueToText(refData.budgetTypes, targetOption.type)} analysis`;

                const sourceOptions = getAnalysisOptions(farm, [values.targetId]);
                const sourceOption = sourceOptions.find((o) => o.value === values.sourceId);
                const sourceIdLabel = !sourceOption ? "With" : sourceOption.type === "Publication" ? "With publication" : `With ${valueToText(refData.budgetTypes, sourceOption.type)} analysis`;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Replace year end analysis" close={close} submitting={submitting} wide>
                            <ModalBody loading={!analysis} error={error}>
                                <div className="Alert--warning u-textCenter u-p-20 u-pt-sm" style={{ margin: "0px -20px" }}>
                                    <i className="icon icon-alert icon--lg icon--confirm" />
                                    <h2>
                                        You are about to completely replace <b>{targetOption.text}</b> with the details from another analysis
                                    </h2>
                                    <p>Use this screen to review the impact of these changes before replacing</p>
                                    <p>
                                        <ZendeskLink title="Click here for more information on replacing year end analyses" url="https://support.overseer.org.nz/hc/en-us/articles/900000894266" rel="noopener noreferrer" target="_blank" />
                                    </p>
                                </div>
                                <Grid>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="targetId" label={targetIdLabel} warning="This analysis will be replaced" options={targetOptions} readOnly component={SelectField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of2">
                                        <Field name="sourceId" label={sourceIdLabel} placeholder="Select an analysis" onChange={handleSourceIdChange(form)} info="The details from this analysis will replace the year end" options={sourceOptions} required component={SelectField} />
                                    </GridCell>
                                </Grid>
                                {values.sourceId && <AnalysisComparisonById title="Impact of replacing this analysis" useBeforeAfter farm={farm} fromId={values.targetId} toId={values.sourceId} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Field name="confirmed" confirmationPhrase={analysis?.name} component={ConfirmationField} />
                                    <Button id="submit" submit primary waiting={submitting} disabled={!values.confirmed}>
                                        Replace
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function useReplaceAnalysisModal(farm, analysisId) {
    const [modal, openModal] = useModal(ReplaceAnalysisModal);

    const openReplaceAnalysisModal = () => {
        const modalProps = {
            farm,
            analysisId,
        };
        openModal(modalProps);
    };

    return [modal, openReplaceAnalysisModal];
}
