import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import Invoice from "containers/Invoice/Invoice";
import { ConfirmProvider } from "components/Confirm";
import SecuredLayout from "layouts/Secured";
import PublicLayout from "layouts/Public";
import "index.css";
import "akuaku.css";
import "_variables.css";
import { AkuakuProvider } from "common/AkuakuContext";
import TagManager from "react-gtm-module";

import Body from "./Body";

const tagManagerArgs = {
    gtmId: "GTM-P9VHWN4",
    dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

export default function App(props) {
    return (
        <AkuakuProvider>
            <ConnectedRouter history={props.history}>
                <ConfirmProvider>
                    <Body>
                        <Route path="/pub" component={PublicLayout} />
                        <Route exact path="/invoice/:paymentId" component={Invoice} />
                        <Route path="/app" render={(p) => <SecuredLayout persistStore={props.persistStore} />} />
                        <Route exact path="/" render={(p) => <SecuredLayout persistStore={props.persistStore} />} />
                    </Body>
                </ConfirmProvider>
            </ConnectedRouter>
        </AkuakuProvider>
    )
}
