import { Fragment, useState } from "react";
import * as utils from "common/utils";
import * as domain from "common/domain";
import Watermark from "components/Watermark";
import ActionLink from "components/ActionLink";
import { EnterpriseNameWithIcon } from "components/EnterpriseNameWithIcon";
import { useAnalysisResults } from "containers/BudgetHome";

export default function StockNumbers({ analysis, enterpriseId, showMobs }) {
    const stockNumbersByEnterprise = useStockNumbersByEnterprise(analysis, enterpriseId);
    const [toggleMobs, setToggleMobs] = useState(showMobs);
    const showAllEnterprises = !enterpriseId;
    const showSingleEnterprise = !showAllEnterprises;
    const expandMobs = toggleMobs || showMobs;

    return (
        <>
            <div className={`Table ${showAllEnterprises ? "Table-Compressed" : ""}`}>
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="20">{showSingleEnterprise ? "Stock numbers" : "Name"}</th>
                            {domain.farmYear.map((month) => (
                                <th key={month} data-width="5" className="numeric">
                                    {utils.month.format(month)}
                                </th>
                            ))}
                            {showSingleEnterprise && <th data-width="5">&nbsp;</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {stockNumbersByEnterprise?.map((enterpriseNumbers) => {
                            const enterprise = analysis.enterprises.find((e) => e.id === enterpriseNumbers.id);
                            const enterpriseKey = `stock_numbers_${enterprise.name}`;
                            const hasMonthlyStockCounts = enterpriseNumbers?.byMonth?.length > 0;
                            return (
                                <Fragment key={enterpriseKey}>
                                    {hasMonthlyStockCounts
                                        ? <>
                                            <tr>
                                                <td>
                                                    {showAllEnterprises
                                                        ? <EnterpriseNameWithIcon enterprise={enterprise} />
                                                        : (
                                                            <ActionLink className="u-flexSplit" onClick={() => setToggleMobs((prevState) => !prevState)}>
                                                                <EnterpriseNameWithIcon enterprise={enterprise} />
                                                                <i className={`icon icon-arrow-${toggleMobs ? "up" : "down"}`}></i>
                                                            </ActionLink>
                                                        )
                                                    }
                                                </td>
                                                {enterpriseNumbers.byMonth.map((m) => {
                                                    const monthKey = `${enterpriseKey}_${m.month}`;
                                                    return (
                                                        <td key={monthKey} id={monthKey} className="numeric">
                                                            {utils.numeric.format(m.count)}
                                                        </td>
                                                    )
                                                })}
                                                {showSingleEnterprise && <td></td>}
                                            </tr>
                                            {expandMobs && enterpriseNumbers.byMob.map((mobNumbers) => {
                                                const mob = enterprise.mobs.find((m) => m.id === mobNumbers.id);
                                                const mobKey = `${enterpriseKey}_${mob.name}`;
                                                const mobAge = getMobAge(mob);
                                                const mobStartWeight = getMobStartWeight(mob);
                                                const mobMedianCalvingDate = getMobMedianCalvingDate(mob);
                                                const mobDryingOffDate = getMobDryingOffDate(mob);
                                                return (
                                                    <tr key={mobKey} className="tr-nested">
                                                        <td>
                                                            {mob.name}
                                                            <div>
                                                                <span className="Table-subtitle"><strong>Class:</strong> {mobNumbers.stockClass}</span>
                                                                <span className="Table-subtitle"> | <strong>Breed:</strong> {mobNumbers.breed}</span>
                                                                {mobAge && <span className="Table-subtitle"> | <strong>Age:</strong> {mobAge}</span>}
                                                                {mobStartWeight && <span className="Table-subtitle"> | <strong>Start weight:</strong> {mobStartWeight}</span>}
                                                                {mobMedianCalvingDate && <span className="Table-subtitle"> | <strong>Median calving date:</strong> {mobMedianCalvingDate}</span>}
                                                                {mobDryingOffDate && <span className="Table-subtitle"> | <strong>Drying off date:</strong> {mobDryingOffDate}</span>}
                                                            </div>
                                                        </td>
                                                        {mobNumbers.byMonth.map((m) => {
                                                            const monthKey = `${mobKey}_${m.month}`;
                                                            return (
                                                                <td key={monthKey} id={monthKey} className="numeric">
                                                                    {utils.numeric.format(m.count)}
                                                                </td>
                                                            )
                                                        })}
                                                        {showSingleEnterprise && <td></td>}
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        : <>
                                            <tr>
                                                <td>
                                                    <EnterpriseNameWithIcon enterprise={enterprise} />
                                                </td>
                                                <td colSpan="12">
                                                    <span className="inline-key u-ml-sm">{enterpriseNumbers.numberingMethod}: </span>
                                                    {enterpriseNumbers.totalRevisedStockUnits?.value > 0 && <span className="inline-value">{enterpriseNumbers.totalRevisedStockUnits?.value}</span>}
                                                    {enterpriseNumbers.count > 0 && <span className="inline-value">{enterpriseNumbers.count}</span>}
                                                </td>
                                                {showSingleEnterprise && <td></td>}
                                            </tr>
                                        </>
                                    }
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

function useStockNumbersByEnterprise(analysis, enterpriseId) {
    const { data: analysisResults } = useAnalysisResults(analysis);

    // This component supports displaying stock numbers for a single enterprise or all enterprises
    const enterprises = enterpriseId
        ? analysisResults?.animals?.stockNumbers?.byEnterprise?.filter((enterprise) => enterprise.id === enterpriseId)
        : analysisResults?.animals?.stockNumbers?.byEnterprise;

    return enterprises;
}

function getMobAge(mob) {
    const startEvent = mob.events?.find((e) => e.eventType === "Starting" || e.month === "July");
    if (startEvent?.ageInMonths) {
        return `${startEvent.ageInMonths} months`
    }
}

function getMobStartWeight(mob) {
    const weightEvent = mob.events?.find((e) => e.eventType === "Starting") || mob.events?.find((e) => e.eventType === "Purchase" && e.weightKg > 0);
    if (weightEvent?.weightKg) {
        return `${weightEvent.weightKg} kg`;
    }
}

function getMobMedianCalvingDate(mob) {
    if (mob.calvingDetails) {
        return `${mob.calvingDetails.midCalvingDay} ${mob.calvingDetails.midCalvingMonth}`;
    }
}

function getMobDryingOffDate(mob) {
    if (mob.calvingDetails) {
        return `${mob.calvingDetails.dryDay} ${mob.calvingDetails.dryMonth}`;
    }
}
