import { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { reduxForm } from "redux-form";
import { useParams, Link } from "react-router-dom";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { Grid, GridCell } from "components/Grid";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import SavePrompt from "components/SavePrompt";
import Alert from "components/Alert";
import * as _utils from "./_utils";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { supplementUtils } from "containers/BudgetHome/Supplements";
import { useRefData, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { useSupplementDistributionModal } from "./DistributionModal";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function Distribution({ farm, analysis }) {
    const { feedSupplementId } = useParams();
    const feedSupplement = analysis.feedSupplements?.find((fs) => fs.id === feedSupplementId);

    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const [supplementDistributionModal, openSupplementDistributionModal] = useSupplementDistributionModal();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    if (!feedSupplement) {
        return null;
    }

    return (
        <>
            <DistributionForm farm={farm} analysis={analysis} feedSupplement={feedSupplement} openSupplementDistributionModal={openSupplementDistributionModal} refData={refData} showQuickTips={showQuickTips} updateAnalysisAsync={updateAnalysisAsync} />
            {supplementDistributionModal}
        </>
    )
}

class DistributionClassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedSupplement: props.feedSupplement,
            distributionsChanged: false,
            submitSucceeded: false,
        };
    }

    _save = async () => {
        const updatedAnalysis = supplementUtils.getUpdatedAnalysisFromSavingSupplement(this.props.analysis, this.state.feedSupplement);
        await this.props.updateAnalysisAsync(updatedAnalysis);
        this.setState({ submitSucceeded: true });
    };

    _hasFullyDistributedSpecifiedFeeding = (feedSupplement) => {
        const specifiedBlockDestinations = (feedSupplement.destinations || []).filter((d) => d.type === "SpecifiedBlocks");
        const isFullyDistributed =
            specifiedBlockDestinations.length === 0 ||
            specifiedBlockDestinations.some((destination) => {
                const totalMonths = _utils.getSpecifiedFeedingTotals(destination.applications || []);
                return totalMonths.total === 0 || totalMonths.total === 100;
            });
        return isFullyDistributed;
    };

    render() {
        const { farm, analysis, refData, handleSubmit, submitting, submitDisabled, dirty, showQuickTips } = this.props;
        const _referrer = `/app/farm/${farm.id}/analysis/${analysis.id}/supplements`;
        const _amountTypeMeta = _utils.getAmountTypeMeta(this.state.feedSupplement.amountType);
        const _isHarvested = this.state.feedSupplement.type === "Harvested";
        const _icon = utils.getSupplementIcon(this.state.feedSupplement);
        const _category = utils.valueToText(refData.supplementCategories, this.state.feedSupplement.category);
        const _sourcedFrom = _utils.getSourcedFrom(analysis, this.state.feedSupplement, refData);
        const _amounts = _utils.getAmounts(analysis, this.state.feedSupplement);
        const _specifiedFeedingRemaining = !this._hasFullyDistributedSpecifiedFeeding(this.state.feedSupplement);
        const _showOffFarm = this.state.feedSupplement.type === "Harvested" && !(this.state.feedSupplement.destinations || []).some((destination) => destination.type === "OffFarm");
        const _showStorage = this.state.feedSupplement.type === "Harvested" && this.state.feedSupplement.category !== "DirectFeeding" && !(this.state.feedSupplement.destinations || []).some((destination) => destination.type === "Storage");
        const _showStructures = _utils.getAvailableDistributionStructures(analysis, this.state.feedSupplement, null, refData).length > 0;
        const _showEnterprises = _utils.getAvailableDistributionEnterprises(analysis, this.state.feedSupplement, null, refData).length > 0;
        const _showBlocks = _utils.getAvailableDistributionBlocks(analysis).length > 0 && !(this.state.feedSupplement.destinations || []).some((destination) => ["SpecifiedBlocks", "AllBlocks"].includes(destination.type));
        const _hasDistributions = this.state.feedSupplement.destinations && this.state.feedSupplement.destinations.length > 0;

        const _onDistributed = (destination) => {
            const destinations = (this.state.feedSupplement.destinations || []).map((d) => {
                return d.id !== destination.id ? d : destination;
            });
            if (!destinations.some((d) => d.id === destination.id)) destinations.push(destination);

            const feedSupplement = {
                ...this.state.feedSupplement,
                destinations: destinations,
            };
            this.setState({ feedSupplement, distributionsChanged: destination.modalWasDirty });
        };

        const _launchCreateModal = (type) => (e) => {
            e.preventDefault();
            const viewModel = {
                id: uuidv4(),
                feedSupplement: this.state.feedSupplement,
                type: type,
                storageCondition: this.state.feedSupplement.category === "DirectFeeding" ? "Nostorage" : "Average",
                amounts: _amounts,
                blockIds: [],
                applications: []
            };
            this.props.openSupplementDistributionModal(analysis, viewModel, _onDistributed);
        };

        const _launchEditModal = (destination) => (e) => {
            const applications = utils.clone(destination.applications || []);
            if (destination.type === "AllBlocks" && applications.length === 0) {
                applications.push({ months: {} });
            }

            const blockIds = applications.reduce((results, app) => {
                if (app.blockId) results.push(app.blockId);
                return results;
            }, []);

            const viewModel = {
                ...destination,
                feedSupplement: this.state.feedSupplement,
                specifyFeeding: applications.some((a) => a.months && Object.keys(a.months).length > 0),
                amounts: _amounts,
                applications,
                blockIds,
                change: this.props.change
            };

            if (destination.type === "OffFarm" && viewModel.feedSupplement.silageStack && viewModel.storageCondition === "Nostorage") {
                delete viewModel.storageCondition;
            }

            this.props.openSupplementDistributionModal(analysis, viewModel, _onDistributed);
        };

        const _deleteDistribution = (id) => (e) => {
            const destinations = (this.state.feedSupplement.destinations || []).filter((d) => d.id !== id);
            const feedSupplement = {
                ...this.state.feedSupplement,
                destinations: destinations,
            };
            this.setState({ feedSupplement, distributionsChanged: true });
        };

        const _distributionActionLinks = (
            <div className="u-flex">
                {_showOffFarm && (
                    <span id="distribute-off-farm-action" onClick={_launchCreateModal("OffFarm")} className={`IconLink--arrow-plus u-link ${_amounts.totalRemaining <= 0 ? "is-disabled" : ""}`}>
                        Off farm
                    </span>
                )}
                {_showStorage && (
                    <span id="distribute-to-storage-action" onClick={_launchCreateModal("Storage")} className={`IconLink--arrow-plus u-link u-ml-sm ${_amounts.totalRemaining <= 0 ? "is-disabled" : ""}`}>
                        Storage
                    </span>
                )}
                {_showStructures && (
                    <span id="distribute-to-structure-action" onClick={_launchCreateModal("Structure")} className={`IconLink--arrow-plus u-link u-ml-sm ${_amounts.totalRemaining <= 0 ? "is-disabled" : ""}`}>
                        Structure(s)
                    </span>
                )}
                {_showEnterprises && (
                    <span id="distribute-to-animals-action" onClick={_launchCreateModal("Enterprise")} className={`IconLink--arrow-plus u-link u-ml-sm ${_amounts.totalRemaining <= 0 ? "is-disabled" : ""}`}>
                        Animals
                    </span>
                )}
                {_showBlocks && (
                    <span id="distribute-to-blocks-action" onClick={_launchCreateModal("AllBlocks")} className={`IconLink--arrow-plus u-link u-ml-sm ${_amounts.totalRemaining <= 0 ? "is-disabled" : ""}`}>
                        Blocks
                    </span>
                )}
            </div>
        );

        return (
            <>
                <form onSubmit={handleSubmit(this._save)}>
                    <SavePrompt blockIf={(dirty || this.state.distributionsChanged) && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                    <Tile title="Supplement distribution" waiting={submitting} referrer={_referrer}>
                        {showQuickTips && <Alert className="u-mb-0" type="help" text="If supplements are sent to storage, it is the user's responsibility to ensure that the same amounts are fed out over time (Supplements imported page). OVERSEER does not do this automatically." bold="Note" />}
                        <TileBody waiting={submitting}>
                            <Grid title="Supplement details">
                                <GridCell>
                                    <div className="Table u-mt-md">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Supplement</th>
                                                    <th>Source</th>
                                                    <th className="u-textCenter">Sourced</th>
                                                    <th className="u-textCenter">Distributed</th>
                                                    <th className="u-textCenter">Remaining</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="u-flex u-flexAlignItemsCenter">
                                                            <img className="u-mr-xs u-p-5" src={_icon} width="35" height="35" alt={`${_category} icon`} />
                                                            <span id={`supplement-category-${this.state.feedSupplement.id}`}>{_category}</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${_isHarvested ? "harvested" : "imported"}/${this.state.feedSupplement.id}`} className="u-flex u-flexAlignItemsCenter" id={`edit-supplement-${this.state.feedSupplement.id}`}>
                                                            <span>
                                                                <b>{`${_isHarvested ? "Harvested:" : "Imported:"}`}</b> {_sourcedFrom.join(", ")}
                                                            </span>
                                                        </Link>
                                                    </td>
                                                    <td className="u-textCenter">
                                                        <span id={`supplement-sourced-${this.state.feedSupplement.id}`}>{`${_amounts.totalSourced} ${_amountTypeMeta.uom}`}</span>
                                                    </td>
                                                    <td className="u-textCenter">
                                                        <span id={`supplement-distributed-${this.state.feedSupplement.id}`}>{`${_amounts.totalDistributed} ${_amountTypeMeta.uom}`}</span>
                                                    </td>
                                                    <td className="u-textCenter">
                                                        <h3 id={`supplement-remaining-${this.state.feedSupplement.id}`} className={`u-mt-0 ${_amounts.totalRemaining === 0 ? "u-textSuccess" : "u-textError"}`}>
                                                            {_amounts.totalRemaining} {_amountTypeMeta.uom}
                                                        </h3>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </GridCell>
                                <GridCell>
                                    <Tile title="Distribution" actions={_distributionActionLinks} padTop tertiary>
                                        <Alert type="info" text="Select the destination for these supplements. You may distribute to multiple destinations. The total amount distributed must equal the total available." />
                                        <TileBody>
                                            <div className="Table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th data-width="md-20 xl-20">Destination</th>
                                                            <th data-width="md-10 xl-10">Amount</th>
                                                            <th className="Calendar-month">Jul</th>
                                                            <th className="Calendar-month">Aug</th>
                                                            <th className="Calendar-month">Sep</th>
                                                            <th className="Calendar-month">Oct</th>
                                                            <th className="Calendar-month">Nov</th>
                                                            <th className="Calendar-month">Dec</th>
                                                            <th className="Calendar-month">Jan</th>
                                                            <th className="Calendar-month">Feb</th>
                                                            <th className="Calendar-month">Mar</th>
                                                            <th className="Calendar-month">Apr</th>
                                                            <th className="Calendar-month">May</th>
                                                            <th className="Calendar-month">Jun</th>
                                                            {_hasDistributions && <th className="th--shrink">&nbsp;</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!_hasDistributions && (
                                                            <tr>
                                                                <td colSpan="100%">
                                                                    <div className="Tile-body">
                                                                        <div className="Tile-body-message">
                                                                            <p className="h4 u-mt-0">Please select a destination for these supplements. You can distribute to more then one destination.</p>
                                                                            {_isHarvested && (
                                                                                <Button id="distribute-off-farm-button" onClick={_launchCreateModal("OffFarm")} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                                                    Off farm
                                                                                </Button>
                                                                            )}
                                                                            {_showStorage && (
                                                                                <Button id="distribute-to-storage-button" onClick={_launchCreateModal("Storage")} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                                                    Storage
                                                                                </Button>
                                                                            )}
                                                                            {_showStructures && (
                                                                                <Button id="distribute-to-structure-button" onClick={_launchCreateModal("Structure")} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                                                    Structure(s)
                                                                                </Button>
                                                                            )}
                                                                            {_showEnterprises && (
                                                                                <Button id="distribute-to-animals-button" onClick={_launchCreateModal("Enterprise")} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                                                    Animals
                                                                                </Button>
                                                                            )}
                                                                            {_showBlocks && (
                                                                                <Button id="distribute-to-blocks-button" onClick={_launchCreateModal("AllBlocks")} className="IconLink--arrow-plus Button Button--secondary u-mt-md">
                                                                                    Blocks
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {_hasDistributions &&
                                                            this.state.feedSupplement.destinations.map((destination) => {
                                                                const category = utils.valueToText(refData.supplementCategories, this.state.feedSupplement.category);
                                                                const destinationIcon = _utils.getDestinationIcon(analysis, destination);
                                                                const title = _utils.getDestinationTitle(analysis, destination, refData);
                                                                const usesMonths = ["SpecifiedBlocks", "AllBlocks", "Enterprise"].includes(destination.type);
                                                                const months = (destination.applications || []).reduce((months, app) => {
                                                                    Object.keys(app.months || {}).forEach((month) => {
                                                                        let amount = app.months[month];
                                                                        if (amount > 0) {
                                                                            const existingMonth = months.find((i) => i.month === month);
                                                                            if (existingMonth) {
                                                                                existingMonth.amount += amount;
                                                                            } else {
                                                                                months.push({ month, amount });
                                                                            }
                                                                        }
                                                                    });
                                                                    return months;
                                                                }, []);
                                                                const totalDistributed = (destination.amount || 0) + (destination.amountAutumn || 0);

                                                                return (
                                                                    <tr key={destination.id}>
                                                                        <td>
                                                                            <ActionLink id={`edit-destination-${destination.id}`} onClick={_launchEditModal(destination)} className="u-flex u-flexAlignItemsCenter">
                                                                                <img className="u-mr-xs u-p-5" src={destinationIcon} width="35" height="35" alt={`${category} icon`} />
                                                                                <span>{title}</span>
                                                                                <span className="icon icon-edit u-ml-auto"></span>
                                                                            </ActionLink>
                                                                        </td>
                                                                        <td>{`${totalDistributed} ${_amountTypeMeta.uom}`}</td>
                                                                        {months.length === 0 && (
                                                                            <td colSpan="12">
                                                                                {usesMonths && (
                                                                                    <span className="u-flex u-flexAlignItemsCenter">
                                                                                        <img className="u-p-5" src={_icon} width="45" height="45" alt={_category} />
                                                                                        <span>Monthly feeding % not specified</span>
                                                                                    </span>
                                                                                )}
                                                                                {!usesMonths && <span>n/a</span>}
                                                                            </td>
                                                                        )}
                                                                        {months.length > 0 &&
                                                                            domain.farmYear.map((month) => {
                                                                                const distributedMonth = months.find((m) => m.month === month);
                                                                                if (distributedMonth) {
                                                                                    return (
                                                                                        <td key={month} className="Calendar-slot">
                                                                                            <img src={_icon} className="Calendar-icon u-p-xxs" width="45" height="45" alt={_category} />
                                                                                            <span className="Calendar-icon-text">{`${distributedMonth.amount} %`}</span>
                                                                                        </td>
                                                                                    );
                                                                                }

                                                                                return <td key={month}></td>;
                                                                            })}
                                                                        <td>
                                                                            <ActionLink id={`delete-destination-${destination.id}`} onClick={_deleteDistribution(destination.id)} className="IconLink--trash" title="Delete" />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                    {_hasDistributions && (
                                                        <tfoot>
                                                            <tr>
                                                                <th>
                                                                    <span className="u-floatRight">
                                                                        <h4 className="u-mt-0">Total distributed</h4>
                                                                    </span>
                                                                </th>
                                                                <th>
                                                                    <h4 className={`u-mt-0 ${_amounts.totalRemaining === 0 ? "u-textSuccess" : ""}`}>
                                                                        {_amounts.totalDistributed} {_amountTypeMeta.uom}
                                                                    </h4>
                                                                </th>
                                                                <th colSpan="12"></th>
                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    )}
                                                </table>
                                            </div>
                                        </TileBody>
                                    </Tile>
                                    {_hasDistributions && _amounts.totalRemaining > 0 && <div className="Field-error u-block">Supplement has not been fully distributed</div>}
                                    {_hasDistributions && _specifiedFeedingRemaining && <div className="Field-error u-block">Monthly feeding to specified blocks must equal 100%</div>}
                                    {_amounts.totalRemaining < 0 && <div className="Field-error u-block">More supplement has been distributed than is currently available</div>}
                                </GridCell>
                            </Grid>
                        </TileBody>
                        <TileFooter>
                            <div className="ButtonBar ButtonBar--fixed">
                                <div className="ButtonBar-left">
                                    <Link to={_referrer} className="Button Button--secondary" id="cancel-button">
                                        Cancel
                                    </Link>
                                </div>
                                <div className="ButtonBar-right">
                                    <Button submit primary waiting={submitting} disabled={submitDisabled || _specifiedFeedingRemaining} id="submit-button">
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </TileFooter>
                    </Tile>
                </form>
            </>
        );
    }
}

const DistributionForm = reduxForm({
    form: "supplementDistributionForm",
    validate: () => {
        const errors = {};
        return errors;
    },
})(DistributionClassComponent);
