import "components/ResultsWidgets/ResultsWidget.css";
import * as utils from "common/utils";
import * as units from "common/units";
import SummaryRow from "../PlanningReport/SummaryRow";
import Watermark from "components/Watermark";
import { useAnalysisResults } from "containers/BudgetHome";
import { getCategorySums, sumNutrientCategory } from "./_utils";

export default function NutrientBudgetDescriptions({ before, after, nutrient }) {
    const { isLoading: afterLoading, data: afterResults } = useAnalysisResults(after?.details);
    const { data: beforeResults } = useAnalysisResults(before?.details);

    if (afterLoading || !afterResults) return null;

    const afterNutrientBudget = afterResults?.nutrientBudget;
    const beforeNutrientBudget = beforeResults?.nutrientBudget;
    const isComparing = !!after && !!before;

    var averageNitrogenLoss = afterResults?.nitrogen?.averageLoss || afterResults?.nitrogen?.netAverageLoss;
    var averageLossNotation = units.getNotationWithoutOf(averageNitrogenLoss?.unit);
    const dp = nutrient.value === "phosphorus" ? 1 : 0;

    const NutrientTable = ({ afterItems, beforeItems, title, description, shortDesc }) => {
        // If there are no items with this nutrient, don't show the table
        const hasBeforeItems = beforeItems?.some(x => x?.nutrients?.[nutrient.value]?.value > 0);
        const hasAfterItems = afterItems?.some(x => x?.nutrients?.[nutrient.value]?.value > 0);
        if (!hasBeforeItems && !hasAfterItems) return null;

        const afterTotal = sumNutrientCategory(afterItems, nutrient) || undefined;
        const beforeTotal = beforeItems && sumNutrientCategory(beforeItems, nutrient) || undefined;

        return (
            <div className="ReportSection u-page-break-avoid">
                <h2>{title}</h2>
                <p className="u-pb-md">{description}</p>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25" className="u-pl-md">
                                    {shortDesc} ({averageLossNotation})
                                </th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {afterItems.map((c) => {
                                const beforeValue = beforeItems?.find((b) => b.name === c.name)?.nutrients?.[nutrient.value]?.value || undefined;
                                const afterValue = c.nutrients?.[nutrient.value]?.value || undefined;
                                if (!beforeValue && !afterValue) return null;
                                return <SummaryRow key={"category-row-" + c.name} beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category={c.name} label={c.description} dp={dp} rowClass={`Table-tr--${nutrient.display.toLowerCase()}loss`} />;
                            })}
                        </tbody>
                        <tfoot>
                            <SummaryRow beforeValue={beforeTotal} afterValue={afterTotal} isComparing={isComparing} category="Total" label=" " dp={dp} rowClass={`Table-tr--${nutrient.display.toLowerCase()}loss`} />
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    const afterSums = getCategorySums(afterNutrientBudget);
    const beforeSums = getCategorySums(beforeNutrientBudget);

    const showLostToAtmosphere = nutrient?.value !== "phosphorus" && (afterSums?.lossToAir?.length || beforeSums?.lossToAir?.length);

    return (
        <>
            <NutrientTable shortDesc="Additions" afterItems={afterSums?.addedItems} beforeItems={beforeSums?.addedItems} title={`${nutrient.text} brought onto farm`} description="Nutrients added to the farm via supplements, climate, fertiliser and effluent." />
            <NutrientTable shortDesc="Products" afterItems={afterSums?.productItems} beforeItems={beforeSums?.productItems} title={`${nutrient.text} removed as product`} description="Nutrients removed from the farm as product and as supplements. The difference between this and nutrients added is then susceptible to leaching or runoff from the farm." />
            <NutrientTable shortDesc="Transfers" afterItems={afterSums?.biological} beforeItems={beforeSums?.biological} title={`Transfer of nutrients`} description="The biological processes that change nutrients available on farm. These nutrients are not taken up by plants and so are removed from the nutrient pool. Also includes the balance of the nutrients in supplements that are transferred to/from storage. " />
            {showLostToAtmosphere && <NutrientTable shortDesc="Loss to air" afterItems={afterSums?.lossToAir} beforeItems={beforeSums?.lossToAir} title={`${nutrient.text} lost to the atmosphere`} description="The nutrients lost into the atmosphere through volatilisation and denitrification." />}
            <NutrientTable shortDesc="Other losses" afterItems={afterSums?.otherLosses} beforeItems={beforeSums?.otherLosses} title={`Other ${nutrient.text} lost from the farm`} description="The nutrients lost from runoff, leaching or directly into water. This is where the excess nutrients runoff or drain from the farm due to water movement (drainage), or are deposited directly into water ways." />
        </>
    )
}
