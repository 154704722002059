export const getCategorySums = (parent) => {
    const addedItems = parent?.added;
    const productItems = parent?.removed.filter((item) => !["To atmosphere", "Leaching, runoff and direct losses"].includes(item.name));
    const biological = parent?.changeInPools;
    const lossToAir = parent?.removed?.find((item) => item.name === "To atmosphere")?.subValues;
    const otherLosses = parent?.removed?.find((item) => item.name === "Leaching, runoff and direct losses")?.subValues;
    return { addedItems, productItems, biological, lossToAir, otherLosses };
}

export const sumNutrientCategory = (category, nutrient) => {
    if (!category || !Array.isArray(category)) return undefined;
    return category.reduce((t, item) => (t += item.nutrients[nutrient.value]?.value), 0);
}
