import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalsSummary({ before, after }) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.animals && !afterResults?.animals) {
        return null;
    }

    const isLoading = afterIsLoading || (before && beforeIsLoading);
    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Animals</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <SummaryRow beforeValue={beforeResults?.animals?.stockingRates?.total?.value} afterValue={afterResults?.animals?.stockingRates?.total?.value} isComparing={isComparing} category="RSU" label="Total" unit={afterResults?.animals?.stockingRates?.total?.unit?.notation} />
                            <SummaryRow beforeValue={beforeResults?.animals?.stockingRates?.byGrazedArea?.value} afterValue={afterResults?.animals?.stockingRates?.byGrazedArea?.value} isComparing={isComparing} category=" " label="RSU by grazed area" unit={afterResults?.animals?.stockingRates?.byGrazedArea?.unit?.notation} dp={2} />
                            <SummaryRow beforeValue={beforeResults?.animals?.stockingRates?.byFarmArea?.value} afterValue={afterResults?.animals?.stockingRates?.byFarmArea?.value} isComparing={isComparing} category=" " label="RSU per farm area" unit={afterResults?.animals?.stockingRates?.byFarmArea?.unit?.notation} dp={2} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.liveweightBrought?.value} afterValue={afterResults?.animals?.produced?.liveweightBrought?.value} isComparing={isComparing} category="Production" label="Total liveweight brought" unit={afterResults?.animals?.produced?.liveweightBrought?.unit?.notation} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.liveweightReared?.value} afterValue={afterResults?.animals?.produced?.liveweightReared?.value} isComparing={isComparing} category=" " label="Total liveweight reared" unit={afterResults?.animals?.produced?.liveweightReared?.unit?.notation} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.liveweightSold?.value} afterValue={afterResults?.animals?.produced?.liveweightSold?.value} isComparing={isComparing} category=" " label="Total liveweight sold" unit={afterResults?.animals?.produced?.liveweightSold?.unit?.notation} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.milkProduction?.value} afterValue={afterResults?.animals?.produced?.milkProduction?.value} isComparing={isComparing} category=" " label="Milk production per cow" unit={afterResults?.animals?.produced?.milkProduction?.unit?.notation} dp={1} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.milkSolids?.value} afterValue={afterResults?.animals?.produced?.milkSolids?.value} isComparing={isComparing} category=" " label="Milk solids" unit={afterResults?.animals?.produced?.milkSolids?.unit?.notation} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.milkingHerdSize?.value} afterValue={afterResults?.animals?.produced?.milkingHerdSize?.value} isComparing={isComparing} category=" " label="Milking herd size" unit={afterResults?.animals?.produced?.milkingHerdSize?.unit?.notation} dp={1} />
                            <SummaryRow beforeValue={beforeResults?.animals?.produced?.percentageOfMaleBeefAnimals?.value} afterValue={afterResults?.animals?.produced?.percentageOfMaleBeefAnimals?.value} isComparing={isComparing} category=" " label="Percentage of male beef animals" unit={afterResults?.animals?.produced?.percentageOfMaleBeefAnimals?.unit?.notation} dp={1} />
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}
