import React from "react";
import { Bar } from "react-chartjs-2";
import { GridCell } from "components/Grid";
import WidgetContainer from "components/WidgetContainer";
import * as utils from "common/utils";

export default function HistoryGraphs({ loading, error, data }) {
    const [showTotal, setShowTotal] = React.useState([]);
    if (loading) return null;
    if (!data) return <div>No Data</div>;

    const evalCharts = (trendData) => {
        const years = trendData && Object.keys(trendData);
        const types = years && years.length > 0 && trendData[years[0]].map((s) => ({ type: s.type, colour: s.colour, name: s.name, units: s.units, allowTotals: s.allowTotals, unitTotal: s.unitTotal }));
        const c =
            types &&
            types.map((typeInfo) => {
                const unit = showTotal.includes(typeInfo.type) ? typeInfo.unitTotal : typeInfo.units;
                return {
                    name: typeInfo.name,
                    type: typeInfo.type,
                    units: unit,
                    allowTotals: typeInfo.allowTotals,
                    options: {
                        scales: {
                            x: { display: true },
                            y: { ticks: { beginAtZero: true }, display: true, title: { display: true, text: unit } },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const { dataset, dataIndex } = tooltipItem;
                                        return dataset.counts[dataIndex] ? `${dataset.counts[dataIndex]} farms` : "";
                                    },
                                    afterLabel: (tooltipItem) => {
                                        return `${showTotal.includes(typeInfo.type) ? "TOTAL - " : ""} ${tooltipItem.raw.toLocaleString()} ${unit}`;
                                    },
                                },
                            },
                        },
                    },
                    data: {
                        datasets: [
                            {
                                data: years.map((year) => getData(typeInfo.type, year, trendData)),
                                counts: years.map((year) => getCount(typeInfo.type, year, trendData)),
                                backgroundColor: typeInfo.colour,
                                borderColor: typeInfo.colour,
                            },
                        ],
                        labels: years,
                    },
                };
            });
        return c;
    };

    const getData = (type, year, trendData) => {
        const trend = trendData && trendData[year] && trendData[year].find((t) => t.type === type);
        return trend && utils.round(showTotal.includes(type) ? trend.totalValue : trend.perUnit, 1);
    };

    const getCount = (type, year, trendData) => {
        const trend = trendData && trendData[year] && trendData[year].find((t) => t.type === type);
        return trend && trend.count;
    };

    const charts = evalCharts(data);

    if (!charts)
        return (
            <div className="HeroPanel">
                <div className="Tile-body-message">
                    <h3 className="u-mt-md">There are no results for this period</h3>
                </div>
            </div>
        );

    return charts.map((chartData) => {
        return (
            <WidgetContainer id="trends" loading={loading} error={error} className="u-width1of2 Farm-trends">
                <GridCell className={`FarmDashboard-cell`}>
                    <div className="Widget">
                        <div className="u-flex u-flexJustifyBetween">
                            <div className="HeroPanel-heading">
                                {chartData.name} ({chartData.units})
                            </div>
                            <div>
                                {chartData.allowTotals && (
                                    <div className="Field u-mt-xs">
                                        <div className="Radio_group Radio_group--inline">
                                            <label className="Radio u-mr-md">
                                                <input checked={showTotal.includes(chartData.type)} onClick={() => setShowTotal([...showTotal, chartData.type])} className="Radio-input" type="radio" />
                                                <span className="Radio-label">Total</span>
                                            </label>
                                            <label className="Radio">
                                                <input checked={!showTotal.includes(chartData.type)} onClick={() => setShowTotal(showTotal.filter((a) => a !== chartData.type))} className="Radio-input" type="radio" />
                                                <span className="Radio-label">Average {chartData.units}</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="Widget-content">
                            <Bar data={chartData.data} height={100} options={chartData.options} legend={{ display: false }} />
                        </div>
                    </div>
                </GridCell>
            </WidgetContainer>
        );
    });
}
