import AnimalsActionsBar from "./AnimalsActionsBar";
import EnterpriseList from "./EnterpriseList";
import BlockList from "./BlockList";

export default function Animals({ farm, analysis }) {
    return (
        <>
            <AnimalsActionsBar farm={farm} analysis={analysis} />
            <EnterpriseList farm={farm} analysis={analysis} />
            <BlockList farm={farm} analysis={analysis} />
        </>
    )
}
