import { Link } from "react-router-dom";
import * as domain from "common/domain";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";

export default function SoilTestsActionsBar({ farm, analysis }) {
    const blocks = analysis.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);
    const _availableBlocks = blocks.filter((block) => !block.soilTestId || !analysis.soilTests.some((t) => !t.defaultTest && t.id === block.soilTestId));

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/soilTests/new`} className={"IconLink--arrow-plus" + (_availableBlocks.length === 0 ? " is-disabled" : "")} id="add-soil-test-link">
                            Add soil tests
                        </Link>
                    </li>
                    {false && !analysis.useFarmSoils && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/soil/blocks`} className="IconLink--settings" id="add-soil-test-link">
                                Manage analysis soils
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000960826" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    );
}
