import { Link } from "react-router-dom";
import * as climateUtils from "./_utils";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";

export default function ClimateActionsBar({ farm, analysis }) {

    const availableSnowfallBlocks = climateUtils.getAvailableSnowfallBlocks(analysis, {});

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            <div className="ActionsBar-left">
                <ul className="ActionsBar-links">
                    <li>
                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/climate/snowfall/new`} className={"IconLink--arrow-plus" + (availableSnowfallBlocks.length === 0 ? " is-disabled" : "")} id="add-snowfall-link">
                            Add snowfall
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000977563" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
