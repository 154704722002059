import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, Field } from "react-final-form";
import { httpClient } from "common/httpClient";
import * as FormUtils from "common/FormUtils";
import { FORM_ERROR } from "final-form";
import Alert from "components/Alert";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import { enterpriseViewModel } from "common/viewModels";
import * as domain from "common/domain";
import Button from "components/Button/Button";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import ConfirmationField from "components/FormFields/ConfirmationField";
import SelectField from "components/FormFields/SelectField";
import SupplementList from "containers/BudgetHome/Overview/Sections/Tables/FarmSupplements";
import * as _supplementsUtils from "containers/BudgetHome/Supplements/_utils";
import * as _effluentUtils from "containers/BudgetHome/Effluent/_utils";
import * as utils from "common/utils";
import { nutrientLossViewModel } from "common/viewModels";
import PlanningReport from "containers/BudgetHome/Reporting/PlanningReport";
import * as _scenarioUtils from "./_utils";
import ZendeskLink from "components/Help/ZendeskLink";
import { Grid, GridCell } from "components/Grid";
import SupplementStorageAndUtilisation, { requiresStorageConditionOrUtilisation, getSupplementStorageAndUtilisationScenarioResults } from "./SupplementStorageAndUtilisation";
import GHGFuelAndElectricity from "./GHGFuelAndElectricity";
import { Panel, PanelBody } from "components/Panel";
import { getDryMatterWeight } from "containers/BudgetHome/Supplements/_queries";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useModal, useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function ScenarioToolModal({ farm, analysis, close }) {
    const online = useOnline();
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const featureTracker = useFeatureTracker(FEATURES.SCENARIO_TOOL, "Open", farm);
    const [viewModel, setViewModel] = useState();
    const topRef = useRef();

    useEffect(() => {
        getViewModel(farm, analysis, refData).then((vm) => setViewModel(vm));
    }, [farm, analysis, refData]);

    const messagesTable = (scenarios, title) => {
        const rows =
            scenarios &&
            scenarios.length > 0 &&
            scenarios.map((s, j) => {
                let rows =
                    (s.errors &&
                        s.errors.map((w, i) => {
                            const id = title + "_errors: " + j + "-" + i;
                            return (
                                <tr key={id} id={id}>
                                    <td className="u-textBold u-textError" valign="top" data-width="30" id={id + "_0"}>
                                        {i === 0 ? s.name : ""}
                                    </td>
                                    <td data-width="70" valign="top" className={"u-textError"} id={id + "_1"}>
                                        {w}
                                    </td>
                                </tr>
                            );
                        })) ||
                    [];
                rows = rows.concat(
                    (s.warnings &&
                        s.warnings.map((w, i) => {
                            const id = title + "_warnings: " + j + "-" + i;
                            return (
                                <tr key={id} id={id}>
                                    <td className="u-textBold u-textWarning" valign="top" data-width="30" id={id + "_0"}>
                                        {i === 0 ? s.name : ""}
                                    </td>
                                    <td data-width="70" valign="top" className={"u-textWarning"} id={id + "_1"}>
                                        {w}
                                    </td>
                                </tr>
                            );
                        })) ||
                        []
                );
                return rows.concat(
                    s.messages &&
                        s.messages.map((m, i) => {
                            const id = title + "_messages-" + j + "-" + i;
                            return (
                                <tr key={id} id={id}>
                                    <td className="u-textBold" valign="top" data-width="30" id={id + "_0"}>
                                        {i === 0 ? s.name : ""}
                                    </td>
                                    <td data-width="70" valign="top" id={id + "_1"}>
                                        {m}
                                    </td>
                                </tr>
                            );
                        })
                );
            });

        return (
            rows && (
                <div className="Table u-mt-md" id={`${title}_messages`}>
                    <table>
                        <thead>
                            <tr>
                                <th>Change</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
            )
        );
    };

    const enterpriseRow = (e, title) => {
        return (
            <tr className={title === "Before" ? "u-before" : "u-after"} id={`${e.id}_${title}`}>
                <td data-width="25">{title}</td>
                {e.slots.length > 0 &&
                    domain.farmYear.map((calendarMonth, i) => {
                        const slot = e.slots.find((slot) => slot.monthIndx === i) || e.slots.find((slot) => slot.month === calendarMonth);
                        const id = `${e.id}_${i}`;
                        if (slot) {
                            return (
                                <td key={id} id={id} className="u-textCenter">
                                    {slot.text}
                                </td>
                            );
                        } else {
                            return <td key={id} id={id} className="Calendar-slot"></td>;
                        }
                    })}
            </tr>
        );
    };

    const rsuRow = (e, title) => {
        const className = title === "Before" ? "u-before" : "u-after";
        const row = (
            <tr className={className} id={`${e.id}_${title}`}>
                <td data-width="25">{title}</td>
                <td colSpan="12">
                    <ul className={"DataWidthTable FarmTable " + className}>
                        <li data-width="md-33" style={{ padding: "5px 20px" }}>
                            <span className="inline-key">Peak number of cows milked:</span>
                            <span className="inline-value" id={`${e.id}_${title}_peak`}>
                                {" "}
                                {e.peakCowsMilked}
                            </span>
                        </li>
                        <li data-width="md-33" style={{ padding: "5px 20px" }}>
                            <span className="inline-key">AVERAGE MOB WEIGHT:</span>
                            <span className="inline-value" id={`${e.id}_${title}_avg`}>
                                {" "}
                                {e.averageMobWeight ? `${e.averageMobWeight}kg` : "Use default"}
                            </span>
                        </li>
                    </ul>
                </td>
            </tr>
        );

        return row;
    };

    const enterpriseTable = (beforeViewModel, afterViewModel, enterpriseId) => {
        const beforeEnterprise = beforeViewModel.find((e) => e.id === enterpriseId);
        const afterEnterprise = afterViewModel.find((e) => e.id === enterpriseId);

        if (beforeEnterprise) {
            return (
                <div className="Table Table-Compressed">
                    <table className="Calendar u-tbl-fix" id="enterprises_tbl">
                        <thead>
                            <tr className="Calendar-months">
                                <th className="Calendar-title">
                                    {beforeEnterprise.icon && <img id={`calendar.titleIcon.${beforeEnterprise.id}`} src={beforeEnterprise.icon} className="u-mr-xs u-p-5" width="35" height="35" alt={beforeEnterprise.title} />}
                                    {!beforeEnterprise.icon && (
                                        <span className="FarmTable-value">
                                            <span>{beforeEnterprise.enterpriseType}</span>
                                        </span>
                                    )}
                                </th>
                                {domain.farmYear.map((m, i) => (
                                    <th key={i} className="u-textCenter">
                                        {m.substring(0, 3)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {beforeEnterprise.type === "DairyPeak" ? rsuRow(beforeEnterprise, "Before") : enterpriseRow(beforeEnterprise, "Before")}
                            {afterEnterprise && afterEnterprise.type === "DairyPeak" ? rsuRow(afterEnterprise, "After") : enterpriseRow(afterEnterprise, "After")}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    const className = (num1, num2) => {
        return num2 > num1 ? "u-textRight u-textSuccess" : num2 < num1 ? "u-textRight u-textError" : "u-textRight";
    };

    const resultsValue = (num) => {
        const rsuValue = utils.round(num / 6000, 0);
        //const percentage = 100*num/total
        return `${utils.round(num, 0).toLocaleString()} (${rsuValue})`;
    };

    const enterpriseResultsTable = (enterprise) => {
        const b = enterprise.results.find((r) => r.type === "Before");

        return (
            <div className="Table Table-Compressed u-mt-md">
                <table className="Calendar" id={`${enterprise.id}_results`}>
                    <thead>
                        <tr>
                            <th>MJ ME (RSU)</th>
                            <th className="u-textCenter">Animals</th>
                            <th className="u-textCenter">Imported</th>
                            <th className="u-textCenter">Harvested</th>
                            <th className="u-textCenter">Crops</th>
                            <th className="u-textCenter">Pasture</th>
                            <th className="u-textCenter">Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enterprise.results.map((r, i) => {
                            const meDifference = utils.round(differenceInME(r), 0);

                            return (
                                <tr className={r.type === "Before" ? "u-before" : "u-after"} key={i} id={`${enterprise.id}_${r.type}_${i}`}>
                                    <td>{r.type === "Adjusted" ? "After Adjustment" : r.type === "After" ? "After scenario" : r.type}</td>
                                    <td className={className(b.animalME, r.animalME)} id={`${enterprise.id}_${r.type}_${i}_animalME`}>
                                        {resultsValue(r.animalME)}
                                    </td>
                                    <td className={className(b.importedME, r.importedME)} id={`${enterprise.id}_${r.type}_${i}_importedME`}>
                                        {resultsValue(r.importedME)}
                                    </td>
                                    <td className={className(b.harvestME, r.harvestME)} id={`${enterprise.id}_${r.type}_${i}_harvestME`}>
                                        {resultsValue(r.harvestME)}
                                    </td>
                                    <td className={className(b.cropsME, r.cropsME)} id={`${enterprise.id}_${r.type}_${i}_cropsME`}>
                                        {resultsValue(r.cropsME)}
                                    </td>
                                    <td className={className(b.pastureME, r.pastureME)} id={`${enterprise.id}_${r.type}_${i}_pastureME`}>
                                        {resultsValue(r.pastureME)}
                                    </td>
                                    <td className={className(0, meDifference)} id={`${enterprise.id}_${r.type}_${i}_meDiff`}>{`${meDifference.toLocaleString()} (${utils.round(meDifference / 6000, 0)})`}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const differenceInME = (meDetails) => {
        const diff = meDetails.importedME + meDetails.harvestME + meDetails.pastureME + meDetails.cropsME - meDetails.animalME;
        // deal with rounding issues
        return Math.abs(diff) <= 1.5 ? 0 : diff;
    };

    const processScenario = (enterpriseScenario, before, after) => {
        if (enterpriseScenario) {
            after.pastureME += enterpriseScenario.pastureME ? enterpriseScenario.pastureME : 0;
            after.importedME += enterpriseScenario.importedME ? enterpriseScenario.importedME : 0;
            after.harvestME += enterpriseScenario.harvestME ? enterpriseScenario.harvestME : 0;
            after.animalME += enterpriseScenario.animalME ? enterpriseScenario.animalME : 0;
            after.dm += enterpriseScenario.dm ? enterpriseScenario.dm : 0;

            // get some rounding errors so will just reduce to 0 if close
            if (after.importedME > -10 && after.importedME < 10) {
                enterpriseScenario.importedME -= after.importedME;
                after.importedME = 0;
            }
            if (after.harvestME > -10 && after.harvestME < 10) {
                enterpriseScenario.harvestME -= after.harvestME;
                after.harvestME = 0;
            }

            if (enterpriseScenario.animalME) enterpriseScenario.messages.push(changeMessage("Animal ME", before.animalME, enterpriseScenario.animalME, "MJ"));
            if (enterpriseScenario.pastureME) enterpriseScenario.messages.push(changeMessage("Pasture ME", before.pastureME, enterpriseScenario.pastureME, "MJ"));
            if (enterpriseScenario.harvestME) enterpriseScenario.messages.push(changeMessage("Harvested ME", before.harvestME, enterpriseScenario.harvestME, "MJ"));
            if (enterpriseScenario.importedME) enterpriseScenario.messages.push(changeMessage("Imported ME", before.importedME, enterpriseScenario.importedME, "MJ"));
            if (enterpriseScenario.dm) enterpriseScenario.messages.push(changeMessage("Dry matter", before.dm, enterpriseScenario.dm, "kg"));
        }
    };

    // run a new scenario, by copying the base analysis and then applying the scenario selected.
    const createScenario = (before, values) => {
        const selectedScenario = values && values.options && values.options.type;
        const adjustedAnimalResults = values.adjustedAnalysis && values.adjustedAnalysis.currentResults && values.adjustedAnalysis.currentResults.animalResults;
        const { options } = values;

        const fertiliserOptionsSelected = options.fertiliser.nFertiliserAdjustment && !isNaN(options.fertiliser.nFertiliserAdjustment) && options.fertiliser.nFertiliserAdjustment !== 0 && options.fertiliser.nFertiliserAdjustment;

        const fertiliserScenario = selectedScenario === "fertiliser" && fertiliserOptionsSelected;
        const irrigationScenario = selectedScenario === "irrigation" && (options.irrigation.type || options.irrigation.strategy);
        const structureScenario = selectedScenario === "structure" && options.structure.percentage && options.structure.months && (options.structure.type === "StandoffPad" || options.structure.feed.option === "existing" || options.structure.feed.feed);
        const importedScenario = selectedScenario === "removePurchased" || selectedScenario === "removePurchasedAndStorage" || selectedScenario === "removeStorage";
        const suplementScenario = selectedScenario === "supplementStorageAndUtilisation" && options.supplementStorageAndUtilisation && Object.keys(options.supplementStorageAndUtilisation).length > 1;
        const winteringScenario = selectedScenario === "wintering" && options.wintering.start && options.wintering.return && options.wintering.percentage;

        if (!fertiliserScenario && !irrigationScenario && !structureScenario && !importedScenario && !suplementScenario && !winteringScenario) return;

        let scenarioResults = { scenarios: [] };

        const budget = before && {
            ...utils.clone(before),
            id: before.id,
            versionId: before.versionId,
            type: before.type,
            name: "Scenario",
        };

        // do winter pad first because fertiliser will then adjust the supplements
        if (structureScenario && options.structure.enterpriseId) {
            const enterprise = budget.enterprises.find((e) => e.id === options.structure.enterpriseId);
            const feedCategory = options.structure.feed.option !== "existing" && options.structure.feed.category;
            const feed = feedCategory && options.structure.feed.feed;
            const structureResults = _scenarioUtils.addStructure(
                budget,
                refData,
                options.structure.type,
                enterprise,
                utils.round(options.structure.percentage, 0),
                utils.round(options.structure.hours, 0),
                Object.keys(options.structure.months).filter((m) => options.structure.months[m]),
                options.structure.storageCondition,
                options.structure.utilisation,
                feedCategory,
                feed,
                options.structure.feed.option === "import",
                adjustedAnimalResults
            );

            scenarioResults.scenarios.push(structureResults);
        }

        // fertiliser changes the pasture growth and harvested supps
        if (fertiliserScenario) {
            scenarioResults.scenarios.push(_scenarioUtils.adjustFertiliser(budget, values.enterpriseME, refData, options.fertiliser.nFertiliserAdjustment, options.fertiliser.nResponseRate, options.fertiliser.adjustmentType, options.fertiliser.monthsselected ? options.fertiliser.months || {} : undefined));
        }

        // irrigation adjustment changes the type and strategy of irrigation
        if (irrigationScenario) {
            scenarioResults.scenarios.push(_scenarioUtils.adjustIrrigation(budget, refData, options.irrigation.type, options.irrigation.strategy));
        }

        // upgrade the storage and utilisation of supplements. Gives more ME for same DM
        if (suplementScenario) {
            scenarioResults.scenarios.push(getSupplementStorageAndUtilisationScenarioResults(budget, options.supplementStorageAndUtilisation, refData));
        }

        if (winteringScenario) {
            scenarioResults.scenarios.push(_scenarioUtils.winterOff(budget, options.wintering.start, options.wintering.return, options.wintering.percentage));
        }

        // ghg fuel & electricity overrides
        if (options.ghgFuelAndElectricity && options.ghgFuelAndElectricity.selected) {
            if (options.ghgFuelAndElectricity.fuel) {
                if (!budget.ghg) budget.ghg = {};
                budget.ghg.fuel = options.ghgFuelAndElectricity.fuel;
            }
            if (options.ghgFuelAndElectricity.electricity) {
                if (!budget.ghg) budget.ghg = {};
                budget.ghg.electricity = options.ghgFuelAndElectricity.electricity;
            }
        }

        if (importedScenario) scenarioResults.scenarios.push(_scenarioUtils.removeImportedSupplements(budget, refData, selectedScenario, values.enterpriseME));
        if (scenarioResults.scenarios.length > 0) {
            scenarioResults.after = budget;

            const newEnterpriseME = [];
            // Create the before and after results for each enterprise.
            scenarioResults.enterprises = values.enterpriseME.map((e) => {
                const enterprise = { id: e.id, enterpriseType: e.enterpriseType, name: e.name, results: [], scenarios: [] };
                e.type = "Before";
                enterprise.results.push(e);
                const eAfter = utils.clone(e);
                eAfter.type = "After";
                enterprise.results.push(eAfter);
                newEnterpriseME.push(eAfter);

                const enterpriseScenario = scenarioResults.scenarios[0].enterprises && scenarioResults.scenarios[0].enterprises.find((er) => er.id === e.id);
                if (enterpriseScenario) {
                    processScenario(enterpriseScenario, e, eAfter);
                    enterpriseScenario.name = scenarioResults.scenarios[0].name;
                    enterprise.scenarios.push(enterpriseScenario);
                }

                return enterprise;
            });

            // they can select fertiliser as an additional scenatio to balance me. process this and add to results
            if (!fertiliserScenario && options.fertiliser.selected && fertiliserOptionsSelected) {
                const fertResults = _scenarioUtils.adjustFertiliser(budget, newEnterpriseME, refData, options.fertiliser.nFertiliserAdjustment, options.fertiliser.nResponseRate, options.fertiliser.adjustmentType, options.fertiliser.monthsselected ? options.fertiliser.months || {} : undefined);
                scenarioResults.scenarios.push(fertResults);
                fertResults.enterprises.forEach((e) => {
                    const existingResults = scenarioResults.enterprises.find((er) => er.id === e.id);
                    const eAfter = existingResults.results.find((r) => r.type === "After");
                    processScenario(e, eAfter, eAfter);
                    e.name = fertResults.name;
                    existingResults.scenarios.push(e);
                });
            }
        }

        return scenarioResults;
    };

    // adjust a scenario so that it balances feed with animals. is run to get initial values and then the model runs and it is run again based on adjusted animal me
    const adjustScenario = (budget, enterpriseResults, values) => {
        const adjustedAnimalResults = values.adjustedAnalysis && values.adjustedAnalysis.currentResults && values.adjustedAnalysis.currentResults.animalResults;

        // Now run any adjustments for each enterprise. Should result in a balance adjusted results (me = imported + farm grown)
        enterpriseResults &&
            enterpriseResults.forEach((e) => {
                const enterpriseValues = values.options.adjustments[e.id];
                const enterpriseAfter = e.results.find((r) => r.type === "After");

                if (enterpriseAfter) {
                    const adjusted = { type: "Adjusted", animalME: enterpriseAfter.animalME, importedME: enterpriseAfter.importedME, harvestME: enterpriseAfter.harvestME, cropsME: enterpriseAfter.cropsME, pastureME: enterpriseAfter.pastureME, dm: enterpriseAfter.dm };
                    if (enterpriseValues) {
                        // work out ME required from supps and/or changing animal numbers. We need to go from animal me after running scenarios to the modelled animal me from running the model using the new values
                        let me = differenceInME(enterpriseAfter);
                        const proportionSupps = enterpriseValues.supplements && enterpriseValues.supplements.percentage ? enterpriseValues.supplements.percentage / 100 : 0;

                        if (adjustedAnimalResults && values.options.type !== "structure") {
                            // animal me may have been adjusted after running the model so need to change starting animal me
                            // what is difference between animal me before running model and what model returned.
                            const changeInMe = adjustedAnimalResults[e.id].totalME - values.adjustedAnimalME[e.id];
                            // adjust starting animal me by the difference and then work out the difference between that animal ME and food available
                            adjusted.animalME += changeInMe;
                            enterpriseAfter.animalME += changeInMe;

                            me = differenceInME(adjusted);
                        }

                        let adjustmentMade = false;

                        // import supplements for animals
                        if (me < 0 && proportionSupps) {
                            // add enough supplements for the missing me. Need to make it a positive value
                            const meFromSupps = -(me * proportionSupps);

                            const meChanges = _scenarioUtils.createImportedSupplement(budget, refData, e, meFromSupps, enterpriseValues.supplements.category, enterpriseValues.supplements.feed, undefined, values.options.supplementStorageAndUtilisation.paddockStorageCondition, values.options.supplementStorageAndUtilisation.paddockUtilisation);
                            const enterpriseScenario = meChanges.enterprises.find((eChange) => eChange.id === e.id);
                            if (enterpriseScenario) {
                                processScenario(enterpriseScenario, enterpriseAfter, adjusted);
                                enterpriseScenario.name = meChanges.name;
                                e.scenarios.push(enterpriseScenario);
                            }
                            me = me + meFromSupps;
                            adjustmentMade = true;
                        }

                        // if user has selected to reduce imports, or no animal selection has been made but we have excess ME then we will try and reduce imported
                        if (me > 0 && adjusted.importedME > 0 && proportionSupps) {
                            const meToRemove = me * proportionSupps;
                            const meChanges = _scenarioUtils.adjustImportedSupplements(budget, refData, enterpriseAfter, -meToRemove);
                            const enterpriseScenario = meChanges.enterprises.find((eChange) => eChange.id === e.id);
                            if (enterpriseScenario) {
                                if (meToRemove > Math.abs(enterpriseScenario.importedME)) enterpriseScenario.messages.push("There are not enough imported supplements to remove, animals will need to be adjusted to balance the food available.");
                                processScenario(enterpriseScenario, enterpriseAfter, adjusted);
                                enterpriseScenario.name = meChanges.name;
                                e.scenarios.push(enterpriseScenario);
                                me = me + enterpriseScenario.importedME;
                            }

                            adjustmentMade = true;
                        }

                        // adjust animals for remaining difference
                        if (me !== 0 && enterpriseValues.animals.option && (enterpriseValues.animals.option === "changeAndChange" || enterpriseValues.animals.option === "changeAndKeep")) {
                            // remaining percentage of me change applied as a proportion of the oroginal animal me (before rmeoving animals)
                            const changeProportion = me / adjusted.animalME;
                            const meChanges = _scenarioUtils.adjustAnimalNumbers(budget, e.id, changeProportion, me, enterpriseValues.animals.option === "changeAndChange", refData);
                            const enterpriseScenario = meChanges.enterprises.find((eChange) => eChange.id === e.id);
                            if (enterpriseScenario) {
                                processScenario(enterpriseScenario, enterpriseAfter, adjusted);
                                enterpriseScenario.name = meChanges.name;
                                e.scenarios.push(enterpriseScenario);
                            }
                            me = 0;
                            adjustmentMade = true;
                        }

                        // finally just adjust pasture to make it balance
                        if (me !== 0 && (adjustedAnimalResults || enterpriseValues.animals.option === "changeAndPasture")) {
                            const existingPasture = utils.round(adjusted.pastureME, 0);
                            adjusted.pastureME = utils.round(adjusted.animalME - adjusted.importedME - adjusted.harvestME - adjusted.cropsME, 0);

                            const pastureScenario = { id: adjusted.id, messages: [`Pasture ME (${existingPasture} MJ) ${adjusted.pastureME > existingPasture ? "increased" : "reduced"} by ${adjusted.pastureME - existingPasture} MJ.`] };
                            pastureScenario.name = "Adjust pasture production";
                            e.scenarios.push(pastureScenario);

                            adjustmentMade = true;
                        }

                        if (adjustmentMade) e.results.push(adjusted);

                        // we save this so that when make adjustments is made and get modelled ME can compare it
                        if (!values.adjustedAnimalME) values.adjustedAnimalME = {};
                        if (!adjustedAnimalResults) values.adjustedAnimalME[e.id] = adjusted.animalME;
                    }
                    e.error = (adjusted.animalME < 0 || adjusted.importedME < 0 || adjusted.harvestME < 0 || adjusted.cropsME < 0 || adjusted.pastureME < 0) && "ME required or food available is negative and so is not a valid system. Please review scenarios, any warnings shown above and energy table shown below.";
                    const rsuDifference = utils.round(differenceInME(adjusted) / 6000, 0);

                    if (e.enterpriseType === "Dairy" && values.options.type === "wintering" && (!enterpriseValues.animals.option || enterpriseValues.animals.option === "noChange")) {
                        e.info = "Unable to calculate change in animal energy requirements from wintering off. Select the adjustments to supplements or animals below and click on run scenario to see impact of the change.";
                    } else if (rsuDifference !== 0) {
                        e.info = rsuDifference > 0 ? `There is more energy available from food than the ${e.name.toLowerCase()} animals require (${Math.abs(rsuDifference)} RSU). If you have imported supplements you can reduce them or increase ${e.name.toLowerCase()} numbers or increase pasture production. Enter a percentage to reduce supplements and/or select an option under adjust animals or pasture production.` : `There is not enough energy available from food for the ${e.name.toLowerCase()} animals on the farm (${Math.abs(rsuDifference)} RSU). You can import new supplements, reduce ${e.name.toLowerCase()} numbers or reduce pasture production. Enter a percentage to import and/or select an option under adjust animals or pasture production.`;
                    }
                }
            });
        budget.currentResults = undefined;
    };

    const validate = (viewModel) => {
        const validation = {
            after: {},
            options: { adjustments: {} },
        };

        if (viewModel.options) {
            const selectedScenario = viewModel && viewModel.options && viewModel.options.type;
            const options = viewModel.options;

            if (selectedScenario === "fertiliser" || (selectedScenario && options.fertiliser.selected)) {
                validation.options.fertiliser = {
                    nFertiliserAdjustment: FormUtils.validators.required(options.fertiliser.nFertiliserAdjustment) || FormUtils.validators.range(-100, 100)(options.fertiliser.nFertiliserAdjustment),
                    nResponseRate: options.fertiliser.nResponseRate ? FormUtils.validators.range(1, 20)(options.fertiliser.nResponseRate) : undefined,
                    months: { July: (options.fertiliser.monthsselected && !options.fertiliser.months && "Please select a month") || undefined },
                };
            }

            if (selectedScenario === "structure") {
                validation.options.structure = {
                    percentage: FormUtils.validators.required(options.structure.percentage) || FormUtils.validators.range(0, 100)(options.structure.percentage),
                    enterpriseId: FormUtils.validators.required(options.structure.enterpriseId),
                    hours: FormUtils.validators.required(options.structure.hours) || FormUtils.validators.range(0, options.structure.type === "FeedingPad" ? 12 : options.structure.type === "StandoffPad" ? 20 : 24)(options.structure.hours),
                };
                validation.options.structure.feed = {
                    feed: options.structure.type !== "StandoffPad" && options.structure.feed.option !== "existing" ? FormUtils.validators.required(options.structure.feed.feed) : undefined,
                };
            }

            if (selectedScenario === "wintering") {
                validation.options.wintering = {
                    percentage: FormUtils.validators.required(options.wintering.percentage) || FormUtils.validators.range(0, 100)(options.wintering.percentage),
                };
            }

            if (options.adjustments) {
                viewModel.before.enterprises.forEach((e) => {
                    if (options.adjustments[e.id]) {
                        if (options.adjustments[e.id].supplements && options.adjustments[e.id].supplements.percentage) {
                            if (!validation.options.adjustments[e.id]) validation.options.adjustments[e.id] = {};

                            validation.options.adjustments[e.id].supplements = {
                                percentage: FormUtils.validators.range(0, 100)(options.adjustments[e.id].supplements.percentage),
                            };
                        }
                        if (options.adjustments[e.id].imported && options.adjustments[e.id].imported.percentage) {
                            if (!validation.options.adjustments[e.id]) validation.options.adjustments[e.id] = {};

                            validation.options.adjustments[e.id].imported = {
                                percentage: FormUtils.validators.range(0, 100)(options.adjustments[e.id].imported.percentage),
                            };
                        }
                    }
                });
            }
        }

        return validation;
    };

    const submitAsync = async (viewModel, form) => {
        if (viewModel.scenario && !viewModel.acceptChanges) {
            form.change("acceptChanges", true);
        } else if (viewModel.scenario) {
            featureTracker.track("Save");
            try {
                viewModel.scenario.name = viewModel.before.name;
                viewModel.scenario.versionId = viewModel.before.versionId;
                viewModel.scenario.scenarioMessages = viewModel.scenarioResults.scenarios.reduce((map, s) => {
                    map[s.name] = s.messages;
                    return map;
                }, {});
                updateAnalysisAsync(viewModel.scenario);
                close();
            } catch (ex) {
                return { [FORM_ERROR]: ex.message };
            }
        } else if (viewModel.adjustedAnalysis) {
            featureTracker.track("Review");
            try {
                let scenario = await runScenario(viewModel.scenarioResults.after);
                form.change("scenario", { ...scenario, wasGeneratedByScenarioTool: true });
                form.change("acceptChanges", false);
                topRef.current.scrollIntoView({ behavior: "smooth" });
            } catch (error) {
                return { [FORM_ERROR]: error };
            }
        } else {
            featureTracker.track("Run");
            try {
                let adjustedAnalysis = await runScenario(viewModel.scenarioResults.after);
                const errors = adjustedAnalysis && adjustedAnalysis.messages && adjustedAnalysis.messages.filter((m) => m.severity === "Error");
                if (errors && errors.length > 0) {
                    topRef.current.scrollIntoView({ behavior: "smooth" });
                    return { [FORM_ERROR]: errors[0].text };
                }

                form.change("adjustedAnalysis", { ...adjustedAnalysis, wasGeneratedByScenarioTool: true });
                form.change("acceptChanges", false);
            } catch (error) {
                return { [FORM_ERROR]: error };
            }
        }
    };

    const changeMessage = (field, before, amount, units) => {
        const change = amount > 0 ? "increased" : "reduced";
        const rounded = Math.abs(utils.round(amount, 0));
        const percentage = before > 0 && utils.round((100 * amount) / before, 0);
        return `${field} (${utils.round(before, 0).toLocaleString()} ${units}) ${change} by ${rounded.toLocaleString()} ${units} ${percentage && percentage > -100 && percentage < 100 ? "(" + percentage + "%)" : ""}.`;
    };

    const clearScenarios = (form) => {
        form.change("adjustedAnalysis", undefined);
        form.change("scenario", undefined);
        form.change("acceptChanges", false);
        topRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const clearFeed = (form, control) => {
        form.change(control, undefined);
    };

    return (
        <Form initialValues={viewModel} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const { before = {}, scenario, adjustedAnalysis, options, acceptChanges } = values;
                const { irrigators, enterprises } = before;
                const structureTypes = refData.structureType.filter((s) => s.value === "FeedingPad" || s.value === "CoveredWinteringPad" || s.value === "UncoveredWinteringPad" || s.value === "StandoffPad");
                const supplementCategories = _supplementsUtils.getCategories("Purchased", refData).filter((c) => c.value !== "Userdefined");
                const supplementaryFeeds = {};
                let supplementaryFeedsPad = options && options.structure.feed.category && _supplementsUtils.getSupplementaryFeeds(options.structure.feed, refData);
                const irrigatorTypes = refData.irrigatorTypes.filter((i) => i.value !== "BorderDyke" && i.value !== "Flood");
                const irrigationStrategies = refData.irrigationSoilMoistureUsage;
                const hasResults = before && before.currentResults && before.currentResults.hasResults;
                const hasDairy = before && before.enterprises && before.enterprises.find((e) => e.type === "Dairy");
                const hasPeakCows = before && before.enterprises && before.enterprises.find((e) => e.specificationMethod === "DairyPeakCowNumbers");
                const hasDairyGoat = before && before.enterprises && before.enterprises.find((e) => e.type === "DairyGoat");
                const months = domain.calendarYear.map((m) => ({ text: m, value: m }));
                const winteringScenario = options && options.wintering && options.type === "wintering" && options.wintering.start && options.wintering.return && options.wintering.percentage;

                const purchased = before && before.feedSupplements && before.feedSupplements.find((s) => s.type === "Purchased");
                const fromStorage = before && before.feedSupplements && before.feedSupplements.find((s) => s.type === "FromStorage");

                // dont do dairy goats for now
                const enterprisesForPad = options && options.structure.type && _effluentUtils.availableEnterprises(before, refData, options.structure.type).filter((v) => !hasDairyGoat || v.value !== hasDairyGoat.id);
                const structureFeedUtilisations = refData.supplementStructureUtilisations;
                const storageConditions = refData.supplementStorageConditions.filter((c) => c.value !== "Nostorage");

                // set up supplement feeds for drop down lists
                options &&
                    options.adjustments &&
                    enterprises &&
                    enterprises.forEach((e) => {
                        const enterpriseValues = options.adjustments[e.id] && options.adjustments[e.id].supplements;
                        supplementaryFeeds[e.id] = enterpriseValues && enterpriseValues.category && _supplementsUtils.getSupplementaryFeeds(enterpriseValues, refData);
                    });

                // create list of possible scenarios
                const scenarioTypes = [{ text: "Change N fertiliser", value: "fertiliser" }];

                let scenarioResults = undefined;
                if (purchased) {
                    scenarioTypes.push({ text: "Remove purchased supplements", value: "removePurchased" });
                    if (fromStorage) scenarioTypes.push({ text: "Remove purchased supplements and supplements from storage", value: "removePurchasedAndStorage" });
                } else if (fromStorage) scenarioTypes.push({ text: "Remove supplements from storage", value: "removeStorage" });

                if (hasDairy) scenarioTypes.push({ text: "Wintering off dairy animals", value: "wintering" });
                if (structureTypes && structureTypes.length > 0) scenarioTypes.push({ text: "Add structure", value: "structure" });

                if (irrigators && irrigators.length > 0) scenarioTypes.push({ text: "Change irrigation systems", value: "irrigation" });

                if (before && before.feedSupplements && before.feedSupplements.some((supp) => (supp.destinations || []).some((dest) => requiresStorageConditionOrUtilisation(dest)))) scenarioTypes.push({ text: "Change supplement storage and utilisation", value: "supplementStorageAndUtilisation" });

                scenarioResults = hasResults && createScenario(before, values);
                scenarioResults && adjustScenario(scenarioResults.after, scenarioResults.enterprises, values);

                values.scenarioResults = scenarioResults;

                const afterEnterpriseModel = scenarioResults && scenarioResults.after && enterpriseViewModel(farm, scenarioResults.after, refData);

                const submitButtonText = scenario ? (acceptChanges ? "Save" : "Accept changes") : adjustedAnalysis ? "Review changes" : "Run scenario";
                const submitDisabled = !hasResults || !online || (!scenarioResults && !scenario) || (scenarioResults && scenarioResults.enterprises.find((e) => e.error || e.info)) || (scenario && acceptChanges && !values.confirmed);
                const disabledControls = scenario || adjustedAnalysis;

                const scenarioInfoText = options && options.type && options[options.type] && options[options.type].message;
                const scenarioError = scenarioResults && scenarioResults.scenarios && scenarioResults.scenarios.find((s) => s.errors && s.errors.length > 0);

                const modalInfo = (
                    <>
                        <p>
                            The scenario tool is designed to support users to develop scenario analyses as part of our focus on supporting sustainable farming. For more information&nbsp;
                            <b>
                                <ZendeskLink title="click here." url="https://support.overseer.org.nz/hc/en-us/articles/900004254163" />
                            </b>
                        </p>
                    </>
                );
                const modalError = !hasResults ? "The selected analysis has no results and so cannot run scenarios. You will need to fix the analysis and try again." : !dirtySinceLastSubmit && submitError;

                const peakCowsWarning = hasPeakCows ? "This analysis uses the peak cow numbers method of entering dairy stock numbers. Peak cow numbers is no longer supported so you can not use the scenario tool on this analysis until you have edited the Dairy enterprise and converted it to using the stock reconciliation method instead." : null;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Scenario tool" close={close} submitting={submitting} wide={!acceptChanges} fluid={acceptChanges}>
                            <ModalBody loading={!options} info={!modalError && !acceptChanges && !peakCowsWarning && modalInfo} warning={peakCowsWarning} error={modalError}>
                                <div ref={topRef}></div>
                                {hasPeakCows ? (
                                    <>
                                        <div className="Tile-body-message">
                                            <i className="icon icon--md icon-alert u-textWarning" />
                                            <p className="lead">Scenario tool not supported with peak cow numbers</p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {options && hasResults && (
                                            <Grid className="Grid--withSmallGutter u-mt-md">
                                                {!acceptChanges && (
                                                    <GridCell className={`u-width1of3 ${scenario || adjustedAnalysis ? "u-xl-hidden" : ""}`}>
                                                        <Panel title="Scenario" notCollapsible green>
                                                            <PanelBody className="u-p-10">
                                                                <Field name="options.type" placeholder="Select a scenario" options={scenarioTypes} info={scenarioInfoText} className="u-mt-sm" required component={SelectField} disabled={disabledControls} />
                                                                <div className={`Scenario-panel`}>
                                                                    {options.type === "structure" && (
                                                                        <div className="Scenario-panel-body">
                                                                            <Field name="options.structure.type" label="Structure" placeholder="Select a structure" options={structureTypes} className="u-mt-sm" required component={SelectField} disabled={disabledControls} />
                                                                            {options.structure.type && enterprisesForPad && enterprisesForPad.length === 0 && <p>There are no enterprises available for the selected structure.</p>}
                                                                            {enterprisesForPad && enterprisesForPad.length > 0 && (
                                                                                <>
                                                                                    <Field name="options.structure.enterpriseId" label="Enterprise" placeholder="Select an enterprise" options={enterprisesForPad} defaultValue={enterprisesForPad[0].value} required component={SelectField} disabled={disabledControls} />
                                                                                    <Grid>
                                                                                        <GridCell className="u-width1of2">
                                                                                            <Field name="options.structure.percentage" label="Percentage of animals" component={TextField} uom="%" type="number" required format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                                        </GridCell>
                                                                                        <GridCell className="u-width1of2">
                                                                                            <Field name="options.structure.hours" label="Hours on pad" component={TextField} placeholder="Enter number of hours" uom="hours" type="number" required format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                                        </GridCell>
                                                                                    </Grid>

                                                                                    <div className="AnnualCheckboxes u-flexWrap">
                                                                                        {domain.farmYear.map((calendarMonth, i) => {
                                                                                            return <Field key={i} name={`options.structure.months.${calendarMonth}`} label={calendarMonth.substring(0, 3)} vertical component={CheckboxField} disabled={disabledControls} />;
                                                                                        })}
                                                                                    </div>
                                                                                    {options.structure.type !== "StandoffPad" && (
                                                                                        <>
                                                                                            <Grid>
                                                                                                <GridCell className="u-width1of2">
                                                                                                    <Field name="options.structure.storageCondition" label="Set storage condition for feed on structure" placeholder="Default value is average" options={storageConditions} component={SelectField} disabled={disabledControls} />
                                                                                                </GridCell>
                                                                                                <GridCell className="u-width1of2">
                                                                                                    <Field name="options.structure.utilisation" label="Set feed utilisation for structure" placeholder="Default value is very good" options={structureFeedUtilisations} component={SelectField} disabled={disabledControls} />
                                                                                                </GridCell>
                                                                                                <GridCell className="u-width1of1">
                                                                                                    <Field name="options.structure.feed.option" label="Select an option for feeding the animals" options={addStructureFeedOptions} defaultValue={addStructureFeedOptions[0].value} component={SelectField} disabled={disabledControls} />
                                                                                                </GridCell>
                                                                                            </Grid>
                                                                                            {options.structure.feed.option !== "existing" && (
                                                                                                <Grid>
                                                                                                    <GridCell className="u-width1of2">
                                                                                                        <Field name="options.structure.feed.category" label="Category" placeholder="Select a category" options={supplementCategories} required component={SelectField} disabled={disabledControls} onChange={() => clearFeed(form, `options.structure.feed.feed`)} />
                                                                                                    </GridCell>
                                                                                                    <GridCell className="u-width1of2">
                                                                                                        <Field name="options.structure.feed.feed" label="Supplementary feed" placeholder="Select a feed" options={supplementaryFeedsPad} required component={SelectField} disabled={disabledControls} />
                                                                                                    </GridCell>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {options.type === "irrigation" && (
                                                                        <div className="Scenario-panel-body">
                                                                            <Field name="options.irrigation.type" label="Replace all systems with this type" placeholder="Select a type" options={irrigatorTypes} className="u-mt-sm" component={SelectField} disabled={disabledControls} />
                                                                            <Field name="options.irrigation.strategy" label="Replace all irrigation with this soil moisture strategy" placeholder="Select a soil moisture strategy" options={irrigationStrategies} required component={SelectField} disabled={disabledControls} />
                                                                        </div>
                                                                    )}
                                                                    {hasDairy && options.type === "wintering" && (
                                                                        <div className="Scenario-panel-body">
                                                                            <Field name="options.wintering.percentage" label="Percentage of herd that left the farm" component={TextField} uom="%" type="number" className="u-mt-sm" required format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                            <Field name="options.wintering.start" label="Starting month" placeholder="Select month animals left the farm" required options={months} component={SelectField} disabled={disabledControls} />
                                                                            <Field name="options.wintering.return" label="Returning month" placeholder="Select month animals returned" required options={months} component={SelectField} disabled={disabledControls} />
                                                                        </div>
                                                                    )}
                                                                    {options.type === "supplementStorageAndUtilisation" && <SupplementStorageAndUtilisation analysis={before} options={options.supplementStorageAndUtilisation} disabled={disabledControls} />}
                                                                    {false && <GHGFuelAndElectricity options={options.ghgFuelAndElectricity} disabled={disabledControls} />}
                                                                </div>
                                                                {options.type && (
                                                                    <>
                                                                        <div className={`Scenario-panel ${options.type === "fertiliser" || options.fertiliser.selected ? "" : "Scenario-panel--closed"}`}>
                                                                            {options.type !== "fertiliser" && (
                                                                                <div className="Scenario-panel-head">
                                                                                    <Field name="options.fertiliser.selected" label="Change N fertiliser" vertical component={CheckboxField} disabled={disabledControls} />
                                                                                </div>
                                                                            )}
                                                                            <div className="Scenario-panel-body">
                                                                                <Field name="options.fertiliser.nFertiliserAdjustment" label="Adjust N Fertiliser by % (use negative values to decrease)" placeholder="Percentage change" uom="%" type="number" className="u-mt-sm" component={TextField} required format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                                <Field name="options.fertiliser.nResponseRate" label="kg of DM grown per kg of N fertiliser" placeholder="7" uom="kg DM/kg N" type="number" component={TextField} format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                                <Field name="options.fertiliser.adjustmentType" label="Adjustment type" options={fertiliserAdjustmentTypes} required component={SelectField} disabled={disabledControls} />
                                                                                {options.fertiliser.adjustmentType === "nConcentration" && <Alert type="info" text="This will convert fertiliser products to custom products after changing the nitrogen concentration. 'N adjusted' will be added to the fertiliser name." />}

                                                                                <div className={`Scenario-panel ${options.fertiliser.monthsselected ? "" : "Scenario-panel--closed"}`}>
                                                                                    <div className="Scenario-panel-head">
                                                                                        <Field name="options.fertiliser.monthsselected" label="Select months to adjust fertiliser" vertical component={CheckboxField} disabled={disabledControls} />
                                                                                    </div>
                                                                                    <div className="Scenario-panel-body">
                                                                                        <div className="AnnualCheckboxes u-flexWrap">
                                                                                            {domain.farmYear.map((calendarMonth, i) => {
                                                                                                return <Field key={i} name={`options.fertiliser.months.${calendarMonth}`} label={calendarMonth.substring(0, 3)} vertical component={CheckboxField} disabled={disabledControls} />;
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </PanelBody>
                                                        </Panel>
                                                    </GridCell>
                                                )}
                                                {!scenario && (
                                                    <GridCell className={adjustedAnalysis ? "u-xl-width2of3" : "u-width2of3"}>
                                                        {scenarioResults && (
                                                            <Panel title="Impact on the farm" error={scenarioError && "There was a problem running the scenario. Please review the errors and warnings in the table below."} midBlue notCollapsible>
                                                                <PanelBody>{messagesTable(scenarioResults.scenarios, "FarmImpact")}</PanelBody>
                                                            </Panel>
                                                        )}
                                                        {scenarioResults && !scenarioError && scenarioResults.enterprises && scenarioResults.enterprises.length > 0 && (
                                                            <Panel title="Impact on enterprises" className="u-mt-md" midBlue notCollapsible>
                                                                <PanelBody className="u-p-10 u-pl-lg">
                                                                    {scenarioResults.enterprises.map((enterprise, i) => {
                                                                        const afterResults = enterprise.results.find((r) => r.type === "After");
                                                                        const adjustedResults = enterprise.results.find((r) => r.type === "Adjusted");
                                                                        const rsuDifference = utils.round(differenceInME(afterResults) / 6000, 0);
                                                                        const remainingPercentage = rsuDifference < 0 && 100 - options.adjustments[enterprise.id].supplements.percentage;
                                                                        const iconClassName = `IconLink--${enterprise.enterpriseType.toLowerCase()}`;
                                                                        const animalOptions = getAnimalOptions(enterprise, winteringScenario && enterprise.enterpriseType === "Dairy" ? 1 : rsuDifference);
                                                                        let successMessage = `Energy requirements of ${enterprise.name} animals balance with energy provided by food. `;
                                                                        successMessage = adjustedAnalysis ? successMessage + "Click on review changes to compare the changes for the scenario." : successMessage + "Once all enterprises balance click on run scenario to see the impact.";

                                                                        return (
                                                                            <Panel title={enterprise.name} iconClassName={iconClassName} key={enterprise.id} notCollapsible skyBlue className={`${i === 0 ? "u-mt-sm" : "u-mt-md"}`}>
                                                                                {enterprise.error && <Alert type="error" className="u-mt-md u-ml-sm u-mr-sm u-mb-0" text={enterprise.error} />}
                                                                                {enterprise.info && <Alert type="warning" className="u-mt-md u-ml-sm u-mr-sm u-mb-0 Alert--highlight" text={<b>{enterprise.info}</b>} />}
                                                                                {!enterprise.info && !enterprise.error && <Alert type="success" className="u-mt-md u-ml-sm u-mr-sm u-mb-0 Alert--highlight" text={<b>{successMessage}</b>} />}

                                                                                {((winteringScenario && enterprise.enterpriseType === "Dairy") || rsuDifference !== 0) && (
                                                                                    <PanelBody>
                                                                                        {afterResults && (
                                                                                            <>
                                                                                                {false && (
                                                                                                    <div className="ScenarioEnterprise-panel">
                                                                                                        <div className="ScenarioEnterprise-panel-title u-text-md Colour--orange">Action</div>
                                                                                                        <div className="ScenarioEnterprise-panel-body"></div>
                                                                                                    </div>
                                                                                                )}
                                                                                                <>
                                                                                                    {((winteringScenario && enterprise.enterpriseType === "Dairy") || rsuDifference !== 0) && (
                                                                                                        <Grid>
                                                                                                            <GridCell>
                                                                                                                <h3 className="u-mt-md u-text-sm Colour--orange">Adjust supplements</h3>
                                                                                                                {rsuDifference > 0 && <p>If there is not enough imported supplements to remove, animals or pasture will be adjusted for the remaining.</p>}
                                                                                                            </GridCell>
                                                                                                            <GridCell className="u-xl-width1of3">
                                                                                                                <Field name={`options.adjustments.${enterprise.id}.supplements.percentage`} label="Percentage of energy to replace with supplements" uom="%" type="number" component={TextField} format={FormUtils.formatters.formatInt()} disabled={disabledControls} formatOnBlur />
                                                                                                            </GridCell>
                                                                                                            {rsuDifference < 0 && values.options.adjustments[enterprise.id].supplements.percentage > 0 && (
                                                                                                                <GridCell className="u-xl-width1of3">
                                                                                                                    <Field name={`options.adjustments.${enterprise.id}.supplements.category`} label="Category" placeholder="Select a category (Default is Silage)" options={supplementCategories} component={SelectField} disabled={disabledControls} onChange={() => clearFeed(form, `options.adjustments.${enterprise.id}.supplements.feed`)} />
                                                                                                                </GridCell>
                                                                                                            )}
                                                                                                            {rsuDifference < 0 && values.options.adjustments[enterprise.id].supplements.percentage > 0 && (
                                                                                                                <GridCell className="u-xl-width1of3">
                                                                                                                    <Field name={`options.adjustments.${enterprise.id}.supplements.feed`} label="Supplementary feed" placeholder="Select a feed (Default is good quality hay)" options={supplementaryFeeds[enterprise.id]} component={SelectField} disabled={disabledControls} />
                                                                                                                </GridCell>
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    )}
                                                                                                    {((winteringScenario && enterprise.enterpriseType === "Dairy") || rsuDifference > 0 || remainingPercentage > 0) && (
                                                                                                        <Grid>
                                                                                                            <GridCell>
                                                                                                                <h3 className="u-mt-lg u-text-sm Colour--orange">{`Adjust ${enterprise.name} numbers or pasture production for remaining energy`}</h3>
                                                                                                            </GridCell>
                                                                                                            <GridCell className="u-xl-width1of3">
                                                                                                                <Field name={`options.adjustments.${enterprise.id}.animals.option`} label={`Adjust animals or pasture production`} options={animalOptions} disabled={disabledControls} required component={SelectField} />
                                                                                                            </GridCell>
                                                                                                        </Grid>
                                                                                                    )}
                                                                                                </>
                                                                                            </>
                                                                                        )}
                                                                                    </PanelBody>
                                                                                )}
                                                                                <PanelBody>
                                                                                    {enterprise.scenarios && enterprise.scenarios.length > 0 && (
                                                                                        <Grid>
                                                                                            <GridCell>
                                                                                                <h3 className="u-mt-lg u-mb-md u-text-sm">Scenario changes</h3>
                                                                                                {messagesTable(enterprise.scenarios, "ScenarioChanges")}
                                                                                            </GridCell>
                                                                                        </Grid>
                                                                                    )}

                                                                                    {(adjustedAnalysis || enterprise.error) && (
                                                                                        <Grid>
                                                                                            <GridCell>
                                                                                                <h3 className="u-mt-lg u-mb-md u-text-sm">{enterprise.name} energy and feed</h3>
                                                                                                {enterpriseResultsTable(enterprise, disabledControls)}
                                                                                                <h3 className="u-mt-lg u-mb-md u-text-sm">Monthly {enterprise.name} numbers</h3>
                                                                                                {enterpriseTable(values.enterpriseModel, afterEnterpriseModel, enterprise.id, adjustedResults)}
                                                                                            </GridCell>
                                                                                        </Grid>
                                                                                    )}
                                                                                </PanelBody>
                                                                            </Panel>
                                                                        );
                                                                    })}
                                                                </PanelBody>
                                                            </Panel>
                                                        )}
                                                        {adjustedAnalysis && !scenarioError && scenarioResults && scenarioResults.enterprises && scenarioResults.enterprises.length > 0 && (
                                                            <Panel title="Impact on supplements" className="u-mt-md" midBlue notCollapsible>
                                                                <PanelBody>
                                                                    <h3 className="u-mt-md u-mb-md u-text-sm">Supplements (before)</h3>
                                                                    <SupplementList analysis={values.before} removeLinks={true} />
                                                                    {scenarioResults && (
                                                                        <>
                                                                            <h3 className="u-mt-lg u-mb-md u-text-sm">Supplements (after)</h3>
                                                                            <SupplementList analysis={scenarioResults.after} removeLinks={true} />
                                                                        </>
                                                                    )}
                                                                </PanelBody>
                                                            </Panel>
                                                        )}
                                                    </GridCell>
                                                )}
                                                {scenario && !acceptChanges && (
                                                    <GridCell className="u-xl-width2of3">
                                                        <PlanningReport farm={farm} analysis={scenario} base={values.before} scenarioMode />
                                                    </GridCell>
                                                )}
                                                {scenario && acceptChanges && (
                                                    <GridCell>
                                                        {true && (
                                                            <div className="Alert--warning u-textCenter u-pr-lg u-pt-lg u-pl-lg" style={{ paddingBottom: "20px" }}>
                                                                <i className="icon icon-alert icon--lg icon--confirm" />
                                                                <div className="h2">
                                                                    You are about to overwrite your <b>{before.name}</b> analysis with the details from the scenario you have just run.
                                                                </div>
                                                                <div className="h2 u-mt-lg u-font-600" style={{ paddingBottom: "30px" }}>
                                                                    Are you sure you want to overwrite this analysis?
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="u-textCenter"></div>
                                                    </GridCell>
                                                )}
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {!dirtySinceLastSubmit && submitError && <Alert type="error" text={submitError} />}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {scenario && acceptChanges && <Field name="confirmed" confirmationPhrase="OVERWRITE" component={ConfirmationField} />}
                                    {disabledControls && (
                                        <Button id="back" tertiary onClick={() => clearScenarios(form)} iconBefore="icon-arrow-left">
                                            Edit scenario
                                        </Button>
                                    )}
                                    <Button id="submit" disabled={submitDisabled} tertiary={!adjustedAnalysis && !scenario} primary={scenario} submit iconAfter={scenario ? "icon-tick-circle" : adjustedAnalysis ? "icon-arrow-right" : ""}>
                                        {submitButtonText}
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    )
}

export function useScenarioToolModal(farm, analysis) {
    const [modal, openModal] = useModal(ScenarioToolModal);

    const openScenarioToolModal = () => {
        const modalProps = {
            farm,
            analysis
        };
        openModal(modalProps);
    };

    return [modal, openScenarioToolModal];
}

async function getViewModel(farm, analysis, refData) {
    if (analysis.feedSupplements && analysis.feedSupplements.length > 0) {
        const supplements = analysis.feedSupplements || [];
        const dryMatterBySupplements = await getDryMatterWeight(farm.id, analysis.id, supplements);
        supplements.forEach((supp) => {
            const dm = dryMatterBySupplements.find((dms) => dms.id === supp.id);
            supp.dryWeight = dm ? dm.dryMatterWeightInTonnes : 0;
        });
    }
    const enterpriseME = analysis && analysis.currentResults && analysis.currentResults.hasResults && _scenarioUtils.getMEByEnterprise(analysis);
    if (enterpriseME) {
        enterpriseME.forEach((e) => {
            const enterprise = analysis.enterprises.find((ent) => e.id === ent.id);
            if (enterprise) {
                e.name = utils.valueToText(refData.enterpriseTypes, enterprise.type);
                e.enterpriseType = enterprise.type;
            }
        });
    }
    const viewModel = {
        id: uuidv4(),
        name: "Scenario 1",
        before: analysis,
        enterpriseME: enterpriseME,
        farmLoss: nutrientLossViewModel(analysis),
        enterpriseModel: enterpriseViewModel(farm, analysis, refData),
        options: {
            fertiliser: {
                message: (
                    <>
                        <p>Increase/decrease amount of nitrogen applied as fertiliser on pastoral blocks (crop blocks are unaffected). Enter a percentage to remove (negative value) or add (positive value). The scenario calculates the amount of pasture lost or gained using the rate of DM grown to KG of N applied. This changes the food available to animals based on current grazing and supplements harvested. Any compost applications will not be adjusted.</p>
                        <p>
                            <b>Note:</b> This uses a crude calculation of pasture growth and so does not recognise unrealistic pasture repsonse rates. It should only be used based on an understanding of the farm and how pasture growth responds to nitrogen fertilser applied.
                        </p>
                    </>
                ),
            },
            irrigation: { message: "Change all irrigators to the selected type and use a different soil moisture strategy for managing irrigation. This changes water applied and the potential for drainage occurring. There is no impact on animals or feed." },
            structure: {
                message: (
                    <>
                        <span>Select a structure to add to the farm. Enter the percentage of animals that will be placed on the structure. Select an option for feeding the animals. This could be a combination of redirecting existing supplements, harvesting supplements or importing new supplements.</span>
                        <p>
                            <b>Note: </b>All effluent from this structure will be exported. You will need to manually set up how effluent is distributed after saving this scenario.
                        </p>
                    </>
                ),
                feed: {},
            },
            adjustments:
                enterpriseME &&
                enterpriseME.reduce((m, e) => {
                    m[e.id] = {
                        animals: { percentage: 100 },
                        supplements: { percentage: 0 },
                        imported: { percentage: 0 },
                    };
                    return m;
                }, {}),
            supplementStorageAndUtilisation: { message: "Improving the storage conditions and utilisation of your supplementary feed will reduce the amount of metabolic energy lost from storage and feeding, effectively making more feed available for your animals." },
            ghgFuelAndElectricity: {
                message: "TODO - GHG MESSAGE HERE",
                fuel: analysis.ghg && analysis.ghg.fuel,
                electricity: analysis.ghg && analysis.ghg.electricity,
            },
            removePurchased: { message: "Remove all purchased supplements (except those fed on structures). You can import new supplements for each enterprise (on the right) to make up for any food lost." },
            removePurchasedAndStorage: { message: "Remove all purchased supplements and supplements from storage (except those fed on structures). You can import new supplements for each enterprise (on the right) to make up for any food lost." },
            removeStorage: { message: "Remove all supplements from storage (except those fed on structures). You can import new supplements for each enterprise (on the right) to make up for any food lost." },
            wintering: { message: "Remove a percentage of the milking herd from the farm for a number of months. This will increase food available and so you can reduce fertiliser below or adjust animals/feed on the right of the screen. The number of animals removed is a percentage of the animals on the farm in the starting month. The number of animals between the start and end will remain the same (all other events are removed)." },
            scenarios: {},
        },
    };
    return viewModel;
}

async function runScenario(analysis) {
    const timeout = 1000 * 60 * 5; // 5 minutes
    return httpClient.post(`farms/${analysis.farmId}/runmodel`, analysis, timeout);
}

function getAnimalOptions(enterprise, rsuDifference) {
    const options = [{ text: "No change", value: "noChange" }];
    if (enterprise.enterpriseType === "Dairy") {
        options.push({ text: `${rsuDifference > 0 ? "Increase" : "Reduce"} ${enterprise.name.toLowerCase()} numbers and total milk production`, value: "changeAndChange" });
        options.push({ text: `${rsuDifference > 0 ? "Increase" : "Reduce"} ${enterprise.name.toLowerCase()} numbers without changing total milk production`, value: "changeAndKeep" });
    } else {
        options.push({ text: `${rsuDifference > 0 ? "Increase" : "Reduce"} ${enterprise.name.toLowerCase()} numbers`, value: "changeAndKeep" });
    }
    options.push({ text: `${rsuDifference > 0 ? "Reduce" : "Increase"} pasture production`, value: "changeAndPasture" });
    return options;
}

const fertiliserAdjustmentTypes = [
    { text: "Adjust application amounts", value: "applications" },
    { text: "Adjust fertilisers with N only", value: "nOnlyFertilisers" },
    { text: "Adjust N concentration in fertilisers", value: "nConcentration" },
];

const addStructureFeedOptions = [
    { text: "Feed from existing supplements and harvest the rest", value: "existing" },
    { text: "Feed from existing supplements and import the rest", value: "mixed" },
    { text: "Import all feed", value: "import" },
];
