import * as utils from "common/utils";
import * as icons from "common/icons";
import * as animalUtils from "./_utils";
import TileSubPanel from "components/TileSubPanel";
import ActionLink from "components/ActionLink";
import { enterpriseViewModel } from "common/viewModels";
import * as domain from "common/domain";
import Button from "components/Button/Button";
import { useConfirm, useNavigate, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";
import { useCreateEnterpriseModal } from "containers/BudgetHome/Animals/Enterprise/CreateEnterpriseModal";

export default function EnterpriseList({ farm, analysis, removeLinks }) {
    const refData = useRefData();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const [createEnterpriseModal, openCreateEnterpriseModal] = useCreateEnterpriseModal(farm, analysis);

    const _deleteEnterprise = (enterprise) => (e) => {
        utils.stopPropagation(e);
        const enterpriseType = utils.valueToText(refData.enterpriseTypes, enterprise.type);
        confirm(`Are you sure you want to delete the ${enterpriseType} enterprise and all its related associations?`, async () => {
            const updatedAnalysis = animalUtils.getUpdatedAnalysisFromDeletingEnterprise(analysis, enterprise.id);
            await updateAnalysisAsync(updatedAnalysis);
        });
    }

    const enterprises = enterpriseViewModel(farm, analysis, refData);

    const _gotoUrl = (url) => (e) => {
        if (url) {
            e.preventDefault();
            navigate(url);
        }
    }

    const { messages = [] } = analysis;

    return (
        <>
            <TileSubPanel title="Enterprises" green stickyHeaderName="analysis.animals.enterprises.collapsed">
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        {enterprises.length === 0 ? (
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <img src={icons.add} className="u-p-5" alt="Plus" />
                                    <p className="lead">You do not have any animal enterprises</p>
                                    <Button id="add-enterprise-button" className="IconLink--arrow-plus Button Button--lg u-mt-lg" onClick={openCreateEnterpriseModal}>
                                        Add enterprise
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="Table">
                                <table className="Calendar">
                                    <thead>
                                        <tr className="Calendar-months">
                                            <th className="Calendar-title">Name</th>
                                            {domain.farmYear.map((m, i) => (
                                                <th key={i} className="u-textCenter">
                                                    {m.substring(0, 3)}
                                                </th>
                                            ))}
                                            <th className="th--shrink">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enterprises.map((e, x) => {
                                            let alertIcon = messages.some((m) => m.entityType === "Enterprise" && m.entityId === e.id && m.severity === "Error") && "error";
                                            alertIcon = alertIcon || (messages.some((m) => m.entityType === "Enterprise" && m.entityId === e.id && m.severity === "Warning") && "warning");
                                            const url = `/app/farm/${farm.id}/analysis/${analysis.id}/animals/enterprises/${e.enterpriseTypeEnum.toLowerCase()}`;
                                            const cells = [
                                                <td className="td--keyValuePair" key={"title"}>
                                                    <span className="u-flex u-flexAlignItemsCenter">
                                                        {alertIcon && (
                                                            <span className={`Todo Todo--` + alertIcon}>
                                                                <i className="icon icon-alert" />
                                                            </span>
                                                        )}
                                                        {e.icon && <img id={`calendar.titleIcon.${e.id}`} src={e.icon} className="u-mr-xs u-p-5" width="35" height="35" alt={e.title} />}
                                                        <span className={removeLinks ? "FarmTable-value" : "FarmTable-value-link a"} id={`edit-${e.enterpriseTypeEnum}`} name={`${e.id}`}>
                                                            <span id={`edit-${e.enterpriseTypeEnum}`} name={`${e.id}`}>
                                                                {e.enterpriseType}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>,
                                            ];
                                            switch (e.type) {
                                                case "Rsu":
                                                    cells.push(
                                                        <td colSpan="12" key={x}>
                                                            <ul className="DataWidthTable FarmTable">
                                                                <li data-width="md-50" style={{ padding: "5px 20px" }}>
                                                                    <span className="inline-key">Total RSU: </span>
                                                                    <span className="inline-value">{e.totalRsu}</span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                    break;
                                                case "DairyPeak":
                                                    cells.push(
                                                        <td colSpan="12" key={x}>
                                                            <ul className="DataWidthTable FarmTable">
                                                                <li data-width="md-50" style={{ padding: "5px 20px" }}>
                                                                    <span className="inline-key">Peak number of cows milked: </span>
                                                                    <span className="inline-value"> {e.peakCowsMilked}</span>
                                                                </li>
                                                                <li data-width="md-50" style={{ padding: "5px 20px" }}>
                                                                    <span className="inline-key">AVERAGE MOB WEIGHT: </span>
                                                                    <span className="inline-value">{e.averageMobWeight ? `${e.averageMobWeight}kg` : "Use default"}</span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                    break;
                                                case "Other":
                                                    cells.push(
                                                        <td colSpan="12" key={x}>
                                                            <ul className="DataWidthTable FarmTable">
                                                                {e.otherLivestockTotalsByType.map((livestock, index) => {
                                                                    return (
                                                                        <li key={index} data-width="md-25" style={{ padding: "5px 20px" }}>
                                                                            <span className="inline-key">{livestock.type}:</span>
                                                                            <span className="inline-value"> {livestock.number.toLocaleString()}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </td>
                                                    );
                                                    break;
                                                case "DairyReplacements":
                                                    cells.push(
                                                        <td colSpan="12" key={x}>
                                                            <ul className="DataWidthTable FarmTable">
                                                                <li style={{ padding: "5px 20px" }}>
                                                                    <span className="inline-key">Replacement grazing:</span>
                                                                    <span className={`inline-value ${e.dairyReplacementGrazingSet ? "" : "u-font-red"}`}> {e.dairyReplacementGrazingMessage}</span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                    break;
                                                case "OutdoorPigs":
                                                    cells.push(
                                                        <td colSpan="12" key={x}>
                                                            <ul className="DataWidthTable FarmTable">
                                                                <li data-width="md-50" style={{ padding: "5px 20px" }}>
                                                                    <span className="inline-key">{e.growOutUnitOnly ? "Growers brought in per week: " : "Number of sows and mated gilts: "}</span>
                                                                    <span className="inline-value">{e.growOutUnitOnly ? e.numbers.growersFinishers.broughtIn : e.numbers.sows.numberSowsMatedGilts}</span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    );
                                                    break;
                                                default:
                                                    e.slots.length > 0 &&
                                                        domain.farmYear.forEach((calendarMonth, i) => {
                                                            const slot = e.slots.find((slot) => slot.monthIndx === i) || e.slots.find((slot) => slot.month === calendarMonth);
                                                            if (slot) {
                                                                cells.push(
                                                                    <td key={`${x}_${i}`} className="u-textCenter">
                                                                        {slot.icon && <img id={`calendar.${calendarMonth}.icon.${e.id}`} src={slot.icon} width={45} height={45} className="Calendar-icon u-p-xxs" alt="Calendar icon" />}
                                                                        {slot.iconText && (
                                                                            <span id={`calendar.${calendarMonth}.iconText.${e.id}`} className="Calendar-icon-text">
                                                                                {slot.iconText}
                                                                            </span>
                                                                        )}
                                                                        {slot.text && <span id={`calendar.${calendarMonth}.text.${e.id}`}>{slot.text.toLocaleString()}</span>}
                                                                    </td>
                                                                );
                                                            } else {
                                                                cells.push(<td key={`${x}_${i}`} className="Calendar-slot"></td>);
                                                            }
                                                        });
                                                    break;
                                            }
                                            cells.push(
                                                <td key={`del-${x}`}>
                                                    <ActionLink id={`delete-enterprise-${e.id}`} onClick={_deleteEnterprise(e)} className="IconLink--trash" title="Delete">
                                                        <span></span>
                                                    </ActionLink>
                                                </td>
                                            );
                                            return (
                                                <tr key={x} className="u-link hover" onClick={_gotoUrl(url)}>
                                                    {cells}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </TileSubPanel>
            {createEnterpriseModal}
        </>
    )
}
