import { useState, useEffect, useCallback, useRef } from "react";
import { Grid, GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import NLossByFarmsChart from "./NLossByFarmsChart";
import NAppliedFromFertiliserToPastoralBlocksChart from "./NAppliedFromFertiliserToPastoralBlocksChart";
import PLossByFarmsChart from "./PLossByFarmsChart";
import GhgByFarmsChart from "./GhgByFarmsChart";
import NSurplusByFarmsChart from "./NSurplusByFarmsChart";
import NceByFarmsChart from "./NceByFarmsChart";
import SelectedFarm from "./SelectedFarm";
import ReportingFarmsMap from "../ReportingFarmsMap";
import { useRefData } from "common/hooks";

export default function BenchmarkingByFarms({ benchmarkData, farmId, farmGroup }) {
    const selectedFarmRef = useRef();
    const benchmarkFarms = useBenchmarkFarms(benchmarkData);
    const availableEnterprises = useAvailableEnterprises(benchmarkFarms);
    const [selectedEnterprises, toggleSelectedEnterprise] = useSelectedEnterprise(availableEnterprises);
    const farms = useFarms(benchmarkFarms, availableEnterprises, selectedEnterprises);
    const [selectedFarm, setSelectedFarm] = useState();
    const mappedFarms = farms ? farms.filter((f) => f.longitude && f.latitude) : [];
    const selectedFarmTop = (selectedFarmRef && selectedFarmRef.current && selectedFarmRef.current.getBoundingClientRect().top) || 0;
    const paddingTop = `${selectedFarmTop < 0 ? -selectedFarmTop + 15 : 15}px`;
    const hasBenchmarkData = benchmarkFarms && benchmarkFarms.length > 0;

    return (
        <>
            {farmGroup && !farmGroup.showBenchmarkReport && farmGroup.role === "Admin" && <Alert type="warning" text="Benchmark reporting is hidden from group members" />}
            {!hasBenchmarkData && (
                <div className="HeroPanel">
                    <div className="Tile-body-message">
                        <h3 className="u-mt-md">There are no results for this period</h3>
                    </div>
                </div>
            )}
            {hasBenchmarkData && (
                <>
                    <Alert
                        type="info"
                        html="<p>Shows how farms compare to each other. For farms that you have permission to see, the map shows the location of each farm. The size of the circle indicates the size of the farm. You can click on a circle to view its details on the right.</p>
                    <p>The bar charts below compare farm results. If viewing this via a farm, the other farms on the bar charts will be shown in a lighter colour. Move the mouse over bars to see loss numbers. If you have access to the farm, the name will also be shown. Select a bar to see the details for the farm.</p>
                    <p>Animal enterprise filtering will only show those farms that have that enterprise. A farm may have more than one enterprise. Select no animals to see cropping only farms.</p>"
                    />
                    <div className="HeroPanel u-print-none">
                        <div className="Field u-mt-md">
                            <div className="h5">Show farms containing</div>
                            <ul className="BlockList u-mt-sm">
                                {availableEnterprises &&
                                    availableEnterprises.map((et) => {
                                        const isSelected = selectedEnterprises && selectedEnterprises.includes(et.value);
                                        return (
                                            <li key={et.value} id={et.value} onClick={() => toggleSelectedEnterprise(et.value)} className={`BlockList-item ${isSelected ? "is-active" : ""}`}>
                                                <span id={`${et.value}-name`}>
                                                    {et.text} ({(et.count || 0).toLocaleString()})
                                                </span>
                                                {isSelected && (
                                                    <ActionLink className="BlockList-item-close">
                                                        <i className="icon icon-cross" />
                                                    </ActionLink>
                                                )}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                    <div className="u-print-show">
                        <div className="u-page-break">
                            {mappedFarms && mappedFarms.length > 0 && (
                                <Panel title="Farms map" className="u-mt-md" skyBlue>
                                    <PanelBody>
                                        <ReportingFarmsMap locations={mappedFarms} selectedLocation={selectedFarm} onLocationSelected={setSelectedFarm} />
                                    </PanelBody>
                                </Panel>
                            )}
                        </div>
                        <div className="u-page-break">
                            <NLossByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                        <div className="u-page-break">
                            <NAppliedFromFertiliserToPastoralBlocksChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                        <div className="u-page-break">
                            <NSurplusByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                        <div className="u-page-break">
                            <NceByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                        <div className="u-page-break">
                            <PLossByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                        <div className="u-page-break">
                            <GhgByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </div>
                    </div>
                    <Grid className="u-print-none">
                        <GridCell className="u-md-width3of4 u-xl-width4of5 u-pr-md">
                            {mappedFarms && mappedFarms.length > 0 && (
                                <Panel title="Farms map" className="u-mt-md" skyBlue>
                                    <PanelBody>
                                        <ReportingFarmsMap locations={mappedFarms} selectedLocation={selectedFarm} onLocationSelected={setSelectedFarm} />
                                    </PanelBody>
                                </Panel>
                            )}
                            <NLossByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                            <NAppliedFromFertiliserToPastoralBlocksChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                            <NSurplusByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                            <NceByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                            <PLossByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                            <GhgByFarmsChart farms={farms} farmId={farmId} selectedFarm={selectedFarm} onFarmSelected={setSelectedFarm} />
                        </GridCell>
                        <GridCell className="u-md-width1of4 u-xl-width1of5 u-pl-0">
                            <div style={{ paddingTop }} ref={selectedFarmRef}>
                                <SelectedFarm selectedFarm={selectedFarm} farmId={farmId} />
                            </div>
                        </GridCell>
                    </Grid>
                </>
            )}
        </>
    );
}

function useBenchmarkFarms(benchmarkData) {
    const [benchmarkFarms, setBenchmarkFarms] = useState();

    useEffect(() => {
        if (benchmarkData) {
            const hasBenchmarkData = (benchmarkData.farms || []).some((farm) => farm.hasResults);
            setBenchmarkFarms(hasBenchmarkData ? benchmarkData.farms : []);
        }
    }, [benchmarkData, benchmarkFarms]);

    return benchmarkFarms;
}

function useAvailableEnterprises(benchmarkFarms) {
    const refData = useRefData();
    const [availableEnterprises, setAvailableEnterprises] = useState();

    useEffect(() => {
        if (benchmarkFarms && refData && !availableEnterprises) {
            const enterprises = benchmarkFarms.reduce(
                (results, farm) => {
                    (refData.enterpriseTypes || []).forEach((et) => {
                        const enterprise = (farm.enterprises || []).find((rsu) => rsu.type === et.value);
                        if (enterprise) {
                            const result = results.find((x) => x.value === et.value);
                            if (!result) {
                                results.push({ ...et, count: 1 });
                            } else {
                                result.count++;
                            }
                        }
                    });
                    return results;
                },
                [{ value: "NONE", text: "No animals", count: benchmarkFarms.filter((f) => f.enterprises.length === 0).length }]
            );

            setAvailableEnterprises(enterprises);
        }
    }, [benchmarkFarms, refData, availableEnterprises]);

    return availableEnterprises;
}

function useSelectedEnterprise(availableEnterprises) {
    const [selectedEnterprises, setSelectedEnterprises] = useState();

    useEffect(() => {
        if (availableEnterprises && !selectedEnterprises) {
            setSelectedEnterprises(availableEnterprises.map((s) => s.value));
        }
    }, [availableEnterprises, selectedEnterprises]);

    const toggleSelectedEnterprise = useCallback(
        (toggledEnterprise) => {
            setSelectedEnterprises((prevState) => {
                const removeFromSelection = prevState.includes(toggledEnterprise);
                const updatedSelections = removeFromSelection ? prevState.filter((ent) => ent !== toggledEnterprise) : [...prevState, toggledEnterprise];
                const nextState = availableEnterprises.filter((et) => updatedSelections.includes(et.value)).map((et) => et.value);
                return nextState;
            });
        },
        [availableEnterprises]
    );

    return [selectedEnterprises, toggleSelectedEnterprise];
}

function useFarms(benchmarkFarms, availableEnterprises, selectedEnterprises) {
    const [farms, setFarms] = useState([]);

    useEffect(() => {
        if (benchmarkFarms && availableEnterprises && selectedEnterprises) {
            const filteredFarms = selectedEnterprises.length === availableEnterprises.length ? benchmarkFarms : benchmarkFarms.filter((f) => f.enterprises.some((enterprise) => selectedEnterprises.includes(enterprise.type)) || (selectedEnterprises.includes("NONE") && f.enterprises.length === 0));
            setFarms(filteredFarms);
        }
    }, [benchmarkFarms, availableEnterprises, selectedEnterprises]);

    return farms;
}
