import { Bar } from "react-chartjs-2";
import * as units from "common/units";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { useOverseerModelRun } from "containers/BudgetHome";

export default function NutrientLossGraphs({ analysis, showNitrogenChartData, showPhosphorusChartData }) {
    const chartData = useBlockSummaryNutrientLossChartData(analysis, showNitrogenChartData, showPhosphorusChartData);

    if (!chartData) return null;

    const averageData = {
        labels: chartData.averages.losses.map(x => x.name),
        datasets: [
            {
                fill: "origin",
                lineTension: 0,
                backgroundColor: chartData.color,
                borderColor: chartData.color,
                pointRadius: 0,
                data: chartData.averages.losses.map(x => x.amount),
            },
        ],
    };

    const totalData = {
        labels: chartData.totals.losses.map(x => x.name),
        datasets: [
            {
                fill: "origin",
                lineTension: 0,
                backgroundColor: chartData.color,
                borderColor: chartData.color,
                pointRadius: 0,
                data: chartData.totals.losses.map(x => x.amount),
            },
        ],
    };

    const options = {
        scales: {
            x: { display: true, ticks: { autoSkip: false } },
            y: { ticks: { beginAtZero: true }, display: true },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <>
            <Panel title={chartData.averages.title}>
                <Watermark />
                <PanelBody>
                    <Bar data={averageData} height={30} options={options} />
                </PanelBody>
            </Panel>
            <Panel title={chartData.totals.title}>
                <Watermark />
                <PanelBody>
                    <Bar data={totalData} height={30} options={options} />
                </PanelBody>
            </Panel>
        </>
    );
}


function useBlockSummaryNutrientLossChartData(analysis, showNitrogenChartData, showPhosphorusChartData) {
    const { data } = useOverseerModelRun(analysis);

    if (data) {
        if (showNitrogenChartData) {
            const firstBlockWithNitrogen = data.modelResults.blocks.find(b => b.nitrogen);
            const nitrogenAverageLossUnit = firstBlockWithNitrogen ? units.getNotationWithoutOf(firstBlockWithNitrogen.nitrogen.averageLoss.unit) : "kg/ha/yr";
            const nitrogenTotalLossUnit = firstBlockWithNitrogen ? units.getNotationWithoutOf(firstBlockWithNitrogen.nitrogen.totalLoss.unit) : "kg/yr";

            const nitrogenLossChartData = {
                color: "#47A96D",
                averages: {
                    title: `Average N loss from root zone  (${nitrogenAverageLossUnit})`,
                    losses: data.modelResults.blocks.filter(b => b.nitrogen).map(x => ({
                        name: analysis.blocks.find(b => b.id === x.id).name,
                        amount: x.nitrogen.averageLoss.value,
                    }))
                },
                totals: {
                    title: `Total N loss from root zone (${nitrogenTotalLossUnit})`,
                    losses: data.modelResults.blocks.filter(b => b.nitrogen).map(x => ({
                        name: analysis.blocks.find(b => b.id === x.id).name,
                        amount: x.nitrogen.totalLoss.value
                    }))
                }
            };
            if (data.modelResults.farm.nitrogen.grossLossFromOtherSources) {
                nitrogenLossChartData.totals.losses.push({
                    name: "Other sources",
                    amount: data.modelResults.farm.nitrogen.grossLossFromOtherSources.value
                });
            }
            return nitrogenLossChartData;
        } else if (showPhosphorusChartData) {
            const firstBlockWithPhosphorus = data.modelResults.blocks.find(b => b.phosphorus);
            const phosphorusAverageLossUnit = firstBlockWithPhosphorus ? units.getNotationWithoutOf(firstBlockWithPhosphorus.phosphorus.averageLoss.unit) : "kg/ha/yr";
            const phosphorusTotalLossUnit = firstBlockWithPhosphorus ? units.getNotationWithoutOf(firstBlockWithPhosphorus.phosphorus.totalLoss.unit) : "kg/yr";

            const phosphorusLossChartData = {
                color: "#e39153",
                averages: {
                    title: `Average P loss (${phosphorusAverageLossUnit})`,
                    losses: data.modelResults.blocks.filter(b => b.phosphorus).map(x => ({
                        name: analysis.blocks.find(b => b.id === x.id).name,
                        amount: x.phosphorus.averageLoss.value,
                    }))
                },
                totals: {
                    title: `Total P loss (${phosphorusTotalLossUnit})`,
                    losses: data.modelResults.blocks.filter(b => b.phosphorus).map(x => ({
                        name: analysis.blocks.find(b => b.id === x.id).name,
                        amount: x.phosphorus.totalLoss.value,
                    }))
                }
            };
            if (data.modelResults.farm.phosphorus.grossLossFromOtherSources) {
                phosphorusLossChartData.totals.losses.push({
                    name: "Other sources",
                    amount: data.modelResults.farm.phosphorus.grossLossFromOtherSources.value
                });
            }
            return phosphorusLossChartData;
        }
    }

    return undefined;
}
