import React from "react";
import { connect } from "react-redux";
import Alert from "components/Alert";

const TextareaPack = ({ input, label, info, placeholder, id, readOnly, isHidden, requiredLabel, noLabel, val, value, onChange, onBlur, disabled, meta: { touched, error, warning, nrf }, uom, validateOnRender, autoFocus, dataWidth, hideErrors, maxLength, className, tip, showQuickTips, height = 100 }) => {
    if (isHidden) return null;

    if (placeholder === 0) placeholder = "0";

    return nrf ? (
        <div className={`Field ${val && val.touched && val.error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <label htmlFor={id} className="Field-label">
                {label}
                {requiredLabel === true && <sup className="required">*</sup>}
                {label === undefined && "\u00A0"}
            </label>
            <div className="Textarea">
                <textarea id={id} name={id} placeholder={placeholder || label} disabled={disabled} readOnly={readOnly} style={{ height: `${height}px` }} autoFocus={autoFocus ? true : false} onChange={(e) => (onChange ? onChange(e) : undefined)} onBlur={(e) => (onBlur ? onBlur(e) : undefined)} value={value || ""} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            <small className="Field-error" id={`${id}-error`}>
                {val && val.message}
            </small>
            {showQuickTips && tip && <Alert type="help" text={tip} />}
        </div>
    ) : (
        <div className={`Field ${(validateOnRender || touched) && error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            {noLabel !== true && (
                <label htmlFor={id || input.name} className="Field-label">
                    {label}
                    {requiredLabel === true && <sup className="required">*</sup>}
                    {label === undefined && "\u00A0"}
                </label>
            )}
            <div className="Textarea">
                <textarea {...input} placeholder={placeholder || label} id={id || input.name} style={{ height: `${height}px` }} readOnly={readOnly} autoFocus={autoFocus ? true : false} disabled={disabled} maxLength={maxLength} />
                {uom && <small className="Input-helper">{uom}</small>}
            </div>
            {hideErrors !== true && (validateOnRender || touched) && error && (
                <small className="Field-error" id={`${id || input.name}-error`}>
                    {error}
                </small>
            )}
            {info && <Alert type="info" text={info} />}
            {showQuickTips && tip && <Alert type="help" text={tip} />}
        </div>
    );
};

export default connect((state) => {
    return {
        showQuickTips: state.app.showQuickTips,
    };
}, null)(TextareaPack);
