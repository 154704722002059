import useDetectPrint from "react-detect-print";
import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";

export default function Overview({ aggregatedData, farmsWithResultsCount, waiting = false, includeLatestYearEnds = false }) {
    const printing = useDetectPrint();
    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;
    return (
        <Panel title="Overview" loading={waiting} green>
            <PanelBody grey={!printing} className="u-print-border">
                <OverviewContent aggregatedData={aggregatedData} farmsWithResultsCount={farmsWithResultsCount} includeLatestYearEnds={includeLatestYearEnds} className="HeroPanel" />
            </PanelBody>
        </Panel>
    )
}

export const OverviewContent = ({ aggregatedData, farmsWithResultsCount, includeLatestYearEnds = false, className }) => {
    if (!aggregatedData || !aggregatedData.results || aggregatedData.totalArea === 0) return null;
    const isTonnes = aggregatedData.results.nLossInKg > 10000;
    const unit = isTonnes ? "tonnes" : "kg";

    return (
        <>
            {includeLatestYearEnds && <Alert type="info" text="Shows total losses of nitrogen, phosphorus, GHG and drainage for the most recently published year end analyses up until the selected year. The per hectare values are calculated by dividing the total loss by the total area." />}
            {!includeLatestYearEnds && <Alert type="info" text="Shows total losses of nitrogen, phosphorus, GHG and drainage for the year end analyses published for the selected year. The per hectare values are calculated by dividing the total loss by the total area." />}
            <div className={className}>
                <div className="HeroValue u-pt-lg u-flexWrap">
                    <div className="HeroValue-item">
                        <h5>Productive area</h5>
                        <div className="HeroValue-item-number" id="productive-area">
                            {utils.round(aggregatedData.productiveArea, 0).toLocaleString()} <small>ha</small>
                        </div>
                    </div>
                    <div className="HeroValue-item">
                        <h5>Farms with results</h5>
                        <div className="HeroValue-item-number" id="farms-with-results">
                            {farmsWithResultsCount.toLocaleString()} <small>farm{farmsWithResultsCount === 1 ? "" : "s"}</small>
                        </div>
                    </div>
                    <div className="HeroValue-item">
                        <h5>Irrigated area</h5>
                        <div className="HeroValue-item-number" id="irrigated-area">
                            {`${utils.round(aggregatedData.irrigatedArea, 0).toLocaleString()} (${utils.round((100 * aggregatedData.irrigatedArea) / aggregatedData.productiveArea, 0).toLocaleString()}%)`} <small>ha</small>
                        </div>
                    </div>
                    <div className="HeroValue-item">
                        <h5>Effluent area receiving liquids</h5>
                        <div className="HeroValue-item-number" id="effluent-liquid-area">
                            {`${utils.round(aggregatedData.results.effluentAreaReceivingLiquid || 0, 0).toLocaleString()} (${utils.round((100 * (aggregatedData.results.effluentAreaReceivingLiquid || 0)) / aggregatedData.productiveArea, 0).toLocaleString()}%)`} <small>ha</small>
                        </div>
                    </div>
                    <div className="HeroValue-item">
                        <h5>Total area</h5>
                        <div className="HeroValue-item-number" id="total-area">
                            {utils.round(aggregatedData.totalArea, 0).toLocaleString()} <small>ha</small>
                        </div>
                    </div>
                </div>
                <div className="u-flex u-flexWrap u-flexJustifyCenter u-mt-lg">
                    <div className="ColourPanel ColourPanel--nloss">
                        <div className="u-pl-md">
                            <h5>Nitrogen loss (after wetland removal)</h5>
                            <div className="u-mt-sm u-pl-lg">
                                <div className="HeroValue-item-number" id="nloss-total">
                                    {aggregatedData.results.nLossInKgStats ? utils.round(aggregatedData.results.nLossInKgStats.total / (isTonnes ? 1000 : 1), 0).toLocaleString() : 0} <small>{unit}</small>
                                </div>
                                <div className="HeroValue-item-number" id="nloss-ha">
                                    {aggregatedData.results.nLossInKgStats ? utils.round(aggregatedData.results.nLossInKgStats.total / aggregatedData.totalArea, 1).toLocaleString() : 0} <small>kg/ha</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ColourPanel ColourPanel--nloss">
                        <div className="u-pl-md">
                            <h5>Nitrogen removed by wetlands</h5>
                            <div className="u-mt-sm u-pl-lg">
                                <div className="HeroValue-item-number" id="wetland-total">
                                    {utils.round(aggregatedData.results.nLossRemovedByWetlandsInKg / (isTonnes ? 1000 : 1), 0).toLocaleString()} <small>{unit}</small>
                                </div>
                                <div className="HeroValue-item-number" id="wetland-percent">
                                    {aggregatedData.results.nLossInKgStats ? utils.round((100 * aggregatedData.results.nLossRemovedByWetlandsInKg) / (aggregatedData.results.nLossInKgStats.total + aggregatedData.results.nLossRemovedByWetlandsInKg), 1) : 0} <small>% of total loss</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ColourPanel ColourPanel--ploss">
                        <div className="u-pl-md">
                            <h5>Phosphorus loss</h5>
                            <div className="u-mt-sm u-pl-lg">
                                <div className="HeroValue-item-number" id="ploss-total">
                                    {aggregatedData.results.pLossInKgStats ? utils.round(aggregatedData.results.pLossInKgStats.total / (isTonnes ? 1000 : 1), 0).toLocaleString() : 0} <small>{unit}</small>
                                </div>
                                <div className="HeroValue-item-number" id="ploss-ha">
                                    {aggregatedData.results.pLossInKgStats ? utils.round(aggregatedData.results.pLossInKgStats.total / aggregatedData.totalArea, 1).toLocaleString() : 0} <small>kg/ha</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ColourPanel ColourPanel--ghg">
                        <div className="u-pl-md">
                            <h5>GHG emissions</h5>
                            <div className="u-mt-sm u-pl-lg">
                                <div className="HeroValue-item-number" id="ghg-total">
                                    {utils.numeric.format(aggregatedData.results.ghgKg / 1000)} <small>t CO2e/yr</small>
                                </div>
                                <div className="HeroValue-item-number" id="ghg-ha">
                                    {utils.numeric.format(aggregatedData.results.ghgKg / aggregatedData.totalArea)} <small>kg CO2e/ha/yr</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
