import { Link } from "react-router-dom";
import * as utils from "common/utils";
import { usePortalModal } from "common/effects";
import ActionLink from "components/ActionLink";
import ChangeBlockTypeModal from "./Modals/ChangeBlockTypeModal";
import { useBlockModal } from "./Modals/BlockModal";
import { categoriseMessage } from "containers/BudgetHome/TabMessages";
import { useConfirm, useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function BlockListCard({ farm, analysis, block }) {
    const [openBlockModal, blockModal] = useBlockModal(farm, analysis);
    const online = useOnline();
    const refData = useRefData();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _blockIcon = utils.getBlockIcon(block.type);
    const _blockType = utils.getBlockTypeText(refData, block);

    const { openModal, getModal } = usePortalModal();

    const _deleteBlock = (budget, block) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete the "${block.name}" block?`, async () => {
            utils.deleteOrphanBlockRef(analysis, block.id);
            analysis.blocks = analysis.blocks.filter((b) => b.id !== block.id);
            await updateAnalysisAsync(analysis);
        });
    };

    const changeBlockType = (analysis, block) => () => {
        const modalProps = {
            analysis,
            block,
        };
        openModal("ChangeBlockTypeModal", modalProps);
    };
    const changeBlockTypeModal = getModal("ChangeBlockTypeModal", ChangeBlockTypeModal);

    const { messages = [] } = analysis;
    const errorIcon = online && messages.find((m) => categoriseMessage(m, "Blocks") && m.entityType === "Block" && m.entityId === block.id && m.severity === "Error") ? "error" : undefined;
    const alertIcon = errorIcon || (online && messages.find((m) => categoriseMessage(m, "Blocks") && m.entityType === "Block" && m.entityId === block.id && m.severity === "Warning" && !analysis.suppressedMessageCodes.includes(m.code)) ? "warning" : undefined);

    const showClimate = utils.requiresClimate(block.type);

    let averageTemp = `Using farm climate (${farm.defaultClimate.averageTemp} ˚ C)`;
    if (block.climate) {
        averageTemp = block.climate.averageTemp + " ˚ C";
    }

    let averageRain = `Using farm climate (${farm.defaultClimate.averageRain.toLocaleString()} mm/yr)`;
    if (block.climate) {
        averageRain = block.climate.averageRain.toLocaleString() + " mm/yr";
    }

    let annualPET = `Using farm climate (${farm.defaultClimate.annualPetInMM.toLocaleString()} mm/yr)`;
    if (block.climate) {
        if (block.climate.annualPetInMM && block.climate.annualPetInMM > 0) annualPET = block.climate.annualPetInMM.toLocaleString() + " mm/yr";
        else if (block.climate.annualPET) annualPET = utils.valueToText(refData.petValues, block.climate.annualPET);
    }

    return (
        <>
            <div className="FarmTable_wrapper" id={block.id}>
                <ul className="DataWidthTable FarmTable hover u-link" onClick={() => openBlockModal(block.id)}>
                    <li className="FarmTable-title" data-width="md-100 xl-30">
                        <div className="FarmTitle">
                            {alertIcon && (
                                <span className={`Todo Todo--${alertIcon} ${errorIcon ? "error-anchor" : ""}`}>
                                    <i className="icon icon-alert" />
                                </span>
                            )}
                            <img className="FarmTitle-icon" src={_blockIcon} alt="Block Type" />
                            <div className="FarmTitle-heading">
                                <span className="FarmTitle-link a">
                                    <span className="FarmTitle-name" id={`title_${block.id}`} name={block.name} title={block.name}>
                                        {block.name}
                                    </span>
                                </span>
                                <div className="FarmTitle-sub" id={`${block.id}_BT`}>
                                    {_blockType}
                                </div>
                            </div>
                        </div>
                    </li>

                    <li data-width="md-25 xl-10">
                        <div className="FarmTable-keyValuePair">
                            <div className="FarmTable-keyValue">
                                <span className="FarmTable-key">Effective area</span>
                                <span className="FarmTable-value" id={`${block.id}_EA`}>
                                    {block.areaInHectares.toLocaleString()}ha
                                </span>
                            </div>
                        </div>
                    </li>
                    {showClimate && (
                        <>
                            <li data-width="md-25 xl-20">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Avg temp</span>
                                        <span className="FarmTable-value" id={`${block.id}_AT`}>
                                            {averageTemp}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li data-width="md-25 xl-20">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Avg rainfall</span>
                                        <span className="FarmTable-value" id={`${block.id}_AR`}>
                                            {averageRain}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li data-width="md-25 xl-20">
                                <div className="FarmTable-keyValuePair">
                                    <div className="FarmTable-keyValue">
                                        <span className="FarmTable-key">Annual PET</span>
                                        <span className="FarmTable-value" id={`${block.id}_AP`}>
                                            {annualPET}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
                <div className="ActionsBar">
                    <div className="ActionsBar-right">
                        <ul className="ActionsBar-links">
                            <li>
                                <ActionLink onClick={changeBlockType(analysis, block)} className="IconLink--replace" id={`change_${block.id}`}>
                                    Change block type
                                </ActionLink>
                            </li>
                            <li>
                                <ActionLink onClick={_deleteBlock(analysis, block)} className="IconLink--trash" id={`delete_${block.id}`}>
                                    Delete block
                                </ActionLink>
                            </li>
                            <li>
                                {true && (
                                    <ActionLink onClick={() => openBlockModal(block.id)} className="IconLink--edit" id={`edit_${block.id}`}>
                                        Edit block
                                    </ActionLink>
                                )}
                                {false && (
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/blocks/${block.id}`} className="IconLink--edit" id={`edit_${block.id}`} name={block.name}>
                                        Edit block
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {changeBlockTypeModal}
            {blockModal}
        </>
    );
}
