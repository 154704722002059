import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useIsEducationProviderUser, useIsStudentUser } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as icons from "common/icons";
import { Panel, PanelBody } from "components/Panel";
import FarmList from "./FarmList";
import ZendeskLink from "components/Help/ZendeskLink";
import CreateFarmLink from "containers/Farm/CreateFarmLink";

export default function MyFarms() {
    const { isLoading: myFarmsLoading, data: myFarms } = useMyFarms();
    const isStudent = useIsStudentUser();
    const isEducationProvider = useIsEducationProviderUser();

    const hasMyFarms = myFarms?.length > 0;

    return (
        <>
            {hasMyFarms && (isStudent || isEducationProvider) && (
                <Panel title={isStudent ? "Templates" : "Shared with student groups"} iconClassName="IconLink--blocks" loading={myFarmsLoading}>
                    <PanelBody loading={myFarmsLoading}>
                        <FarmList farms={myFarms} templates />
                    </PanelBody>
                </Panel>
            )}
            <Panel title="My farms" iconClassName="IconLink--blocks" loading={myFarmsLoading}>
                <PanelBody loading={myFarmsLoading}>
                    {hasMyFarms && <FarmList farms={myFarms} />}
                    {!hasMyFarms && (
                        <div className="Tile-body-message">
                            <div className="Alert Alert--info" style={{ textAlign: "left", marginTop: "-60px" }}>
                                <i className="icon icon-info" id="register-info" />
                                <span>
                                    Please read the{" "}
                                    <b>
                                        {" "}
                                        <ZendeskLink title="farm account guide" url="https://support.overseer.org.nz/hc/en-us/articles/900000887303" rel="noopener noreferrer" target="_blank" />
                                    </b>{" "}
                                    to help you get started with creating farms.
                                    <br /> For any other help, please search the{" "}
                                    <b>
                                        <ZendeskLink title="OverseerFM knowledge base." url="https://support.overseer.org.nz/" rel="noopener noreferrer" target="_blank" />
                                    </b>
                                </span>
                            </div>
                            <img className="u-p-5" src={icons.add} alt="Plus" />
                            <p className="lead">You do not have any farms</p>
                            <CreateFarmLink id="farm-list-create" className="Button Button--lg u-mt-lg" />
                            <Link to="/app/search/farms" id="farm-search-link" className="IconLink--search Button Button--lg u-mt-md">
                                Find existing farms
                            </Link>
                        </div>
                    )}
                </PanelBody>
            </Panel>
        </>
    )
}

function useMyFarms() {
    const query = useQuery({
        queryKey: ["dashboard", "my-farms"],
        queryFn: async () => httpClient.get(`farms`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error
    };
}
