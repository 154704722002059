import { Link } from "react-router-dom";
import * as icons from "common/icons";
import TileSubPanel from "components/TileSubPanel";
import { supplementsViewModel } from "common/viewModels";
import ActionLink from "components/ActionLink";
import { useConfirm, useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function SupplementList({ farm, analysis, removeLinks }) {
    const online = useOnline();
    const refData = useRefData();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const _deleteSupplement = (supplement) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete this supplement?`, async () => {
            analysis.feedSupplements = analysis.feedSupplements.filter((item) => item.id !== supplement.id);
            await updateAnalysisAsync(analysis);
        });
    };

    const viewModel = supplementsViewModel(analysis, refData);

    const getTotals = (type, amountType) => {
        const lineItems = viewModel.filter((b) => b.type === type && b.amountType === amountType);
        let suffix = "";
        switch (amountType) {
            case "Weight":
                suffix = " tonnes ";
                break;
            case "Bales":
                suffix = " bales ";
                break;
            case "Cuts":
                suffix = " cuts ";
                break;
            case "Volume":
                suffix = " m3 ";
                break;
            default:
                break;
        }
        const amount = lineItems.reduce((total, item) => item.amounts.totalSourced + total, 0);
        return amount > 0 ? amount.toLocaleString() + suffix : null;
    };

    const purchasedWeight = getTotals("Purchased", "Weight");
    const purchasedBales = getTotals("Purchased", "Bales");
    const purchasedCuts = getTotals("Purchased", "Cuts");
    const purchasedVolume = getTotals("Purchased", "Volume");
    const purchasedList = [];
    purchasedWeight && purchasedList.push(purchasedWeight);
    purchasedBales && purchasedList.push(purchasedBales);
    purchasedCuts && purchasedList.push(purchasedCuts);
    purchasedVolume && purchasedList.push(purchasedVolume);

    const harvestedWeight = getTotals("Harvested", "Weight");
    const harvestedBales = getTotals("Harvested", "Bales");
    const harvestedCuts = getTotals("Harvested", "Cuts");
    const harvestedVolume = getTotals("Harvested", "Volume");
    const harvestedList = [];
    harvestedWeight && harvestedList.push(harvestedWeight);
    harvestedBales && harvestedList.push(harvestedBales);
    harvestedCuts && harvestedList.push(harvestedCuts);
    harvestedVolume && harvestedList.push(harvestedVolume);

    return (
        <>
            <TileSubPanel title="Supplements" green stickyHeaderName="budget.supplements.supplements.collapsed">
                {
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            {viewModel.length === 0 && (
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <img className="u-p-5" src={icons.add} alt="Plus" />
                                        <p className="lead">You do not have any feed supplements yet</p>
                                        <p>Click a button below to get started</p>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/harvested`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-harvested-button">
                                            Add harvested supplement
                                        </Link>
                                        <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/imported`} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-imported-button">
                                            Add imported supplement
                                        </Link>
                                    </div>
                                </div>
                            )}
                            {viewModel.length > 0 && (
                                <div className="Table u-mt-md">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th data-width="md-15 xl-15">Category</th>
                                                <th data-width="md-15 xl-15">Feed</th>
                                                <th data-width="md-25 xl-25">Source</th>
                                                <th data-width="md-25 xl-5">Dry weight</th>
                                                <th data-width="md-10 xl-7.5">Sourced</th>
                                                <th data-width="md-10 xl-7.5">Distributed</th>
                                                <th data-width="md-5 xl-5">Remaining</th>
                                                <th data-width="md-20 xl-20">Destination</th>
                                                {viewModel.length > 0 && !removeLinks && <th className="th--shrink">&nbsp;</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewModel.map((vm) => {
                                                return (
                                                    <tr key={vm.id}>
                                                        <td>
                                                            <span className="u-flex u-flexAlignItemsCenter">
                                                                {vm.hasErrors && online && (
                                                                    <span className="Todo Todo--error error-anchor">
                                                                        <i className="icon icon-alert" />
                                                                    </span>
                                                                )}
                                                                {!vm.hasErrors && vm.hasWarnings && online && (
                                                                    <span className="Todo Todo--warning">
                                                                        <i className="icon icon-alert" />
                                                                    </span>
                                                                )}
                                                                <img className="u-mr-xs u-p-5" src={vm.icon} width="35" height="35" alt={`${vm.category} icon`} />
                                                                <span id={`supplement-category-${vm.id}`}>{vm.category}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span id={`supplement-feed-${vm.id}`}>{`${vm.feed}`}</span>
                                                        </td>
                                                        <td>
                                                            {!removeLinks && (
                                                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${vm.isHarvested ? "harvested" : "imported"}/${vm.id}`} className="u-flex u-flexAlignItemsCenter" id={`edit-supplement-${vm.id}`}>
                                                                    <span>
                                                                        <b>{vm.sourceType}</b> {vm.sourcedFrom.join(", ")}
                                                                    </span>
                                                                </Link>
                                                            )}
                                                            {removeLinks && (
                                                                <span>
                                                                    <b>{vm.sourceType}</b> {vm.sourcedFrom.join(", ")}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <span id={`supplement-dryweight-${vm.id}`}>{vm.type === "FromStorage" || vm.amounts.dryWeight ? "Yes" : "No"}</span>
                                                        </td>
                                                        <td>
                                                            <span id={`supplement-sourced-${vm.id}`}>{`${vm.amounts.totalSourced} ${vm.amountTypeMeta.uom}`}</span>
                                                        </td>
                                                        <td className="u-textCenter">
                                                            <span id={`supplement-distributed-${vm.id}`}>{`${vm.amounts.totalDistributed} ${vm.amountTypeMeta.uom}`}</span>
                                                        </td>
                                                        <td className="u-textCenter">
                                                            <h3 id={`supplement-remaining-${vm.id}`} className={`u-mt-0 ${vm.amounts.totalRemaining === 0 ? "u-textSuccess" : "u-textError"}`}>
                                                                {vm.amounts.totalRemaining}
                                                            </h3>
                                                        </td>
                                                        <td>
                                                            {!removeLinks && (
                                                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/supplements/${vm.id}/distribution`} className={`${vm.distributedTo.length ? "" : "IconLink--copy"}`} id={`distribute-supplement-${vm.id}`}>
                                                                    {vm.distributedTo.length > 0 && (
                                                                        <span>
                                                                            <b>Distributed:</b> {vm.distributedTo.join(", ")}
                                                                        </span>
                                                                    )}
                                                                    {vm.distributedTo.length === 0 && <span>Distribute</span>}
                                                                </Link>
                                                            )}
                                                            {removeLinks && vm.distributedTo.length > 0 && (
                                                                <span>
                                                                    <b>Distributed:</b> {vm.distributedTo.join(", ")}
                                                                </span>
                                                            )}
                                                        </td>
                                                        {!removeLinks && (
                                                            <td>
                                                                <ActionLink id={`delete-supplement-${vm.id}`} onClick={_deleteSupplement(vm)} className="IconLink--trash" title="Delete">
                                                                    <span></span>
                                                                </ActionLink>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="u-flex u-flexJustifyEnd">
                                                        <div className="u-table-clear Table-Compressed">
                                                            <table>
                                                                <tbody>
                                                                    {viewModel.find((b) => b.type === "Purchased") && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Imported purchased total: </b>
                                                                            </td>
                                                                            <td>{purchasedList.join(", ")}</td>
                                                                        </tr>
                                                                    )}
                                                                    {viewModel.find((b) => b.type === "FromStorage") && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Imported from stage total:</b>
                                                                            </td>
                                                                            <td>{getTotals("FromStorage", "Weight")}</td>
                                                                        </tr>
                                                                    )}
                                                                    {viewModel.find((b) => b.type === "Harvested") && (
                                                                        <tr style={{ fontWeight: "normal" }}>
                                                                            <td align="right">
                                                                                <b>Harvested total:</b>
                                                                            </td>
                                                                            <td>{harvestedList.join(", ")}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </TileSubPanel>
        </>
    )
}
