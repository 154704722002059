import React from "react";
import ZendeskLink from "components/Help/ZendeskLink";

const HelpArticleSearchResults = ({ helpArticles, selectedIndex, loading }) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        if (helpArticles) {
            const data = helpArticles.map((result) => {
                return {
                    ...result,
                    ref: React.createRef(),
                };
            });
            setData(data);
        }
    }, [helpArticles]);

    React.useEffect(() => {
        if (selectedIndex >= 0 && selectedIndex < data.length) {
            const selectedData = data[selectedIndex];
            selectedData?.ref?.current?.focus();
        }
    }, [data, selectedIndex]);

    if (!loading && !helpArticles) return null;

    return (
        <div className="QuickSearch-results-section">
            {loading && (
                <div className="u-pb-sm u-pt-md">
                    <span className="u-font-600">Knowlegebase loading ...</span>
                </div>
            )}
            {!loading && (
                <>
                    <div className="u-pb-sm u-font-600">Help articles ({data.length})</div>
                    {data.map((result, i) => {
                        const summary = result.snippet && result.snippet.split("<em>").join("<b>").split("</em>").join("</b>");
                        return (
                            <ZendeskLink key={result.id} url={`https://support.overseer.org.nz/hc/en-us/articles/${result.id}`} ref={result.ref}>
                                <div className={`Search-result ${selectedIndex === i ? "selected" : ""}`}>
                                    <div className="u-pl-sm u-pr-sm">
                                        <span className="u-textBold a">{result.title}</span>
                                        {summary && <div className="u-textPrimary" dangerouslySetInnerHTML={{ __html: summary }}></div>}
                                    </div>
                                </div>
                            </ZendeskLink>
                        );
                    })}
                </>
            )}
        </div>
    );
};
export default HelpArticleSearchResults;
