import ActionLink from "components/ActionLink";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { useBlockModal } from "./Modals/BlockModal";
import ImportMapLink from "containers/BudgetHome/Blocks/Modals/ImportMapLink";
import { useEditFarmAreaModal } from "./Modals/EditFarmAreaModal";

export default function BlocksActions({ farm, analysis }) {
    const [openBlockModal, blockModal] = useBlockModal(farm, analysis);
    const [openEditFarmAreaModal, editFarmAreaModal] = useEditFarmAreaModal(analysis);
    const isFirstBlock = analysis.blocks && analysis.blocks.length === 0 && !analysis.hasUnassignedLand;

    return (
        <>
            <div className="ActionsBar ActionsBar--super u-print-none">
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        {!isFirstBlock && (
                            <li>
                                <ActionLink id="add-block-link" className="IconLink--arrow-plus" onClick={openBlockModal}>
                                    Add new block
                                </ActionLink>
                            </li>
                        )}
                        {analysis.hasUnassignedLand && (
                            <li>
                                <ActionLink id="assign-land-link" className="IconLink--arrow-plus" onClick={openBlockModal}>
                                    Assign mapped land to a new block
                                </ActionLink>
                            </li>
                        )}
                        {isFirstBlock && (
                            <li>
                                <ActionLink id="add-block-link" className="IconLink--arrow-plus" onClick={openBlockModal}>
                                    Create first block
                                </ActionLink>
                            </li>
                        )}
                        <li>
                            <ImportMapLink farm={farm} analysis={analysis} className="IconLink--upload" />
                        </li>
                    </ul>
                </div>
                <div className="ActionsBar-right">
                    <ul className="ActionsBar-links">
                        {analysis.hasCompletedBlocks && (
                            <li>
                                <ActionLink id="edit-farm-area-link" className="IconLink--edit" onClick={openEditFarmAreaModal}>
                                    Edit farm area
                                </ActionLink>
                            </li>
                        )}
                        {analysis.hasCompletedBlocks && process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                            <li>
                                <ScenarioToolLink farm={farm} analysis={analysis} />
                            </li>
                        )}
                        <li>
                            <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000889543" className="IconLink--new-tab u-font-green" />
                        </li>
                    </ul>
                </div>
            </div>
            {blockModal}
            {editFarmAreaModal}
        </>
    );
}
