import "./NotificationsBell.css";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import ZendeskLink from "components/Help/ZendeskLink";
import { useDimssNotificationAsync, useNotifications } from "./_hooks";

export function NotificationsBell() {
    const { data: notifications } = useNotifications();
    const dismissNotificationAsync = useDimssNotificationAsync();

    const hasNotifications = notifications?.length > 0 || false;

    return (
        <>
            <ActionLink id="nav-menu-bell" className="UtilityNav-user-trigger" title={hasNotifications ? `You have ${notifications.length} notification${notifications.length > 1 ? "s" : ""}` : ""}>
                <span className="UtilityNav-user-token">
                    <i className={`icon icon-notification u-textBold ${hasNotifications ? "u-textSuccess" : "u-textCoolBlue"}`} />
                </span>
                {hasNotifications && <span className="NotificationsBell-badge">{notifications.length}</span>}
            </ActionLink>
            <ul className="UtilityNav-user-dropdown">
                <li className="NotificationsBell-list">
                    <div id="nav-menu-bell-notifications" className="NotificationsBell-list-bg">
                        {notifications?.map((notification) => {
                            const isMaintenanceWindowNotification = notification.type === "maintenance";
                            const canDismiss = !isMaintenanceWindowNotification;
                            const alertType = isMaintenanceWindowNotification ? "warning" : notification.type || "info";
                            return <Alert key={notification.id} type={alertType} html={notification.content} onAction={canDismiss ? () => dismissNotificationAsync(notification.id) : undefined} actionMessage="Dismiss" actionAlignRight />
                        })}
                        <ZendeskLink title="View all software release notices" url="https://support.overseer.org.nz/hc/en-us/articles/900000877983" className={`IconLink--new-tab u-ml-auto u-mr-sm ${hasNotifications ? "u-mt-sm" : ""}`} />
                    </div>
                </li>
            </ul>
        </>
    )
}
