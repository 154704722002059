import { useQuery, useIsMutating } from "@tanstack/react-query";
import { useOnline } from "common/hooks";
import { httpClient } from "common/httpClient";

export function useOverseerModelRun(analysis) {
    const isOnline = useOnline();
    const wasGeneratedByScenarioTool = analysis?.wasGeneratedByScenarioTool === true;
    const queryKey = wasGeneratedByScenarioTool
        ? ["farm-details", analysis?.farmId, "scenario-tool", analysis?.id]
        : ["farm-details", analysis?.farmId, "model-results", analysis?.id];
    const isFullAnalysisJson = analysis?.hasCompletedBlocks === true && analysis.blocks !== undefined;
    const enabled = isOnline && isFullAnalysisJson && !analysis.hasValidationErrors;
    const staleTime = wasGeneratedByScenarioTool ? 0 : 1000 * 60 * 5; // Do not cache results from scenario tool generated analyses

    // Use results data from v1 analysis summary as react query placeholder data
    const placeholderData = getAnalysisResultsPlaceholderData(analysis);

    const query = useQuery({
        queryKey,
        queryFn: async () => {
            const timeout = 1000 * 60 * 2; // 2 minutes
            if (wasGeneratedByScenarioTool) {
                // Get model results by analysis JSON
                return httpClient.post(`v2/model-results/overseer`, analysis, timeout);
            } else {
                // Get model results by analysis id
                return httpClient.get(`v2/model-results/overseer/${analysis?.id}`, timeout)
            }
        },
        retry: false,
        refetchOnWindowFocus: false,
        placeholderData,
        staleTime,
        enabled
    });

    return {
        isFetching: enabled && query.isFetching,
        isLoading: enabled && query.isLoading, // We say we're only loading when enabled ;)
        error: query.error?.message,
        data: query.error ? undefined : query.data,
        paymentRequired: query.error?.status === 402,
    };
}

export function useAnalysisResults(analysis) {
    const { isFetching, isLoading, error, data, paymentRequired } = useOverseerModelRun(analysis);
    const isAnalysisSaving = useIsMutating({ mutationKey: ["updateAnalysisAsync", analysis?.id] }) > 0;
    const isStale = isAnalysisSaving || isFetching || isLoading;

    if (data?.modelResults && !error) {
        return {
            isLoading,
            isStale,
            error,
            data: {
                modelVersion: data.modelVersion,
                ...data.modelResults.farm
            },
            paymentRequired,
        };
    } else {
        return {
            isLoading,
            isStale,
            error,
            paymentRequired
        };
    }
}

export function useBlockResults(analysis, block) {
    const { isFetching, isLoading, error, data, paymentRequired } = useOverseerModelRun(analysis);
    const isAnalysisSaving = useIsMutating({ mutationKey: ["updateAnalysisAsync", analysis?.id] }) > 0;
    const isStale = isAnalysisSaving || isFetching || isLoading;

    if (data?.modelResults?.blocks && block?.id && !error) {
        const blockModelResults = data.modelResults.blocks.find(b => b.id === block.id);
        return {
            isLoading,
            isStale,
            error,
            data: {
                modelVersion: data.modelVersion,
                ...blockModelResults
            },
            paymentRequired,
        };
    } else {
        return {
            isLoading,
            isStale,
            error,
            paymentRequired,
        };
    }
}

export function useCarbonSequestration(farmId, analysisId) {
    const query = useQuery({
        queryKey: ['model-results', 'forestcarbon', farmId, analysisId],
        queryFn: async () => httpClient.get(`farms/${farmId}/analysis/${analysisId}/forestcarbon`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isLoading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export function useAnalysisSummary(analysis) {
    const isOnline = useOnline();
    const isFullAnalysisJson = analysis?.hasCompletedBlocks === true && analysis.blocks !== undefined;
    const enabled = isOnline && isFullAnalysisJson;

    const query = useQuery({
        queryKey: ["farm-details", analysis?.farmId, "analysis-summary", analysis?.id],
        queryFn: async () => {
            return httpClient.post(`v2/analysis-summary`, analysis);
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

function getAnalysisResultsPlaceholderData(analysis) {
    if (!analysis) return undefined;

    const isFullAnalysisJson = analysis?.hasCompletedBlocks === true && analysis?.blocks !== undefined;
    if (isFullAnalysisJson) return undefined;

    const hasResults = analysis.nLossPerHa > 0;
    if (!hasResults) return undefined;

    // Bit of a hack to get the model run results data from the summary version of an analysis into the 
    // same schema as the full version of an analysis. This is so we can use the same components to display.
    const placeholderData = {
        modelVersion: analysis.modelVersion,
        modelResults: {
            farm: {
                nitrogen: {
                    netAverageLoss: {
                        value: analysis.nLossPerHa,
                        unit: {
                            measuredIn: "kg",
                            of: "N",
                            per: "ha",
                            perPeriod: "yr",
                            notation: "kg N/ha/yr"
                        }
                    }
                },
                phosphorus: {
                    netAverageLoss: {
                        value: analysis.pLossPerHa,
                        unit: {
                            measuredIn: "kg",
                            of: "N",
                            per: "ha",
                            perPeriod: "yr",
                            notation: "kg N/ha/yr"
                        }
                    }
                },
                ghg: {
                    emissions: {
                        average: {
                            value: analysis.ghgkPerHa,
                            unit: {
                                measuredIn: "kg",
                                of: "CO2e",
                                per: "ha",
                                perPeriod: "yr",
                                notation: "kg CO2e/ha/yr"
                            }
                        }
                    }
                }
            }
        }
    };

    return placeholderData;
}
