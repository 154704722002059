import Alert from "components/Alert";
import { FileSummary } from "components/PlanningReport";
import AnimalsSummary from "./AnimalsSummary";
import AnimalsSummaryByEnterprise from "./AnimalsSummaryByEnterprise";
import AnimalsRsuByMonthCharts from "./AnimalsRsuByMonthCharts";
import AnimalsStockNumbersByMonthCharts from "./AnimalsStockNumbersByMonthCharts";
import { useAnalysisResults } from "containers/BudgetHome";

export default function Animals({ before, after, showFileComparison }) {
    const { isLoading, data: afterResults } = useAnalysisResults(after?.details);

    const hasResults = isLoading || afterResults?.animals?.stockingRates?.total?.value > 0;
    if (!hasResults) {
        return (
            <div className="u-pt-lg">
                <Alert type="info" text="There are no animal results for this analysis" />
            </div>
        );
    }

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <AnimalsSummary before={before} after={after} />
            <AnimalsSummaryByEnterprise before={before} after={after} />
            <AnimalsRsuByMonthCharts before={before} after={after} />
            <AnimalsStockNumbersByMonthCharts before={before} after={after} />
        </div>
    )
}
