import * as utils from "common/utils";
import { useAuthContext, useOnline, useRefData } from "common/hooks";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { PublishAnalysisActionLink } from "containers/Publications";

export default function BlocksActions({ farm, analysis }) {
    const authContext = useAuthContext();
    const refData = useRefData();
    const online = useOnline();

    const hasFarmUpdateAccess = utils.canUpdateFarm(farm);
    const hasAnalysisWriteAccess = analysis.role === "Write";
    const canPublish = online && refData.deploymentType !== "Science" && analysis.hasCompletedBlocks && (!farm.expired || authContext.canPublishWithoutSubscription) && ((hasAnalysisWriteAccess && analysis.type === "PrivateYearEnd") || (hasFarmUpdateAccess && ["YearEnd", "Predictive"].includes(analysis.type)));

    return (
        <>
            <div className="ActionsBar ActionsBar--super u-print-none">
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        {canPublish && (
                            <li>
                                <PublishAnalysisActionLink farmId={analysis.farmId} analysisId={analysis.id} />
                            </li>
                        )}
                    </ul>
                </div>
                <div className="ActionsBar-right">
                    <ul className="ActionsBar-links">
                        {analysis.hasCompletedBlocks && process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                            <li>
                                <ScenarioToolLink farm={farm} analysis={analysis} />
                            </li>
                        )}
                        <li>
                            <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000978743-Reports-tab" className="IconLink--new-tab u-font-green" />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
