import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import * as utils from "common/utils";
import { getFarmTimeline, ComparisonTable } from "components/AnalysisComparisons/ComparisonUtils";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import { Panel, PanelBody } from "components/Panel";
import FarmTimelineYearWidget from "./FarmTimelineYearWidget";
import ActionLink from "components/ActionLink";
import ZendeskLink from "components/Help/ZendeskLink";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";

export default function FarmTimeline({ farm }) {
    const dispatch = useDispatch();
    const referrer = `/app/farm/${farm.id}`;
    const [data, setData] = useState();
    const [waiting, setWaiting] = useState();
    const [excludedIds, setExcludedIds] = useState([]);

    const farmId = farm && farm.id;
    const publications = !utils.canUpdateFarm(farm);

    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Farm timeline" }];

    useEffect(() => {
        if (farmId) {
            let isMounted = true;
            setWaiting(true);
            dispatch(getFarmTimeline(farmId, excludedIds))
                .then((response) => isMounted && setData(response))
                .catch((error) => isMounted && setData({ error }))
                .finally(() => isMounted && setWaiting(false));
            return () => (isMounted = false);
        } else setData(undefined);
    }, [farmId, excludedIds, dispatch]);

    const addToExcludedIds = (id) => {
        setExcludedIds((prevState) => [...prevState, id]);
        setData(undefined);
    };

    const clearExcludeIds = () => {
        setExcludedIds([]);
        setData(undefined);
    };

    const loading = !data || waiting;
    const hasData = data && data.comparisons && data.comparisons.length > 0 && farm.budgets;
    const error = !waiting && ((data && data.error) || (!hasData && "Must have more than one year end or plan to generate a time line. Select reset cleared analyses at the top of the panel or return to the farm."));

    const budgets = publications
        ? farm.budgets &&
          farm.budgets
              .filter((b) => (excludedIds.length === 0 || !excludedIds.includes(b.id)) && b.class === "PublishedAnalysis" && b.year && b.nLossPerHa)
              .map((b) => b)
              .sort((a, b) => a.year - b.year)
        : farm.budgets &&
          farm.budgets
              .filter((b) => (excludedIds.length === 0 || !excludedIds.includes(b.id)) && b.class === "Budget" && b.year && b.nLossPerHa)
              .map((b) => b)
              .sort((a, b) => a.year - b.year);

    const info = <p>The following shows a timeline for this farm. Each analysis that has a year is shown with high level information that describes the farm system in that year. The changes made between analyses are listed between the years. Only analyses that have results are shown.</p>;

    const actions = (
        <div className="u-flex">
            <ActionLink id="clear-excluded-ids" className="IconLink--refresh u-link u-textWhite" onClick={clearExcludeIds}>
                Reset cleared analyses
            </ActionLink>
            <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/4407060537881" className="IconLink--new-tab u-textWhite u-pl-md" />
        </div>
    );

    return (
        <Page>
            <FeatureTracker feature={FEATURES.FARM_TIMELINE} farm={farm} />
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Panel title="Farm timeline" iconClassName="IconLink--clock" error={error} info={info} actions={actions} referrer={referrer}>
                    <PanelBody loading={loading} waiting={loading}>
                        {!error && hasData && (
                            <div className="u-pt-sm">
                                {data.comparisons.map((comparison, i) => {
                                    const analysis = budgets.find((b) => b.id === comparison.fromId);
                                    const toAnalysis = budgets.find((b) => b.id === comparison.toId);
                                    const last = i === data.comparisons.length - 1;

                                    return (
                                        <Fragment key={analysis.id}>
                                            <table style={{ width: "100%" }} key={"budget-" + analysis.id} className={i > 0 ? "u-page-break" : ""}>
                                                <tbody className="u-border rounded">
                                                    <tr>
                                                        <td className="u-textCenter u-pl-lg u-pr-lg u-pt-xl u-pb-xl" colSpan="2" data-width="5">
                                                            <div className={`farm-plan-circle farm-plan-circle-${analysis.type === "YearEnd" ? "yearend" : "plan"}`} onClick={() => addToExcludedIds(analysis.id)}>
                                                                <span id={`${analysis.id}-nce`}>{analysis.year}</span>
                                                            </div>
                                                        </td>
                                                        <td data-width="95">
                                                            <FarmTimelineYearWidget analysis={analysis} addToExcludedIds={addToExcludedIds} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td className="vl"></td>
                                                        <td>
                                                            <ComparisonTable comparison={comparison} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {last && (
                                                <table style={{ width: "100%" }} key={"budget-" + toAnalysis.id} className={i > 0 ? "u-page-break" : ""}>
                                                    <tbody className="u-border rounded">
                                                        <tr>
                                                            <td className="u-textCenter u-pl-lg u-pr-lg u-pt-xl u-pb-xl" colSpan="2" data-width="5">
                                                                <div className={`farm-plan-circle farm-plan-circle-${toAnalysis.type === "YearEnd" ? "yearend" : "plan"}`} onClick={() => addToExcludedIds(toAnalysis.id)}>
                                                                    <span id={`${toAnalysis.id}-nce`}>{toAnalysis.year}</span>
                                                                </div>
                                                            </td>
                                                            <td data-width="95">
                                                                <FarmTimelineYearWidget analysis={toAnalysis} addToExcludedIds={addToExcludedIds} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    )
}
