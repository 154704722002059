import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function GhgSummary({ before, after }) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.ghg && !afterResults?.ghg) {
        return null;
    }

    const afterGhgResults = afterResults?.ghg;
    const afterGhgTotals = {
        methane: afterGhgResults?.emissions?.methane?.total,
        nitrousOxide: afterGhgResults?.emissions?.nitrousOxide?.total,
        carbonDioxide: afterGhgResults?.emissions?.carbonDioxide?.total,
        total: afterGhgResults?.emissions?.total
    }
    const beforeGhgTotals = {
        methane: beforeResults?.ghg?.emissions?.methane?.total,
        nitrousOxide: beforeResults?.ghg?.emissions?.nitrousOxide?.total,
        carbonDioxide: beforeResults?.ghg?.emissions?.carbonDioxide?.total,
        total: beforeResults?.ghg?.emissions?.total
    }

    const ghgUnit = afterGhgTotals?.total?.unit?.notation;

    const beforeTreeArea = before?.details?.blocks.filter((b) => b.type === "NonProductiveTreesAndScrub").reduce((t, b) => (t += b.areaInHectares), 0);
    const afterTreeArea = after?.details?.blocks.filter((b) => b.type === "NonProductiveTreesAndScrub").reduce((t, b) => (t += b.areaInHectares), 0);

    const isLoading = afterIsLoading || (before && beforeIsLoading);
    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Greenhouse gas emissions</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <SummaryRow beforeValue={beforeGhgTotals?.total?.value} afterValue={afterGhgTotals?.total?.value} isComparing={isComparing} label="Total GHG emissions" unit={ghgUnit} dp={1} rowClass="Table-tr--ghg" indent />
                            <SummaryRow beforeValue={beforeGhgTotals?.methane?.value} afterValue={afterGhgTotals?.methane?.value} isComparing={isComparing} label="Methane" unit={ghgUnit} dp={1} rowClass={`Table-tr--${methaneColour.name}`} indent />
                            <SummaryRow beforeValue={beforeGhgTotals?.nitrousOxide?.value} afterValue={afterGhgTotals?.nitrousOxide?.value} isComparing={isComparing} label="Nitrous oxide" unit={ghgUnit} dp={1} rowClass={`Table-tr--${n2oColour.name}`} indent />
                            <SummaryRow beforeValue={beforeGhgTotals?.carbonDioxide?.value} afterValue={afterGhgTotals?.carbonDioxide?.value} isComparing={isComparing} label="Carbon dioxide" unit={ghgUnit} dp={1} rowClass={`Table-tr--${co2Colour.name}`} indent />
                            <SummaryRow beforeValue={beforeTreeArea} afterValue={afterTreeArea} isComparing={isComparing} label="Tree and scrub area" unit="ha" dp={1} />
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}

const methaneColour = { name: utils.getColour(4), hex: utils.colourHexIndex[4] };
const n2oColour = { name: utils.getColour(0), hex: utils.colourHexIndex[0] };
const co2Colour = { name: utils.getColour(9), hex: utils.colourHexIndex[9] };
