import TileSubPanel from "components/TileSubPanel";
import { cropViewModel } from "common/viewModels";
import { useRefData } from "common/hooks";

export default function TotalCropsList({ analysis }) {
    const refData = useRefData();
    const crops = cropViewModel(analysis, refData);

    return (
        Object.keys(crops).length > 0 && (
            <TileSubPanel title="Crops/Pasture - Reporting year only" green stickyHeaderName="analysis.crops.cropsPasture.collapsed">
                {
                    <div className="FarmTable_wrapper">
                        <div className="FarmTable-supplementary">
                            <div className="Table u-mt-md">
                                <table>
                                    <thead>
                                        <tr>
                                            <th data-width="md-20 xl-20">Crop/Pasture</th>
                                            <th data-width="md-10 xl-10">Hectares (sown)</th>
                                            <th data-width="md-10 xl-10">Yield</th>
                                            <th data-width="md-10 xl-10">Pasture grown (t DM/yr)</th>
                                            <th data-width="md-10 xl-10">Pasture intake (t DM/yr)</th>
                                            <th data-width="md-10 xl-10">Supplements (t DM/yr)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(crops).map((key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <span className="u-flex u-flexAlignItemsCenter">
                                                            <img className="u-mr-xs u-p-5" src={crops[key].icon} width="35" height="35" alt={`${key} icon`} />
                                                            <span id={`crops-name-${key}`}>{crops[key].name}</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span id={`crops-yield-${key}`}>{crops[key].area.toFixed(1)}</span>
                                                    </td>
                                                    <td>
                                                        <span id={`crops-yield-${key}`}>{crops[key].units && crops[key].yield ? crops[key].yield.toFixed(0) + " " + crops[key].units.replace("/ha", "") : "-"}</span>
                                                    </td>
                                                    <td>
                                                        <span id={`crops-yield-${key}`}>{crops[key].grown > 0 ? crops[key].grown.toFixed(0) : "-"}</span>
                                                    </td>
                                                    <td>
                                                        <span id={`crops-yield-${key}`}>{crops[key].intake > 0 ? crops[key].intake.toFixed(0) : "-"}</span>
                                                    </td>
                                                    <td>
                                                        <span id={`crops-yield-${key}`}>{crops[key].supplements ? crops[key].supplements.toFixed(0) : "-"}</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </TileSubPanel>
        )
    )
}
