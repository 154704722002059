import * as units from "common/units";
import { numeric } from "common/utils";
import Spinner from "components/Spinner/Spinner";
import { useAnalysisResults } from "containers/BudgetHome";

export default function NutrientLossGraphs({ analysis }) {
    const { isLoading, data: analysisResults } = useAnalysisResults(analysis);

    if (!analysisResults) return null;

    var uom = units.getNotationWithoutOf(analysisResults?.nitrogen?.netTotalLoss?.unit);

    return (
        <div className="Table u-mt-md u-pos-rel u-overflow-hidden">
            <table>
                <thead>
                    <tr>
                        <th id="header-nutrient">
                            Nutrient loss
                        </th>
                        <th id="header-blocks" className="numeric">
                            From blocks ({uom})
                        </th>
                        <th id="header-other" className="numeric">
                            From other sources ({uom})
                        </th>
                        <th id="header-other" className="numeric">
                            Gross farm loss ({uom})
                        </th>
                        <th id="header-wetalnds" className="numeric">
                            Removed by wetlands ({uom})
                        </th>
                        <th id="header-riparia" className="numeric">
                            Removed by riparian strips ({uom})
                        </th>
                        <th id="header-farm" className="numeric">
                            Net farm loss ({uom})
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="N" key="N">
                        <td id="N-label">Nitrogen</td>
                        <td id="N-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.nitrogen?.grossLossFromBlocks?.value)}</>
                            }
                        </td>
                        <td id="N-Other" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.nitrogen?.grossLossFromOtherSources?.value)}</>
                            }
                        </td>
                        <td id="N-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.nitrogen?.grossTotalLoss?.value)}</>
                            }
                        </td>
                        <td id="N-Wetlands" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.nitrogen?.removedByWetlands?.value)}</>
                            }
                        </td>
                        <td id="N-Riparian" className="numeric">-</td>
                        <td id="N-Farm" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.nitrogen?.netTotalLoss?.value)}</>
                            }
                        </td>
                    </tr>
                    <tr id="P" key="P">
                        <td id="P-label">Phosphorus</td>
                        <td id="P-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.phosphorus?.grossLossFromBlocks?.value)}</>
                            }
                        </td>
                        <td id="P-Other" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.phosphorus?.grossLossFromOtherSources?.value)}</>
                            }
                        </td>
                        <td id="P-Total" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.phosphorus?.grossTotalLoss?.value)}</>
                            }
                        </td>
                        <td id="P-Wetlands" className="numeric">-</td>
                        <td id="P-Riparian" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.phosphorus?.removedByRiparianStrips?.value)}</>
                            }
                        </td>
                        <td id="P-Farm" className="numeric">
                            {isLoading
                                ? <Spinner dark results />
                                : <>{numeric.format(analysisResults?.phosphorus?.netTotalLoss?.value)}</>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}