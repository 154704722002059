import * as utils from "common/utils";

export default function SummaryRow({ beforeValue, afterValue, isComparing, category, label, prefix = category, unit, showZero, dispalyValuesAsText, rowClass, indent, dp = 0 }) {
    // Parse values
    let beforeNumeric = parseFloat(beforeValue) || 0;
    let afterNumeric = parseFloat(afterValue) || 0;
    if (!showZero && !dispalyValuesAsText) {
        if (beforeNumeric === 0) {
            beforeNumeric = undefined;
        }
        if (afterNumeric === 0) {
            afterNumeric = undefined;
        }
    }

    // Render nothing if both values are undefined
    if (beforeNumeric === undefined && afterNumeric === undefined) {
        return null;
    }

    // Calculate difference
    let diff = afterNumeric - beforeNumeric || 0;
    if (!beforeNumeric && afterNumeric > 0) {
        diff = afterNumeric;
    }
    if (beforeNumeric && !afterNumeric) {
        diff = -beforeNumeric;
    }
    const diffPercentage = `${utils.numeric.format(diff / (beforeNumeric || diff) * 100)}%`;
    const increased = diff > 0;
    const decreased = diff < 0;
    const change = utils.numeric.format(diff, dp);
    const changeTextClassName = decreased ? "u-textError u-textBold" : "u-textSuccess u-textBold";
    const changeArrowClassName = increased ? "icon-arrow-up1 IconLink--compressed" : decreased ? "icon-arrow-down1 IconLink--compressed" : "";

    const id = `${utils.strToId(prefix, "")}_${utils.strToId(label, "")}`;

    return (
        <tr className={rowClass}>
            {category && <td className="u-font-600 u-pl-md u-pt-sm u-pb-sm" valign="top">{category}</td>}
            <td className={`u-pt-sm u-pb-sm ${indent ? "u-pl-lg" : "u-pl-sm"}`} valign="top">
                {`${label} ${unit ? "(" + unit + ")" : ""}`}
            </td>
            {isComparing && (
                <td className="u-pt-sm u-pb-sm numeric u-before" valign="top">
                    <div className="u-mb-sm u-textBold" id={`base_${id}`}>
                        {dispalyValuesAsText ? beforeValue : utils.numeric.format(beforeNumeric, dp)}
                    </div>
                </td>
            )}
            <td className={`u-pt-sm u-pb-sm numeric ${isComparing ? "u-after" : ""}`} valign="top">
                <div className="u-mb-sm u-textBold" id={`actual_${id}`}>
                    {dispalyValuesAsText ? afterValue : utils.numeric.format(afterNumeric, dp)}
                </div>
            </td>
            {isComparing && (
                <td className="numeric u-pt-sm u-pb-sm" valign="top">
                    {diff !== 0
                        ? <div className="u-flex u-flexJustifyEnd u-flexAlignItemsCenter" style={{ position: "relative", left: "8px" }}>
                            <div className="u-text-sm">({diffPercentage})</div>
                            <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-textBold u-textSecondary u-ml-xs">
                                <span id={`change_${id}`}>{increased && "+"}{change}</span>
                                <span className={`${changeArrowClassName} ${changeTextClassName}`}></span>
                            </div>
                        </div>
                        : <div className="u-pr-xs">-</div>
                    }
                </td>
            )}
        </tr>
    )
}
