import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import * as utils from "common/utils";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { Link } from "react-router-dom";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";

export default function Activity({ farm }) {
    const referrer = `/app/farm/${farm.id}`;
    const activity = farm?.activity || [];
    const featureTracker = useFeatureTracker(FEATURES.ANALYSIS_LINKS_LOG_PAGE, undefined, farm);
    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Farm activity" }];
    const info = <>This list shows the last 100 activities that have happened on the farm's analyses. Please note that this list only shows one entry per analysis/publication per user per day. This means that if a user updates an analysis multiple times throughout the day only the latest entry will show in this list.</>;

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <Panel title="Farm activity" referrer={referrer} iconClassName="IconLink--lock" info={info}>
                    <PanelBody>
                        <div>
                            <SortableTable data={activity} defaultSortCriteria={{ fieldName: "time", fieldType: "date", desc: true }} pageSize={50}>
                                {({ data, sortCriteria, handleSort }) => {
                                    return (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <SortableTableHeader label="Date" fieldName="time" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                    <SortableTableHeader label="User" fieldName="userName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Analysis" fieldName="budgetName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                    <SortableTableHeader label="Description" fieldName="description" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((a) => {
                                                    return (
                                                        <tr key={`${a.budgetId}-${a.time}-${a.userName}`} className="hover">
                                                            <td valign="top">{utils.dateLong(a.time, "-")}</td>
                                                            <td valign="top">{a.userName}</td>
                                                            {a.description !== "Deleted" ? (
                                                                <td valign="top">
                                                                    <Link to={`/app/farm/${farm.id}/analysis/${a.budgetId}`} className="u-link" onClick={() => featureTracker.track("Clicked")}>
                                                                        {a.budgetName}
                                                                    </Link>
                                                                </td>
                                                            ) : (
                                                                <td>{a.budgetName}</td>
                                                            )}
                                                            <td valign="top">{a.description}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    );
                                }}
                            </SortableTable>
                        </div>
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    )
}
