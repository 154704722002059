import { Link } from "react-router-dom";
import { GridCell } from "components/Grid";
import * as utils from "common/utils";
import { BlockType } from "common/domain";
import ActionLink from "components/ActionLink";
import { useRefData } from "common/hooks";
import { AnalysisResultsWidget } from "components/ResultsWidgets";

export default function FarmTimelineYearWidget({ analysis, addToExcludedIds }) {
    const refData = useRefData();

    const getFertiliserForBlockType = (budget, blockType) => {
        const synthetic = utils.round(budget.syntheticFertiliserRates[blockType], 1).toLocaleString();
        const organic = utils.round(budget.organicFertiliserRates[blockType], 1).toLocaleString();
        const typeName = utils.valueToText(refData.blockTypes, blockType);

        return `N applied as fertiliser on ${typeName} blocks, ${synthetic} kg/ha as synthetic and ${organic} kg/ha as organic.`;
    };

    const GetBudgetInfo = ({ analysis }) => {
        const rsuTotal = analysis.enterpriseSummaries && analysis.enterpriseSummaries.reduce((t, e) => (t += e.rsu), 0);
        const rsuPercentages =
            analysis.enterpriseSummaries &&
            analysis.enterpriseSummaries.map((e) => {
                return `${utils.round((100 * e.rsu) / rsuTotal, 0)}% ${utils.valueToText(refData.enterpriseTypes, e.type)}`;
            });
        const totalArea = analysis.totalFarmArea > 0 && `Total area is ${analysis.totalFarmArea.toLocaleString()} ha. `;
        const productiveArea = analysis.productiveArea > 0 && `Productive area is ${analysis.productiveArea.toLocaleString()} ha. `;
        const irrigatedArea = analysis.irrigatedArea > 0 && `Irrigated area is ${analysis.irrigatedArea.toLocaleString()} ha. `;
        const effluentArea = analysis.effluentArea > 0 && `Effluent area is ${analysis.effluentArea.toLocaleString()} ha. `;
        const wetlandArea = analysis.wetlandArea > 0 && `Wetland area is ${analysis.wetlandArea.toLocaleString()} ha. `;
        const structures = analysis.structureSummaries && analysis.structureSummaries.map((s) => utils.valueToText(refData.structureType, s.type)).join(", ");

        const irrigationAreaTotal = analysis.irrigationSummaries && analysis.irrigationSummaries.reduce((t, i) => (t += i.area), 0);
        const irrigators = analysis.irrigationSummaries && analysis.irrigationSummaries.map((i) => `${utils.valueToText(refData.irrigatorTypes, i.system)} - ${i.management && i.management !== "Undefined" ? utils.valueToText(refData.irrigationSoilMoistureUsage, i.management) : "Fixed"} (${utils.round((100 * i.area) / irrigationAreaTotal, 0)}%)`).join(", ");
        const nSupplements = analysis.importedSupplementNutrients && analysis.importedSupplementNutrients["N"];

        return (
            <div className="u-pt-md">
                <div className="SimpleTable">
                    <table>
                        <tbody>
                            <tr valign="top">
                                <td className="u-font-600">Type of analysis</td>
                                <td className="u-textLeft">{analysis.type === "YearEnd" ? "Year end" : "Plan"}</td>
                            </tr>
                            <tr valign="top">
                                <td className="u-font-600">Area</td>
                                <td className="u-textLeft">{`${totalArea ? totalArea : ""} ${productiveArea ? productiveArea : ""} ${irrigatedArea ? irrigatedArea : ""} ${effluentArea ? effluentArea : ""} ${wetlandArea ? wetlandArea : ""}`}</td>
                            </tr>
                            {analysis.rsuPerHa && (
                                <tr valign="top">
                                    <td className="u-font-600">RSU</td>
                                    <td className="u-textLeft">{`Total stock rate is ${utils.round(analysis.rsuPerHa, 1).toLocaleString()} RSU/ha. This is ${rsuPercentages.join(", ")}`}</td>
                                </tr>
                            )}
                            {analysis.nSurplus && (
                                <tr valign="top">
                                    <td className="u-font-600">Nitrogen</td>
                                    <td className="u-textLeft">{`N surplus is ${analysis.nSurplus} kg/ha. ${analysis.averageAnnualDrainage ? `Average annual drainage is ${utils.round(analysis.averageAnnualDrainage, 1).toLocaleString()}mm` : ""}`}</td>
                                </tr>
                            )}
                            {(analysis.syntheticFertiliserRates[BlockType.ProductiveCrops] > 0 || analysis.organicFertiliserRates[BlockType.ProductiveCrops] > 0) && (
                                <tr valign="top">
                                    <td className="u-font-600">N applied to crops</td>
                                    <td className="u-textLeft">{getFertiliserForBlockType(analysis, BlockType.ProductiveCrops)}</td>
                                </tr>
                            )}
                            {(analysis.syntheticFertiliserRates[BlockType.ProductivePasture] > 0 || analysis.organicFertiliserRates[BlockType.ProductivePasture] > 0) && (
                                <tr valign="top">
                                    <td className="u-font-600">N applied to pasture</td>
                                    <td className="u-textLeft">{getFertiliserForBlockType(analysis, BlockType.ProductivePasture)}</td>
                                </tr>
                            )}
                            {nSupplements > 0 && (
                                <tr valign="top">
                                    <td className="u-font-600">N imported in supplements</td>
                                    <td className="u-textLeft">{`Imported ${nSupplements} kg/ha of nitrogen from supplements.`}</td>
                                </tr>
                            )}
                            {structures !== "" && (
                                <tr valign="top">
                                    <td className="u-font-600">Structures</td>
                                    <td className="u-textLeft">{structures}</td>
                                </tr>
                            )}
                            {irrigators !== "" && (
                                <tr valign="top">
                                    <td className="u-font-600">Irrigators</td>
                                    <td className="u-textLeft">{irrigators}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const linkUrl = `/app/farm/${analysis.farmId}/analysis/${analysis.id}${analysis.class === "PublishedAnalysis" ? "/overview" : ""}`;

    return (
        <GridCell className="FarmDashboard-cell">
            <div className="u-pt-lg upl-lg u-pr-lg u-pb-lg" style={{ minWidth: "600px" }}>
                <div className="Widget-header">
                    <div className="Widget-header-select">
                        <AnalysisResultsWidget analysis={analysis} perHaOnly hideVersion />
                    </div>
                    <div className="u-ml-auto u-flexSplit">
                        <ActionLink id="remove-analysis" className="IconLink--cross-circle u-link" onClick={() => addToExcludedIds(analysis.id)}>
                            <span>Remove from timeline</span>
                        </ActionLink>
                        <Link to={linkUrl}>
                            <span className="IconLink--compare u-link u-ml-md">
                                <span>View analysis</span>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="Widget-content">
                    <div className="u-flex">
                        <div className="u-flex-growBig">{analysis && <GetBudgetInfo analysis={analysis} />}</div>
                    </div>
                </div>
            </div>
        </GridCell>
    );
}
