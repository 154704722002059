import { useState, useEffect } from "react";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { useBlockResults } from "containers/BudgetHome";
import { BlockNutrientBudget } from "components/NutrientBudget";
import BlockSoilIrrigation from "./BlockSoilIrrigation";
import BlockNitrogenPoolsGraphs from "./BlockNitrogenPoolsGraphs";
import BlockDescription from "./BlockDescription";

export default function BlockDetails({ farm, analysis }) {
    const [block, setBlock] = useState();

    useEffect(() => {
        if (!block && analysis?.blocks?.length > 0) {
            setBlock(analysis.blocks[0]);
        }
    }, [analysis, block]);

    const { data: blockResults } = useBlockResults(analysis, block);

    const handleBlockIdChange = (blockId) => {
        setBlock(analysis.blocks.find((b) => b.id === blockId));
    };

    return (
        <>
            {block && <BlockDescription farm={farm} analysis={analysis} block={block} onBlockChange={handleBlockIdChange} />}

            {blockResults && block?.currentResults?.soilIrrigation && (
                <Panel title={"Results by soil and irrigation - " + block.name} skyBlue collapsible stickyHeaderName="budget.overview.blockDetails.resultsBySoil">
                    <PanelBody>
                        <Watermark />
                        <BlockSoilIrrigation farm={farm} analysis={analysis} block={block} />
                    </PanelBody>
                </Panel>
            )}

            {blockResults && block.isProductive && (
                <Panel title={"N pool graphs - " + block.name + " (Background only)"} info="These are the N pool and change in N pool graphs for the background model. You can click on each of the labels (eg. Plant uptake label) to add or remove it from the graph." skyBlue collapsible stickyHeaderName="budget.overview.blockDetails.nPoolGraphs">
                    <PanelBody>
                        <Watermark />
                        <BlockNitrogenPoolsGraphs analysis={analysis} block={block} />
                    </PanelBody>
                </Panel>
            )}

            {blockResults?.nutrientBudget && (
                <Panel title={"Nutrient budget - " + block.name} skyBlue collapsible stickyHeaderName="budget.overview.blockDetails.nutrientBudget">
                    <PanelBody>
                        <Watermark />
                        <BlockNutrientBudget id="block" analysis={analysis} block={block} />
                    </PanelBody>
                </Panel>
            )}
        </>
    );
}
