import { Link } from "react-router-dom";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileSubPanel from "components/TileSubPanel";
import TabMessages from "containers/BudgetHome/TabMessages";
import PageTabs, { TAB } from "containers/BudgetHome/PageTabs";
import IrrigationCard from "./IrrigationCard";
import IrrigatorList from "./IrrigatorList";
import { getBlockList } from "common/utils";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import * as irrigationUtils from "./_utils";
import { useRefData } from "common/hooks";

export default function Irrigation({ farm, analysis }) {
    const refData = useRefData();

    let allUsedBlocks = [];
    const { irrigators = [] } = analysis;
    analysis.irrigators = irrigators;
    /* eslint-disable no-unused-vars */
    for (const irrigator of analysis.irrigators) {
        /* eslint-disable no-unused-vars */
        for (const app of irrigator.applications) {
            allUsedBlocks = [...allUsedBlocks, ...app.blockIds];
        }
    }
    const blockList = getBlockList(analysis.blocks);

    const intersections = irrigators.map((i) => ({ id: i.id, blockIntersections: irrigationUtils.getBlocksIntersectedByDrawnIrrigators(analysis, i.id) }));

    return (
        <Tile>
            <PageTabs farm={farm} analysis={analysis} tab={TAB.IRRIGATION} />
            {
                <TileBody>
                    <TabMessages farm={farm} analysis={analysis} tab={TAB.IRRIGATION} />
                    <div className="ActionsBar ActionsBar--super u-print-none">
                        <div className="ActionsBar-left">
                            <ul className="ActionsBar-links">
                                <li>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/irrigation/new`} className="IconLink--arrow-plus" id="irrigator-add-link">
                                        <span>Add irrigation system</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="ActionsBar-right">
                            <ul className="ActionsBar-links">
                                {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                                    <li>
                                        <ScenarioToolLink farm={farm} analysis={analysis} />
                                    </li>
                                )}
                                <li>
                                    <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000978523" className="IconLink--new-tab u-font-green" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <IrrigatorList farm={farm} analysis={analysis} />
                    {refData &&
                        refData.blockTypes &&
                        refData.blockTypes.map((blockType) => {
                            const blocksByType = blockList.productiveBlocks.filter((block) => block.type === blockType.value).sort((a, b) => (b.type === a.type ? (b.name < a.name ? 1 : -1) : b.type > a.type ? 1 : -1));
                            return (
                                blocksByType.length > 0 && (
                                    <TileSubPanel key={blockType.value} title={`${blockType.text} ${blockType.value === "FodderCrop" ? "rotations" : " blocks"}`} stickyHeaderName={`analysis.irrigation.${blockType.value}.collapsed`}>
                                        {blocksByType.map((block) => (
                                            <IrrigationCard key={block.id} block={block} farm={farm} analysis={analysis} intersections={intersections} />
                                        ))}
                                    </TileSubPanel>
                                )
                            );
                        })}
                    {blockList.nonProductiveBlocks.length > 0 && (
                        <TileSubPanel title="Other blocks" stickyHeaderName="analysis.irrigation.otherBlocks.collapsed">
                            {blockList.nonProductiveBlocks.map((block) => (
                                <IrrigationCard key={block.id} block={block} farm={farm} analysis={analysis} intersections={intersections} />
                            ))}
                        </TileSubPanel>
                    )}
                </TileBody>
            }
        </Tile>
    )
}
