import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "components/Page";
import PageBody from "components/PageBody";
import FarmHomePageHead from "containers/FarmHome/FarmHomePageHead";
import { Panel, PanelBody } from "components/Panel";
import BarChart from "components/BarChart/BarChart";
import * as utils from "common/utils";
import { getBenchmarkStatistics } from "containers/Reporting/_actions";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useOnline } from "common/hooks";

export default function Trends({ farm }) {
    const referrer = `/app/farm/${farm.id}`;
    const { loading, error, charts } = useFarmTrendCharts(farm);
    const breadcrumb = [{ name: farm.displayName, to: referrer }, { name: "Trends" }];
    const infoText = "The trends graphs show your farm's results over time based on the farm's year end analyses. Each graph shows year ending results (y-axis) for the year shown on the x-axis. The median line shows the median value based on all other farms in Overseer.";

    return (
        <Page>
            <FarmHomePageHead breadcrumb={breadcrumb} />
            <PageBody>
                <FeatureTracker feature={FEATURES.FARM_TRENDS} farm={farm} />
                <Panel title="Trends" iconClassName="IconLink--stats-dots" referrer={`/app/farm/${farm.id}`} error={error} info={infoText}>
                    <PanelBody loading={loading} waiting={loading}>
                        {!error && <div className="u-bg-grey u-pt-sm u-border">{charts && charts.map((chart) => <BarChart key={chart.data.title} className="u-xxl-width1of3 u-xl-width1of2 u-sm-width1of1" data={chart.data} options={chart.options} />)}</div>}
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    );
}

export const useFarmTrendCharts = (farm, limtYears = 20) => {
    const online = useOnline();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [trendBudgets] = React.useState(
        (farm?.budgets &&
            farm.budgets
                .filter((b) => b.class === "Budget" && b.year && b.role === "Write" && b.nLossPerHa)
                .map((b) => b)
                .sort((a, b) => (a.year > b.year ? -1 : 1))
                .slice(0, limtYears)
                .reverse()) ||
        []
    );
    const [trendData, setTrendData] = React.useState();

    const getCharts = () => {
        const productiveArea = { name: utils.getColour(2), hex: utils.colourHexIndex[6] };
        const effluentArea = { name: utils.getColour(9), hex: utils.colourHexIndex[8] };

        const years = trendData && Object.keys(trendData);

        const chartData =
            years && years.length > 0
                ? trendData[years[0]].map((s) => {
                    const data = getChartDatasets(s.type, trendBudgets, trendData);
                    return data && getChartData(data, `${s.name} (${s.units})`, s.colour);
                })
                : [];

        if (chartData.length > 0) {
            chartData.push(
                getChartData(
                    trendBudgets.map((b) => {
                        return { year: b.year, value: b.productiveArea };
                    }),
                    "Productive area (ha)",
                    productiveArea.hex
                )
            );
            chartData.push(
                getChartData(
                    trendBudgets.map((b) => {
                        return { year: b.year, value: b.irrigatedArea };
                    }),
                    "Irrigated area (ha)",
                    "#4285f4"
                )
            );
            chartData.push(
                getChartData(
                    trendBudgets.map((b) => {
                        return { year: b.year, value: b.effluentArea };
                    }),
                    "Effluent area (ha)",
                    effluentArea.hex
                )
            );
        }

        const charts = chartData
            .filter((data) => data !== null)
            .map((data) => ({
                data,
                options: {
                    scales: {
                        x: { display: true },
                        y: { ticks: { beginAtZero: true }, display: true },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    datasets: {
                        line: {
                            tension: 0.4,
                        },
                    },
                },
            }));
        return charts;
    };

    useEffect(() => {
        isMounted.current = true;
        const fetchAsync = async (yearEndBudgetsWithResults) => {
            if (yearEndBudgetsWithResults.length < 2) {
                isMounted.current && setTrendData([]);
            } else {
                await dispatch(getBenchmarkStatistics({ years: yearEndBudgetsWithResults.map((b) => b.year) }))
                    .then((response) => isMounted.current && setTrendData(response))
                    .catch(() => isMounted.current && setError("Error loading farm trends"));
            }
        };

        if (online && trendBudgets && !trendData) {
            fetchAsync(trendBudgets).finally(() => isMounted.current && setLoading(false));
        }
        return () => (isMounted.current = false);
    }, [online, dispatch, trendBudgets, trendData]);

    // NOTE TO FUTURE SELF
    // The getCharts function is wrapped in a getter because we want to make sure the chartjs component
    // is always rendering a new instance of chart data. Chartjs uses references a charts data source by ref
    // so this way we make sure the entire data source is re-initialized on each call to the "charts" getter.
    return {
        loading,
        error,
        get charts() {
            return getCharts();
        },
    };
};

const getChartData = (chartDatasets, title, colour) => {
    if (!chartDatasets || chartDatasets.length === 0 || chartDatasets.reduce((t, o) => (t += o.value ? o.value : 0), 0) === 0) return null;

    const data = {
        labels: chartDatasets.map((b) => `${b.year}`),
        title: title,
        datasets: [
            {
                fill: "origin",
                lineTension: 0,
                label: title,
                backgroundColor: colour,
                borderColor: "#47A96D",
                pointRadius: 0,
                order: 2,
                data: chartDatasets.map((d) => utils.round(d.value, 1)),
            },
        ],
    };
    if (chartDatasets.reduce((t, b) => (t += b.median ? b.median : 0), 0) > 0) data.datasets.push({ type: "line", fill: "origin", order: 1, label: "Median", data: chartDatasets.map((b) => utils.round(b.median, 1)) });

    return data;
};

const getMedian = (type, year, trendData) => {
    const trend = trendData && trendData[year] && trendData[year].find((t) => t.type === type);
    return trend && trend.statistics && trend.statistics.median;
};

const getChartDatasets = (type, trendBudgets, trendData) => {
    switch (type) {
        case "NLossPerHa":
            return trendBudgets
                .filter((b) => b.nLossPerHa)
                .map((b) => {
                    return { year: b.year, value: b.nLossPerHa, median: getMedian(type, b.year, trendData) };
                });
        case "PLossPerHa":
            return trendBudgets
                .filter((b) => b.pLossPerHa)
                .map((b) => {
                    return { year: b.year, value: b.pLossPerHa, median: getMedian(type, b.year, trendData) };
                });
        case "NSurplus":
            return trendBudgets
                .filter((b) => b.nSurplus)
                .map((b) => {
                    return { year: b.year, value: b.nSurplus, median: getMedian(type, b.year, trendData) };
                });
        case "NCE":
            return trendBudgets
                .filter((b) => b.nce)
                .map((b) => {
                    return { year: b.year, value: b.nce, median: getMedian(type, b.year, trendData) };
                });
        case "GHGPerHa":
            return trendBudgets
                .filter((b) => b.ghgSummary)
                .map((b) => {
                    return { year: b.year, value: b.ghgSummary.total, median: getMedian(type, b.year, trendData) };
                });
        case "MethanePerHa":
            return trendBudgets
                .filter((b) => b.ghgSummary)
                .map((b) => {
                    return { year: b.year, value: b.ghgSummary.methaneEmissionsTotal, median: getMedian(type, b.year, trendData) };
                });
        case "NitrousOxidePerHa":
            return trendBudgets
                .filter((b) => b.ghgSummary)
                .map((b) => {
                    return { year: b.year, value: b.ghgSummary.n2OEmissionsTotal, median: getMedian(type, b.year, trendData) };
                });
        case "CarbonDioxidePerHa":
            return trendBudgets
                .filter((b) => b.ghgSummary)
                .map((b) => {
                    return { year: b.year, value: b.ghgSummary.cO2EmissionsTotal, median: getMedian(type, b.year, trendData) };
                });
        case "Drainage":
            return trendBudgets
                .filter((b) => b.averageAnnualDrainage)
                .map((b) => {
                    return { year: b.year, value: b.averageAnnualDrainage, median: getMedian(type, b.year, trendData) };
                });
        case "RSUPerHa":
            return trendBudgets
                .filter((b) => b.rsuPerHa)
                .map((b) => {
                    return { year: b.year, value: b.rsuPerHa, median: getMedian(type, b.year, trendData) };
                });
        case "SyntheticRateOnPasture":
            return trendBudgets
                .filter((b) => b.syntheticFertiliserRates)
                .map((b) => {
                    return { year: b.year, value: b.syntheticFertiliserRates["ProductivePasture"], median: getMedian(type, b.year, trendData) };
                });
        case "OrganicRateOnPasture":
            return trendBudgets
                .filter((b) => b.organicFertiliserRates)
                .map((b) => {
                    return { year: b.year, value: b.organicFertiliserRates["ProductivePasture"], median: getMedian(type, b.year, trendData) };
                });
        default:
            return null;
    }
};
