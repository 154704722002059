import { Fragment, useState } from "react";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import SelectPack from "components/SelectPack";
import ZendeskLink from "components/Help/ZendeskLink";
import { Link } from "react-router-dom";
import * as domain from "common/domain";
import * as utils from "common/utils";
import ActionLink from "components/ActionLink";
import { Line, Bar } from "react-chartjs-2";
import { useCarbonSequestration } from "containers/BudgetHome";
import { useRefData } from "common/hooks";

export default function CarbonStock({ farm, analysis }) {
    const { isLoading, error, data } = useCarbonSequestration(farm.id, analysis.id);
    const refData = useRefData();
    const [state, setState] = useState({});
    const { blocks = [] } = analysis;
    const csBlocks = blocks.filter((b) => b.type === domain.BlockType.NonProductiveTreesAndScrub && b.forests && b.forests.length > 0);
    const { forestType = [] } = refData;
    let budgetForestArea = 0;
    let budgetBlockArea = 0;
    let ghgEmissionsTotal = 0;
    let currentCarbonStock = 0;
    let bestCarbonStock = 0;
    let bestCarbonStockYear = 0;

    const productiveBlocks = blocks.filter((b) => b.isProductive && b.type !== "FodderCrop");
    const productiveBlockArea = productiveBlocks.reduce((total, block) => (total += block.areaInHectares), 0);
    const farmEmissionsTotal = utils.round((productiveBlockArea * ghgEmissionsTotal) / 1000, 2);

    const budgetBarNData = {
        labels: [],
        datasets: [],
    };

    const totalsBarNData = {
        labels: [],
        datasets: [],
    };

    const lineOptions = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"} carbon stock total tonnes (indicative)`,
                },
            },
            x: {
                title: {
                    display: true,
                    text: "Age",
                },
            },
        },
        datasets: {
            line: {
                tension: 0.4,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItem) => {
                        const { dataset } = tooltipItem[0];
                        return `${dataset.label} - Age: ${tooltipItem[0].label}`;
                    },
                    label: (tooltipItem) => {
                        return `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"}: ${tooltipItem.formattedValue} tonnes`;
                    },
                    afterLabel: (tooltipItem, data) => {
                        if (tooltipItem.index > 0 && state.displaycumulative === "cumulative") {
                            const { dataset } = tooltipItem;
                            const val = dataset.data[tooltipItem.dataIndex] - dataset.data[tooltipItem.dataIndex - 1];
                            return `Annual: ${utils.round(val, 2)} tonnes`;
                        }
                    },
                },
            },
        },
    };

    const harvestOptions = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"} carbon stock total tonnes (indicative)`,
                },
            },
            x: {
                title: {
                    display: true,
                    text: "Years since harvest",
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItem) => {
                        const { dataset } = tooltipItem[0];
                        return `${dataset.label} - ${tooltipItem[0].label}`;
                    },
                    label: (tooltipItem) => {
                        return `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"}: ${tooltipItem.formattedValue} tonnes`;
                    },
                    afterLabel: (tooltipItem) => {
                        if (tooltipItem.dataIndex > 0 && state.displaycumulative === "cumulative") {
                            const { dataset } = tooltipItem;
                            const val = dataset.data[tooltipItem.dataIndex] - dataset.data[tooltipItem.dataIndex - 1];
                            return `Annual: ${utils.round(val, 2)} tonnes`;
                        }
                    },
                },
            },
        },
    };

    const barOptions = {
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: "Years",
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"} carbon stock total tonnes (indicative)`,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItem) => {
                        const { dataset } = tooltipItem[0];
                        return `${dataset.label} - Year: ${tooltipItem[0].label}`;
                    },
                    label: (tooltipItem) => {
                        if (tooltipItem.datasetIndex === 0) {
                            return `${state.displaycumulative === "cumulative" ? "Cumulative" : "Actual"}: ${tooltipItem.formattedValue} tonnes`;
                        }
                    },
                    afterLabel: (tooltipItem) => {
                        if (tooltipItem.dataIndex > 0 && tooltipItem.datasetIndex === 0 && state.displaycumulative === "cumulative") {
                            const { dataset } = tooltipItem;
                            const val = dataset.data[tooltipItem.dataIndex] - dataset.data[tooltipItem.dataIndex - 1];
                            return `Annual: ${utils.round(val, 2)} tonnes`;
                        }
                    },
                },
            },
        },
    };

    return (
        <Panel title="Carbon stock" skyBlue loading={isLoading} waiting={isLoading} inError={error}>
            {data && (
                <PanelBody>
                    {data.length > 0 && (
                        <>
                            <Alert
                                type="info"
                                text="The carbon stock report below has been
                                developed based on lookup tables for post 1989 forest land by MPI. Pre-calculated values in look-
                                up tables are not specific to an individual farm. For further information on Carbon Look-up tables,
                                please contact MPI. email climatechange@mpi.govt.nz or call 0800 CLIMATE (0800 25 46 28)
                                and select option 3."
                            />

                            <Alert
                                type="warning"
                                text={
                                    <div>
                                        <b>Disclaimer:</b>The information in this report has no statutory or regulatory effect and is of a guidance nature only. The information should not be relied on as a substitute for the wording of the Climate Change Response Act 2002. While every effort has been made to ensure the information in this report is accurate, Overseer Ltd does not accept any responsibility or liability for error of fact, omission, interpretation or opinion that may be present, nor for the consequences of any decisions based on this information.{" "}
                                    </div>
                                }
                            />

                            <Alert
                                type="info"
                                text={
                                    <div>
                                        This report shows the potential for tree blocks accumulating carbon stocks over time - <i>cumulative</i>, as well as the potential for <i>annual carbon stocks</i>. You can view the annual carbon stocks information by hovering on the graph on year one and subsequent years. Alternatively, toggle between <i>cumulative</i> and <i>annual carbon stocks</i> information by selecting a reporting method. You will see how much additional carbon stocks tree blocks will have sequestered in each twelve-month period. From here, you can assess how changes in carbon stocks might impact your farm emissions profile. For more information on the carbon stock tool &nbsp;
                                        <ZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000922343" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" title="click here." />{" "}
                                    </div>
                                }
                            />
                        </>
                    )}

                    {data.length === 0 ? (
                        <div className="Table u-mt-md">
                            <div className="Tile-body">
                                <div className="Tile-body-message">
                                    <h3>You do not have any forests set up for the carbon stock tool. Please edit your tree blocks and add forests to view details for your farm.</h3>
                                    <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}`} id="create-tree-block" className="IconLink--arrow-plus Button Button--sm u-mt-md">
                                        Add forests
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <SelectPack meta={{ nrf: true }} onChange={(e) => setState({ ...state, displaycumulative: e.target.value })} value={state.displaycumulative} autoFocus={true} id="custom_soil_group" label="Reporting method" dataWidth="25" requiredLabel={false}>
                                <option value="cumulative">Cumulative</option>
                                <option value="actual">Annual</option>
                            </SelectPack>
                        </div>
                    )}
                    {data.length > 0 && (
                        <>
                            {csBlocks.map((b, i) => {
                                const { forests = [] } = b;
                                //let blockArea = 0;
                                const blockNData = {
                                    labels: [],
                                    datasets: [],
                                };
                                budgetBlockArea += b.areaInHectares;
                                const blockIcon = utils.getBlockIcon(b.type);
                                return (
                                    <Fragment key={i}>
                                        <div className="h2 u-pt-lg">
                                            <div className="FarmTitle">
                                                <img className="FarmTitle-icon" src={blockIcon} alt="Block" />
                                                <div className="FarmTitle-heading">
                                                    <span className="FarmTitle-name" title={b.blockName}>
                                                        {b.name}
                                                    </span>
                                                    <div className="FarmTitle-sub">Trees and scrub - {b.areaInHectares}ha</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Table u-mt-sm">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Name/Type</th>
                                                        <th>Age</th>
                                                        <th>Percentage of block</th>
                                                        <th>Area</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {forests.map((f, x) => {
                                                        const forestResults = data.find((fr) => fr.blockId === b.id && fr.forestId === f.id) || {};
                                                        const area = utils.round((b.areaInHectares * f.percentage) / 100 || 1, 1);
                                                        //blockArea += area
                                                        budgetForestArea += f.isHarvested ? 0 : area;
                                                        const { carbonStockValues = [] } = forestResults;
                                                        const carbonArray = state.displaycumulative === "cumulative" ? carbonStockValues.map((cs) => utils.round(cs * area, 2)) : carbonStockValues.map((cs, i) => (i === 0 ? 0 : utils.round((cs - carbonStockValues[i - 1]) * area, 2)));

                                                        if (state.displaycumulative !== "cumulative" && carbonStockValues.length > 0) {
                                                            carbonArray[0] = utils.round((carbonStockValues[0] - forestResults.previousUntilForestAge) * area, 2);
                                                        }

                                                        const forestDataset = {
                                                            label: `${f.name || utils.valueToText(forestType, f.forestType)} ${f.isHarvested ? " - (residual)" : ""}`,
                                                            fill: false,
                                                            lineTension: 0.1,
                                                            backgroundColor: utils.getGraphColour(x),
                                                            borderColor: utils.getGraphColour(x),
                                                            data: carbonArray,
                                                        };

                                                        const nData = {
                                                            labels: carbonStockValues.map((fr, ix) => (f.isHarvested ? f.numberOfYearsSinceHarvested : f.forestAge) + ix),
                                                            datasets: [forestDataset],
                                                        };

                                                        blockNData.datasets.unshift(forestDataset);

                                                        while (blockNData.labels.length < nData.labels.length) {
                                                            blockNData.labels.push(blockNData.labels.length);
                                                        }
                                                        while (budgetBarNData.labels.length < nData.labels.length) {
                                                            budgetBarNData.labels.push(budgetBarNData.labels.length);
                                                        }
                                                        while (totalsBarNData.labels.length < nData.labels.length) {
                                                            totalsBarNData.labels.push(totalsBarNData.labels.length);
                                                        }

                                                        let budgetDataSet = budgetBarNData.datasets.find((bd) => bd.id === b.id);

                                                        const names = csBlocks.filter((cs) => cs.name === b.name);
                                                        const label = names.length > 1 ? `${b.name}_${csBlocks.indexOf(b)}` : b.name;

                                                        if (!budgetDataSet) {
                                                            budgetDataSet = {
                                                                id: b.id,
                                                                label: label,
                                                                fill: false,
                                                                isHarvested: f.isHarvested,
                                                                type: "bar",
                                                                stacked: true,
                                                                lineTension: 0.1,
                                                                backgroundColor: utils.getGraphColour(i),
                                                                borderColor: utils.getGraphColour(i),
                                                                data: carbonArray,
                                                            };
                                                            budgetBarNData.datasets.unshift(budgetDataSet);
                                                        } else {
                                                            budgetDataSet.data = budgetBarNData.labels.map((l, li) => {
                                                                let sum = 0;
                                                                if (budgetDataSet.data.length > li) {
                                                                    sum += budgetDataSet.data[li];
                                                                }
                                                                if (carbonArray.length > li) {
                                                                    sum += carbonArray[li];
                                                                }
                                                                return utils.round(sum, 2);
                                                            });
                                                        }
                                                        //block total line
                                                        if (forests.length === x + 1) {
                                                            blockNData.datasets.unshift({
                                                                label: "Block total",
                                                                type: "line",
                                                                fill: false,
                                                                stacked: false,
                                                                lineTension: 0.1,
                                                                backgroundColor: utils.getGraphColour(10),
                                                                borderColor: utils.getGraphColour(10),
                                                                data: budgetBarNData.labels.map((l, li) => {
                                                                    return utils.round(
                                                                        blockNData.datasets.reduce((total, dataset) => (dataset.data.length > li ? (total += dataset.data[li]) : total), 0),
                                                                        2
                                                                    );
                                                                }),
                                                            });
                                                        }

                                                        //budget totals line
                                                        if (csBlocks.length === i + 1 && forests.length === x + 1) {
                                                            const ds = {
                                                                label: "Farm total",
                                                                type: "line",
                                                                fill: false,
                                                                stacked: false,
                                                                lineTension: 0.1,
                                                                backgroundColor: utils.getGraphColour(10),
                                                                borderColor: utils.getGraphColour(10),
                                                                data: budgetBarNData.labels.map((l, li) => {
                                                                    return utils.round(
                                                                        budgetBarNData.datasets.reduce((total, dataset) => (dataset.data.length > li ? (total += dataset.data[li]) : total), 0),
                                                                        2
                                                                    );
                                                                }),
                                                            };
                                                            budgetBarNData.datasets.unshift(ds);

                                                            if (ds.data.length > 0) {
                                                                currentCarbonStock = ds.data[0];
                                                            }
                                                            bestCarbonStock = ds.data.reduce((best, current) => (current > best ? current : best), 0);
                                                            bestCarbonStockYear = ds.data.indexOf(bestCarbonStock);

                                                            const dsOffset = {
                                                                label: "Offset",
                                                                type: "line",
                                                                fill: false,
                                                                stacked: false,
                                                                lineTension: 0.1,
                                                                backgroundColor: utils.getGraphColour(1),
                                                                borderColor: utils.getGraphColour(1),
                                                                data: ds.data.map((v) => utils.round(farmEmissionsTotal - v, 2)),
                                                            };

                                                            totalsBarNData.datasets.unshift(dsOffset);
                                                        }

                                                        const forestUiId = `${b.id}_${f.id}`;
                                                        const showForestGraph = state[forestUiId];

                                                        return (
                                                            <Fragment key={x}>
                                                                <tr>
                                                                    <td>
                                                                        <ActionLink className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween" onClick={() => setState({ ...state, [forestUiId]: !showForestGraph })}>
                                                                            {f.name || utils.valueToText(forestType, f.forestType)}
                                                                            {data.length > 0 && <i className={`icon icon-arrow-${showForestGraph ? "up" : "down"}`}></i>}
                                                                        </ActionLink>
                                                                    </td>
                                                                    <td>
                                                                        {f.forestAge} <small>years</small>
                                                                    </td>
                                                                    <td>
                                                                        {f.percentage}
                                                                        <small>%</small>
                                                                    </td>
                                                                    <td>
                                                                        {utils.round(area, 2)}
                                                                        <small>ha</small>
                                                                    </td>
                                                                </tr>
                                                                {data.length > 0 && showForestGraph && (
                                                                    <tr>
                                                                        <td colSpan="4">
                                                                            <div className="u-pt-md">
                                                                                <Line data={nData} legend={{ display: true }} height={75} options={f.isHarvested ? harvestOptions : lineOptions} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {blockNData.datasets.length > 2 && (
                                            <div className="u-pt-md">
                                                <Bar data={blockNData} options={barOptions} />
                                            </div>
                                        )}
                                    </Fragment>
                                );
                            })}
                            {budgetBarNData.datasets.length > 2 && (
                                <>
                                    <div className="h2 u-mb-sm u-mt-lg">{state.displaycumulative === "cumulative" ? "Cumulative" : "Annual"} carbon stock totals</div>
                                    {false && (
                                        <div className="Table u-mt-md u-mb-md">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Totals</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="HeroValue">
                                                                <div className="HeroValue-item">
                                                                    <h5>Block area</h5>
                                                                    <p className="HeroValue-item-number">
                                                                        {budgetBlockArea}
                                                                        <small>ha</small>
                                                                    </p>
                                                                </div>
                                                                <div className="HeroValue-item">
                                                                    <h5>Forest area</h5>
                                                                    <p className="HeroValue-item-number">
                                                                        {utils.round(budgetForestArea, 1)}
                                                                        <small>ha</small>
                                                                    </p>
                                                                </div>
                                                                <div className="HeroValue-item">
                                                                    <h5>Forest percentage</h5>
                                                                    <p className="HeroValue-item-number">
                                                                        {utils.round((budgetForestArea / budgetBlockArea) * 100, 1)}
                                                                        <small>%</small>
                                                                    </p>
                                                                </div>
                                                                <div className="HeroValue-item">
                                                                    <h5>Current carbon stock</h5>
                                                                    <p className="HeroValue-item-number">
                                                                        {utils.round(currentCarbonStock, 1).toLocaleString()} <small>tonnes</small>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <Bar data={budgetBarNData} options={barOptions} />
                                </>
                            )}
                            {false && (
                                <>
                                    <div className="h2 u-mb-sm">Tree blocks carbon sequestration (indicative)</div>
                                    <div className="Table u-mt-md u-mb-md">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Totals</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="HeroValue">
                                                            <div className="HeroValue-item">
                                                                <h5>Farm CO2 equivalent emissions</h5>
                                                                <div className="u-flex u-flexColumn">
                                                                    <span className="h1">
                                                                        {utils.round(farmEmissionsTotal, 1).toLocaleString()} <small>tonnes</small>
                                                                    </span>
                                                                    <span className="u-textNoWrap">
                                                                        {utils.round(ghgEmissionsTotal / 1000, 2)} tonnes/ha * {utils.round(productiveBlockArea, 2)}ha (productive area)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="HeroValue-item">
                                                                <h5>Farm CO2 equivalent emissions - carbon stock (year 0)</h5>
                                                                <div className="u-flex u-flexColumn">
                                                                    <span className="h1">
                                                                        {utils.round(farmEmissionsTotal - currentCarbonStock, 0).toLocaleString()} <small>tonnes</small>
                                                                    </span>
                                                                    <span className="u-textNoWrap">
                                                                        {utils.round(farmEmissionsTotal, 1).toLocaleString()} tonnes (farm emissions) - {utils.round(currentCarbonStock, 1).toLocaleString()} tonnes (carbon stock)
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="HeroValue-item">
                                                                <h5>Farm CO2 equivalent emissions - carbon stock (year 30)</h5>
                                                                <div className="u-flex u-flexColumn">
                                                                    <span className="h1">
                                                                        {utils.round(farmEmissionsTotal - bestCarbonStock, 0).toLocaleString()} <small>tonnes</small>
                                                                    </span>
                                                                    <span className="u-textNoWrap">
                                                                        {utils.round(farmEmissionsTotal, 1).toLocaleString()} tonnes (farm emissions) - {utils.round(bestCarbonStock, 1).toLocaleString()} tonnes (carbon stock) at year {bestCarbonStockYear}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                            {false && <Bar data={totalsBarNData} options={barOptions} />}
                        </>
                    )}
                </PanelBody>
            )}
        </Panel>
    )
}
