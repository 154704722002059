import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import * as utils from "common/utils";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { usePdfModal } from "components/PdfModal";
import ZendeskLink from "components/Help/ZendeskLink";
import { useDatasetModal } from "./DatasetModal";
import { getDatasets, deleteDataset, updateDataset, executeModelTest } from "./_actions";
import { useDatasets, useScienceSettings, useServerNotifications } from "./_hooks";
import { useConfirm, useOnline } from "common/hooks";

export default function Datasets() {
    const dispatch = useDispatch();
    const online = useOnline();
    const confirm = useConfirm();
    const isLoading = useSelector((state) => state.app.isLoading);
    const datasets = useDatasets();
    const { data: settings } = useScienceSettings();
    const serverNotification = useServerNotifications();
    const [pdfModal, openPdfModal] = usePdfModal("Guide to datasets", "https://docs.overseer.org.nz/sci/OverseerSciGuide.pdf");
    const [openDatasetModal, datasetModal] = useDatasetModal();

    const info = (
        <span>
            The following is a list of datasets within your organisation. For a guide on using datasets please{" "}
            <ActionLink onClick={openPdfModal} className="u-textBold">
                click here
            </ActionLink>{" "}
            or turn on the help tip at the top right of the screen
        </span>
    );
    const help = (
        <>
            <p className="u-mb-sm">Datasets contain a set of farm analyses that can be run through the model and the results examined. This screen shows the list of existing datasets for your organisation.</p>
            <p>You can create a new empty dataset by selecting the "Create dataset" button at the top right of the screen.</p>
            <p>You can delete you datasets from here. Please note that if a dataset is deleted all model runs and farm analyses that were in the dataset are deleted.</p>
        </>
    );
    const tableData = (datasets || []).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })).map((dataset) => ({ ...dataset, createdByUser: dataset.createdBy ? dataset.createdBy.userFullName : "" }));

    const _createDataset = () => {
        openDatasetModal(undefined, (newDataset) => dispatch(push(`/app/datasets/${newDataset.id}`)));
    };

    const _toggleTestDataset = (dataset, toggle) => () => {
        dataset.testDataset = toggle;
        dispatch(updateDataset(dataset)).then(() => dispatch(getDatasets()));
    };

    const _executeModelTest = (datasetId, failedOnly) => () => {
        dispatch(executeModelTest(datasetId, failedOnly));
    };

    const _deleteDataset = (dataset) => (e) => {
        e.preventDefault();
        confirm(`Are you sure you want to delete the "${dataset.name}" dataset and all of its associated analyses?`, () => dispatch(deleteDataset(dataset.id)));
    };

    return (
        <Page>
            <section className="PageHead">
                <div className="Container">
                    <div className="FarmTitle">
                        <div className="FarmTitle-heading">
                            <span id="page-head-title" className="FarmTitle-name">
                                Datasets
                            </span>
                        </div>
                    </div>
                    <div className="PageHead-actions">
                        {online && datasets && settings && datasets.length < settings.maxDatasets && (
                            <ActionLink onClick={_createDataset} id="create-datasset" className="IconLink--arrow-plus">
                                Create dataset
                            </ActionLink>
                        )}
                        {online && datasets && settings && datasets.length >= settings.maxDatasets && <span className="UnpaidSubscription">{"Reached limit of " + settings.maxDatasets + " datasets for your organisation."}</span>}
                        {!settings && <span className="UnpaidSubscription">{"Your organisation is not set up to use datasets."}</span>}
                        {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && (
                            <ActionLink onClick={_executeModelTest()} id="execute-all-model-tests" className="IconLink--settings">
                                Execute all model tests
                            </ActionLink>
                        )}
                        {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && (
                            <ActionLink onClick={_executeModelTest(undefined, true)} id="execute-failed-model-tests" className="IconLink--settings">
                                Execute failed model tests
                            </ActionLink>
                        )}
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/4414695497369" className="IconLink--new-tab u-font-green" />
                    </div>
                </div>
            </section>
            <PageBody>
                {serverNotification && <Alert type="info" text={serverNotification.message} />}
                <Panel title="My datasets" info={info} help={help} iconClassName="IconLink--equalizer" loading={isLoading}>
                    <PanelBody loading={!datasets}>
                        <SortableTable data={tableData} defaultSortCriteria={{ fieldName: "dateCreated", fieldType: "date", desc: true }}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Project" fieldName="project" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Dataset name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Date created" fieldName="dateCreated" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                                <SortableTableHeader label="Created by" fieldName="createdByUser" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <th>Last test run</th>}
                                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <th>Time taken</th>}
                                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <th>Test dataset</th>}
                                                {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <th></th>}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((dataset) => {
                                                const className = dataset.testDataset ? (dataset.testsPassed ? "Table-tr--green" : "Table-tr--red") : "";
                                                return (
                                                    <tr key={dataset.id} className={className + " hover"}>
                                                        <td>{dataset.project || "-"}</td>
                                                        <td>
                                                            {online && (
                                                                <Link to={`/app/datasets/${dataset.id}`} id={"view-dataset-link-" + dataset.id}>
                                                                    {dataset.name}
                                                                </Link>
                                                            )}
                                                            {!online && dataset.name}
                                                        </td>
                                                        <td>{utils.dateLong(dataset.dateCreated)}</td>
                                                        <td>{dataset.createdBy ? dataset.createdBy.userFullName : "-"}</td>
                                                        {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <td>{dataset.testDataset && (dataset.executionError ? dataset.executionError : dataset.lastRun && utils.dateLong(dataset.lastRun))}</td>}
                                                        {settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && <td>{dataset.testDataset && dataset.timeTaken && dataset.timeTaken}</td>}
                                                        {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && (
                                                            <td className="u-textCenter">
                                                                {
                                                                    <label className="Checkbox" htmlFor={"test-dataset-" + dataset.id}>
                                                                        <input className="Checkbox-input" type="checkbox" id={"test-dataset-" + dataset.id} checked={dataset.testDataset} onChange={_toggleTestDataset(dataset, !dataset.testDataset)} />
                                                                        <span className="Checkbox-label"></span>
                                                                    </label>
                                                                }
                                                            </td>
                                                        )}
                                                        {online && settings && settings.allowedFunctions && settings.allowedFunctions.includes("Testing") && (
                                                            <td className="u-textCenter">
                                                                <ActionLink id={`execute-test-${dataset.id}`} onClick={_executeModelTest(dataset.id)}>
                                                                    {"Execute test"}
                                                                </ActionLink>
                                                            </td>
                                                        )}
                                                        <td>
                                                            <ActionLink id={`delete-dataset-${dataset.id}`} onClick={_deleteDataset(dataset)} className="IconLink--cross-circle" title="Delete">
                                                                <span></span>
                                                            </ActionLink>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </PanelBody>
                </Panel>
            </PageBody>
            {pdfModal}
            {datasetModal}
        </Page>
    )
}
