import * as utils from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import Watermark from "components/Watermark";
import { useAnalysisResults } from "containers/BudgetHome";

export default function StockingRate({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    if (!analysisResults.animals) {
        return null;
    }

    const stockingRates = analysisResults?.animals?.stockingRates;

    return (
        <Panel title="Stocking rate for all enterprises" info={PAGE.INFO} help={PAGE.HELP} skyBlue>
            <PanelBody>
                <div className="Table">
                    <Watermark />
                    <table>
                        <thead>
                            <tr className="tr--white tr--joinTop">
                                <th>&nbsp;</th>
                                <th colSpan="2">Average stocking rate (RSU/ha/yr)</th>
                                <th colSpan="3">Total stocking rate (RSU/yr)</th>
                            </tr>
                            <tr>
                                <th data-width="20">Enterprise</th>
                                <th data-width="15" className="numeric">Grazed area</th>
                                <th data-width="15" className="numeric">Total farm area</th>
                                <th data-width="12.5" className="numeric">Pasture eaten</th>
                                <th data-width="12.5" className="numeric">Time on pasture</th>
                                <th data-width="10" className="numeric">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockingRates?.byEnterprise?.map(enterprise => {
                                const enterpriseKey = `stocking_rate_${enterprise.name}`;
                                return (
                                    <tr key={enterprise.id}>
                                        <td>{enterprise.name}</td>
                                        <td id={`${enterpriseKey}_byGrazedArea`} className="numeric">
                                            {utils.numeric.format(enterprise.byGrazedArea.value, 2)}
                                        </td>
                                        <td id={`${enterpriseKey}_byFarmArea`} className="numeric total">
                                            {utils.numeric.format(enterprise.byFarmArea.value, 2)}
                                        </td>
                                        <td id={`${enterpriseKey}_fromPastureEaten`} className="numeric">
                                            {utils.numeric.format(enterprise.fromPastureEaten.value)}
                                        </td>
                                        <td id={`${enterpriseKey}_fromTimeOnPasture`} className="numeric">
                                            {utils.numeric.format(enterprise.fromTimeOnPasture.value)}
                                        </td>
                                        <td id={`${enterpriseKey}_total`} className="numeric total">
                                            {utils.numeric.format(enterprise.total.value)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {stockingRates &&
                                <tr className="totals">
                                    <th>Totals</th>
                                    <th id="totals_byGrazedArea" className="numeric total">
                                        {utils.numeric.format(stockingRates.byGrazedArea.value, 2)}
                                    </th>
                                    <th id="totals_fromTimeOnPasture" className="numeric total">
                                        {utils.numeric.format(stockingRates.byFarmArea.value, 2)}
                                    </th>
                                    <th id="totals_fromPastureEaten" className="numeric total">
                                        {utils.numeric.format(stockingRates.fromPastureEaten.value)}
                                    </th>
                                    <th id="totals_fromTimeOnPasture" className="numeric total">
                                        {utils.numeric.format(stockingRates.fromTimeOnPasture.value)}
                                    </th>
                                    <th id="totals_total" className="numeric total">
                                        {utils.numeric.format(stockingRates.total.value)}
                                    </th>
                                </tr>
                            }
                        </tfoot>
                    </table>
                </div>
            </PanelBody>
        </Panel>
    )
}

const PAGE = {
    INFO: "The stocking rate shows the total revised stock units (RSU) and estimated RSU per hectare for each enterprise.",
    HELP: <>
        A revised stock unit (RSU) is defined as an animal with an intake of 6000 MJ ME intake per year. This is similar to a standard stock unit. <br />
        This enables the carrying capacity of dairy and non-dairy systems to be compared, based on feed intake.
        <br />
        <br />
        The outputs shown for each enterprise are:
        <ul>
            <li>
                <b>Grazed area: </b>Stocking rate on grazed pasture estimated as total RSU divided by grazed area (pastoral blocks).
            </li>
            <li>
                <b>Total farm area: </b>Total stocking rate estimated as total RSU divided by total farm area (RSU/ha)
            </li>
            <li>
                <b>Pasture eaten: </b>Carrying capacity based on pasture intake (ME of pasture consumed by animals). This is pasture consumed on all block types. As the amount of supplements fed out increases, 'Pasture eaten' RSU decreases as a proportion of total RSU.
            </li>
            <li>
                <b>Time on pasture: </b>RSU estimated from feed consumed while on pastoral blocks. Includes ME supplied from pasture together with supplements and crops fed on pastoral blocks. As the amount of supplements directed to structures increases, 'Time of pasture' RSU decreases as a proportion of total RSU.
            </li>
            <li>
                <b>Total: </b>The total carrying capacity as RSU.
            </li>
        </ul>
    </>
}
