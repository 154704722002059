import { useState, useMemo, Fragment } from "react";
import * as utils from "common/utils";
import * as domain from "common/domain";
import { RadioButtonOptionSelector } from "components/RadioButtonOptionSelector";
import Watermark from "components/Watermark";
import ActionLink from "components/ActionLink";
import { useAnalysisResults } from "containers/BudgetHome";

export default function Diet({ analysis, enterpriseId }) {
    const data = useData(analysis, enterpriseId);
    const [selectedValue, setSelectedValue] = useState(OPTIONS[0].value);

    const [expandedSources, setExpandedSources] = useState([]);
    const expandSource = (sourceKey) => setExpandedSources((prevState) => prevState.includes(sourceKey) ? prevState.filter((key) => key !== sourceKey) : [...prevState, sourceKey]);

    const selectedData = useMemo(() => {
        return data?.[selectedValue];
    }, [data, selectedValue])

    const showPercentagesFootnote = selectedValue === OPTIONS[0].value;
    const showSubSources = selectedValue !== OPTIONS[1].value;
    const showUnitOfMeasure = selectedValue === OPTIONS[2].value;
    const dp = selectedValue === OPTIONS[2].value ? 1 : 0;

    const rootKey = `diet_${selectedValue}_${selectedData?.name}`;

    if (!selectedData) {
        return null;
    } 

    return (
        <>
            <div className="Table">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="20">Diet</th>
                            <th colSpan="13">
                                <RadioButtonOptionSelector name="diet_options" options={OPTIONS} value={selectedValue} onChange={setSelectedValue} />
                            </th>
                        </tr>
                        <tr>
                            <th className="u-borderTop-none">&nbsp;</th>
                            {domain.farmYear.map((month) => (
                                <th key={month} data-width="5" className="numeric">
                                    {utils.month.format(month)}
                                </th>
                            ))}
                            <th data-width="5" className="numeric">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedData?.bySource?.map((source) => {
                            const sourceKey = `${rootKey}_${source.name}`;
                            const hasSubSources = showSubSources && source.subSources?.length > 0;
                            const isSourceExpanded = hasSubSources && expandedSources.includes(source.name);
                            return (
                                <Fragment key={sourceKey}>
                                    <tr>
                                        <td>
                                            {hasSubSources
                                                ? <ActionLink onClick={() => expandSource(source.name)} className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
                                                    {source.name}
                                                    <i className={`icon icon-arrow-${isSourceExpanded ? "up" : "down"}`}></i>
                                                </ActionLink>
                                                : source.name
                                            }
                                        </td>
                                        {source.byMonth.map((m) => {
                                            const monthKey = `${sourceKey}_${m.month}`;
                                            const isOverfeedError = m.percentageOfRequirements?.value > 120;
                                            return (
                                                <td key={monthKey} className={`numeric ${isOverfeedError ? "Alert--error" : undefined}`}>
                                                    <div id={monthKey}>{utils.numeric.format(m.amount.value, dp)}{showUnitOfMeasure && m.amount.value > 0 && m.amount.unit.notation}</div>
                                                    {m.percentageOfRequirements?.value > 0 && (
                                                        <div className={`small ${isOverfeedError ? "u-textError" : undefined}`}>
                                                            <span id={`${monthKey}_percentageOfRequirements`}>{utils.numeric.format(m.percentageOfRequirements.value, 1)}</span>
                                                            <span id={`${monthKey}_percentageOfRequirements_uom`}>{m.percentageOfRequirements.unit.notation}</span>
                                                            <sup>*</sup>
                                                        </div>
                                                    )}
                                                </td>
                                            )
                                        })}
                                        <td id={`${sourceKey}_total`} className="numeric total">
                                            {utils.numeric.format(source.total.value, dp)}{showUnitOfMeasure && source.total.value > 0 && source.total.unit.notation}
                                        </td>
                                    </tr>
                                    {isSourceExpanded && source.subSources.map((subSource) => {
                                        const subSourceKey = `${sourceKey}_${subSource.name}`;
                                        return (
                                            <tr key={subSourceKey} className="tr-nested">
                                                <td>
                                                    {subSource.name}
                                                </td>
                                                {subSource.byMonth.map((m) => {
                                                    const monthKey = `${subSourceKey}_${m.month}`;
                                                    return (
                                                        <td key={monthKey} id={monthKey} className="numeric">
                                                            {utils.numeric.format(m.amount.value, dp)}{showUnitOfMeasure && m.amount.value > 0 && m.amount.unit.notation}
                                                        </td>
                                                    )
                                                })}
                                                <td id={`${subSourceKey}_total`} className="numeric total">
                                                    {utils.numeric.format(subSource.total.value, dp)}{showUnitOfMeasure && subSource.total.value > 0 && subSource.total.unit.notation}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        {selectedData &&
                            <tr className="totals">
                                <th>Totals</th>
                                {selectedData.byMonth.map((m) => {
                                    return (
                                        <th key={`${rootKey}_totals_${m.month}`} className="numeric total">
                                            {utils.numeric.format(m.amount.value, dp)}{showUnitOfMeasure && m.amount.value > 0 && m.amount.unit.notation}
                                        </th>
                                    )
                                })}
                                <th id={`${rootKey}_total`} className="numeric total">
                                    {utils.numeric.format(selectedData.total.value, dp)}{showUnitOfMeasure && selectedData.total.value > 0 && selectedData.total.unit.notation}
                                </th>
                            </tr>
                        }
                    </tfoot>
                </table>
            </div>
            {showPercentagesFootnote && <sub>** Percentage of ME requirements from this source. Anything over 120% of ME requirements is considered an overfeed error.</sub>}
        </>
    )
}

function useData(analysis, enterpriseId) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    if (analysisResults?.animals) {
        const enterpriseFeed = analysisResults?.animals?.sources?.byEnterprise?.find((e) => e.id === enterpriseId);

        const data = {};
        data[OPTIONS[0].value] = getMetabolicEnergyData(enterpriseFeed);
        data[OPTIONS[1].value] = getRsuData(enterpriseFeed);
        data[OPTIONS[2].value] = getProportionOfDietData(enterpriseFeed);
        return data
    }
}

function getMetabolicEnergyData(enterpriseFeed) {
    const data = {
        bySource: enterpriseFeed?.bySource?.map((src) => {
            const source = {
                name: src.name,
                byMonth: src.byMonth.map((m) => {
                    return {
                        sequence: m.sequence,
                        month: m.month,
                        amount: m.metabolicEnergy,
                        percentageOfRequirements: m.percentageOfRequirements
                    }
                }),
                total: src.totalMetabolicEnergy,
            }
            if (src.subSources) {
                source.subSources = src.subSources.map((subSrc) => {
                    return {
                        name: subSrc.name,
                        byMonth: subSrc.byMonth.map((m) => {
                            return {
                                sequence: m.sequence,
                                month: m.month,
                                amount: m.metabolicEnergy,
                            }
                        }),
                        total: subSrc.totalMetabolicEnergy,
                    }
                })
            }
            return source;
        }),
        byMonth: enterpriseFeed?.byMonth?.map((m) => {
            return {
                sequence: m.sequence,
                month: m.month,
                amount: m.metabolicEnergy,
            }
        }),
        total: enterpriseFeed?.totalMetabolicEnergy,
    }
    return data;
}

function getRsuData(enterpriseFeed) {
    const data = {
        bySource: enterpriseFeed?.bySource?.map((src) => {
            const source = {
                name: src.name,
                byMonth: src.byMonth.map((m) => {
                    return {
                        sequence: m.sequence,
                        month: m.month,
                        amount: m.revisedStockUnits,
                    }
                }),
                total: src.totalRevisedStockUnits
            }
            if (src.subSources) {
                source.subSources = src.subSources.map((subSrc) => {
                    return {
                        name: subSrc.name,
                        byMonth: subSrc.byMonth.map((m) => {
                            return {
                                sequence: m.sequence,
                                month: m.month,
                                amount: m.revisedStockUnits,
                            }
                        }),
                        total: subSrc.totalRevisedStockUnits
                    }
                })
            }
            return source;
        }),
        byMonth: enterpriseFeed?.byMonth?.map((m) => {
            return {
                sequence: m.sequence,
                month: m.month,
                amount: m.revisedStockUnits,
            }
        }),
        total: enterpriseFeed?.totalRevisedStockUnits
    }
    return data;
}

function getProportionOfDietData(enterpriseFeed) {
    const data = {
        bySource: enterpriseFeed?.bySource?.map((src) => {
            const source = {
                name: src.name,
                byMonth: src.byMonth.map((m) => {
                    return {
                        sequence: m.sequence,
                        month: m.month,
                        amount: m.proportionOfDiet,
                        uom: m.proportionOfDiet.value ? m.proportionOfDiet.unit.notation : undefined
                    }
                }),
                total: src.totalProportionOfDiet
            }
            if (src.subSources) {
                source.subSources = src.subSources.map((subSrc) => {
                    return {
                        name: subSrc.name,
                        byMonth: subSrc.byMonth.map((m) => {
                            return {
                                sequence: m.sequence,
                                month: m.month,
                                amount: m.proportionOfDiet,
                                uom: m.proportionOfDiet.value ? m.proportionOfDiet.unit.notation : undefined
                            }
                        }),
                        total: subSrc.totalProportionOfDiet
                    }
                })
            }
            return source;
        }),
        byMonth: enterpriseFeed?.byMonth?.map((m) => {
            return {
                sequence: m.sequence,
                month: m.month,
                amount: m.proportionOfDiet,
                uom: m.proportionOfDiet.value ? m.proportionOfDiet.unit.notation : undefined
            }
        }),
        total: enterpriseFeed?.totalProportionOfDiet
    }
    return data;
}

const OPTIONS = [
    { value: "metabolicEnergy", text: "Metabolic energy", uom: "MJ ME" },
    { value: "rsu", text: "Revised stock units", uom: "RSU" },
    { value: "proportionOfDiet", text: "Proportion of diet", uom: "%" },
];
