import "components/DatePickerCssOverrides.css";
import "react-datepicker/dist/react-datepicker.css";
import { Component } from "react";
import { reduxForm, Field, FieldArray, arrayPush, arrayInsert } from "redux-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useOnline, useRefData, useShowQuickTips } from "common/hooks";
import * as normalizers from "common/normalizers";
import * as utils from "common/utils";
import * as validations from "common/validations";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import { Button } from "components/Button";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import FieldGroup from "components/FieldGroup";
import SelectPack from "components/SelectPack2";
import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import MonthDayInputPack from "components/MonthDayInputPack";
import DistributionErrors from "../DistributionErrors";
import * as animalUtils from "../_utils";
import LivestockList from "./LivestockList";
import OtherLivestockList from "./OtherLivestockList";
import HealthSupplementsSummary from "./HealthSupplementsSummary";
import DairyGoatLeftOvers from "./DairyGoatLeftOvers";
import AnimalDistribution from "components/AnimalDistribution";
import { deselectMonths } from "../../Effluent/_utils";
import DairyCalving from "../DairyCalving";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function EnterpriseDetails({ farm, analysis, initialValues }) {
    const online = useOnline();
    const refData = useRefData();
    const showQuickTips = useShowQuickTips();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);
    const addEvent = useAddLivestockEvent();

    return <EnterpriseDetailsForm farm={farm} analysis={analysis} initialValues={initialValues} updateAnalysisAsync={updateAnalysisAsync} addEvent={addEvent} online={online} refData={refData} showQuickTips={showQuickTips } />
}

class EnterpriseDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const { farm, analysis, initialValues: ent, refData } = props;
        const enterprise = utils.clone(ent);
        const { enterprises = [] } = analysis;
        const { peakCowNumbers = {} } = enterprise;

        const state = {
            title: enterprise.name,
            referrer: `/app/farm/${farm.id}/analysis/${analysis.id}/animals`,
            enterpriseType: enterprise.type,
            livestocks: enterprise.mobs || [],
            healthSupplements: enterprise.healthSupplements || {},
            livestockChanged: false,
            healthSupplementsChanged: false,
            submitSucceeded: false,
            analysisHasDairyPeak: analysisHasDairyPeak(enterprises),
        };
        if (!peakCowNumbers.replacementGrazing) {
            peakCowNumbers.replacementGrazing = "Undefined";
        }
        if (enterprise.type) {
            const isDairyPeak = enterprise.specificationMethod === "DairyPeakCowNumbers";
            state[`show${showType(enterprise.type, enterprise.specificationMethod)}Fields`] = true;
            state.showMonthlyStockRecFields = ["DairyReplacements", "DairyGoat", "Other"].includes(enterprise.type) ? false : enterprise.specificationMethod === "MonthlyStockRec";
            state.milkSolids = enterprise.annualMilkSolidsYield_kgyr;
            state.specifyCalving = enterprise.specifyCalving;
            state.calvingDetails = enterprise.specifyCalving ? peakCowNumbers.calvingDetails : undefined;
            state.milkSolidsPerCow = this._calcMilkSolidsPerCow(state.milkSolids, state.livestocks, peakCowNumbers.amount);
            state.showMilkProductionFields = enterprise.specificationMethod === "DairyPeakCowNumbers" || this._showMilkProductionFields(state.enterpriseType, state.livestocks);
            state.enterpriseName = utils.valueToText(refData.enterpriseTypes, enterprise.type);
            state.enterpriseDefaults = (refData.enterpriseDefaults || []).find((ed) => ed.enterpriseType === enterprise.type);
            state.defaultParturitionDate = this._getDefaultParturitionDate(enterprise.weaningDate, state.enterpriseDefaults);
            state.defaultWeaningDate = this._getDefaultWeaningDate(enterprise.parturitionDate, state.enterpriseDefaults);
            state.defaultReplacementRate = this._getDefaultReplacementRate(state.enterpriseDefaults);
            state.defaultAvgMobRate = this._getDefaultAvgMobRate(state.enterpriseDefaults, peakCowNumbers.breed);
            state.defaultMilkProductionValues = this._getDefaultMilkProductionValues(enterprise.annualMilkSolidsYield_kgyr, state.livestocks, state.enterpriseDefaults, peakCowNumbers.breed);
            state.animalsGrazingOff = (isDairyPeak || enterprise.specificationMethod === "RSU") && (!enterprise.animalsGrazingOff || enterprise.animalsGrazingOff.length === 0) ? [] : enterprise.animalsGrazingOff;
            state.specificationMethod = enterprise.specificationMethod;
            if (isDairyPeak) {
                state.peakCowAmount = peakCowNumbers.amount;
                state.peakCowBreed = peakCowNumbers.breed;
            }
        } else {
            state.enterpriseTypes = animalUtils.getEnterpriseTypeOptions(analysis, refData);
        }
        this.state = state;
    }

    _getDefaultParturitionDate = (weaningDateString, enterpriseDefaults) => {
        if (!enterpriseDefaults) return undefined;

        if (weaningDateString) {
            const weaningDate = moment(weaningDateString, "DD MMMM");
            weaningDate.year(2014);
            return weaningDate.subtract(enterpriseDefaults.defaultWeaningAge, "d").format("DD MMMM");
        }

        const parturitionDate = moment(enterpriseDefaults.defaultBirthDayOfYear, "DDD");
        parturitionDate.year(2014);
        return parturitionDate.format("DD MMMM");
    };

    _getDefaultWeaningDate = (parturitionDateString, enterpriseDefaults) => {
        if (!enterpriseDefaults) return undefined;

        const parturitionDate = parturitionDateString ? moment(parturitionDateString, "DD MMMM") : moment(enterpriseDefaults.defaultBirthDayOfYear, "DDD");
        parturitionDate.year(2014);
        return parturitionDate.add(enterpriseDefaults.defaultWeaningAge + 1, "d").format("DD MMMM");
    };

    _getDefaultReplacementRate = (enterpriseDefaults) => {
        return enterpriseDefaults ? enterpriseDefaults.defaultReplacementPercentage || 0 : 0;
    };

    _getDefaultAvgMobRate = (enterpriseDefaults, breed) => {
        if (!enterpriseDefaults) return 0;
        const { standardReferenceWeight = {} } = enterpriseDefaults;
        return standardReferenceWeight[breed] || 0;
    };

    _getDefaultMilkProductionValues = (milkSolids, livestocks, enterpriseDefaults, peakBreed) => {
        if (!peakBreed && (livestocks.length === 0 || !milkSolids || !enterpriseDefaults || !enterpriseDefaults.cowFat)) return { fatYield: 0, milkVolumeYield: 0 };

        const milkingHerds = livestocks.filter((l) => l.dairyStockClass === "MilkingHerd");
        if (milkingHerds.length === 0 && !peakBreed) return { fatYield: 0, milkVolumeYield: 0 };

        const breed = peakBreed || milkingHerds[0].dairyBreed;

        if (!enterpriseDefaults.cowFat || !enterpriseDefaults.cowProtein || !enterpriseDefaults.cowMilkRatio) {
            return { fatYield: 0, milkVolumeYield: 0 };
        }

        const cowFat = enterpriseDefaults.cowFat[breed];
        const cowProtein = enterpriseDefaults.cowProtein[breed];
        const milkRatio = enterpriseDefaults.cowMilkRatio[breed];

        const fatYield = utils.round((cowFat / (cowFat + cowProtein)) * milkSolids, 0);
        const milkVolumeYield = utils.round(milkSolids * milkRatio, 0);
        return { fatYield, milkVolumeYield };
    };

    _getSpecificationMethodOptions = () => {
        const { refData, initialValues: enterprise } = this.props;

        const options = (refData?.specificationMethod || []).reduce((results, option) => {
            if (option.value === "MonthlyStockRec") {
                results.push(option);
            } else if (enterprise.type !== "Dairy" && option.value === "RSU") {
                results.push(option);
            } else if (enterprise.type === "Dairy" && option.value === "DairyPeakCowNumbers") {
                // PRD-5419: peak cows is no longer supported
                results.push({
                    ...option,
                    text: `Peak cow numbers (NO LONGER SUPPORTED)`,
                    disabled: true,
                });
            }
            return results;
        }, []);

        return options;
    };

    _showMilkProductionFields = (enterpriseType, livestocks) => {
        if (enterpriseType === "Dairy" && livestocks.some((l) => l.dairyStockClass === "MilkingHerd")) return true;

        if (enterpriseType === "DairyGoat" && livestocks.some((l) => l.dairyGoatStockClass.startsWith("DoesMilking"))) return true;

        return false;
    };

    _parturitionDateChanged = (e, newValue) => {
        const defaultWeaningDate = this._getDefaultWeaningDate(newValue, this.state.enterpriseDefaults);
        this.setState({ defaultWeaningDate });
    };

    _weaningDateChanged = (e, newValue) => {
        const defaultParturitionDate = this._getDefaultParturitionDate(newValue, this.state.enterpriseDefaults);
        this.setState({ defaultParturitionDate });
    };

    _peakCowBreedChanged = (e, newValue) => {
        const defaultMilkProductionValues = this._getDefaultMilkProductionValues(this.state.milkSolids, this.state.livestocks, this.state.enterpriseDefaults, newValue);
        const milkSolidsPerCow = this._calcMilkSolidsPerCow(this.state.milkSolids, this.state.livestocks, this.state.peakCowAmount);
        const averageMobWeight = this._getDefaultAvgMobRate(this.state.enterpriseDefaults, newValue);
        this.setState({ defaultMilkProductionValues, milkSolidsPerCow, showMilkProductionFields: this.state.peakCowAmount && newValue, peakCowBreed: newValue, defaultAvgMobRate: averageMobWeight });
    };

    _peakCowAmountChanged = (e, newValue) => {
        const amount = Number(newValue);
        const defaultMilkProductionValues = this._getDefaultMilkProductionValues(this.state.milkSolids, this.state.livestocks, this.state.enterpriseDefaults, this.state.peakCowBreed);
        const milkSolidsPerCow = this._calcMilkSolidsPerCow(this.state.milkSolids, this.state.livestocks, amount);
        this.setState({ defaultMilkProductionValues, milkSolidsPerCow, showMilkProductionFields: this.state.peakCowBreed && newValue, peakCowAmount: amount });
    };

    _milkSolidsChanged = (e, newValue) => {
        const defaultMilkProductionValues = this._getDefaultMilkProductionValues(newValue, this.state.livestocks, this.state.enterpriseDefaults, this.state.peakCowBreed);
        const milkSolidsPerCow = this._calcMilkSolidsPerCow(newValue, this.state.livestocks, this.state.peakCowAmount);
        this.setState({ milkSolids: newValue, defaultMilkProductionValues, milkSolidsPerCow });
    };

    _hasBreedingFemales = (enterpriseType, livestocks) => {
        if (enterpriseType === "Sheep" && livestocks.some((l) => l.sheepStockClass.startsWith("BreedingEwes"))) return true;

        if (enterpriseType === "Beef" && livestocks.some((l) => ["CowsMixedAgeBreeding", "CowsBreeding"].includes(l.beefStockClass))) return true;

        if (enterpriseType === "Deer" && livestocks.some((l) => l.deerStockClass.startsWith("BreedingHinds"))) return true;

        return false;
    };

    _hasBreedsThatRequireFemales = (enterpriseType, livestocks) => {
        if (enterpriseType === "Sheep" && livestocks.some((l) => ["Hoggets", "BreedingRams"].includes(l.sheepStockClass))) return true;

        if (enterpriseType === "Beef" && livestocks.some((l) => ["HeifersBreeding", "BullsBreeding"].includes(l.beefStockClass))) return true;

        if (enterpriseType === "Deer" && livestocks.some((l) => l.deerStockClass.startsWith("BreedingStags"))) return true;

        return false;
    };

    _areBreedingFemalesRequired = (enterpriseType, livestocks) => {
        const hasBreedsThatRequireFemales = this._hasBreedsThatRequireFemales(enterpriseType, livestocks);
        const hasBreedingFemales = this._hasBreedingFemales(enterpriseType, livestocks);

        return hasBreedsThatRequireFemales && !hasBreedingFemales;
    };

    _areMilkersRequired = (enterpriseType, livestocks) => {
        if (enterpriseType === "Dairy" && !this.state.showDairyPeakCowNumbersFields && !livestocks.some((l) => l.dairyStockClass === "MilkingHerd")) return true;

        if (enterpriseType === "DairyGoat" && !livestocks.some((l) => l.dairyGoatStockClass.startsWith("DoesMilking"))) return true;

        return false;
    };

    _stockNumberMethodChanged = (e, newValue) => {
        const isDairyPeak = newValue === "DairyPeakCowNumbers";
        const showMilkProductionFields = (isDairyPeak && this.state.peakCowAmount && this.state.peakCowBreed) || this._showMilkProductionFields(this.state.enterpriseType, this.state.livestocks);

        this.setState({
            showMonthlyStockRecFields: ["DairyReplacements", "DairyGoat", "Other"].includes(this.state.enterpriseType) ? false : newValue === "MonthlyStockRec",
            showDairyPeakCowNumbersFields: false,
            showSheepFields: false,
            showBeefFields: false,
            showDairyGrazingFields: false,
            showDeerFields: false,
            showOtherFields: false,
            showRSUFields: false,
            specificationMethod: newValue,
            showMilkProductionFields: showMilkProductionFields,
            specifyCalving: false,
            analysisHasDairyPeak: isDairyPeak,
            [`show${showType(this.state.enterpriseType, newValue)}Fields`]: true,
        });
        if (newValue === "DairyPeakCowNumbers") {
            this.setState({ specifyCalving: this.state.calvingDetails ? true : false });
        }
    };

    _enterpriseTypeChanged = (e, newValue) => {
        const enterpriseDefaults = (this.props.refData.enterpriseDefaults || []).find((ed) => ed.enterpriseType === newValue);
        const defaultMilkProductionValues = this._getDefaultMilkProductionValues(0, [], enterpriseDefaults, this.state.peakCowBreed);
        const { enterprise = {} } = this.state;
        const { peakCowNumbers = {} } = enterprise;

        const defaultParturitionDate = this._getDefaultParturitionDate(enterprise.weaningDate, enterpriseDefaults);
        const defaultWeaningDate = this._getDefaultWeaningDate(enterprise.parturitionDate, enterpriseDefaults);
        const defaultReplacementRate = this._getDefaultReplacementRate(enterpriseDefaults);
        const defaultAvgMobRate = this._getDefaultAvgMobRate(enterpriseDefaults, peakCowNumbers.breed);

        const specificationMethod = newValue === "Dairy" ? "MonthlyStockRec" : this.state.specificationMethod;

        this.setState({
            dairyStockNumbersMethod: undefined,
            enterpriseType: newValue,
            showMilkProductionFields: false,
            showMonthlyStockRecFields: ["Sheep", "Beef", "DairyGrazing", "Deer", "Dairy"].includes(newValue),
            showRSUFields: false,
            showDairyPeakCowNumbersFields: false,
            showDairyReplacementsFields: false,
            showSheepFields: false,
            showBeefFields: false,
            showDairyGrazingFields: false,
            showDeerFields: false,
            showDairyGoatFields: false,
            showOtherFields: false,
            [`show${showType(newValue, specificationMethod)}Fields`]: true,
            livestocks: [],
            //defaultAvgMobRate: 0,
            enterpriseDefaults,
            defaultMilkProductionValues,
            defaultParturitionDate,
            defaultWeaningDate,
            defaultReplacementRate,
            defaultAvgMobRate,
            specificationMethod,
        });
    };

    _livestockAdded = (livestock) => {
        const stockClass = animalUtils.getStockClass(livestock);
        const isBreedingReplacementStockClass = animalUtils.isBreedingReplacementStockClass(livestock.enterpriseType, stockClass);
        if (!isBreedingReplacementStockClass) {
            livestock.mated = false;
        }

        const minDate = (livestock.events || []).reduce((resultDate, event, i) => {
            if (["Starting", "Weaning", "Purchase"].includes(event.eventType)) {
                let eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (!resultDate) resultDate = eventDate;
                if (eventDate.isBefore(resultDate)) resultDate = eventDate;
            }
            return resultDate;
        }, null);

        (livestock.events || []).forEach((event, i) => {
            if (event.eventType === "Purchase") {
                const eventDate = utils.parseDate(event.eventDate, "DD MMMM");
                if (eventDate.isAfter(minDate)) {
                    delete event.ageInMonths;
                    delete event.weightKg;
                }
            }
        });

        const livestocks = this.state.livestocks.map((ls) => (ls.id === livestock.id ? livestock : ls));
        if (!livestocks.some((ls) => ls.id === livestock.id)) {
            livestocks.push(livestock);
        }

        const milkSolidsPerCow = this._calcMilkSolidsPerCow(this.state.milkSolids, livestocks, this.state.peakCowAmount);
        const dairyPeakMilkSolids = this.state.showDairyPeakCowNumbersFields && this.state.peakCowBreed && this.state.peakCowAmount;

        this.setState({
            showMilkProductionFields: dairyPeakMilkSolids || this._showMilkProductionFields(this.state.enterpriseType, livestocks),
            livestocks,
            milkSolidsPerCow,
            livestockChanged: livestock.modalWasDirty,
        });

        this._validateLivestocks(this.state.enterpriseType, livestocks);
    };

    _livestockDeleted = (livestock) => {
        const livestocks = this.state.livestocks.filter((item) => item.id !== livestock.id);

        const milkSolidsPerCow = this._calcMilkSolidsPerCow(this.state.milkSolids, livestocks, this.state.peakCowAmount);
        const dairyPeakMilkSolids = this.state.showDairyPeakCowNumbersFields && this.state.peakCowBreed && this.state.peakCowAmount;

        this.setState({
            showMilkProductionFields: dairyPeakMilkSolids || this._showMilkProductionFields(this.state.enterpriseType, livestocks),
            livestocks,
            milkSolidsPerCow,
            livestockChanged: true,
        });

        this._validateLivestocks(this.state.enterpriseType, livestocks);
    };

    _onOtherLivestockAddedOrRemoved = (otherLivestockCount) => {
        this._validateLivestocks(this.state.enterpriseType, [], otherLivestockCount);
    };

    _addEvent = (event, insertAt) => {
        this.props.addEvent(event, insertAt);
    };

    _healthSupplementsSaved = (healthSupplements) => {
        this.setState({ healthSupplements, healthSupplementsChanged: healthSupplements.modalWasDirty });
    };

    _winterGrazingOffValidate = () => {
        const animalsGrazingOffValidation = {};
        if (this.state.showDairyPeakCowNumbersFields || this.state.showRSUFields) {
            const { animalsGrazingOff = [] } = this.state;
            animalsGrazingOff.filter((g) => isNaN(g.percentageOfAnimals) || g.percentageOfAnimals < 1 || g.percentageOfAnimals > 100).forEach((a) => (animalsGrazingOffValidation[`${a.id}_percentageOfAnimals`] = { touched: true, error: true, message: `Must be between 1 and 100` }));
            animalsGrazingOff.filter((g) => !g.months || g.months.length === 0).forEach((a) => (animalsGrazingOffValidation[`${a.id}_month`] = { touched: true, error: true, message: `Please select at least one month` }));
        }
        this.setState({ animalsGrazingOffValidation: animalsGrazingOffValidation });
        const keyCount = Object.keys(animalsGrazingOffValidation).length;
        return keyCount === 0;
    };

    _winterGrazingOffAdd = () => {
        const { animalsGrazingOff = [] } = this.state;
        if (animalsGrazingOff.length < 12) {
            animalsGrazingOff.push({ id: uuidv4(), percentageOfAnimals: 0 });
        }
        this.setState({ animalsGrazingOff: animalsGrazingOff });
    };

    _winterGrazingOffChange = (e, source) => {
        const { animalsGrazingOff = [] } = this.state;
        const application = animalsGrazingOff.find((a) => a.id === source.applicationId);
        if (application) {
            switch (source.key) {
                case "month": {
                    const { months = [] } = application;
                    application.months = e.target.checked ? [...[source.month], ...months] : months.filter((m) => m !== source.month);
                    deselectMonths(application, animalsGrazingOff);
                    break;
                }
                default:
                    application[source.key] = utils.ctrlVal(e);
                    break;
            }
        }
        this._winterGrazingOffValidate();
        this.setState({ animalsGrazingOff: animalsGrazingOff });
    };

    _winterGrazingOffDelete = (id) => {
        let { animalsGrazingOff = [] } = this.state;
        animalsGrazingOff = animalsGrazingOff.filter((a) => a.id !== id);
        this.setState({ animalsGrazingOff: animalsGrazingOff });
    };

    _calvingToggleSpecify = () => {
        const specifyCalving = !this.state.specifyCalving;
        let calvingDetails = undefined;
        if (specifyCalving) {
            calvingDetails = [{ calvingHerdPercentage: 100, midCalvingDay: 20, midCalvingMonth: "July" }];
        }
        this.setState({ specifyCalving: specifyCalving, calvingDetails: calvingDetails });
    };

    _calvingDetailsChange = (calvingDetails) => {
        calvingDetails.forEach((c) => {
            const strMidCalvingDate = c.midCalvingDay ? `${c.midCalvingDay} ${c.midCalvingMonth}` : null;
            const strDryDate = c.dryDay ? `${c.dryDay} ${c.dryMonth}` : null;
            const [isValid, days] = animalUtils.validateLactationDates(strMidCalvingDate, strDryDate);
            c.error = isValid ? undefined : days;
        });
        this.setState({ calvingDetails: calvingDetails });
    };

    _calvingDetailsAddHerd = () => {
        const { calvingDetails = [] } = this.state;
        calvingDetails.push({ calvingHerdPercentage: 0, midCalvingDay: 20, midCalvingMonth: "July" });
        resetCalvingPercentages(calvingDetails);
        this.setState({ calvingDetails: calvingDetails });
    };

    _calvingDetailsDeleteHerd = (indx) => {
        const { calvingDetails = [] } = this.state;
        if (calvingDetails.length >= indx - 1) {
            calvingDetails.splice(indx, 1);
        }
        resetCalvingPercentages(calvingDetails);
        this.setState({ calvingDetails: calvingDetails });
    };

    _validateLivestocks = (enterpriseType, livestocks, otherLivestockCount) => {
        const livestocksRequired = (livestocks.length === 0 && !this.state.showDairyPeakCowNumbersFields && !this.state.analysisHasDairyPeak && !this.state.showRSUFields && !this.state.showOtherFields) || (enterpriseType === "Other" && otherLivestockCount === 0);
        const milkersRequired = !livestocksRequired && this._areMilkersRequired(enterpriseType, livestocks);
        const breedingFemalesRequired = !livestocksRequired && this._areBreedingFemalesRequired(enterpriseType, livestocks);

        this.setState({
            livestocksRequired,
            milkersRequired,
            breedingFemalesRequired,
        });

        return !livestocksRequired && !milkersRequired && !breedingFemalesRequired;
    };

    _calcMilkSolidsPerCow = (milkSolids, livestocks, peakCowAmount) => {
        let totalStockCount = 0;
        let monthsWithStock = 0;
        let livestocksCount = livestocks.length;
        livestocks.forEach((livestock) => {
            livestock.stockCounts.forEach((item) => {
                if (item.stockCount > 0) {
                    totalStockCount += item.stockCount;
                    monthsWithStock++;
                }
            });
        });
        if (peakCowAmount) {
            monthsWithStock = 12;
            totalStockCount = peakCowAmount * 12;
            livestocksCount = 1;
        }

        const avgCowsPerMonth = utils.round((totalStockCount / monthsWithStock) * livestocksCount, 0);
        const milkSolidsPerCow = utils.round(milkSolids / avgCowsPerMonth, 0);
        return { avgCowsPerMonth, milkSolidsPerCow };
    };

    _save = async (enterprise) => {
        if (!this._validateLivestocks(this.state.enterpriseType, this.state.livestocks, (enterprise.otherLivestocks || []).length)) return;

        if (!this._winterGrazingOffValidate()) return;

        if (this.state.enterpriseType !== "Sheep") {
            enterprise.woolProduction_kg = null;
        }
        if (this.state.enterpriseType !== "Deer") {
            enterprise.annualAntlerYield_kg = null;
            enterprise.annualVelvetYield_kg = null;
        }
        if (!this.state.showMilkProductionFields) {
            enterprise.annualMilkSolidsYield_kgyr = null;
            enterprise.lactationLength_days = null;
        }
        if (!this.state.showMilkProductionFields) {
            enterprise.annualMilkFatYield_kgyr = null;
            enterprise.annualMilkVolumeYield_ltyr = null;
        }
        if (!enterprise.parturitionPercentage) {
            enterprise.parturitionPercentage = null;
        }

        enterprise.specificationMethod = this.state.specificationMethod;
        if (!this.state.enterpriseType === "Dairy") {
            delete enterprise.specificationMethod;
        }

        if (this.state.showDairyPeakCowNumbersFields || this.state.showRSUFields) {
            const { animalsGrazingOff = [] } = this.state;
            enterprise.animalsGrazingOff = animalsGrazingOff.filter((a) => a.months && a.months.length > 0);
            delete enterprise.mobs;
            delete enterprise.parturitionPercentage;
            delete enterprise.isCalvesFedPowder;
        } else {
            delete enterprise.animalsGrazingOff;
            delete enterprise.numberOfRSU;
            delete enterprise.malePercentage;
            delete enterprise.isGrazedOutFirst;
        }

        if (this.state.showDairyPeakCowNumbersFields) {
            const { calvingDetails } = this.state;
            const { peakCowNumbers = {} } = enterprise;
            if (this.state.specifyCalving) {
                if (calvingDetails && calvingDetails.some((c) => c.error)) {
                    return;
                }
                peakCowNumbers.calvingDetails = calvingDetails;
            } else {
                delete peakCowNumbers.calvingDetails;
            }
            if (enterprise.peakCowNumbers.replacementGrazing === "Undefined") {
                delete enterprise.peakCowNumbers.replacementGrazing;
            }

            if (peakCowNumbers.averageMobWeight === 0) {
                delete peakCowNumbers.averageMobWeight;
            }
        } else if (!this.state.showRSUFields) {
            delete enterprise.peakCowNumbers;

            enterprise.mobs = this.state.livestocks;
            enterprise.mobs.forEach((mob) => {
                if (mob.calvingDetails && mob.dairyStockClass === "MilkingHerd") {
                    const midCalvingDate = moment(mob.calvingDetails.midCalvingDate, "DD MMMM");
                    midCalvingDate.year(2014);
                    const dryDate = moment(mob.calvingDetails.dryDate, "DD MMMM");
                    dryDate.year(2014);
                    mob.calvingDetails = {
                        ...mob.calvingDetails,
                        midCalvingDay: midCalvingDate.isValid() ? midCalvingDate.date() : null,
                        midCalvingMonth: midCalvingDate.isValid() ? midCalvingDate.format("MMMM") : null,
                        dryDay: dryDate.isValid() ? dryDate.date() : null,
                        dryMonth: dryDate.isValid() ? dryDate.format("MMMM") : null,
                    };
                } else {
                    mob.calvingDetails = null;
                }

                mob.events = mob.events.map((event) => {
                    const eventDate = moment(event.eventDate, "DD MMMM");
                    eventDate.year(2014);
                    return {
                        ...event,
                        day: eventDate.date(),
                        month: eventDate.format("MMMM"),
                    };
                });
            });
        }

        if (this.state.showRSUFields) {
            delete enterprise.peakCowNumbers;
            delete enterprise.parturitionDay;
            delete enterprise.parturitionMonth;
            delete enterprise.weaningDay;
            delete enterprise.weaningMonth;
            delete enterprise.replacementRate;
            delete enterprise.weaningWeight_kg;
        } else {
            const parturitionDate = moment(enterprise.parturitionDate, "DD MMMM");
            enterprise.parturitionDay = parturitionDate.isValid() ? parturitionDate.date() : null;
            enterprise.parturitionMonth = parturitionDate.isValid() ? parturitionDate.format("MMMM") : null;

            const weaningDate = moment(enterprise.weaningDate, "DD MMMM");
            enterprise.weaningDay = weaningDate.isValid() ? weaningDate.date() : null;
            enterprise.weaningMonth = weaningDate.isValid() ? weaningDate.format("MMMM") : null;
        }
        enterprise.healthSupplements = this.state.healthSupplements;

        const { analysis } = this.props;
        let updatedAnalysis = animalUtils.getUpdatedAnalysisFromSavingEnterprise(analysis, enterprise);

        if (this.state.showDairyPeakCowNumbersFields) {
            const dairyReplacements = analysis.enterprises.find((e) => e.type === "DairyReplacements");
            if (dairyReplacements) {
                delete dairyReplacements.mobs;
                updatedAnalysis = animalUtils.getUpdatedAnalysisFromSavingEnterprise(analysis, dairyReplacements);
            }
        }

        await this.props.updateAnalysisAsync(updatedAnalysis);

        this.setState({ submitSucceeded: true });
    };

    render() {
        const { online, analysis, initialValues: enterprise, refData, handleSubmit, dirty, showQuickTips } = this.props;

        const { messages = [] } = analysis;

        const hasBreedingFemales = this._hasBreedingFemales(this.state.enterpriseType, this.state.livestocks);
        const minReplacementRate = this.state.showDairyGoatFields ? 5 : hasBreedingFemales ? 2 : 0;
        const replacementRateValidations = [validations.range(minReplacementRate, 100)];
        if (minReplacementRate > 0) replacementRateValidations.push(validations.valueNotEmpty);

        const minParturitionPercentage = hasBreedingFemales ? 1 : 0;
        const parturitionPercentageValidations = [validations.range(minParturitionPercentage, 300)];
        if (minParturitionPercentage > 0) parturitionPercentageValidations.push(validations.valueNotEmpty);

        const minMilkSolids = this.state.showDairyGoatFields ? 1 : this.state.milkSolidsPerCow ? this.state.milkSolidsPerCow.avgCowsPerMonth * 100 : 100;
        const maxMilkSolids = this.state.showDairyGoatFields ? 999999999 : this.state.milkSolidsPerCow ? this.state.milkSolidsPerCow.avgCowsPerMonth * 1000 : 1000;
        let milkSolidsValidations = [validations.required];
        if (maxMilkSolids > 0) milkSolidsValidations.push(validations.range(minMilkSolids, maxMilkSolids));
        else milkSolidsValidations.push(validations.minValue(1));

        const { livestocks = [], animalsGrazingOff = [], animalsGrazingOffValidation = {} } = this.state;
        const showIsGrazedOutFirst = animalsGrazingOff.some((a) => a.percentageOfAnimals > 0) && (this.state.showDairyPeakCowNumbersFields || this.state.showRSUFields);
        let { dairyReplacementGrazing = [] } = refData;
        dairyReplacementGrazing = dairyReplacementGrazing.filter((r) => r.value !== "OffFarmAtBirth");
        dairyReplacementGrazing.unshift({ value: "Undefined", text: "No replacements" });

        const specificationMethodOptions = this._getSpecificationMethodOptions();

        const showDairyGoatLeftOvers = this.state.showDairyGoatFields && (animalUtils.hasNonDairyGoatEnterprises(analysis) || animalUtils.hasDairyGoatWinteringPad(analysis));

        const icon = this.state.enterpriseType && utils.getAnimalIcon(this.state.enterpriseType);

        const showLivestockEvents = !this.state.showDairyPeakCowNumbersFields && !this.state.showRSUFields && this.state.enterpriseType && refData && refData.animalEventTypes && this.state.livestocks && this.state.livestocks.length > 0;

        const livestockEnterprise = { ...enterprise, ...{ specificationMethod: this.state.specificationMethod } };

        const isPeakCows = this.state.specificationMethod === "DairyPeakCowNumbers";
        const peakCowsWarning = isPeakCows ? <>Stock reconciliation must be selected to proceed. Peak cow numbers are no longer supported in Overseer FM.</> : undefined;

        const isMonthlyStockRec = this.state.specificationMethod === "MonthlyStockRec";
        const stockRecInfo = isMonthlyStockRec ? "Requires events to be entered for each stock class. The events determine the number of animals on the farm each month." : undefined;

        let breedOptions = animalUtils.dairyBreeds("dairy", livestocks, refData);
        if (this.state.showDairyPeakCowNumbersFields) {
            breedOptions = breedOptions.filter(opt => opt.weight > 0);
        }

        return (
            <form onSubmit={handleSubmit(this._save)}>
                <SavePrompt blockIf={(dirty || this.state.livestockChanged || this.state.healthSupplementsChanged) && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={this.state.referrer} />
                <Field name="id" type="hidden" component="input" />
                <Field name="type" type="hidden" component="input" />
                <Tile title={this.state.title} waiting={this.props.submitting} referrer={this.state.referrer} img={icon}>
                    <Alert type="info" text="Enter the enterprise details and add livestock by stock class. Health supplements fed out to the enterprise may also be added at the bottom of the page." />
                    <TileBody className="u-overflow-visible">
                        {!enterprise.isNew && <DistributionErrors analysis={analysis} enterprise={this.state.enterpriseName} />}
                        <div className="Grid Grid--withGutter">
                            {(enterprise.isNew || this.state.showDairyPeakCowNumbersFields || this.state.showMonthlyStockRecFields || this.state.showRSUFields) && (
                                <div className="Grid-cell">
                                    <FieldGroup>{(this.state.showDairyPeakCowNumbersFields || this.state.showMonthlyStockRecFields || this.state.showRSUFields) && <Field name="specificationMethod" label="Stock numbers method" validate={[validations.required]} options={specificationMethodOptions} dataWidth="33" tip={stockRecInfo} warning={peakCowsWarning} onChange={this._stockNumberMethodChanged} component={SelectPack} />}</FieldGroup>
                                </div>
                            )}
                            {this.state.enterpriseType && !this.state.showOtherFields && (
                                <div className="Grid-cell">
                                    {this.state.showRSUFields && (
                                        <FieldGroup>
                                            {this.state.enterpriseType === "Beef" && this.state.showRSUFields && <Field name="malePercentage" label="Proportion of feed eaten by male cattle" type="text" normalize={normalizers.integer} requiredLabel={false} dataWidth="33" component={InputPack} validate={[validations.range(0, 100)]} />}
                                            {this.state.showRSUFields && <Field name="numberOfRSU" type="text" normalize={normalizers.integer} label="Total RSU" requiredLabel={true} dataWidth="33" component={InputPack} validate={[validations.range(1, 500000), validations.required]} />}
                                        </FieldGroup>
                                    )}
                                    {(this.state.showSheepFields || this.state.showBeefFields || this.state.showDeerFields || this.state.showDairyGoatFields) && (
                                        <FieldGroup>
                                            <Field name="parturitionDate" label="Mean birth date" placeholder={this.state.defaultParturitionDate} defaultDate={this.state.defaultParturitionDate} onChange={this._parturitionDateChanged} clearable={true} dataWidth="33" component={MonthDayInputPack} />
                                            <Field name="weaningDate" label="Mean weaning date" placeholder={this.state.defaultWeaningDate} defaultDate={this.state.defaultWeaningDate} onChange={this._weaningDateChanged} clearable={true} dataWidth="33" component={MonthDayInputPack} />
                                        </FieldGroup>
                                    )}
                                    {this.state.showDairyPeakCowNumbersFields && (
                                        <FieldGroup>
                                            <Field name="peakCowNumbers.breed" label="Breed" placeholder="Select a stock breed" options={breedOptions} dataWidth="33" required={true} validate={[validations.required]} readOnly component={SelectPack} onChange={this._peakCowBreedChanged} />
                                            <Field name="peakCowNumbers.amount" type="text" normalize={normalizers.integer} label="Peak number of cows milked" requiredLabel={true} placeholder="0" dataWidth="33" readOnly component={InputPack} onChange={this._peakCowAmountChanged} validate={[validations.required, validations.range(1, 32000)]} />
                                        </FieldGroup>
                                    )}
                                    {this.state.showDairyPeakCowNumbersFields && (
                                        <FieldGroup>
                                            <Field name="peakCowNumbers.constantNumbers" label="Breeding numbers are constant" type="checkbox" readOnly component={CheckboxPack} />
                                        </FieldGroup>
                                    )}
                                    {this.state.showDairyPeakCowNumbersFields && (
                                        <FieldGroup>
                                            <Field name="peakCowNumbers.replacementGrazing" label="Replacement grazing" options={dairyReplacementGrazing} dataWidth="33" required={false} readOnly component={SelectPack} />
                                            <Field name="peakCowNumbers.averageMobWeight" type="text" normalize={normalizers.integer} label="Average mob weight" requiredLabel={false} placeholder={this.state.defaultAvgMobRate} dataWidth="33" readOnly component={InputPack} validate={[validations.range(250, 1500)]} />
                                        </FieldGroup>
                                    )}
                                    {((!this.state.showDairyReplacementsFields && !this.state.showRSUFields && !this.state.showOtherFields) || this.state.showSheepFields || this.state.showBeefFields || this.state.showDeerFields) && (
                                        <FieldGroup>
                                            {!this.state.showDairyReplacementsFields && !this.state.showRSUFields && !this.state.showOtherFields && <Field name="replacementRate" type="text" normalize={normalizers.integer} label="Breeding replacement rate" requiredLabel={minReplacementRate > 0} placeholder={this.state.defaultReplacementRate} uom="%/yr" dataWidth="33" readOnly={isPeakCows} component={InputPack} validate={replacementRateValidations} tip={!isPeakCows ? "Proportion of mature breeding animals replaced/culled each year." : undefined} />}
                                            {(this.state.showSheepFields || this.state.showBeefFields || this.state.showDeerFields) && <Field name="parturitionPercentage" type="text" normalize={normalizers.integer} label="Birth rate" requiredLabel={minParturitionPercentage > 0} placeholder="0" uom="%" dataWidth="33" component={InputPack} validate={parturitionPercentageValidations} tip={"Number weaned divided by the number of breeding animals at birth"} />}
                                        </FieldGroup>
                                    )}

                                    {this.state.enterpriseType === "Dairy" && !this.state.showDairyPeakCowNumbersFields && (
                                        <FieldGroup>
                                            <Field name="isCalvesFedPowder" label="Calves fed milk powder" type="checkbox" component={CheckboxPack} tip="If calves are fed milk powder, energy required to produce milk for replacement animals is excluded from the enterprise energy requirements." />
                                        </FieldGroup>
                                    )}
                                    {(this.state.showMilkProductionFields || this.state.showSheepFields || this.state.enterpriseType === "Deer") && (
                                        <div className="Grid-cell">
                                            <h3>Production</h3>
                                            {this.state.showMilkProductionFields && showQuickTips && !isPeakCows && <Alert type="help" text="Enter annual milk solids production for the farm. Breed or regional based defaults are used for the other production values. It is recommended that specifics are entered if the animal production differs from LIC typical values. This is more likely to occur for dairy cross animals where milk fat and milk yield can vary greatly between herds due to the different degree of cross present in the herd." />}
                                            {this.state.showMilkProductionFields && enterprise.type !== "DairyReplacements" && (
                                                <FieldGroup>
                                                    <Field name="annualMilkSolidsYield_kgyr" type="text" normalize={normalizers.integer} label="Milk solids" onChange={this._milkSolidsChanged} requiredLabel={true} placeholder="0" uom="kg/yr" dataWidth="33" readOnly={isPeakCows} component={InputPack} validate={milkSolidsValidations} />
                                                    {(this.state.showMonthlyStockRecFields || this.state.showDairyPeakCowNumbersFields) && <Field name="onceADayMilking" label="Once a day milking" options={refData.onceADayMilkingTypes} dataWidth="33" readOnly={isPeakCows} component={SelectPack} />}
                                                    <Field name="lactationLength_days" type="text" normalize={normalizers.integer} label="Lactation length" placeholder="266" uom="days" dataWidth="33" readOnly={isPeakCows} component={InputPack} validate={[validations.range(100, 366)]} />
                                                </FieldGroup>
                                            )}
                                            {(this.state.showMonthlyStockRecFields || this.state.showDairyPeakCowNumbersFields) && this.state.showMilkProductionFields && (
                                                <FieldGroup>
                                                    <Field name="annualMilkVolumeYield_ltyr" type="text" normalize={normalizers.integer} label="Milk volume yield" placeholder={this.state.defaultMilkProductionValues.milkVolumeYield} uom="litre/yr" dataWidth="33" readOnly={isPeakCows} component={InputPack} validate={[validations.range(0, 999999999)]} />
                                                    <Field name="annualMilkFatYield_kgyr" type="text" normalize={normalizers.integer} label="Fat yield" placeholder={this.state.defaultMilkProductionValues.fatYield} uom="kg/yr" dataWidth="33" readOnly={isPeakCows} component={InputPack} validate={[validations.range(0, 999999999)]} />
                                                </FieldGroup>
                                            )}
                                            {this.state.enterpriseType === "Deer" && (
                                                <FieldGroup>
                                                    <Field name="annualAntlerYield_kg" type="text" normalize={normalizers.integer} label="Antler" placeholder="0" uom="kg/yr" dataWidth="33" component={InputPack} validate={[validations.range(0, 999999999)]} />
                                                    <Field name="annualVelvetYield_kg" type="text" normalize={normalizers.integer} label="Velvet" placeholder="0" uom="kg/yr" dataWidth="33" component={InputPack} validate={[validations.range(0, 999999999)]} />
                                                </FieldGroup>
                                            )}
                                        </div>
                                    )}
                                    {this.state.enterpriseType === "Sheep" && (
                                        <FieldGroup>
                                            <Field name="woolProduction_kg" type="text" normalize={normalizers.integer} label="Greasy wool" placeholder="Enter total weight of wool produced" uom="kg/yr" dataWidth="33" component={InputPack} validate={[validations.range(0, 999999999)]} />
                                        </FieldGroup>
                                    )}
                                    {this.state.showDairyPeakCowNumbersFields && (
                                        <div className="Grid-cell">
                                            <FieldGroup>
                                                <Field name="specifyCalving" label="Specify dairy calving times (including multiple calvings)" type="checkbox" readOnly={isPeakCows} component={CheckboxPack} onChange={this._calvingToggleSpecify} />
                                            </FieldGroup>
                                        </div>
                                    )}
                                    {this.state.specifyCalving && (
                                        <div className="Grid-cell u-mt-sm">
                                            <Tile title="Calving times" className="u-overflow-visible" indent tertiary>
                                                <TileBody className="u-overflow-visible">
                                                    <DairyCalving calvingDetails={this.state.calvingDetails} deleteHerd={(i) => this._calvingDetailsDeleteHerd(i)} addHerd={() => this._calvingDetailsAddHerd()} updateCalvingDetails={(cd) => this._calvingDetailsChange(cd)} />
                                                </TileBody>
                                            </Tile>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!this.state.showDairyPeakCowNumbersFields && !this.state.showRSUFields && !this.state.showOtherFields && !(this.state.analysisHasDairyPeak && this.state.showDairyReplacementsFields) && this.state.enterpriseType && (
                                <div className="Grid-cell u-mt-lg">
                                    <LivestockList online={online} livestocks={this.state.livestocks} showLivestockEvents={showLivestockEvents} enterpriseType={this.state.enterpriseType} refData={refData} saved={this._livestockAdded} deleted={this._livestockDeleted} addEvent={this._addEvent} farm={this.props.farm} analysis={this.props.analysis} enterprise={livestockEnterprise} messages={messages} />
                                </div>
                            )}
                            {this.state.showOtherFields && (
                                <div className="Grid-cell u-mt-lg">
                                    <FieldArray name="otherLivestocks" component={OtherLivestockList} rerenderOnEveryChange onAddOrRemove={this._onOtherLivestockAddedOrRemoved} />
                                </div>
                            )}

                            {(this.state.showDairyPeakCowNumbersFields || this.state.showRSUFields) && (
                                <div className="Grid-cell u-mt-lg">
                                    <Tile
                                        title="Winter grazing off"
                                        actions={
                                            isPeakCows ? undefined : (
                                                <span className="IconLink--arrow-plus u-link" id="structure_add_animals" onClick={() => this._winterGrazingOffAdd()}>
                                                    Add animal distribution
                                                </span>
                                            )
                                        }
                                        tertiary
                                    >
                                        {showQuickTips && !isPeakCows && <Alert type="help" text="For each month enter the % of stock grazed off. Percentage grazing off is the number of animals as a percentage of peak cow numbers less culls." />}
                                        {isPeakCows && <Alert type="warning" text="These fields have been disabled due to peak cow numbers no longer being supported. Please convert to using the stock reconciliation method instead." />}
                                        <TileBody>
                                            <AnimalDistribution readOnly={isPeakCows} percentLabel="% of breeding stock" applications={animalsGrazingOff} validation={animalsGrazingOffValidation} addApplication={() => this._winterGrazingOffAdd()} onChange={(e, s) => this._winterGrazingOffChange(e, s)} deleteApplication={(id) => this._winterGrazingOffDelete(id)} animalsTab={`/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/animals`} enterprises={analysis.enterprises} daysPerMonth={false} canDeleteAnimals={true} noAnimalHeading="You have not specified distribution for winter grazing off" disableAddAnimals={true} dairyOnly={true} hideTime={true} />
                                        </TileBody>
                                    </Tile>
                                </div>
                            )}
                            {showIsGrazedOutFirst && (
                                <div className="Grid-cell">
                                    <FieldGroup>
                                        <Field name="isGrazedOutFirst" label="Grazed out most of farm prior to removal of animals" type="checkbox" readOnly={isPeakCows} component={CheckboxPack} />
                                    </FieldGroup>
                                </div>
                            )}
                            {this.state.enterpriseType && this.state.livestocksRequired && (
                                <div className="Grid-cell">
                                    <div className="Field-error u-block">Livestock required</div>
                                </div>
                            )}
                            {this.state.enterpriseType && this.state.milkersRequired && (
                                <div className="Grid-cell">
                                    <div className="Field-error u-block">Milk producing livestock required</div>
                                </div>
                            )}
                            {this.state.enterpriseType && this.state.breedingFemalesRequired && (
                                <div className="Grid-cell">
                                    <div className="Field-error u-block">Breeding female livestock required</div>
                                </div>
                            )}
                            {this.state.enterpriseType && (
                                <div className="Grid-cell u-mt-lg">
                                    <HealthSupplementsSummary enterpriseType={this.state.enterpriseType} healthSupplements={this.state.healthSupplements} saved={this._healthSupplementsSaved} />
                                </div>
                            )}
                            {showDairyGoatLeftOvers && (
                                <div className="Grid-cell u-mt-lg">
                                    <DairyGoatLeftOvers enterprise={enterprise} analysis={analysis} />
                                </div>
                            )}
                        </div>
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={this.state.referrer} className="Button Button--secondary" id="cancel-button" disabled={this.props.submitting}>
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="submit-button" submit primary waiting={this.props.submitting}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </form>
        )
    }
}

const EnterpriseDetailsForm = reduxForm({
    form: "enterprise-details-form",
})(EnterpriseDetailsClassComponent);

const resetCalvingPercentages = (calvingDetails) => {
    const quotient = Math.floor(100 / calvingDetails.length);
    calvingDetails.forEach((c, i) => (i < calvingDetails.length - 1 ? (c.calvingHerdPercentage = quotient) : (c.calvingHerdPercentage = 100 - (calvingDetails.length - 1) * quotient)));
}

const analysisHasDairyPeak = (enterprises) => {
    return enterprises.some((e) => e.type === "Dairy" && e.specificationMethod === "DairyPeakCowNumbers");
}

const showType = (type, specificationMethod) => {
    return ((["Sheep", "Deer", "Beef", "DairyGrazing"].includes(type) && specificationMethod === "RSU") || (type === "Dairy" && specificationMethod !== "RSU"))
        ? specificationMethod
        : type;
}

/**
 * Helper hook for wrapping arrayInsert and arrayPush functions from redux-form
 */
function useAddLivestockEvent() {
    const dispatch = useDispatch();

    return (evt, insertAt) => {
        if (insertAt >= 0) dispatch(arrayInsert("modal-form", "events", insertAt, { eventDate: evt.eventDate, eventType: evt.eventType, weightType: evt.weightType, weightMethod: evt.weightMethod }));
        else dispatch(arrayPush("modal-form", "events", { eventDate: evt.eventDate, eventType: evt.eventType, weightType: evt.weightType, weightMethod: evt.weightMethod }));
    }
}
