import { Bar } from "react-chartjs-2";
import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalsStockNumbersByMonthCharts({ before, after }) {
    const { isLoading, stockNumbersByEnterprise } = useStockNumbersByMonth(before, after);

    const hasData = !isLoading && stockNumbersByEnterprise?.length > 0;
    if (!hasData) {
        return null;
    }

    return (
        <>
            <div className="ReportSection">
                <h1>Enterprise stock numbers by month</h1>
                <LoadingPanel isLoading={isLoading}>
                    {stockNumbersByEnterprise.map((enterprise) => {
                        return (
                            <div key={enterprise.title} className="ReportSection u-page-break-avoid">
                                <h2>{enterprise.title}</h2>
                                <div className="printable-chart-container">
                                    <Bar data={enterprise.chartData} options={enterprise.chartOptions} className="printable-chart" />
                                </div>
                            </div>
                        )
                    })}
                </LoadingPanel>
            </div>
        </>
    )
}

function useStockNumbersByMonth(before, after) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);
    const isLoading = afterIsLoading === true || (before !== undefined && beforeIsLoading === true);

    // Cater for the distinct union of enterprises when comparing
    const beforeEnterpriseNames = beforeResults?.animals?.stockNumbers?.byEnterprise?.filter(e => e.byMob?.length)?.map((e) => e.name) || [];
    const afterEnterpriseNames = afterResults?.animals?.stockNumbers?.byEnterprise?.filter(e => e.byMob?.length)?.map((e) => e.name) || [];
    const distinctEnterpriseNames = [...new Set([...beforeEnterpriseNames, ...afterEnterpriseNames])];

    const stockNumbersByEnterprise = distinctEnterpriseNames.map((enterpriseName) => {
        const beforeEnterprise = beforeResults?.animals?.stockNumbers?.byEnterprise?.find((e) => e.name === enterpriseName);
        const afterEnterprise = afterResults?.animals?.stockNumbers?.byEnterprise?.find((e) => e.name === enterpriseName);

        let beforeData;
        if (before) {
            beforeData = {
                displayName: before?.displayName,
                data: beforeEnterprise?.byMonth?.map(x => ({ month: x.month, value: x.count }))
            }
        }
        const afterData = {
            displayName: after?.displayName,
            data: afterEnterprise?.byMonth?.map(x => ({ month: x.month, value: x.count }))
        }

        const chartData = getChartData(enterpriseName, "count", beforeData, afterData);
        return chartData;
    })

    return {
        isLoading,
        stockNumbersByEnterprise,
    }
}

function getChartData(title, uom, before, after, maxY) {
    const datasets = [];

    if (before) {
        const beforeDataset = {
            label: before.displayName,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#e3eef8",
            borderColor: "#509bc2",
            borderWidth: 1,
            data: before.data?.map(x => x.value) || after?.data?.map(x => 0), // Fill with zeros if "before" does not have equivalent data to "after""
        };
        datasets.push(beforeDataset);
    }

    if (after) {
        const afterDataset = {
            label: after.displayName,
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#c6f3c6",
            borderColor: "#52af6e",
            borderWidth: 1,
            data: after.data?.map(x => x.value) || before?.data?.map(x => 0), // Fill with zeros if "after" does not have equivalent data to "before"
        };
        datasets.push(afterDataset);
    }

    const chartOptions = getChartOptions(title, uom, maxY);
    const months = before?.data?.map(x => x.month) || after?.data?.map(x => x.month);

    const result = {
        title: chartOptions.scales.y.title.text,
        chartData: {
            labels: months,
            datasets
        },
        chartOptions,
    }
    return result;
}

function getChartOptions(title, uom, maxY) {
    const titleText = uom ? `${title} (${uom})` : title;
    return {
        scales: {
            y: {
                display: true,
                title: {
                    text: titleText,
                    display: true
                },
                max: maxY,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if (uom) {
                            return `${utils.numeric.format(tooltipItem.raw)} ${uom}`;
                        } else {
                            return utils.numeric.format(tooltipItem.raw);
                        }
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    }
}
