import { Fragment } from "react";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import { cropViewModel } from "common/viewModels";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";

export default function CropSummary({ before, after }) {
    const refData = useRefData();
    const actualCrops = cropViewModel(after.details, refData);
    const baseCrops = before && cropViewModel(before.details, refData);
    const uniqueKeys = Array.from(new Set([...Object.keys(actualCrops), ...(before ? Object.keys(baseCrops) : [])]));

    if (uniqueKeys.length === 0) {
        return null;
    }

    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Crops</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="25"></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueKeys.map((key) => {
                            const actualValue = actualCrops[key];
                            const baseValue = baseCrops && baseCrops[key];
                            const cropName = actualValue ? actualValue.name : baseValue.name;
                            const yieldUnit = (actualValue && actualValue.units) || (baseValue && baseValue.units);
                            return (
                                <Fragment key={key}>
                                    <SummaryRow beforeValue={baseValue?.area} afterValue={actualValue?.area} isComparing={isComparing} category={cropName} label="Area" prefix={cropName} unit="ha" dp={1} />
                                    <SummaryRow beforeValue={baseValue?.yield} afterValue={actualValue?.yield} isComparing={isComparing} category=" " label="Yield" prefix={cropName} unit={yieldUnit} />
                                    <SummaryRow beforeValue={baseValue?.grown} afterValue={actualValue?.grown} isComparing={isComparing} category=" " label="Pasture grown" prefix={cropName} unit="t DM/yr" />
                                    <SummaryRow beforeValue={baseValue?.intake} afterValue={actualValue?.intake} isComparing={isComparing} category=" " label="Pasture intake" prefix={cropName} unit="t DM/yr" />
                                    <SummaryRow beforeValue={baseValue?.supplements} afterValue={actualValue?.supplements} isComparing={isComparing} category=" " label="Supplements" prefix={cropName} unit="t DM/yr" />
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
