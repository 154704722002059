import * as utils from "common/utils";
import { LoadingPanel } from "components/LoadingPanel";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";

export default function FeedSummary({ before, after }) {
    const { isLoading: beforeIsLoading, data: beforeResults } = useAnalysisResults(before?.details);
    const { isLoading: afterIsLoading, data: afterResults } = useAnalysisResults(after?.details);
    if (!beforeResults?.animals && !afterResults?.animals) {
        return null;
    }

    const isLoading = afterIsLoading || (before && beforeIsLoading);
    const isComparing = !!before && !!after;
    const uom = afterResults?.animals?.sources?.totalRevisedStockUnits?.unit?.notation;

    return (
        <div className="ReportSection">
            <h1>Feed</h1>
            <LoadingPanel isLoading={isLoading}>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <SummaryRow beforeValue={beforeResults?.animals?.sources?.totalRevisedStockUnits?.value} afterValue={afterResults?.animals?.sources?.totalRevisedStockUnits?.value} isComparing={isComparing} category="RSU" label="Total" unit={uom} />
                            {afterResults?.animals?.sources?.bySource?.map((afterSource) => {
                                const beforeSource = beforeResults?.animals?.sources?.bySource?.find((b) => b.name === afterSource.name);
                                return <SummaryRow key={afterSource.name} beforeValue={beforeSource?.totalRevisedStockUnits?.value} afterValue={afterSource?.totalRevisedStockUnits?.value} isComparing={isComparing} category=" " label={afterSource.name} prefix="RSU" unit={uom} />;
                            })}
                        </tbody>
                    </table>
                </div>
            </LoadingPanel>
        </div>
    )
}
