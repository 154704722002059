import { useState } from "react";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import { useAnalysisResults } from "containers/BudgetHome";
import StockNumbers from "./StockNumbers";
import RsuFromPastureByBlock from "./RsuFromPastureByBlock";
import Diet from "./Diet";

export default function PastureAndDiet({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    const [enterpriseId, setEnterpriseId] = useState(analysis?.enterprises?.[0]?.id);

    if (!analysisResults?.animals) {
        return null;
    }

    return (
        <Panel title="Block pasture &amp; diet (ME source)" info={PAGE.INFO} help={PAGE.HELP} skyBlue className="u-page-break">
            <PanelBody>
                <ul className="SubMenu">
                    {analysis?.enterprises.map((enterprise) => (
                        <li key={enterprise.id} className={`SubMenu-item ${enterprise.id === enterpriseId ? "is-active" : ""}`}>
                            <ActionLink onClick={() => setEnterpriseId(enterprise.id)}>
                                <span>{enterprise.name}</span>
                            </ActionLink>
                        </li>
                    ))}
                </ul>
                <StockNumbers analysis={analysis} enterpriseId={enterpriseId} />
                <RsuFromPastureByBlock analysis={analysis} enterpriseId={enterpriseId} />
                <Diet analysis={analysis} enterpriseId={enterpriseId} />
            </PanelBody>
        </Panel>
    )
}

const PAGE = {
    INFO: "Shows the stock numbers, feed requirements and source for each enterprise. The first table shows the stock numbers by month. The second table shows the RSU due to pasture intake by block and month. The third table shows the proportion of the diet for each source by month. Select the enterprise name to switch between each enterprise. You can expand each source to view where the feed was distributed.",
    HELP: <>
        The diet (ME source) table shows the proportion of the diet for each month (ME from a given source for that month divided by the ME supplied by all sources for that month). The sources are:
        <ul>
            <li>
                <b>Pasture: </b>From fresh pasture
            </li>
            <li>
                <b>Crops: </b>From farm grown crops.
            </li>
            <li>
                <b>Farm supplements: </b>From farm grown and stored supplements (hay, silage and baleage).
            </li>
            <li>
                <b>Fodder supplements: </b>From imported fodder supplements (hay, silage, straws and maize).
            </li>
            <li>
                <b>Other supplements: </b>From other imported and left over supplements.
            </li>
            <li>
                <b>Animal ME requirements: </b>The ratio of ME intake from all sources divided by animal ME requirements if % is selected, otherwise it shows animal ME requirements per RSU or ME intake. There is a buffer of about 20% on feed requirements, that is, the model will provide reports when the feed supply is within 20% of animal requirement. Hence ME from feed may not equal animal ME requirements.
            </li>
        </ul>
    </>,
}
