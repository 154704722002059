import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import * as utils from "common/utils";
import ZendeskLink from "components/Help/ZendeskLink";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import { useAnalysisBenchmarks } from "containers/hooks";

export default function Feasibilty({ farm, analysis }) {
    const { isFetching, isLoading, data: benchmarks } = useAnalysisBenchmarks(farm.id, analysis.id);
    const featureTracker = useFeatureTracker(FEATURES.FEASIBILITY, "Open", farm);

    useEffect(() => {
        if (benchmarks) {
            featureTracker.track("Viewed");
        }
    }, [benchmarks, featureTracker, farm]);

    const getChartOptions = (uom, step) => {
        const chartOptions = {
            showScale: true,
            pointDot: true,
            showLines: true,
            scales: {
                y: {
                    title: {
                        display: true,
                        text: "Number of blocks",
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: step,
                        min: 0,
                    },
                },
                x: {
                    title: {
                        display: true,
                        text: "Pasture growth (kg DM/ha)",
                    },
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem) => {
                            return `${tooltipItem.raw.toLocaleString()} ${uom}`;
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
        };
        return chartOptions;
    };

    /*const getChartData = (blockvalue, data, label) => {
        const sortedData = data.sort((a, b) => a > b?1:-1)
        return {           
            datasets: [{
                label: label,
                fill: false,
                lineTension: 0.1,
                backgroundColor: sortedData.map(d => d===blockvalue?utils.graphColoursMuted[2]:utils.graphColoursMuted[0]),
                borderColor: utils.graphColoursMuted[0],
                data: sortedData
            }],
            labels: sortedData
        }
    }*/

    const getHistogramData = (blockvalue, data) => {
        const sortedData = data.sort((a, b) => (a.item > b.item ? 1 : -1));
        return {
            datasets: [
                {
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: sortedData.map((d) => (blockvalue >= d.item && blockvalue < d.item + 1000 ? utils.graphColoursMuted[2] : utils.graphColoursMuted[0])),
                    borderColor: utils.graphColoursMuted[2],
                    data: sortedData.map((d) => d.count),
                },
            ],
            labels: sortedData.map((d) => d.item),
        };
    };

    const showChart = (histogram, b, key) => {
        return (
            <div className="Table" key={key + "-table"}>
                <table className="Calendar">
                    <tbody>
                        <tr key={key + "-histogram"}>
                            <td valign="top" data-width="20" style={{ minWidth: "170px" }}>
                                <h4>{b.blockName}</h4>
                                {b.description.split(",").map((d, i) => (
                                    <li key={i}>{d}</li>
                                ))}
                                <li>{`${b.numberOfBlocks} matching blocks`}</li>
                            </td>
                            <td>
                                <Bar data={histogram} height={50} options={getChartOptions("", 1)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const showMessage = (b, key) => {
        return (
            <div className="Table" key={key + "-table"}>
                <table className="Calendar">
                    <tbody>
                        <tr key={key + "-histogram"}>
                            <td valign="top" data-width="20" style={{ minWidth: "170px" }}>
                                <h4>{b.blockName}</h4>
                            </td>
                            <td>
                                <span>{b.message}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Panel title="Block pasture growth benchmarking" skyBlue waiting={isFetching} notCollapsible medium actions={<ZendeskLink title="Page help" url="https://overseer-helpdesk.zendesk.com/hc/en-us/articles/900004316083" className="IconLink--new-tab u-font-white" />}>
            <PanelBody waiting={isFetching} loading={isLoading}>
                <Alert type="info" text="Compares pasture growth for each block with blocks that have similar rainfall, temperature, N added in fertiliser and irrigation. The height of the bar in the graphs show the number of blocks that have the pasture growth shown at the bottom of the graph. The purple bar shows where the block sits in the population." />
                <div className="HeroPanel">
                    {benchmarks &&
                        benchmarks.map((b) => {
                            if (b.message) return showMessage(b, b.blockId);
                            else {
                                const histogram = getHistogramData(b.pastureGrowth, b.pastureGrowthHistogram);
                                return histogram && showChart(histogram, b, b.blockId);
                            }
                        })}
                    {!benchmarks && (
                        <div className="Tile-body-message">
                            <p className="lead">No data</p>
                        </div>
                    )}
                </div>
            </PanelBody>
        </Panel>
    )
}
