import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { SummaryRow } from "components/PlanningReport";

export default function PhysicalCharacteristics({ before, after }) {
    const CalcValues = ({ srcKey, render, parent }) => {
        const actualValue = after[parent][srcKey];
        const baseValue = before && before[parent][srcKey];
        return render(baseValue, actualValue);
    };

    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Physical characteristics</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="25"></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <CalcValues srcKey="totalFarmArea" parent="details" render={(beforeValue) => <SummaryRow beforeValue={beforeValue} afterValue={after.details.totalFarmArea} isComparing={isComparing} category="Land area" prefix="LandArea" label="Farm area" unit="ha" dp={1} />} />
                        <CalcValues srcKey="productiveArea" parent="summary" render={(beforeValue, afterValue) => <SummaryRow beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category=" " prefix="LandArea" label="Productive block area" unit="ha" dp={1} />} />
                        <CalcValues srcKey="temperature" parent="summary" render={(beforeValue, afterValue) => <SummaryRow beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category="Climate" prefix="Climate" label="Average temperature" unit="&deg;C" dp={1} />} />
                        <CalcValues srcKey="rainfall" parent="summary" render={(beforeValue, afterValue) => <SummaryRow beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category=" " prefix="Climate" label="Average rainfall" unit="mm" />} />
                        <CalcValues srcKey="pet" parent="summary" render={(beforeValue, afterValue) => <SummaryRow beforeValue={beforeValue} afterValue={afterValue} isComparing={isComparing} category=" " prefix="Climate" label="Average PET" unit="mm" />} />
                    </tbody>
                </table>
            </div>
        </div>
    )
}
