import { useState, useEffect } from "react";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import MapZoomButton from "components/Map/MapZoomButton";

export default function ReportingFarmsMap({ locations, selectedLocation, onLocationSelected }) {
    const { map, mapId, setMapRef } = useGoogleMap();
    const geoJson = useGeoJson(locations, selectedLocation);

    const mapData = useMapData(map, geoJson);
    useMapStyle(mapData);
    useOnPolygonHover(mapData);
    useOnPolygonClick(mapData, locations, selectedLocation, onLocationSelected);
    useOnSelectedLocationChange(mapData, selectedLocation);

    return (
        <div className="MapWithList-wrapper u-mt-md">
            <div className="MapWithList u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
                <div style={{ minHeight: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                    <div ref={setMapRef} style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }} />
                    <MapZoomButton mapData={mapData} mapId={mapId} />
                </div>
            </div>
        </div>
    );
}

function useGeoJson(locations, selectedLocation) {
    const [geoJson, setGeoJson] = useState();

    useEffect(() => {
        if (locations) {
            const newGeoJson = { type: "FeatureCollection", features: [] };
            newGeoJson.features = locations.map((f, i) => {
                const radius = Math.sqrt((f.area * 10000) / Math.PI);
                const circleFeature = geoJsonUtils.circle(f.latitude, f.longitude, radius);
                circleFeature.id = f.id;
                circleFeature.properties.farmId = f.farmId;
                circleFeature.properties.analysisId = f.analysisId;
                circleFeature.properties.layer = "Nitrogen";
                circleFeature.properties.area = f.area;
                circleFeature.properties.type = f.cutAndCarry ? "CutAndCarr" : f.type;
                circleFeature.properties.name = f.name;
                circleFeature.properties.selected = selectedLocation ? selectedLocation.id === f.id && selectedLocation.analysisId === f.analysisId : false;
                return circleFeature;
            });
            setGeoJson(newGeoJson);
        }
    }, [locations, selectedLocation]);

    return geoJson;
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    // Set map data.
    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    // Refresh map if geoJson changes.
    useEffect(() => {
        if (mapData && geoJson) {
            mapData.forEach((feature) => mapData.remove(feature));
            mapData.addGeoJson(geoJson);
        }
    }, [mapData, geoJson]);

    return mapData;
}

function useMapStyle(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            const getColour = (type) => {
                switch (type) {
                    case "CropAndHorticulture":
                        return "#f66364";
                    case "NonDairyAnimals":
                        return "#f5b04d";
                    case "Dairy":
                        return "#71c989";
                    case "Mixed":
                        return "#93bfeb";
                    default:
                        return utils.GetBlockColour(type); // Must be by block
                }
            };

            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const selected = feature.getProperty("selected");
                const type = feature.getProperty("type");

                if (hovered || selected) {
                    return {
                        zIndex: 2,
                        fillColor: "#973b36",
                        strokeColor: "#973b36",
                        strokeWeigth: 3,
                        fillOpacity: 0.3,
                    };
                }

                return {
                    zIndex: 1,
                    fillColor: "#fff",
                    strokeColor: getColour(type),
                    strokeWeigth: 2,
                    fillOpacity: 0.3,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonHover(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                feature.setProperty("hovered", true);
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                feature.setProperty("hovered", false);
            });

            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonClick(mapData, locations, selectedLocation, onClick) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && locations && onClick && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                const location = locations.find((l) => l.id === feature.getId());
                if (location) {
                    onClick(location);
                }
            });
            setRunOnce(true);
        }
    }, [mapData, locations, selectedLocation, onClick, runOnce]);
}

function useOnSelectedLocationChange(mapData, selectedLocation) {
    useEffect(() => {
        if (mapData && selectedLocation) {
            mapData.forEach((mapFeature) => {
                if (mapFeature.getId() === selectedLocation.id) {
                    mapFeature.setProperty("selected", true);
                } else {
                    mapFeature.setProperty("selected", false);
                }
            });
        }
    }, [mapData, selectedLocation]);
}
