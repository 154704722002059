import * as utils from "common/utils";
import { useAnalysisResults } from "containers/BudgetHome/hooks";

export default function FarmGHG({ analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);

    const ghgResults = analysisResults?.ghg;
    if (!ghgResults) {
        return <>There are no GHG results for this analysis</>
    }

    const totalMethaneEmissions = ghgResults?.emissions?.methane?.total;
    const totalNitrousOxideEmissions = ghgResults?.emissions?.nitrousOxide?.total;
    const totalCarbonDioxideEmissions = ghgResults?.emissions?.carbonDioxide?.total;
    const totalEmissions = ghgResults?.emissions?.total;

    return (
        <div className="Table Table-Compressed">
            <table>
                <thead>
                    <tr>
                        <th className="numeric" data-width="25">Methane ({totalMethaneEmissions?.unit?.notation})</th>
                        <th className="numeric" data-width="25">Nitrous oxide ({totalNitrousOxideEmissions?.unit?.notation})</th>
                        <th className="numeric" data-width="25">Carbon dioxide ({totalCarbonDioxideEmissions?.unit?.notation})</th>
                        <th className="numeric" data-width="25">Total GHG emissions ({totalEmissions?.unit?.notation})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="numeric">
                            {utils.numeric.format(totalMethaneEmissions?.value, 1)}
                        </td>
                        <td className="numeric">
                            {utils.numeric.format(totalNitrousOxideEmissions?.value, 1)}
                        </td>
                        <td className="numeric">
                            {utils.numeric.format(totalCarbonDioxideEmissions?.value, 1)}
                        </td>
                        <td className="numeric">
                            {utils.numeric.format(totalEmissions?.value, 1)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
