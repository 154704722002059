import { useOnline, useOnLocationChange } from "common/hooks";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody } from "components/Panel";
import Alert from "components/Alert";
import { useNotifications } from "components/NotificationsBell/_hooks";

export default function OfflinePanel({ children }) {
    const online = useOnline();
    const { data: notifications, refetch: refetchNotifications } = useNotifications();
    useOnLocationChange(refetchNotifications);

    const upcomingMaintenanceWindowNotification = notifications?.find(n => n.type === "maintenance");

    if (online) {
        return (
            <>
                {upcomingMaintenanceWindowNotification && <Alert className="u-mt-0 u-mb-0" type="warning" html={upcomingMaintenanceWindowNotification.content} />}
                {children}
            </>
        );
    } else {
        return (
            <>
                {upcomingMaintenanceWindowNotification && <Alert className="u-mt-0 u-mb-0" type="warning" html={upcomingMaintenanceWindowNotification.content} />}
                <Page>
                    <PageBody>
                        <Panel>
                            <PanelBody>
                                <div className="Tile-body-message">
                                    <i className="icon icon--md icon-alert u-textError" />
                                    <p className="lead">
                                        {upcomingMaintenanceWindowNotification
                                            ? <>
                                                <div dangerouslySetInnerHTML={{ __html: upcomingMaintenanceWindowNotification.content }} />
                                            </>
                                            : <>Overseer is currently offline. Please try again later.</>
                                        }
                                    </p>
                                </div>
                            </PanelBody>
                        </Panel>
                    </PageBody>
                </Page>
            </>
        )
    }
}
