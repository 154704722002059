import { Link } from "react-router-dom";
import * as utils from "common/utils";
import * as icons from "common/icons";
import { useConfirm, useShowQuickTips } from "common/hooks";
import { factoryFertiliser } from "common/domain";
import { useAnalysisSummary } from "containers/BudgetHome";
import TileSubPanel from "components/TileSubPanel";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { useFertiliserProductModal } from "../ProductModal";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function FertilisersGreenBox({ farm, analysis }) {
    const { isFetching, isLoading, fertiliserSummary } = useFertiliserSummary(analysis);
    const showQuickTips = useShowQuickTips();
    const [fertiliserProductModal, openFertiliserProductModal] = useFertiliserProductModal();
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const hasFert = !!fertiliserSummary;
    const hasDCD = analysis?.nitrateInhibitors?.length > 0 || false;

    const addFert = (material) => {
        const fert = factoryFertiliser();
        fert.id = "NEW";
        const viewModel = {
            material,
            farm,
            analysis,
            fertiliser: fert,
            isLastPreviousLime: false,
            customCompositions: [],
            isNew: true
        };
        openFertiliserProductModal(viewModel);
    }

    const editFert = (fertId) => {
        const fert = analysis.fertiliser.find((f) => f.id === fertId);
        const viewModel = {
            material: fert.material,
            farm,
            analysis,
            fertiliser: fert,
            isLastPreviousLime: false,
            customCompositions: [],
            isNew: false
        };
        openFertiliserProductModal(viewModel);
    }

    const deleteFert = (fertId) => {
        confirm(`Are you sure you want to delete this fertiliser?`, async () => {
            analysis.fertiliser = analysis.fertiliser.filter((f) => f.id !== fertId);
            updateAnalysisAsync(analysis);
        });
    }

    return (
        <>
            <TileSubPanel title="Fertilisers" waiting={isFetching} loading={isLoading} green stickyHeaderName="analysis.fertiliser.greenBox.collapsed">
                {showQuickTips && <Alert type="help" text="This is a list of all fertilisers used on the farm. Select the fertiliser name to edit the applications of this fertiliser or select the edit icon at the end of the row to edit the details for the fertiliser." />}
                <div className="FarmTable_wrapper">
                    <div className="FarmTable-supplementary u-pt-md">
                        {hasFert
                            ? <>
                                <div className="Table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th data-width="md-15 xl-15">Manufacturer/material</th>
                                                <th data-width="md-20 xl-30">Name</th>
                                                <th className="numeric">Applications</th>
                                                <th data-width="md-10 xl-10" className="numeric">Total applied (kg/yr)</th>
                                                <th className="numeric">N</th>
                                                <th className="numeric">P</th>
                                                <th className="numeric">K</th>
                                                <th className="numeric">S</th>
                                                <th className="numeric">Ca</th>
                                                <th className="numeric">Mg</th>
                                                <th className="numeric">Na</th>
                                                <th className="th--shrink"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fertiliserSummary?.byName?.map((fert) => {
                                                const fertIcon = utils.getFertiliserIcon(fert.material);
                                                const fertHasErrors = analysis?.messages?.find((m) => m.category === "Fertiliser" && m.entityType === "Fertiliser" && m.entityId === fert.id && m.severity === "Error") ? "error" : undefined;
                                                return (
                                                    <tr key={fert.id}>
                                                        <td>
                                                            <span className="u-flex u-flexAlignItemsCenter">
                                                                {fertHasErrors && (
                                                                    <span className="Todo Todo--error error-anchor">
                                                                        <i className="icon icon-alert" />
                                                                    </span>
                                                                )}
                                                                <img className="u-mr-xs u-p-5" src={fertIcon} width="35" height="35" alt={`${fert.manufacturer} icon`} />
                                                                <span id={`fertiliser-manufacturer-${fert.id}`}>{fert.manufacturer}</span>
                                                            </span>
                                                        </td>
                                                        {isFetching 
                                                            ? <td className="u-textMidGrey u-textBold">
                                                                {fert.name}
                                                            </td> 
                                                            : <td>
                                                                <Link id={`fertiliserid-${fert.id}`} to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/fertiliser/applications?productId=${fert.id}`} className="u-textBold">
                                                                    {fert.name}
                                                                </Link>
                                                            </td>
                                                        }
                                                        <td className="numeric">{utils.numeric.format(fert.numberOfApplications)}</td>
                                                        <td id={`fertiliser-amount-${fert.id}`} className="numeric">{utils.numeric.format(fert.totalApplied?.value)}</td>
                                                        <td id={`fertiliser-nutrient-N-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.nitrogen?.value)}</td>
                                                        <td id={`fertiliser-nutrient-P-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.phosphorus?.value)}</td>
                                                        <td id={`fertiliser-nutrient-K-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.potassium?.value)}</td>
                                                        <td id={`fertiliser-nutrient-S-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.sulphur?.value)}</td>
                                                        <td id={`fertiliser-nutrient-Ca-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.calcium?.value)}</td>
                                                        <td id={`fertiliser-nutrient-Mg-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.magnesium?.value)}</td>
                                                        <td id={`fertiliser-nutrient-Na-${fert.id}`} className="numeric">{utils.numeric.format(fert.nutrientsApplied.sodium?.value)}</td>
                                                        <td>
                                                            <div className="u-flexSplit">
                                                                <ActionLink className="IconLink--edit" id={`edit-fertiliser-${fert.id}`} onClick={() => editFert(fert.id)} title="Edit fertiliser" disabled={isFetching}></ActionLink>
                                                                <ActionLink className="IconLink--trash" id={`delete-fertiliser-${fert.id}`} onClick={() => deleteFert(fert.id)} title="Delete fertiliser" disabled={isFetching}></ActionLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr className="totals">
                                                <th>Totals</th>
                                                <th></th>
                                                <th></th>
                                                <th id="fertiliser-total-applied" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.totalApplied?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-N-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.nitrogen?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-P-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.phosphorus?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-K-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.potassium?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-S-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.sulphur?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-Ca-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.calcium?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-Mg-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.magnesium?.value)}
                                                </th>
                                                <th id="fertiliser-nutrient-S-total" className="numeric total">
                                                    {utils.numeric.format(fertiliserSummary?.appliedByNutrient?.sodium?.value)}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </>
                            : <>
                                <div className="Tile-body">
                                    <div className="Tile-body-message">
                                        <img src={icons.add} className="u-p-5" alt="Plus" />
                                        <p className="lead">You do not have any fertilisers</p>
                                        <Button onClick={() => addFert("Product")} className="IconLink--arrow-plus Button Button--lg u-mt-lg" id="add-product">
                                            Add product
                                        </Button>
                                        <Button onClick={() => addFert("Lime")} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="add-lime">
                                            Add lime
                                        </Button>
                                        <Button onClick={() => addFert("Organic")} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="add-organic">
                                            Add organic
                                        </Button>
                                        <Button onClick={() => addFert("Soluble")} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="add-soluble">
                                            Add soluble
                                        </Button>
                                        {!hasDCD && (
                                            <Link to={`/app/farm/${analysis.farmId}/analysis/${analysis.id}/fertiliser/dcd`} className="IconLink--arrow-plus Button Button--lg u-mt-md" id="fertiliser-add-dcd">
                                                <span>Add DCD</span>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </TileSubPanel>
            {fertiliserProductModal}
        </>
    )
}

function useFertiliserSummary(analysis) {
    const { isFetching, isLoading, data } = useAnalysisSummary(analysis);

    return {
        isFetching,
        isLoading,
        fertiliserSummary: data?.fertilisers
    }
}
