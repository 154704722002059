import InputPack from "components/InputPack";
import CheckboxPack from "components/CheckboxPack";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import * as domain from "common/domain";
import { Grid, GridCell } from "components/Grid";
import BlockSelector from "components/new/BlockSelector";

export default function BlockDetails({ block, blocks, validation, onChange }) {
    const monthResown = block.monthResown === "Undefined" ? undefined : block.monthResown;
    let { crops = [] } = block;
    //Ignore the auto generated gazed crop on the last day
    crops = crops.filter((c) => c.events && !(c.cropId === "Pasture" && c.events.some((e) => e.month === block.monthResown)));
    const blockIdsVal = validation["blockIds"] || {};
    const showLowNMin = process.env.REACT_APP_PRODUCT === "sc" || block.lowNMineralisation;
    const lowNMinMsg = block.lowNMineralisation && process.env.REACT_APP_PRODUCT !== "sc" && "Low N mineralisation capacity is now deprecated. If you uncheck this option it will be no longer be available";

    return (
        <Grid>
            <GridCell className="u-md-width1of2 u-lg-width1of3">
                <input type="hidden" value={block.id} id="blockid" />
                <InputPack id="name" type="text" label="Rotation name" onChange={(e) => onChange(e, { type: "block", key: "name" })} meta={{ nrf: true }} value={block.name} val={validation.name} placeholder="Enter a name used to identify this rotation." requiredLabel={true} />
            </GridCell>
            <GridCell className="u-md-width1of2 u-lg-width1of3">
                <NumericInputPack id="rotationArea" label="Rotation area" requiredLabel={true} val={validation.rotationArea} onChange={(e) => onChange(e, { type: "block", key: "rotationArea" })} decimalPlaces={1} uom="ha" placeholder="Enter the area under cultivation that rotates through pasture blocks." value={block.rotationArea} />
            </GridCell>
            <GridCell className="u-width1of1">
                <Grid>
                    <GridCell className="u-md-width1of2 u-lg-width1of3">{showLowNMin && <CheckboxPack info={lowNMinMsg} id="lowNMineralisation" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "block", key: "lowNMineralisation" })} value={block.lowNMineralisation} label="Low N mineralisation capacity" requiredLabel={true} />}</GridCell>
                </Grid>
            </GridCell>
            <GridCell className="u-md-width1of2 u-lg-width1of3">
                <SelectPack id="monthResown" label="Month resown in pasture" meta={{ nrf: true }} onChange={(e) => onChange(e, { type: "block", key: "monthResown" })} value={monthResown} val={validation.monthResown} disabled={crops.length > 0} tip="The last month of a fodder crop’s crop rotation. The block is sown back into permanent grazed pasture in this month." requiredLabel={true}>
                    <option value="" disabled={true}>
                        Select when resown in pasture
                    </option>
                    {domain.calendarYear.map((m, i) => (
                        <option key={i} value={m}>
                            {m}
                        </option>
                    ))}
                </SelectPack>
            </GridCell>
            <GridCell className="u-md-width1of2 u-lg-width1of3">
                <NumericInputPack id="nonPastureApplication" label="Months since fertiliser/effluent applied to pasture" uom="months" requiredLabel={false} val={validation.nonPastureApplication} placeholder="Number of months prior to the start of the reporting year." onChange={(e) => onChange(e, { type: "block", key: "nonPastureApplication" })} decimalPlaces={0} value={block.nonPastureApplication} />
            </GridCell>
            <GridCell className="u-mt-md u-md-width1of1 u-lg-width2of3">
                <BlockSelector
                    instructions="Select one or more pasture blocks that the crop rotates through"
                    input={{
                        value: block.blockIds,
                        onChange: (blockIds) => onChange({ target: { value: blockIds } }, { id: "blockIds", key: "blockIds", type: "block" }),
                    }}
                    availableBlocks={blocks}
                    meta={{
                        touched: blockIdsVal.touched,
                        error: blockIdsVal.message,
                    }}
                />
            </GridCell>
        </Grid>
    );
}
