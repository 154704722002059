import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { push } from "connected-react-router";
import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { useAuthContext, useIsOverseerEd, useRefData } from "common/hooks";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import ZendeskLink from "components/Help/ZendeskLink";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import TextareaField from "components/FormFields/TextareaField";
import Button from "components/Button/Button";
import Alert from "components/Alert";
import { isPublishingToMPIWholeFarmDataProject } from "containers/Publications";
import { useModal } from "common/hooks";
import { useFarm, useAnalysis, useFarmGroupsByFarm, useCreatePublicationAsync, useUpdatePublicationAsync, useFarmAccess } from "containers/hooks";
import { useQueryClient } from "@tanstack/react-query";

export default function PublicationDetailsModal({ publication, commentsOnly, close }) {
    const authContext = useAuthContext();
    const { data: farm } = useFarm(publication.farmId);
    const { data: analysis } = useAnalysis(publication.farmId, publication.budgetId);
    const { data: farmGroups } = useFarmGroupsByFarm(publication.farmId);
    const publications = farm?.publications || [];
    const publisheeOptions = usePublisheeOptions(farm, analysis, publications, farmGroups);
    const createPublicationAsync = useCreatePublicationAsync();
    const updatePublicationAsync = useUpdatePublicationAsync();
    const dispatch = useDispatch();
    const statusOptions = useStatusOptions(publication);
    const initialValues = useInitialValues(publication);
    const queryClient = useQueryClient();

    const formInfo = (
        <>
            When publishing to an external organisation or group, a snapshot of your farm data will be available to the external party. They may share this information depending on the agreement you have. If in doubt please contact the organisation or group to see how your information will be used. For more information regarding publishing please <ZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000877303" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" title="click here." />
        </>
    );
    const publishingToExternalOrgInfo = (
        <>
            The organisation you are publishing to may access and download the analysis and associated data at any time after publication, and publishing cannot be undone. We do not control the organisation's use or disclosure of accessed analyses or data. If you want to restrict the organisation's use or disclosure of data, you will need to make private arrangements with them before you publish the data to them. <ZendeskLink url=" https://support.overseer.org.nz/hc/en-us/articles/900000877303" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" title="For more information on publishing please click here." />
        </>
    );

    const editAnalysis = () => {
        dispatch(push(`/app/farm/${publication.farmId}/analysis/${publication.budgetId}/overview`));
        if (close) close();
    };

    const resetPublishee = (form) => () => {
        form.change("publisheeId", null);
    };

    const onPublisheeChange = (form) => (publisheeId) => {
        const existingPublication = publications.find((p) => (p.accountId === publisheeId || p.farmGroupId === publisheeId) && p.budgetId === publication.budgetId);
        if (existingPublication) {
            form.change("status", existingPublication.status);
            form.change("farmIdentifier", existingPublication.farmIdentifier);
            form.change("reference", existingPublication.reference);
        } else {
            const otherPublicationToThisPublishee = publications.find((p) => p.accountId === publisheeId || p.farmGroupId === publisheeId);
            if (otherPublicationToThisPublishee) {
                form.change("farmIdentifier", otherPublicationToThisPublishee.farmIdentifier);
            } else {
                form.change("farmIdentifier", null);
            }
            form.change("status", null);
            form.change("reference", null);
        }

        const isPublishingToFarmGroup = farmGroups?.some((fg) => fg.id === publisheeId);
        const isPublishingToMPI = isPublishingToMPIWholeFarmDataProject(publisheeId);
        if (isPublishingToFarmGroup || isPublishingToMPI) {
            form.change("status", "Submitted");
        }

        if (isPublishingToFarmGroup) {
            form.change("farmGroupId", publisheeId);
            form.change("accountId", null);
        } else {
            form.change("accountId", publisheeId);
            form.change("farmGroupId", null);
        }
    };

    const onAddConsentApplicantNameChanged = (form) => (checked) => {
        if (!checked) {
            form.change("consentApplicantName", null);
        }
    };

    const validate = async (values) => {
        const errors = {};

        errors.publisheeId = FormUtils.validators.required(values.publisheeId);
        errors.status = FormUtils.validators.required(values.status);

        const farmIdentifierIsRequiredIfPublishingToMPI = isPublishingToMPIWholeFarmDataProject(values.publisheeId);
        if (farmIdentifierIsRequiredIfPublishingToMPI) {
            errors.farmIdentifier = FormUtils.validators.required(values.farmIdentifier);
        }
        errors.farmIdentifier = errors.farmIdentifier || FormUtils.validators.maxLength(50)(values.farmIdentifier);

        errors.reference = FormUtils.validators.maxLength(50)(values.reference);
        errors.comments = FormUtils.validators.maxLength(1000)(values.comments);

        if (values.addConsentApplicantName) {
            errors.consentApplicantName = FormUtils.validators.required(values.consentApplicantName);
            errors.consentApplicantName = errors.consentApplicantName || FormUtils.validators.maxLength(200)(values.consentApplicantName);
        }

        return errors;
    };

    const submit = async (values) => {
        const isEditingExistingPublication = values.id;
        if (isEditingExistingPublication) {
            const result = await updatePublicationAsync(values)
                .then(() => {
                    queryClient.invalidateQueries({ queryKey: ["reporting"] });
                    close();
                })
                .catch((ex) => ({ [FORM_ERROR]: ex.message }));
            return result;
        } else {
            const result = await createPublicationAsync(values)
                .then(() => {
                    queryClient.invalidateQueries({ queryKey: ["reporting"] });
                    close();
                })
                .catch((ex) => ({ [FORM_ERROR]: ex.message }));
            return result;
        }
    };

    return (
        <Form initialValues={initialValues} validate={validate} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                const isPublishingToSelf = values.publisheeId === authContext.accountId;
                const isPublishingToFarmGroup = farmGroups?.some((fg) => fg.id === values.publisheeId) || false;
                const isPublishingToMPI = isPublishingToMPIWholeFarmDataProject(values.publisheeId);
                const isPublishingToExternalOrg = values.publisheeId && !isPublishingToSelf && !isPublishingToFarmGroup;
                const analysisHasErrors = (analysis?.messages || []).some((m) => m.severity === "Error");
                const cannotPublishWithErrors = (isPublishingToFarmGroup || isPublishingToMPI) && analysisHasErrors;
                const canPublish = !cannotPublishWithErrors;
                const isEditingExistingPublication = !!values.id;
                const showAddConsentApplicantName = !isPublishingToSelf && !isPublishingToFarmGroup && !isPublishingToMPI;

                const title = isEditingExistingPublication ? `Publication - ${analysis?.name} (v${values.version})` : `Publish analysis - ${analysis?.name} (v${analysis?.version + 1})`;
                const publisheeName = values.publisheeId && publisheeOptions && publisheeOptions.some((p) => p.value === values.publisheeId) && publisheeOptions.find((p) => p.value === values.publisheeId).text;
                const publisheeInfo = getPublisheeInfo(farmGroups, values.publisheeId);

                const showStatus = !isPublishingToFarmGroup && !isPublishingToMPI;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={title} close={close} submitting={submitting} wide allowOffline>
                            <ModalBody info={formInfo} error={submitError}>
                                {cannotPublishWithErrors && (
                                    <>
                                        <div className="Tile-body-message">
                                            <i className="icon icon--md icon-alert u-textInfo" />
                                            <p className="lead">This analysis has errors that you need to resolve before publishing to "{publisheeName}"</p>
                                            <Button id="reset-publishee" className="IconLink--refresh Button Button--secondary u-mt-md" onClick={resetPublishee(form)}>
                                                Choose a different publishee
                                            </Button>
                                            <Button id="edit-analysis" className="IconLink--edit Button Button--secondary u-mt-md" onClick={editAnalysis}>
                                                Edit the analysis
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {canPublish && (
                                    <Grid>
                                        <Field name="id" type="hidden" component="input" />
                                        <Field name="farmId" type="hidden" component="input" />
                                        <Field name="budgetId" type="hidden" component="input" />
                                        <GridCell className="u-lg-width1of2">
                                            {!isEditingExistingPublication && <Field name="publisheeId" label="Publish to" placeholder="Select a publishee" options={publisheeOptions} info={publisheeInfo} onChange={onPublisheeChange(form)} required component={SelectField} />}
                                            {isEditingExistingPublication && <Field name="publisheeId" label="Published to" options={[{ value: values.publisheeId, text: values.publishedTo }]} disabled component={SelectField} />}
                                        </GridCell>
                                        {!commentsOnly && (
                                            <>
                                                {values.publisheeId && (
                                                    <>
                                                        <GridCell className="u-lg-width1of2">{showStatus && <Field name="status" label="Status" placeholder="Select a status" options={statusOptions} required component={SelectField} />}</GridCell>
                                                        <GridCell className="u-lg-width1of2">
                                                            {!isPublishingToMPI && <Field name="farmIdentifier" label="Publishee's farm identifier" placeholder="Enter the publishee's identifier for this farm" info="The identifier for this farm as defined by the organisation/group being published to" component={TextField} />}
                                                            {isPublishingToMPI && <Field name="farmIdentifier" label="MPI ID" placeholder="Enter the MPI ID for this farm" info="The MPI ID is only used to collate the anonymised farm data. It cannot be used to identify the farm." required component={TextField} />}
                                                        </GridCell>
                                                        <GridCell className="u-lg-width1of2">{!isPublishingToMPI && <Field name="reference" label="Publishee's reference" placeholder="Enter the publishee's reference for this publication" info="The reference for this publication as defined by the organisation/group being published to" component={TextField} />}</GridCell>
                                                        {showAddConsentApplicantName && (
                                                            <>
                                                                <GridCell className="u-lg-width1of2">
                                                                    <Field name="addConsentApplicantName" label="Add consent applicant's name" type="checkbox" onChange={onAddConsentApplicantNameChanged(form)} component={CheckboxField} />
                                                                </GridCell>
                                                            </>
                                                        )}
                                                        {values.addConsentApplicantName && (
                                                            <>
                                                                <GridCell className="u-lg-width1of2">
                                                                    <Field name="consentApplicantName" label="Consent applicant's name" placeholder="Enter the name of the consent application as described in the consent" required component={TextField} />
                                                                </GridCell>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {values.publisheeId && (
                                            <>
                                                <GridCell>
                                                    <Field name="comments" label="Comments" rows={5} info="Publication comments can be viewed by clicking the 'History' link within a publication" component={TextareaField} />
                                                </GridCell>
                                            </>
                                        )}
                                        {isPublishingToExternalOrg && (
                                            <>
                                                <GridCell className="u-mt-md">
                                                    <Alert type="info" text={publishingToExternalOrgInfo} />
                                                </GridCell>
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    {canPublish && (
                                        <>
                                            <Button id="submit" submit primary waiting={submitting} disabled={submitting}>
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

function useInitialValues(publication) {
    if (publication.id) {
        publication.publisheeId = publication.accountId || publication.farmGroupId;
    }
    return publication;
}

function usePublisheeOptions(farm, analysis, publications, farmGroups) {
    const authContext = useAuthContext();
    const { data: publishees } = usePublishees();
    const isOverseerEd = useIsOverseerEd();
    const { data: farmAccess } = useFarmAccess();

    const createPublisheeOption = (option) => {
        let existingPublication = publications.find((p) => ((p.accountId && p.accountId === option.value) || (p.farmGroupId && p.farmGroupId === option.value)) && p.budgetId === analysis?.id);
        if (existingPublication) {
            return {
                ...option,
                text: `${option.text} (Existing publication - ${existingPublication.status})`,
                disabled: existingPublication.status === "Reviewing" || existingPublication.status === "Closed",
            };
        }

        return option;
    };

    let options = [];
    let groupIndex = 0;

    const hasActiveSubscription = farm?.expired === false;
    if (hasActiveSubscription) {
        const publishToSelfOption = {
            value: authContext.accountId,
            text: authContext.accountName,
            groupLabel: "My organisation",
            groupIndex,
        };
        options.push(createPublisheeOption(publishToSelfOption));
        groupIndex++;

        // Add farm owners as publishees
        const owners = farmAccess?.access?.filter((access) => access.role === "Owner");
        if (owners?.length > 0) {
            owners.forEach((owner) => {
                const publishToOwnerOption = {
                    value: owner.accountId,
                    text: owner.accountName,
                    groupLabel: "Farm owners",
                    groupIndex,
                };
                options.push(createPublisheeOption(publishToOwnerOption));
            });
            groupIndex++;
        }

        if (analysis?.type === "YearEnd" || (analysis?.type === "Predictive" && analysis?.year)) {
            if (farmGroups?.length > 0) {
                farmGroups.forEach((farmGroup) => {
                    const publishToFarmGroupOption = {
                        value: farmGroup.id,
                        text: farmGroup.name,
                        groupLabel: "Farm groups",
                        groupIndex,
                    };
                    options.push(createPublisheeOption(publishToFarmGroupOption));
                });
                groupIndex++;
            }
        }
    }

    if (analysis?.type !== "PrivateYearEnd" && publishees) {
        publishees
            .filter((category) => category.value !== "ReceivesAnonymisedDataOnly" || authContext.canPublishToMPIWholeFarmDataProject)
            .filter((category) => category.value !== "EducationProviders" || isOverseerEd)
            .forEach((category) => {
                const isCouncil = category.value === "Councils";
                const isEducationProviders = category.value === "EducationProviders";
                options = options.concat(
                    category.children
                        .filter((child) => !isEducationProviders || child.value === authContext.parentAccountId)
                        .map((child) => {
                            const publisheeOption = {
                                ...child,
                                disabled: isOverseerEd && isCouncil,
                                groupLabel: category.text,
                                groupIndex,
                            };
                            return createPublisheeOption(publisheeOption);
                        })
                );
                groupIndex++;
            });
    }

    options.sort((a, b) => {
        // Sort by group index
        if (a.groupIndex > b.groupIndex) return 1;
        if (a.groupIndex < b.groupIndex) return -1;

        // Then by label
        if (a.text.toUpperCase() > b.text.toUpperCase()) return 1;
        if (a.text.toUpperCase() < b.text.toUpperCase()) return -1;

        return 0;
    });

    return options;
}

function useStatusOptions(publication) {
    const refData = useRefData();
    const statuses = (refData.publicationStatuses || []).filter((rd) => {
        if (publication.status === rd.value) return true;
        if ((!publication.id || publication.hasPublishorAccess) && ["Draft", "Submitted"].includes(rd.value)) return true;
        if (publication.hasPublisheeAccess && ["Returned", "Reviewing", "Processed", "Closed"].includes(rd.value)) return true;
        return false;
    });
    return statuses;
}

function getPublisheeInfo(farmGroups, publisheeId) {
    const farmGroup = farmGroups?.find((fg) => fg.id === publisheeId);

    if (!farmGroup) return null;

    if (farmGroup.farmGroupVisibility === "Private")
        return (
            <>
                You are publishing to a farm group with the visiblity set to <b>private</b>. Only the organisation, {farmGroup.administratorOrganisation} who created the farm group will see this publication.
            </>
        );

    if (farmGroup.farmGroupVisibility === "Owner")
        return (
            <>
                You are publishing to a farm group with the visiblity set to <b>owner</b>. Owner access allows all farm owners of the group to see the farm group reports.
            </>
        );

    return (
        <>
            You are publishing to a farm group with the visiblity set to <b>farm access</b>. Farm access allows any organisation who has access to the farm to view the farm group reports.
        </>
    );
}

function usePublishees() {
    const query = useQuery({
        queryKey: ["publishees"],
        queryFn: async () => httpClient.get(`publications/publishees`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function usePublicationDetailsModal(publication, commentsOnly) {
    const [modal, openModal] = useModal(PublicationDetailsModal);

    const openPublicationDetailsModal = () => {
        if (publication.id) {
            publication.publisheeId = publication.accountId || publication.farmGroupId;
        }

        const modalProps = {
            publication,
            commentsOnly,
        };
        openModal(modalProps);
    };

    return [modal, openPublicationDetailsModal];
}
