import { Bar, Radar } from "react-chartjs-2";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { FileSummary, SummaryRow } from "components/PlanningReport";

export default function Block({ before, after, showFileComparison }) {
    const refData = useRefData();
    const allBlocks = refData.blockTypes.map((b) => ({
        type: b.value,
        colour: utils.GetBlockColour(b.value),
        name: b.text,
        actual: {
            blockCount: 0,
            areaInHectares: 0,
            nLoss: 0,
            pLoss: 0,
            nSurplus: 0,
            grown: 0,
            intake: 0,
            supplements: 0,
        },
        base: {
            blockCount: 0,
            areaInHectares: 0,
            nLoss: 0,
            pLoss: 0,
            nSurplus: 0,
            grown: 0,
            intake: 0,
            supplements: 0,
        },
    }));

    allBlocks.push({ type: "CutAndCarry", name: "Cut and carry", colour: utils.GetBlockColour("CutAndCarry"), actual: { blockCount: 0, areaInHectares: 0, nLoss: 0, pLoss: 0, nSurplus: 0, grown: 0, intake: 0, supplements: 0 }, base: { blockCount: 0, areaInHectares: 0, nLoss: 0, pLoss: 0, nSurplus: 0, grown: 0, intake: 0, supplements: 0 } });
    allBlocks.push({ type: "NonBlock", name: "Non blocked", colour: utils.GetBlockColour("NonBlock"), actual: { blockCount: 0, areaInHectares: 0, nLoss: 0, pLoss: 0, nSurplus: 0, grown: 0, intake: 0, supplements: 0 }, base: { blockCount: 0, areaInHectares: 0, nLoss: 0, pLoss: 0, nSurplus: 0, grown: 0, intake: 0, supplements: 0 } });
    allBlocks.sort((a, b) => (utils.GetBlockOrder(a.type) > utils.GetBlockOrder(b.type) ? 1 : -1));

    const combineBlocks = (budget, key) => {
        const { blocks = [] } = budget;
        blocks.forEach((block) => {
            const blockType = block.type === "ProductivePasture" && (!block.animals || block.animals.length === 0) ? "CutAndCarry" : block.type;
            const allBlock = allBlocks.find((ab) => ab.type === blockType);
            allBlock.active = true;
            const area = block.type === "FodderCrop" ? block.rotationArea : block.areaInHectares;
            const latestResults = block.currentResults ? block.currentResults : {};
            const nLoss = !latestResults.nitrogenOverview || latestResults.nitrogenOverview.totalLost === undefined ? 0 : latestResults.nitrogenOverview.totalLost;
            const pLoss = !latestResults.phosphorusOverview || latestResults.phosphorusOverview.totalLost === undefined ? 0 : latestResults.phosphorusOverview.totalLost;
            const nSurplus = (!latestResults.nitrogenOverview || latestResults.nitrogenOverview.surplus === undefined ? 0 : latestResults.nitrogenOverview.surplus) * area;

            const pastureResults = block.currentResults && block.currentResults.pastureResults ? block.currentResults.pastureResults : {};
            var grown = pastureResults.pastureGrowth ? (pastureResults.pastureGrowth * area) / 1000 : 0;
            var intake = pastureResults.pastureIntake ? (pastureResults.pastureIntake * area) / 1000 : 0;
            var supplements = pastureResults.supplementsRemoved ? (pastureResults.supplementsRemoved * area) / 1000 : 0;

            allBlock[key].blockCount += 1;
            allBlock[key].areaInHectares += area;
            allBlock[key].nLoss += nLoss;
            allBlock[key].pLoss += pLoss;
            allBlock[key].nSurplus += nSurplus;

            allBlock[key].grown += grown;
            allBlock[key].intake += intake;
            allBlock[key].supplements += supplements;
        });

        const totalBlockArea = blocks.filter((b) => b.type !== "FodderCrop").reduce((total, block) => (total += block.areaInHectares), 0);
        const nonBlockedArea = utils.round(budget.totalFarmArea - totalBlockArea, 0);
        if (nonBlockedArea > 0) {
            const nonBlocked = allBlocks.find((ab) => ab.type === "NonBlock");
            nonBlocked[key].areaInHectares = nonBlockedArea;
            nonBlocked.active = true;
        }
    };

    combineBlocks(after.details, "actual");
    if (before) {
        combineBlocks(before.details, "base");
    }

    const filteredBlocks = allBlocks.filter((b) => b.active);

    const radarData = {
        labels: filteredBlocks.map((b) => b.name),
        datasets: [
            {
                label: after.displayName,
                backgroundColor: "rgba(68, 170, 108,0.2)",
                pointBackgroundColor: "rgba(68, 170, 108,1)",
                borderColor: "rgba(68, 170, 108,1)",
                lineTension: 0.3,
                data: filteredBlocks.map((b) => utils.round(b.actual.areaInHectares, 1)),
            },
        ],
    };

    const barData = {
        labels: filteredBlocks.map((b) => b.name),
        datasets: [
            {
                label: after.displayName,
                data: filteredBlocks.map((b) => utils.round(b.actual.areaInHectares, 1)),
                backgroundColor: filteredBlocks.map((b) => b.colour),
                borderColor: "#52af6e",
                borderWidth: 1,
            },
        ],
    };

    if (before) {
        radarData.datasets.unshift({
            label: before.displayName,
            backgroundColor: "rgba(140, 190, 229,0.2)",
            pointBackgroundColor: "rgba(140, 190, 229,1)",
            borderColor: "rgba(140, 190, 229,1)",
            lineTension: 0.3,
            data: filteredBlocks.map((b) => utils.round(b.base.areaInHectares, 1)),
        });
        barData.datasets.unshift({
            label: before.displayName,
            backgroundColor: "#E3EEF8",
            borderColor: "#509BC2",
            borderWidth: 1,
            data: filteredBlocks.map((b) => utils.round(b.base.areaInHectares, 1)),
        });
    }

    const hasBase = barData.datasets.length > 1;
    for (let i = 0; i < refData.blockTypes.length; i++) {
        let blockData = barData.datasets[0].data[i] || 0;
        if (hasBase) {
            blockData += barData.datasets[1].data[i] || 0;
        }
        if (blockData === 0) {
            barData.datasets[0].data.splice(i, 1);
            barData.labels.splice(i, 1);
            if (hasBase) {
                barData.datasets[1].data.splice(i, 1);
            }
        }
    }

    const barOptions = {
        scales: {
            y: {
                display: true,
                title: { display: true, text: "Area (ha)" },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    const isComparing = !!before && !!after;

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}
            <div className="ReportSection u-page-break-avoid">
                <h1>Block types</h1>
                <div className="Table Table-Compressed">
                    <Watermark />
                    <table>
                        <thead>
                            <tr>
                                <th data-width="25"></th>
                                <th></th>
                                {isComparing && (
                                    <th className="numeric u-before" data-width="12.5">
                                        {utils.truncateText(before.displayName, 35)}
                                    </th>
                                )}
                                <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                    {utils.truncateText(after.displayName, 35)}
                                </th>
                                {isComparing && (
                                    <th className="numeric" data-width="16.7">
                                        Change
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBlocks.map((block, i) => {
                                const { areaInHectares = 0 } = block.actual;
                                const baseAreaBlock = before && block.base;
                                const result = [];
                                const lineClass = block.name === "Cut and carry" ? "Table-tr--CutAndCarry" : "Table-tr--" + block.type;
                                const hasPasture = ["ProductivePasture", "ProductiveCrop", "ProductiveFruit"].includes(block.type);
                                const hasSupplements = ["ProductivePasture", "ProductiveCrop"].includes(block.type);
                                const { grown = 0, supplements = 0, intake = 0 } = block.actual;
                                const baseGrown = before && block.base && block.base.grown;
                                const baseIntake = before && block.base && block.base.intake;
                                const baseSupplements = before && block.base && block.base.supplements;

                                result.push(<SummaryRow key={i + "a"} beforeValue={baseAreaBlock?.areaInHectares} afterValue={areaInHectares} isComparing={isComparing} category={block.name} label="Area" prefix={block.name} unit="ha" dp={1} rowClass={`${lineClass} ${hasPasture || hasSupplements ? "Table-tr--start" : ""}`} />);

                                if (grown > 0 || baseGrown > 0) {
                                    result.push(<SummaryRow key={i + "b"} beforeValue={baseGrown} afterValue={grown} isComparing={isComparing} category=" " label="Pasture grown" prefix={block.name} unit="t DM/yr" rowClass={`${lineClass} ${hasSupplements ? "Table-tr--middle" : "Table-tr--end"}`} />);
                                }

                                if (intake > 0 || baseIntake > 0) {
                                    result.push(<SummaryRow key={i + "c"} beforeValue={baseIntake} afterValue={intake} isComparing={isComparing} category=" " label="Pasture intake" prefix={block.name} unit="t DM/yr" rowClass={`${lineClass} ${hasSupplements ? "Table-tr--middle" : "Table-tr--end"}`} />);
                                }

                                if (supplements > 0 || baseSupplements > 0) {
                                    result.push(<SummaryRow key={i + "d"} beforeValue={baseSupplements} afterValue={supplements} isComparing={isComparing} category=" " label="Supplements harvested" prefix={block.name} unit="t DM/yr" rowClass={`${lineClass} Table-tr--end`} />);
                                }
                                return result;
                            })}
                        </tbody>
                        <tfoot>
                            <SummaryRow beforeValue={before?.details?.totalFarmArea} afterValue={after?.details?.totalFarmArea} isComparing={isComparing} category="Total area" label="" unit="ha" dp={1} />
                        </tfoot>
                    </table>
                </div>
                {false && <Radar data={radarData} options={{ legend: { display: true } }} width={400} height={400} />}
            </div>
            <div className="ReportSection u-page-break-avoid">
                <div className="ReportSection">
                    <h2>Effective area by block type</h2>
                    <div className="printable-chart-container">
                        <Bar data={barData} options={barOptions} className="printable-chart" />
                    </div>
                </div>
            </div>
        </div>
    )
}
