import { useState, useEffect, useCallback, useRef } from "react";
import { Grid, GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import Alert from "components/Alert";
import { Panel, PanelBody } from "components/Panel";
import NLossByBlocksChart from "./NLossByBlocksChart";
import NAppliedFromFertiliserByBlocksChart from "./NAppliedFromFertiliserByBlocksChart";
import PLossByBlocksChart from "./PLossByBlocksChart";
import SelectedBlock from "./SelectedBlock";
import * as rptUtils from "../_utils";
import ReportingFarmsMap from "../ReportingFarmsMap";
import { useRefData } from "common/hooks";

const BenchmarkingByBlocks = ({ benchmarkData, farmId, farmGroup, refData }) => {
    const selectedBlockRef = useRef();
    const benchmarkFarms = useBenchmarkFarms(benchmarkData);
    const availableEnterprises = useAvailableEnterprises(benchmarkFarms);
    const [selectedEnterprises, toggleSelectedEnterprise] = useSelectedEnterprise(availableEnterprises);
    const farms = useFarms(benchmarkFarms, availableEnterprises, selectedEnterprises);
    const availableBlockTypes = useAvailableBlockTypes(benchmarkFarms);
    const [selectedBlockTypes, toggleSelectedBlockTypes] = useSelectedBlockTypes(availableBlockTypes);
    const blocks = useBlocks(farms, selectedBlockTypes);
    const [selectedBlock, setSelectedBlock] = useState();
    const mappedBlocks = blocks ? blocks.filter((f) => (!selectedBlockTypes || selectedBlockTypes.includes(f.type)) && f.longitude && f.latitude) : [];
    const selectedBlockTop = (selectedBlockRef && selectedBlockRef.current && selectedBlockRef.current.getBoundingClientRect().top) || 0;
    const paddingTop = `${selectedBlockTop < 0 ? -selectedBlockTop + 15 : 15}px`;
    const hasBenchmarkData = benchmarkFarms && benchmarkFarms.length > 0;

    return (
        <>
            {farmGroup && !farmGroup.showBenchmarkReport && farmGroup.role === "Admin" && <Alert type="warning" text="Benchmark reporting is hidden from group members" />}
            {!hasBenchmarkData && (
                <div className="HeroPanel">
                    <div className="Tile-body-message">
                        <h3 className="u-mt-md">There are no results for this period</h3>
                    </div>
                </div>
            )}
            {hasBenchmarkData && (
                <>
                    <Alert
                        type="info"
                        html="<p>Shows how blocks compare to each other. For farms that you have permission to see, the map shows the location of each block. The size of the circle indicates the size of the block. You can click on a circle to view its details on the right.</p>
                    <p>The bar charts below compare block results. If viewing this via a farm, the other farm blocks will be shown in a lighter colour. Move the mouse over bars to see loss numbers. If you have access to the farm, the name will also be shown. Select a bar to see the details for the farm. </p>
                    <p>Animal enterprise filtering will only show those farms that have that enterprise. A farm may have more then one enterprise. Select no animals to see cropping only farms. Blocks are filtered by type, click on each type to change the filter.</p>
                    <p><b>Please note:</b> The map is only showing productive blocks that have been drawn only whereas the bar charts below are showing blocks of all types - drawn or not.</p>
                    "
                    />
                    <div className="HeroPanel u-print-none">
                        <div className="Field u-mt-md">
                            <div className="h5">Show farms containing</div>
                            <ul className="BlockList u-mt-sm">
                                {availableEnterprises &&
                                    availableEnterprises.map((et) => {
                                        const isSelected = selectedEnterprises && selectedEnterprises.includes(et.value);
                                        return (
                                            <li key={et.value} id={et.value} onClick={() => toggleSelectedEnterprise(et.value)} className={`BlockList-item ${isSelected ? "is-active" : ""}`}>
                                                <span id={`${et.value}-name`}>
                                                    {et.text} ({(et.count || 0).toLocaleString()})
                                                </span>
                                                {isSelected && (
                                                    <ActionLink className="BlockList-item-close">
                                                        <i className="icon icon-cross" />
                                                    </ActionLink>
                                                )}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        {availableBlockTypes && availableBlockTypes.length > 0 && (
                            <div className="Field u-mt-lg">
                                <label className="Field-label">Filter by block type</label>
                                <ul className="BlockList u-mt-xs">
                                    {availableBlockTypes.sort(rptUtils.blockCompare).map((bt, i) => {
                                        const isSelected = selectedBlockTypes && selectedBlockTypes.includes(bt.value);
                                        return (
                                            <li key={bt.value} id={bt.value} onClick={() => toggleSelectedBlockTypes(bt.value)} className={`BlockList-item ${isSelected ? "is-active" : ""}`}>
                                                <span id={`${bt.value}-name`}>
                                                    {bt.text} ({(bt.count || 0).toLocaleString()})
                                                </span>
                                                {isSelected && (
                                                    <ActionLink className="BlockList-item-close">
                                                        <i className="icon icon-cross" />
                                                    </ActionLink>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="u-print-show">
                        <div className="u-page-break">
                            {blocks && blocks.length > 0 && (
                                <Panel title="Blocks map" className="u-mt-md" skyBlue>
                                    <PanelBody>
                                        <ReportingFarmsMap locations={mappedBlocks} selectedLocation={selectedBlock} onLocationSelected={setSelectedBlock} />
                                    </PanelBody>
                                </Panel>
                            )}
                        </div>
                        <div className="u-page-break">
                            <NLossByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                        </div>
                        <div className="u-page-break">
                            <NAppliedFromFertiliserByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                        </div>
                        <div className="u-page-break">
                            <PLossByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                        </div>
                    </div>
                    <Grid className="u-print-none">
                        <GridCell className="u-md-width3of4 u-xl-width4of5 u-pr-md">
                            {blocks && blocks.length > 0 && (
                                <Panel title="Blocks map" className="u-mt-md" skyBlue>
                                    <PanelBody>
                                        <ReportingFarmsMap locations={mappedBlocks} selectedLocation={selectedBlock} onLocationSelected={setSelectedBlock} />
                                    </PanelBody>
                                </Panel>
                            )}
                            <NLossByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                            <NAppliedFromFertiliserByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                            <PLossByBlocksChart blocks={blocks} farmId={farmId} enterprises={selectedEnterprises} selectedBlock={selectedBlock} onBlockSelected={setSelectedBlock} />
                        </GridCell>
                        <GridCell className="u-md-width1of4 u-xl-width1of5 u-pl-0">
                            <div style={{ paddingTop }} ref={selectedBlockRef}>
                                <SelectedBlock selectedBlock={selectedBlock} farmId={farmId} availableBlockTypes={availableBlockTypes} />
                            </div>
                        </GridCell>
                    </Grid>
                </>
            )}
        </>
    );
};

export default BenchmarkingByBlocks;

function useBenchmarkFarms(benchmarkData) {
    const [benchmarkFarms, setBenchmarkFarms] = useState();

    useEffect(() => {
        if (benchmarkData) {
            const hasBenchmarkData = (benchmarkData.farms || []).some((farm) => farm.hasResults);
            setBenchmarkFarms(hasBenchmarkData ? benchmarkData.farms : []);
        }
    }, [benchmarkData, benchmarkFarms]);

    return benchmarkFarms;
}

function useAvailableEnterprises(benchmarkFarms) {
    const refData = useRefData();
    const [availableEnterprises, setAvailableEnterprises] = useState();

    useEffect(() => {
        if (benchmarkFarms && refData && !availableEnterprises) {
            const enterprises = benchmarkFarms.reduce(
                (results, farm) => {
                    (refData.enterpriseTypes || []).forEach((et) => {
                        const enterprise = (farm.enterprises || []).find((rsu) => rsu.type === et.value);
                        if (enterprise) {
                            enterprise.label = et.text;
                            const result = results.find((x) => x.value === et.value);
                            if (!result) {
                                results.push({ ...et, count: 1 });
                            } else {
                                result.count++;
                            }
                        }
                    });
                    return results;
                },
                [{ value: "NONE", text: "No animals", count: benchmarkFarms.filter((f) => f.enterprises.length === 0).length }]
            );

            setAvailableEnterprises(enterprises);
        }
    }, [benchmarkFarms, refData, availableEnterprises]);

    return availableEnterprises;
}

function useSelectedEnterprise(availableEnterprises) {
    const [selectedEnterprises, setSelectedEnterprises] = useState();

    useEffect(() => {
        if (availableEnterprises && !selectedEnterprises) {
            setSelectedEnterprises(availableEnterprises.map((s) => s.value));
        }
    }, [availableEnterprises, selectedEnterprises]);

    const toggleSelectedEnterprise = useCallback(
        (toggledEnterprise) => {
            setSelectedEnterprises((prevState) => {
                const removeFromSelection = prevState.includes(toggledEnterprise);
                const updatedSelections = removeFromSelection ? prevState.filter((ent) => ent !== toggledEnterprise) : [...prevState, toggledEnterprise];
                const nextState = availableEnterprises.filter((et) => updatedSelections.includes(et.value)).map((et) => et.value);
                return nextState;
            });
        },
        [availableEnterprises]
    );

    return [selectedEnterprises, toggleSelectedEnterprise];
}

function useFarms(benchmarkFarms, availableEnterprises, selectedEnterprises) {
    const [farms, setFarms] = useState([]);

    useEffect(() => {
        if (benchmarkFarms && availableEnterprises && selectedEnterprises) {
            const filteredFarms = selectedEnterprises.length === availableEnterprises.length ? benchmarkFarms : benchmarkFarms.filter((f) => f.enterprises.some((rsu) => selectedEnterprises.includes(rsu.type)) || (selectedEnterprises.includes("NONE") && f.enterprises.length === 0));
            setFarms(filteredFarms);
        }
    }, [benchmarkFarms, availableEnterprises, selectedEnterprises]);

    return farms;
}

function useAvailableBlockTypes(benchmarkFarms) {
    const refData = useRefData();
    const [availableBlockTypes, setAvailableBlockTypes] = useState();

    useEffect(() => {
        if (benchmarkFarms && refData && !availableBlockTypes) {
            const blockTypes = benchmarkFarms.reduce((results, farm) => {
                const blockTypes = farm.blocks.map((b) => (b.cutAndCarry ? "CutAndCarry" : b.type));

                const refDataBlockTypes = (refData.blockTypes || []).reduce((results, bt) => {
                    results.push(bt);
                    if (bt.value === "ProductivePasture") {
                        results.push({ value: "CutAndCarry", text: "Cut and carry" });
                    }
                    return results;
                }, []);

                refDataBlockTypes.forEach((bt) => {
                    if (blockTypes.includes(bt.value)) {
                        const count = blockTypes.filter((blockType) => blockType === bt.value).length;
                        const result = results.find((x) => x.value === bt.value);
                        if (!result) {
                            results.push({ ...bt, count });
                        } else {
                            result.count += count;
                        }
                    }
                });
                return results;
            }, []);

            setAvailableBlockTypes(blockTypes);
        }
    }, [benchmarkFarms, refData, availableBlockTypes]);

    return availableBlockTypes;
}

function useSelectedBlockTypes(availableBlockTypes) {
    const [selectedBlockTypes, setSelectedBlockTypes] = useState();

    useEffect(() => {
        if (availableBlockTypes && !selectedBlockTypes) {
            setSelectedBlockTypes(availableBlockTypes.map((s) => s.value));
        }
    }, [availableBlockTypes, selectedBlockTypes]);

    const toggleSelectedBlockType = useCallback(
        (toggledBlockType) => {
            setSelectedBlockTypes((prevState) => {
                const removeFromSelection = prevState.includes(toggledBlockType);
                const updatedSelections = removeFromSelection ? prevState.filter((ent) => ent !== toggledBlockType) : [...prevState, toggledBlockType];
                const nextState = availableBlockTypes.filter((et) => updatedSelections.includes(et.value)).map((et) => et.value);
                return nextState;
            });
        },
        [availableBlockTypes]
    );

    return [selectedBlockTypes, toggleSelectedBlockType];
}

function useBlocks(farms, selectedBlockTypes) {
    const [blocks, setBlocks] = useState([]);

    useEffect(() => {
        if (farms && selectedBlockTypes) {
            const filteredBlocks = farms.reduce((results, farm) => {
                const blocksByType = farm.blocks
                    .filter((b) => (selectedBlockTypes.includes(b.type) && !b.cutAndCarry) || (selectedBlockTypes.includes("CutAndCarry") && b.cutAndCarry))
                    .map((b) => ({
                        ...b,
                        farmId: farm.farmId,
                        farmName: farm.farmName,
                        analysisId: farm.analysisId,
                        isMyFarm: farm.isMyFarm,
                    }));
                return [...results, ...blocksByType];
            }, []);
            setBlocks(filteredBlocks);
        }
    }, [farms, selectedBlockTypes]);

    return blocks;
}
