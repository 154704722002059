import { useState } from "react";
import * as utils from "common/utils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import ZendeskLink from "components/Help/ZendeskLink";
import { useAnalysisAuditLog } from "containers/hooks";
import { useRefData, useModal, useAuthContext } from "common/hooks";

export default function AnalysisAuditLogModal({ farm, analysis, close }) {
    const authContext = useAuthContext();
    const refData = useRefData();
    const { data: auditLog } = useAnalysisAuditLog(farm.id, analysis.id);
    const [isLogOpen, setIsLogOpen] = useState();

    const toggleSysLog = (changedOn) => {
        if (isLogOpen) {
            setIsLogOpen(false);
        } else {
            setIsLogOpen(changedOn);
        }
    }

    const showResults = (r) => {
        return (
            <>
                {r.nLossPerHa && (
                    <p key={`results-nha}`}>
                        <b>N (kg/ha):</b> {r.nLossPerHa.from === 0 ? "X" : r.nLossPerHa.from} to {r.nLossPerHa.to === 0 ? "X" : r.nLossPerHa.to}
                    </p>
                )}
                {r.nLossTotal && (
                    <p key={`results-n`}>
                        <b>N (kg):</b> {r.nLossTotal.from === 0 ? "X" : r.nLossTotal.from.toLocaleString()} to {r.nLossTotal.to === 0 ? "X" : r.nLossTotal.to.toLocaleString()}
                    </p>
                )}
                {r.pLossPerHa && (
                    <p key={`results-pha`}>
                        <b>P (kg/ha):</b> {r.pLossPerHa.from === 0 ? "X" : r.pLossPerHa.from} to {r.pLossPerHa.to === 0 ? "X" : r.pLossPerHa.to}
                    </p>
                )}
                {r.pLossTotal && (
                    <p key={`results-p`}>
                        <b>P (kg):</b> {r.pLossTotal.from === 0 ? "X" : r.pLossTotal.from.toLocaleString()} to {r.pLossTotal.to === 0 ? "X" : r.pLossTotal.to.toLocaleString()}
                    </p>
                )}
                {r.ghg && (
                    <p key={`results-g`}>
                        <b>GHG (kg/ha):</b> {r.ghg.from === 0 ? "X" : r.ghg.from.toLocaleString()} to {r.ghg.to === 0 ? "X" : r.ghg.to.toLocaleString()}
                    </p>
                )}
            </>
        );
    }

    const showPhysical = (r) => {
        const items = [];
        r.farmArea &&
            items.push(
                <p key={`physical-area}`}>
                    <b>- Farm area (ha):</b> {r.farmArea.from === 0 ? "Block area" : r.farmArea.from.toLocaleString()} to {r.farmArea.to === 0 ? "Block area" : r.farmArea.to.toLocaleString()}
                </p>
            );
        r.productiveBlockArea &&
            items.push(
                <p key={`physical-block`}>
                    <b>- Block area (ha):</b> {r.productiveBlockArea.from.toLocaleString()} to {r.productiveBlockArea.to.toLocaleString()}
                </p>
            );
        r.temperature &&
            !isNaN(r.temperature.from) &&
            !isNaN(r.temperature.to) &&
            items.push(
                <p key={`physical-temp`}>
                    <b>- Temp (C):</b> {r.temperature.from === 0 ? "default" : r.temperature.from} to {r.temperature.to}
                </p>
            );
        r.rainfallMM &&
            items.push(
                <p key={`physical-rain`}>
                    <b>- Rain (mm):</b> {r.rainfallMM.from === 0 ? "default" : r.rainfallMM.from.toLocaleString()} to {r.rainfallMM.to.toLocaleString()}
                </p>
            );
        r.petmm &&
            items.push(
                <p key={`physical-pet`}>
                    <b> - PET (mm):</b> {r.petmm.from === 0 ? "default" : r.petmm.from.toLocaleString()} to {r.petmm.to.toLocaleString()}
                </p>
            );
        r.soilAreas &&
            r.soilAreas.forEach((s, i) =>
                items.push(
                    <p key={`physical-${s.name}-${i}`}>
                        <b>- {s.name} area (ha):</b> {s.from.toLocaleString()} to {s.to.toLocaleString()}
                    </p>
                )
            );
        if (items.length === 0) return null;
        return <>{items}</>;
    }

    const showSystem = (r) => {
        const items = [];
        r.syntheticFertiliserNutrientsAppliedKG &&
            r.syntheticFertiliserNutrientsAppliedKG["N"] &&
            items.push(
                <div>
                    <p className="u-textBold">- Synthetic fertiliser N (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {r.syntheticFertiliserNutrientsAppliedKG["N"].map((f, j) => (
                            <li key={j}>
                                <p>
                                    {f.month}: {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        r.syntheticFertiliserNutrientsAppliedKG &&
            r.syntheticFertiliserNutrientsAppliedKG["P"] &&
            items.push(
                <div>
                    <p className="u-textBold">- Synthetic fertiliser P (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {r.syntheticFertiliserNutrientsAppliedKG["P"].map((f, j) => (
                            <li key={j}>
                                <p>
                                    {f.month}: {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        r.organicFertiliserNutrientsAppliedKG &&
            r.organicFertiliserNutrientsAppliedKG["N"] &&
            items.push(
                <div>
                    <p className="u-textBold">- Organic fertiliser N (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {r.organicFertiliserNutrientsAppliedKG["N"].map((f, j) => (
                            <li key={j}>
                                <p>
                                    {f.month}: {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        r.organicFertiliserNutrientsAppliedKG &&
            r.organicFertiliserNutrientsAppliedKG["P"] &&
            items.push(
                <div>
                    <p className="u-textBold">- Organic fertiliser P (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {r.organicFertiliserNutrientsAppliedKG["P"].map((f, j) => (
                            <li key={j}>
                                <p>
                                    {f.month}: {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        r.animalRSU &&
            Object.keys(r.animalRSU).forEach((e, i) =>
                items.push(
                    <p key={i}>
                        <b>- {utils.valueToText(refData.enterpriseTypes, e)} (RSU):</b> {r.animalRSU[e].from.toLocaleString()} to {r.animalRSU[e].to.toLocaleString()}
                    </p>
                )
            );
        r.animalNumbers &&
            false &&
            Object.keys(r.animalNumbers).forEach((e, i) =>
                items.push(
                    <div>
                        <p className="u-textBold">- {utils.valueToText(refData.enterpriseTypes, e)} (numbers)</p>
                        <ul className="disc u-mt-sm">
                            {r.animalNumbers[e]
                                .filter((f) => f.percentageChange > 0)
                                .map((f, j) => (
                                    <li key={j}>
                                        <p>
                                            {f.month}: {f.from.toLocaleString()} to {f.to.toLocaleString()}
                                        </p>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )
            );
        r.supplementsImportedKG &&
            items.push(
                <div>
                    <p className="u-textBold">- Supplement nutrients (kg)</p>{" "}
                    <ul className="disc u-mt-sm">
                        {Object.keys(r.supplementsImportedKG)
                            .filter((n) => n === "N" || n === "P")
                            .map((n, i) => (
                                <p key={i}>
                                    <b>{n}</b> {r.supplementsImportedKG[n].from.toLocaleString()} to {r.supplementsImportedKG[n].to.toLocaleString()}
                                </p>
                            ))}
                    </ul>
                </div>
            );
        r.totalIrrigationApplied &&
            items.push(
                <p>
                    <b>- Irrigation applied (mm):</b> {r.totalIrrigationApplied.from.toLocaleString()} to {r.totalIrrigationApplied.to.toLocaleString()}
                </p>
            );
        r.milkSolidsProductionKG &&
            items.push(
                <p>
                    <b>- Milk solids (kg):</b> {r.milkSolidsProductionKG.from.toLocaleString()} to {r.milkSolidsProductionKG.to.toLocaleString()}
                </p>
            );
        r.pastureProduction &&
            items.push(
                <p>
                    <b>- Pasture production (kg DM):</b> {r.pastureProduction.from.toLocaleString()} to {r.pastureProduction.to.toLocaleString()}
                </p>
            );
        r.averagePastureProduction &&
            items.push(
                <p>
                    <b>- Average pasture (kg DM/ha):</b> {r.averagePastureProduction.from.toLocaleString()} to {r.averagePastureProduction.to.toLocaleString()}
                </p>
            );
        r.croppingArea &&
            items.push(
                <p>
                    <b>- Cropping area (ha):</b> {r.croppingArea.from} to {r.croppingArea.to.toLocaleString()}
                </p>
            );
        if (items.length === 0) return null;
        return <>{items}</>;
    }

    const getScreenName = (path) => {
        if (!path) return "";
        if (path.includes("blocks/new")) return "Blocks - A new block was created";
        if (path.includes("blocks/")) return "Blocks - A block was edited";

        if (path.includes("snowfall/new")) return "Climate - Snowfall was created";
        if (path.includes("snowfall/")) return "Climate - Snowfall was edited";

        if (path.includes("soil/soilTests/new")) return "Soil test - A soil test was added";
        if (path.includes("soil/soilTests/")) return "Soil test - A soil test was edited";
        if (path.includes("soil")) return "Soil - A farm soil was edited";

        if (path.includes("drainage/unfenced/new")) return "Drainage - An unfenced wetland was added";
        if (path.includes("drainage/unfenced/")) return "Drainage - An unfenced wetland was edited";
        if (path.includes("drainage/fenced/")) return "Drainage - A fenced wetland was edited on the drainage tab";
        if (path.includes("drainage/moletilesystem")) return "Drainage - A mole/tile drainage system was added/edited or deleted";
        if (path.includes("drainage/other")) return "Drainage - An other drainage system was added/edited or deleted";

        if (path.includes("crops/pasture")) return "Pasture/Crops - Pasture was added to or edited on pasture blocks";
        if (path.includes("crops/rotation/new")) return "Pasture/Crops - A fodder crop rotation was added";
        if (path.includes("crops/rotation/")) return "Pasture/Crops - A fodder crop rotation was edited";
        if (path.includes("crops/fruit/")) return "Pasture/Crops - Fruit has been added to or edited on fruit blocks";
        if (path.includes("crops/crop/")) return "Pasture/Crops - Crops have been added to or edited on crop blocks";

        if (path.includes("animals/enterprises/beef")) return "Animals - Beef enterprise has been edited";
        if (path.includes("animals/enterprises/dairy")) return "Animals - Dairy enterprise has been edited";
        if (path.includes("animals/enterprises/sheep")) return "Animals - Sheep enterprise has been edited";
        if (path.includes("animals/enterprises/dairygrazing")) return "Animals - Dairy grazing enterprise has been edited";
        if (path.includes("animals/enterprises/deer")) return "Animals - Deer grazing enterprise has been edited";
        if (path.includes("animals/enterprises/dairygoat")) return "Animals - Dairy goat enterprise has been edited";
        if (path.includes("animals/enterprises/outdoorpigs")) return "Animals - Outdoor pigs enterprise has been edited";
        if (path.includes("animals/enterprises/other")) return "Animals - Other animals enterprise has been edited";
        if (path.includes("animals/distribution")) return "Animals - Animal distribution has been edited";
        if (path.includes("animals")) return "Animals - Animals enterprise has been deleted";

        if (path.includes("structures/structure")) return "Structures/Effluent - A structure was edited";
        if (path.includes("structures/effluent")) return "Structures/Effluent - An effluent system has been edited";

        if (path.includes("supplements/harvested")) return "Supplements - A harvested supplement has been edited";
        if (path.includes("supplements/imported")) return "Supplements - An imported supplement has been edited";
        if (path.includes("distribution")) return "Supplements - Supplements have been distributed";

        if (path.includes("fertiliser")) return "Fertiliser - A fertiliser has been edited";
        if (path.includes("fertiliser/applications")) return "Fertiliser - A fertiliser application has been edited";

        if (path.includes("irrigation/new")) return "Irrigation - An irrigation system has been created";
        if (path.includes("irrigation/applications")) return "Irrigation - An irrigation application has been edited";

        if (path.includes("ghg/fuel")) return "GHG - Fuel footprint overrides were edited";
        if (path.includes("ghg/fertiliser")) return "GHG - Fertiliser application footprint overrides were edited";
        if (path.includes("ghg/transport")) return "GHG - Transport footprint overrides were edited";
        if (path.includes("ghg/electricity")) return "GHG - Electricity footprint overrides were edited";

        return "Analysis updated";
    }

    const getReleaseNotesUrl = (modelVersion) => {
        const baseUrl = "https://support.overseer.org.nz/hc/en-us/articles/";
        switch (modelVersion) {
            case "6.5.7":
                return baseUrl + "37595129798425";
            case "6.5.6":
                return baseUrl + "34614448647833";
            case "6.5.5":
                return baseUrl + "32903554057241";
            case "6.5.4":
                return baseUrl + "25848909075865";
            case "6.5.3":
                return baseUrl + "23986325469337";
            case "6.5.2":
                return baseUrl + "19315463463065";
            case "6.5.1":
                return baseUrl + "17839069845145";
            case "6.5.0":
                return baseUrl + "12730566419481";
            case "6.4.3":
                return baseUrl + "5342784444441";
            case "6.4.2":
                return baseUrl + "4412310769561";
            case "6.4.1":
                return baseUrl + "4407107447193";
            case "6.4.0":
                return baseUrl + "4404231934617";
            default:
                return null;
        }
    }

    const showResultsColumn = !farm.expired;

    const info = "This table shows a list of changes for this analysis. Entries are only created when the change has an effect on the modelled results. We will be adding more information about what has changed to this log over time.";

    return (
        <Modal title={`Analysis audit log - ${analysis.name}`} close={close} fluid wide>
            <ModalBody loading={!auditLog} info={info} error={auditLog && auditLog.error}>
                <div className="Table u-mt-md">
                    <div style={{ overflowY: "scroll" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="15">Date</th>
                                    <th data-width="15">Organisation</th>
                                    <th data-width="15">User</th>
                                    {showResultsColumn && <th data-width="15">Results</th>}
                                    <th data-width="40">Changes</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                        <table>
                            <tbody>
                                {(!auditLog || (auditLog.changes || []).length === 0) && (
                                    <tr key={analysis.created}>
                                        <td data-width="15" className="td--alignTop">
                                            {utils.dateLong(analysis.created)}
                                        </td>
                                        <td data-width="15"></td>
                                        <td data-width="15"></td>
                                        {showResultsColumn && <td data-width="15"></td>}
                                        <td data-width="40">
                                            <p className="u-textBold u-textTertiary">Analysis created</p>
                                        </td>
                                    </tr>
                                )}
                                {auditLog &&
                                    auditLog.changes &&
                                    auditLog.changes
                                        .sort((a, b) => (b.changedOn < a.changedOn ? -1 : 1))
                                        .map((a) => {
                                            let isModelVersionUpgrade = false;

                                            const sysChanges = a.inputChanges
                                                ? a.inputChanges.map((c, i) => {
                                                    let releaseNotesLink;
                                                    if (c.section.startsWith("Model version upgrade:")) {
                                                        const releaseNotesUrl = getReleaseNotesUrl(a.modelVersion);
                                                        if (releaseNotesUrl) {
                                                            isModelVersionUpgrade = true;
                                                            releaseNotesLink = <ZendeskLink title={`${a.modelVersion} release notes`} url={releaseNotesUrl} className="IconLink--new-tab u-font-green" />;
                                                        }
                                                    }
                                                    return (
                                                        <div key={`inputs-${i}`}>
                                                            <p className="u-textBold">{c.section}</p> {releaseNotesLink}{" "}
                                                            <ul className="disc u-mt-sm">
                                                                {c.changes.map((ic, j) => (
                                                                    <li key={j}>{ic}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    );
                                                })
                                                : a.comparison && a.comparison.system && showSystem(a.comparison.system);
                                            const phyChanges = a.comparison && a.comparison.physical && showPhysical(a.comparison.physical);

                                            return (
                                                <>
                                                    <tr key={a.changedOn}>
                                                        <td data-width="15" className="td--alignTop">
                                                            <div className="u-flex u-flexJustifyBetween">
                                                                <span>{utils.dateLong(a.changedOn)}</span>
                                                                {a.objectDataChanges && authContext.isSystemAdmin && <ActionLink className={`RoundButton RoundButton--${isLogOpen === a.changedOn ? "up" : "down"} u-font-md`} onClick={() => toggleSysLog(a.changedOn)} name="override-smap-button" />}
                                                            </div>
                                                        </td>
                                                        <td data-width="15" className="td--alignTop">
                                                            {a.accountName}
                                                        </td>
                                                        <td data-width="15" className="td--alignTop">
                                                            {a.usersName}
                                                        </td>
                                                        {showResultsColumn && <td data-width="15" className="td--alignTop">
                                                            {a.resultChanges
                                                                ? a.resultChanges.map((c, i) => (
                                                                    <p key={`results-${i}`}>
                                                                        <b>{c.result}:</b> {c.from} to {c.to}
                                                                    </p>
                                                                ))
                                                                : a.comparison && a.comparison.results
                                                                    ? showResults(a.comparison.results)
                                                                    : !a.comparison
                                                                        ? ""
                                                                        : "No results have yet been created for this analysis"}
                                                        </td>}
                                                        <td data-width="40" className="td--alignTop">
                                                            <p>
                                                                <b className="u-textTertiary">{getScreenName(a.path)}</b>
                                                            </p>
                                                            {sysChanges && !isModelVersionUpgrade && (
                                                                <p>
                                                                    <b className="u-textSecondary">Farm system changes</b>
                                                                </p>
                                                            )}
                                                            {sysChanges}
                                                            {phyChanges && (
                                                                <p>
                                                                    <b className="u-textSecondary">Physical farm changes</b>
                                                                </p>
                                                            )}
                                                            {phyChanges}
                                                            <br />
                                                        </td>
                                                    </tr>
                                                    {isLogOpen === a.changedOn && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="h5 u-mb-sm">Auto-generated input comparison</div>
                                                                {false && <div style={{ whiteSpace: "pre-wrap" }}>{a.objectDataChanges}</div>}
                                                                <div className="Table">
                                                                    <span dangerouslySetInnerHTML={{ __html: a.objectDataChanges }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            );
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalFooterLeft></ModalFooterLeft>
                <ModalFooterRight>
                    <Button id="cancel" onClick={close} secondary>
                        Close
                    </Button>
                </ModalFooterRight>
            </ModalFooter>
        </Modal>
    )
}

export function useAnalysisAuditLogModal(farm, analysis) {
    const [modal, openModal] = useModal(AnalysisAuditLogModal);

    const openAnalysisAuditLogModal = () => {
        const modalProps = {
            farm,
            analysis,
        };
        openModal(modalProps);
    };

    return [modal, openAnalysisAuditLogModal];
}
