import React from "react";
import { connect } from "react-redux";
import Alert from "components/Alert";

const CheckboxPack = (field = {}) => {
    const { input = {}, info } = field;

    const _onChange = (e) => {
        if (input.onChange) input.onChange(e, e.target.checked);

        if (field.onChange) field.onChange(e, e.target.checked);
    };

    if (field.isHidden) return null;

    const checked = input.checked || input.value || field.checked || false;

    return field.vertical ? (
        <label className="Checkbox" htmlFor={field.id}>
            <input className="Checkbox-input" {...field.input} id={field.id || field.input.name} onChange={_onChange} type="checkbox" checked={checked} />
            <span className="Checkbox-label">{field.label}</span>
        </label>
    ) : field.meta.nrf ? (
        <div className={"Field " + field.className + (field.val && field.val.error ? " has-error" : "")} data-width={field.dataWidth}>
            <label htmlFor={field.id} className={`Checkbox ${field.disabled ? "is-disabled" : ""}`}>
                <input id={field.id} name={field.name} type="checkbox" checked={field.value || false} disabled={field.disabled || field.readOnly} onChange={(e) => field.onChange(e)} className="Checkbox-input" />
                <span className="Checkbox-label">{field.label}</span>
            </label>
            {field.val && field.val.touched && field.val.error && (
                <small className="Field-error" id={`${field.id}-error`}>
                    {field.val && field.val.error}
                </small>
            )}
            {info && <Alert type="info" text={info} />}
            {field.showQuickTips && field.tip && <Alert type="help" text={field.tip} />}
        </div>
    ) : (
        <div className={"Field " + field.className + (field.meta.touched && field.meta.error ? " has-error" : "")} data-width={field.dataWidth}>
            <label htmlFor={field.id || field.input.name} className={`Checkbox ${field.disabled ? "is-disabled" : ""}`}>
                <input {...field.input} id={field.id || field.input.name} type="checkbox" checked={checked} disabled={field.disabled || field.readOnly} onChange={_onChange} className="Checkbox-input" />
                <span className="Checkbox-label">{field.label}</span>
            </label>
            {field.meta.touched && field.meta.error && (
                <small className="Field-error" id={`${field.id}-error`}>
                    {field.meta.error}
                </small>
            )}
            {field.showQuickTips && field.tip && <Alert type="help" text={field.tip} />}
        </div>
    );
};

export default connect((state) => {
    return {
        showQuickTips: state.app.showQuickTips,
    };
}, null)(CheckboxPack);
