import { useEffect, useState, createRef } from "react";
import * as utils from "common/utils";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useGoogleApi, useGoogleMap } from "components/Map/hooks";
import * as icons from "common/icons";
import { Panel, PanelBody } from "components/Panel";
import ActionLink from "components/ActionLink";
import Spinner from "components/Spinner/Spinner";
import MapLegend from "components/Map/MapLegend";
import MapZoomButton from "components/Map/MapZoomButton";
import MapBlockLabels from "components/Map/MapBlockLabels";
import TileBodyMessage from "components/TileBodyMessage";
import { useBlockModal } from "containers/BudgetHome/Blocks/Modals/BlockModal";
import Button from "components/Button/Button";
import { useConfirm, useOnline, useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "../../hooks";

export default function MapView({ farm, analysis }) {
    const online = useOnline();
    const [selectedBlockId, setSelectedBlockId] = useState();

    const { map, mapId, setMapRef } = useGoogleMap({ center: { lat: farm.latitude, lng: farm.longitude }, zoom: 12 });
    const geoJson = useAnalysisGeoJson(analysis);

    const mapData = useMapData(map, geoJson);
    useMapStyle(mapData);
    useOnPolygonHover(mapData);
    useOnPolygonClick(mapData, setSelectedBlockId);
    useOnSelectedBlockChange(mapData, selectedBlockId);

    const mapError = !online && "Overseer is currently offline. Mapping is unavailable.";
    const mapLoading = online && !geoJson && !mapError;
    const showMap = !mapLoading && !mapError;

    return (
        <div className="mapContainer">
            <div className="mapContainer__layers">
                <MapViewBlockList farm={farm} analysis={analysis} selectedBlockId={selectedBlockId} onClick={setSelectedBlockId} />
            </div>
            <div className={`mapContainer__map ${mapLoading ? "waiting" : ""}`}>
                {mapLoading && (
                    <div className="Tile-body-message">
                        <Spinner dark />
                        <p className="lead">Loading map...</p>
                    </div>
                )}
                {!mapLoading && mapError && (
                    <div className="Tile-body-message u-textError">
                        <i className="icon icon--md icon-alert" />
                        <p className="lead">{mapError}</p>
                    </div>
                )}
                <div className={`map ${showMap ? "" : "u-hidden"}`} ref={setMapRef} />
                <MapBlockLabels mapData={mapData} mapId={mapId} geoJson={geoJson} blocks={analysis.blocks} />
                <MapLegend
                    mapData={mapData}
                    mapId={mapId}
                    layersToShow={[
                        { id: "UnassignedLand", title: "Unassigned Land", sortOrder: 0 },
                        { id: "Irrigator", title: "Drawn Irrigators", sortOrder: 1 },
                    ]}
                />
                <MapZoomButton mapData={mapData} mapId={mapId} />
            </div>
        </div>
    );
}

function useAnalysisGeoJson(analysis) {
    const [geoJson, setGeoJson] = useState();

    // Set geoJson whenever analysis features change.
    useEffect(() => {
        const features = (analysis.features || []).reduce((results, feature) => {
            // Flatten and hide irrigators by default.
            const flattenedFeatures = geoJsonUtils.flatten(feature, ["Irrigator"]);
            return [...results, ...flattenedFeatures];
        }, []);
        setGeoJson({ type: "FeatureCollection", features });
    }, [analysis.features]);

    return geoJson;
}

function useMapData(map, geoJson) {
    const google = useGoogleApi();
    const [mapData, setMapData] = useState();

    // Set map data.
    useEffect(() => {
        if (google && map && geoJson && !mapData) {
            const data = new google.maps.Data({ map });
            data.addGeoJson(geoJson);
            setMapData(data);
        }
    }, [google, map, geoJson, mapData]);

    // Refresh map if geoJson changes.
    useEffect(() => {
        if (mapData && geoJson) {
            mapData.forEach((feature) => mapData.remove(feature));
            mapData.addGeoJson(geoJson);
        }
    }, [mapData, geoJson]);

    return mapData;
}

function useMapStyle(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            mapData.setStyle((feature) => {
                const hovered = feature.getProperty("hovered");
                const selected = feature.getProperty("selected");
                const visible = feature.getProperty("visible");
                const layer = feature.getProperty("layer");
                const layerColor = geoJsonUtils.getMapLayerColor(layer);

                let zIndex = 0;
                if (hovered || selected) {
                    zIndex = 2;
                } else if (layer === "Block") {
                    zIndex = 1;
                }

                return {
                    strokeColor: layerColor,
                    strokeWeight: hovered ? 4 : 3,
                    fillColor: selected || ["UnassignedLand", "Irrigator"].includes(layer) ? layerColor : "white",
                    fillOpacity: 0.3,
                    zIndex,
                    visible,
                };
            });
            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonHover(mapData) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && !runOnce) {
            // Add 'mouseover' event for hover styling.
            mapData.addListener("mouseover", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", true);
                }
            });

            // Add 'mouseout' event for un-hover styling.
            mapData.addListener("mouseout", ({ feature }) => {
                if (feature.getProperty("blockId")) {
                    feature.setProperty("hovered", false);
                }
            });

            setRunOnce(true);
        }
    }, [mapData, runOnce]);
}

function useOnPolygonClick(mapData, onClick) {
    const [runOnce, setRunOnce] = useState(false);

    useEffect(() => {
        if (mapData && onClick && !runOnce) {
            mapData.addListener("click", ({ feature }) => {
                const blockId = feature.getProperty("blockId");
                if (blockId) {
                    onClick(blockId);
                }
            });
            setRunOnce(true);
        }
    }, [mapData, onClick, runOnce]);
}

function useOnSelectedBlockChange(mapData, selectedBlockId) {
    useEffect(() => {
        if (mapData && selectedBlockId) {
            mapData.forEach((mapFeature) => {
                if (mapFeature.getProperty("blockId") === selectedBlockId) {
                    mapFeature.setProperty("selected", true);
                } else {
                    mapFeature.setProperty("selected", false);
                }
            });
        }
    }, [mapData, selectedBlockId]);
}

function MapViewBlockList({ farm, analysis, selectedBlockId, onClick }) {
    const confirm = useConfirm();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    const [openBlockModal, blockModal] = useBlockModal(farm, analysis);
    const blocksByType = useBlocksByType(analysis, selectedBlockId);
    const layerColor = geoJsonUtils.getMapLayerColor("Block");
    const layerRGBA = utils.hexToRGBA(layerColor);

    const _selectBlock = (blockId) => (e) => {
        e.stopPropagation();
        if (onClick) {
            onClick(blockId);
        }
    };

    const _editBlock = (blockId) => (e) => {
        e.stopPropagation();
        openBlockModal(blockId);
    };

    const _deleteBlock = (block) => (e) => {
        e.stopPropagation();
        confirm(`Are you sure you want to delete the "${block.name}" block?`, async () => {
            utils.deleteOrphanBlockRef(analysis, block.id);
            analysis.blocks = analysis.blocks.filter((b) => b.id !== block.id);
            await updateAnalysisAsync(analysis);
        });
    }

    if (!blocksByType) return null;

    return (
        <>
            {blocksByType.length === 0 && (
                <TileBodyMessage>
                    <img src={icons.add} className="u-p-5" alt="Plus" />
                    <Button onClick={openBlockModal} className="IconLink--blocks Button--secondary Button u-mt-md">
                        Assign mapped land to a new block
                    </Button>
                </TileBodyMessage>
            )}
            {blocksByType.length > 0 &&
                blocksByType.map((blockType) => {
                    return (
                        <Panel key={blockType.type} title={`${blockType.type} blocks (${blockType.blocks.length})`} midBlue>
                            <PanelBody grey>
                                <div className="Table u-bg-white">
                                    <table>
                                        <tbody>
                                            {blockType.blocks.map((block) => {
                                                const selected = block.id === selectedBlockId;
                                                return (
                                                    <tr key={block.id} onClick={_selectBlock(block.id)} className={`hover ${selected ? "Table-tr--color" : ""}`} style={selected ? { "--row-color": layerColor, backgroundColor: layerRGBA } : {}}>
                                                        <td valign="top" ref={block.scrollRef}>
                                                            <div className="u-flex u-flexAlignItemsCenter">
                                                                <ActionLink id={`edit-block-${block.id}`} onClick={_editBlock(block.id)} className="u-textBold">
                                                                    {block.name}
                                                                </ActionLink>
                                                                <ActionLink id={`delete-block-${block.id}`} onClick={_deleteBlock(block)} className="IconLink--trash u-ml-auto" title="Delete"></ActionLink>
                                                            </div>
                                                            <div className="u-flexSplit">
                                                                <span>{block.description}</span>
                                                                {block.isProductive && !block.drawnArea && <span className="IconLink--alert u-textWarning">Not drawn</span>}
                                                                {block.drawnArea > 0 && block.drawnArea !== block.area && (
                                                                    <span className="IconLink--alert u-textWarning">
                                                                        Drawn area: <span className="u-textPrimary u-textLight u-ml-xs">{block.drawnArea + " ha"}</span>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </PanelBody>
                        </Panel>
                    );
                })}
            {blockModal}
        </>
    );
}

function useBlocksByType(analysis, selectedBlockId) {
    const refData = useRefData();
    const [blocksByType, setBlocksByType] = useState();

    // Set blocksByType.
    useEffect(() => {
        if (analysis?.blocks?.length > 0 && refData) {
            const blocks = analysis.blocks.filter((b) => b.type !== "FodderCrop");
            const blockList = utils.getBlockList(blocks);
            const bbt = refData.blockTypes
                .filter((bt) => bt.value !== "FodderCrop")
                .reduce((results, bt) => {
                    const productiveBlocksOfThisType = blockList.productiveBlocks.filter((b) => b.type === bt.value);
                    if (productiveBlocksOfThisType.length > 0) {
                        results.push({
                            type: bt.text,
                            blocks: productiveBlocksOfThisType.map((block) => {
                                const blockFeatures = (analysis.features || []).filter((f) => f.properties.blockId === block.id);
                                const drawnArea = blockFeatures.reduce((total, blockFeature) => (total += geoJsonUtils.area(blockFeature)), 0);
                                return {
                                    id: block.id,
                                    name: block.name,
                                    description: utils.getBlockTypeText(refData, block),
                                    isProductive: true,
                                    area: block.areaInHectares,
                                    drawnArea: utils.round(drawnArea, 1),
                                    scrollRef: createRef(),
                                };
                            }),
                        });
                    }
                    const nonProductiveBlocksOfThisType = blockList.nonProductiveBlocks.filter((b) => b.type === bt.value);
                    if (nonProductiveBlocksOfThisType.length > 0) {
                        let otherBlocks = results.find((r) => r.type === "Other");
                        if (!otherBlocks) {
                            otherBlocks = {
                                type: "Other",
                                blocks: [],
                            };
                            results.push(otherBlocks);
                        }
                        otherBlocks.blocks = [
                            ...otherBlocks.blocks,
                            ...nonProductiveBlocksOfThisType.map((block) => {
                                const blockFeatures = (analysis.features || []).filter((f) => f.properties.blockId === block.id);
                                const drawnArea = blockFeatures.reduce((total, blockFeature) => (total += geoJsonUtils.area(blockFeature)), 0);
                                return {
                                    id: block.id,
                                    name: block.name,
                                    description: utils.getBlockTypeText(refData, block),
                                    area: block.areaInHectares,
                                    drawnArea: utils.round(drawnArea, 1),
                                    scrollRef: createRef(),
                                };
                            }),
                        ];
                    }
                    return results;
                }, []);
            setBlocksByType(bbt);
        }
    }, [analysis, refData]);

    // Scroll to selected block.
    useEffect(() => {
        if (blocksByType && selectedBlockId) {
            blocksByType.forEach((blockType) => {
                const block = blockType.blocks.find((b) => b.id === selectedBlockId);
                if (block && block.scrollRef && block.scrollRef.current) {
                    block.scrollRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
                    return;
                }
            });
        }
    }, [blocksByType, selectedBlockId]);

    return blocksByType;
}
