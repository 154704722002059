import ZendeskLink from "components/Help/ZendeskLink";
import FeatureTracker, { FEATURES } from "components/FeatureTracker/FeatureTracker";
import Alert from "components/Alert";
import { StockingRate, EnterpriseMonthResults, PastureAndDiet } from "containers/BudgetHome/Animals/Reports";
import { useAnalysisResults } from "containers/BudgetHome";

export default function AnimalReports({ farm, analysis }) {
    const { data: analysisResults } = useAnalysisResults(analysis);
    const hasAnimalResults = analysisResults?.animals !== undefined;

    return (
        <>
            <FeatureTracker feature={FEATURES.ANIMAL_REPORT} farm={farm} />
            {hasAnimalResults
                ? <>
                    <Alert className="u-print-none" type="info" text="Contains information about animal RSU by block and the dietary requirements for all enterprises. You can print this page by selecting print under the browser menu. For PDF, change the destination to PDF. Portrait is recommended for this report." moreInfo={<ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900002144106" className="IconLink--new-tab u-font-green" />} />
                    <StockingRate analysis={analysis} />
                    <EnterpriseMonthResults analysis={analysis} />
                    <PastureAndDiet analysis={analysis} />
                </>
                : <>
                    <Alert type="error" text="There are no animal results for this analysis" />
                </>
            }
        </>
    );
}
