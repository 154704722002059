import { Link } from "react-router-dom";
import TileSubPanel from "components/TileSubPanel";
import Alert from "components/Alert";
import TabMessages from "containers/BudgetHome/TabMessages";
import { TAB } from "containers/BudgetHome/PageTabs";
import AdditiveList from "./AdditiveList";
import BlockListCard from "./BlockListCard";
import * as utils from "common/utils";
import BlockListGroup from "./BlockListGroup";
import * as cropUtils from "containers/BudgetHome/Crops/_utils";
import * as domain from "common/domain";
import * as fertUtils from "./_utils";
import ActionLink from "components/ActionLink";
import { factoryFertiliser } from "common/domain";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";
import { FertilisersGreenBox } from "containers/BudgetHome/Fertiliser/FertilisersGreenBox";
import { useFertiliserProductModal } from "./ProductModal";
import { useConfirm, useShowQuickTips } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function Fertiliser({ farm, analysis }) {
    const showQuickTips = useShowQuickTips();
    const confirm = useConfirm();
    const [fertiliserProductModal, openFertiliserProductModal] = useFertiliserProductModal();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    fertUtils.updateApplicationModel(analysis);
    const { blocks = [] } = analysis;
    const blockList = utils.getBlockList(blocks);
    const hasDCD = (analysis.nitrateInhibitors || []).length > 0;

    const groupByMonthResown = (blocks) => {
        return blocks.reduce((groups, block) => {
            let blockGroup = groups.find((g) => g.monthResown === block.monthResown);
            if (!blockGroup) {
                blockGroup = {
                    blocks: [],
                    monthResown: block.monthResown,
                    type: block.type,
                    calendar: cropUtils.rotationYear(block),
                };
                groups.push(blockGroup);
            }
            blockGroup.blocks.push(block);
            return groups;
        }, []);
    };

    const groupApplicationsConfirm = () => {
        confirm(`Merging applies identical kg/ha applications to a set of blocks. Are you sure you want to merge?`, async () => {
            analysis.fertiliser.forEach((fert) => {
                fert.applications = fertUtils.groupApplications(fert.applications);
            });
            await updateAnalysisAsync(analysis);
        });
    }

    const ungroupApplicationsConfirm = () => {
        confirm(`Splitting will create individual applications for each block based on kg/ha applications. Are you sure you want to split?`, async () => {
            analysis.fertiliser.forEach((fert) => {
                fert.applications = fertUtils.ungroupApplications(fert.applications);
            });
            await updateAnalysisAsync(analysis);
        });
    }

    const addFertilser = (material) => {
        const fert = factoryFertiliser();
        fert.id = "NEW";
        const viewModel = {
            material,
            farm,
            analysis,
            fertiliser: fert,
            isLastPreviousLime: false,
            customCompositions: [],
            isNew: true
        };
        openFertiliserProductModal(viewModel);
    }

    const fruitBlocks = { type: "ProductiveFruit", blocks: blocks.filter((b) => b.type === "ProductiveFruit"), calendar: domain.farmYear };
    const pastureBlocks = { type: "ProductivePasture", blocks: blocks.filter((b) => b.type === "ProductivePasture"), calendar: domain.farmYear };
    const fodderCropGroups = groupByMonthResown(blocks.filter((b) => b.type === "FodderCrop"));
    const productiveCropGroups = groupByMonthResown(blocks.filter((b) => b.type === "ProductiveCrop"));
    const blockGroups = [];
    const cropBlocks = [...fodderCropGroups, ...productiveCropGroups];
    if (fruitBlocks.blocks.length > 0) blockGroups.push(fruitBlocks);
    if (pastureBlocks.blocks.length > 0) blockGroups.push(pastureBlocks);
    cropBlocks.forEach((g) => blockGroups.push(g));

    return (
        <>
            <TabMessages farm={farm} analysis={analysis} tab={TAB.FERTILISER} />
            <div className="ActionsBar ActionsBar--super u-print-none">
                <ul className="ActionsBar-links">
                    <li>
                        <ActionLink className="IconLink--arrow-plus" id="fertiliser-add-product" onClick={() => addFertilser("Product")}>
                            <span>Add product</span>
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink className="IconLink--arrow-plus" id="fertiliser-add-lime" onClick={() => addFertilser("Lime")}>
                            <span>Add lime</span>
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink className="IconLink--arrow-plus" id="fertiliser-add-organic" onClick={() => addFertilser("Organic")}>
                            <span>Add organic</span>
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink className="IconLink--arrow-plus" id="fertiliser-add-soluble" onClick={() => addFertilser("Soluble")}>
                            <span>Add soluble</span>
                        </ActionLink>
                    </li>
                    {!hasDCD && (
                        <li>
                            <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/dcd`} className="IconLink--arrow-plus" id="fertiliser-add-dcd">
                                <span>Add DCD</span>
                            </Link>
                        </li>
                    )}
                </ul>

                <ul className="ActionsBar-links">
                    <li>
                        <Link className="IconLink--arrow-plus" id="fertiliser-add-applications" to={`/app/farm/${farm.id}/analysis/${analysis.id}/fertiliser/applications`}>
                            <span>Add applications</span>
                        </Link>
                    </li>
                    <li>
                        <ActionLink className="IconLink--group" id="fertiliser-group" onClick={() => groupApplicationsConfirm()}>
                            <span>Merge applications</span>
                        </ActionLink>
                    </li>
                    <li>
                        <ActionLink className="IconLink--copy" id="fertiliser-split" onClick={() => ungroupApplicationsConfirm()}>
                            <span>Split applications</span>
                        </ActionLink>
                    </li>
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://support.overseer.org.nz/hc/en-us/articles/900000962046" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>

            <FertilisersGreenBox farm={farm} analysis={analysis} />
            
            {hasDCD && <AdditiveList farm={farm} analysis={analysis} />}

            {showQuickTips && <Alert type="help" text="Each block shows the total fertiliser applied each month. Click on the down arrow left of the block name to view each fertiliser application. Click on the block name to edit or add new applications to the block." />}

            {blockGroups.map((g, i) => (
                <BlockListGroup key={i} blockGroup={g} farm={farm} analysis={analysis} />
            ))}
            {blockList.nonProductiveBlocks.length > 0 && (
                <TileSubPanel title="Other blocks" stickyHeaderName="analysis.fertiliser.otherBlocks.collapsed">
                    {blockList.nonProductiveBlocks.map((block) => (
                        <BlockListCard key={block.id} block={block} farm={farm} analysis={analysis} />
                    ))}
                </TileSubPanel>
            )}
            {fertiliserProductModal}
        </>
    )
}
