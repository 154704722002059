import iconFarm from "../images/ic-block-farm.svg";
import iconBlockFruit from "../images/ic-block-fruit.svg";
import iconBlockCrop from "../images/ic-block-crop.svg";
import iconBlockPasture from "../images/ic-block-fodder.svg";
import iconBlockTrees from "../images/ic-block-trees.svg";
import iconRiparian from "../images/ic-block-riparian.svg";
import iconBlockStructure from "../images/ic-structure-1.svg";
import iconHelp from "../images/ic-help.svg";
import iconClose from "../images/ic-close.svg";
import iconInfo from "../images/ic-info.svg";

import iconAdd from "../images/ic-add.svg";
import iconSuccess from "../images/ic-success.svg";
import iconError from "../images/ic-error.svg";
import iconCancel from "../images/ic-cancel.svg";
import iconSearch from "../images/ic-search.svg";
import iconSearchMedium from "../images/ic-md-search.svg";
import iconClear from "../images/ic-cross.svg";
import iconBlocks from "../images/ic-blocks.svg";

import iconLogoLongFm from "../images/logo-fm-long.svg";
import iconLogoSmallFm from "../images/logo-fm-short.svg";
import iconLogoLongEd from "../images/logo-ed-long.svg";
import iconGototop from "../images/gototop.svg";
import iconPaystation from "../images/paystation-logo.png";

import iconVisa from "../images/visa-curved-32px.png";
import iconMastercard from "../images/mastercard-curved-32px.png";
import iconAmericanExpress from "../images/american-express-curved-32px.png";
import iconInvoice from "../images/invoice32px.png";
import iconLogoSmallEd from "../images/logo-ed-short.svg";
import iconLogoLongSci from "../images/logo-sci-long.svg";
import iconLogoSmallSci from "../images/logo-sci-short.svg";
import iconFmPrint from "../images/logo-fm-print.svg";
import iconSciPrint from "../images/logo-sci-print.svg";
import iconEdPrint from "../images/logo-ed-print.svg";
import iconMapBlock from "../images/ic-location-pointer-primary-light.svg";
import iconMapSelect from "../images/ic-location-pointer.svg";
import iconUnknown from "../images/_placeholder-30x30.svg";
import iconKiwifruit from "../images/ic-fruit-kiwifruit.svg";
import iconApple from "../images/ic-fruit-apples.svg";
import iconGrapes from "../images/ic-fruit-grapes.svg";
import iconAvocado from "../images/ic-fruit-avocado.svg";
import iconPeach from "../images/ic-fruit-peaches.svg";
import iconPasture from "../images/ic-grass-only.svg";
import iconSuppBaleage from "../images/ic-supp-baleage.svg";
import iconPriorFallow from "../images/ic-prior-fallow.svg";
import iconPriorCrop from "../images/ic-prior-crop.svg";
import iconGrassOnly from "../images/ic-grass-only.svg";
import iconFruitPeaches from "../images/ic-fruit-peaches.svg";
import iconFruitKiwifruit from "../images/ic-fruit-kiwifruit.svg";
import iconFruitGrapes from "../images/ic-fruit-grapes.svg";
import iconFruitAvocado from "../images/ic-fruit-avocado.svg";
import iconFruitApples from "../images/ic-fruit-apples.svg";
import iconCropsZucchini from "../images/ic-crops-Zucchini.svg";
import iconCropsWatermelon from "../images/ic-crops-watermelon.svg";
import iconCropsTurnip from "../images/ic-crops-Turnip.svg";
import iconCropsTurnipLeaf from "../images/ic-crops-turnipleaf.svg";
import iconCropsTulip from "../images/ic-crops-Tulip.svg";
import iconCropsTomato from "../images/ic-crops-Tomato.svg";
import iconCropsSweetcorn from "../images/ic-crops-Sweetcorn.svg";
import iconCropsSwede from "../images/ic-crops-Swede.svg";
import iconCropsSunflower from "../images/ic-crops-Sunflower.svg";
import iconCropsSquash from "../images/ic-crops-Squash.svg";
import iconCropsSpringonion from "../images/ic-crops-Spring-Onion.svg";
import iconCropsSpinach from "../images/ic-crops-Spinach.svg";
import iconCropsSilverbeet from "../images/ic-crops-Silver-Beet.svg";
import iconCropsSeed from "../images/ic-crops-seed.svg";
import iconCropsRootVego from "../images/ic-crops-root-vege.svg";
import iconCropsRockmelon from "../images/ic-crops-Rockmelon.svg";
import iconCropsRedonion from "../images/ic-crops-red-onion.svg";
import iconCropsPumpkin from "../images/ic-crops-Squash.svg";
import iconCropsPermanentPasture from "../images/ic-crops-premanent-pasture.svg";
import iconCropsPotatoe from "../images/ic-crops-potatoe.svg";
import iconCropsPeas from "../images/ic-crops-Peas.svg";
import iconCropsParsnip from "../images/ic-crops-Parsnip.svg";
import iconCropsPakchoi from "../images/ic-crops-Pak-choi.svg";
import iconCropsOnion from "../images/ic-crops-Onion.svg";
import iconCropsLupin from "../images/ic-crops-Lupin.svg";
import iconCropsLily from "../images/ic-crops-Lily.svg";
import iconCropsLentils from "../images/ic-crops-Lentils.svg";
import iconCropsLegume from "../images/ic-crops-legume-vege.svg";
import iconCropsLeek from "../images/ic-crops-Leek.svg";
import iconCropsKumera from "../images/ic-crops-kumera.svg";
import iconCropsHemp from "../images/ic-crops-Hemp.svg";
import iconCropsGreenVege from "../images/ic-crops-green-vege.svg";
import iconCropsGreenManure from "../images/ic-crops-green-manure.svg";
import iconCropsGrain from "../images/ic-crops-grain.svg";
import iconCropsGarlic from "../images/ic-crops-garlic.svg";
import iconCropsForages from "../images/ic-crops-forages.svg";
import iconCropsFodder from "../images/ic-crops-fodder.svg";
import iconCropsCoriander from "../images/ic-crops-Coriander.svg";
import iconCropsCelery from "../images/ic-crops-Celery.svg";
import iconCropsCauliflower from "../images/ic-crops-cauliflour.svg";
import iconCropsCabbage from "../images/ic-crops-Cabbage.svg";
import iconCropsBrusselSprouts from "../images/ic-crops-Brussel-Sprouts.svg";
import iconCropsBroccoli from "../images/ic-crops-Broccoli.svg";
import iconCropsBroadbeans from "../images/ic-crops-broad-Beans.svg";
import iconCropsBeets from "../images/ic-crops-Beets.svg";
import iconCropsBeans from "../images/ic-crops-beans.svg";
import iconCropsBareGround from "../images/ic-crops-bare-ground.svg";
import iconCropsRedClover from "../images/ic-crops-red-clover.svg";
import iconCropsRocket from "../images/ic-crops-Rocket.svg";
import iconCropsOats from "../images/ic-crops-Oats.svg";
import iconCropsHops from "../images/ic-crops-Hops.svg";
import iconCropsTriticale from "../images/ic-crops-triticale.svg";
import iconCropsLotus from "../images/ic-crops-Lotus.svg";
import iconCropsLettuce from "../images/ic-crops-Lettuce.svg";
import iconCropsPlantain from "../images/ic-crops-Plantain.svg";
import iconCropsChickpeas from "../images/ic-crops-chickpeas.svg";
import iconCropsSage from "../images/ic-crops-Sage.svg";
import iconCropsLucerne from "../images/ic-crops-Lucerne.svg";
import iconCropsPhalaris from "../images/ic-crops-Phalaris.svg";
import iconCropsTallFescue from "../images/ic-crops-Tall-fescue.svg";
import iconCropsTimothy from "../images/ic-crops-Timothy.svg";
import iconCropsPhacelia from "../images/ic-crops-Phacelia.svg";
import iconCropsCanola from "../images/ic-crops-Canola.svg";
import iconCropsLinseed from "../images/ic-crops-Linseed.svg";
import iconIrrigatorFlood from "../images/ic-irr-flood.svg";
import iconIrrigatorBorder from "../images/ic-irr-border.svg";
import iconIrrigatorKline from "../images/ic-irr-klines.svg";
import iconIrrigatorLinear from "../images/ic-irr-linear.svg";
import iconIrrigatorMicro from "../images/ic-irr-micro.svg";
import iconIrrigatorSet from "../images/ic-irr-solid-set.svg";
import iconIrrigatorSprayline from "../images/ic-irr-spraylines.svg";
import iconIrrigatorTravelling from "../images/ic-irr-travelling-irrigator.svg";
import iconCultivate1 from "../images/ic-cultivation-1.svg";
import iconCultivate2 from "../images/ic-cultivation-2.svg";
import iconCultivate3 from "../images/ic-cultivation-3.svg";
import iconCultivate4 from "../images/ic-cultivation-4.svg";
import iconSoil from "../images/ic-soil.svg";
import iconSnow from "../images/ic-snow.svg";
import iconPolygon from "../images/ic-polygon.svg";
import iconTempWarm from "../images/ic-temp-warm.svg";
import iconTempMed from "../images/ic-temp-med.svg";
import iconTempCold from "../images/ic-temp-cold.svg";
import iconRain from "../images/ic-rain.svg";
import iconSunny from "../images/ic-sunny.svg";
import iconCloudy from "../images/ic-cloudy.svg";
import iconFertiliserForm from "../images/ic-fert-form.svg";
import iconFertiliserLime from "../images/ic-fert-lime.svg";
import iconFertiliserOrganic from "../images/ic-fert-organic.svg";
import iconFertiliserProduct from "../images/ic-fert-prod.svg";
import iconFertiliserSoluble from "../images/ic-fert-soluble.svg";
import iconSheep from "../images/ic-animals-sheep.svg";
import iconSheepOutline from "../images/ic-animals-sheep-outline.svg";
import iconBeef from "../images/ic-animals-beef.svg";
import iconBeefOutline from "../images/ic-animals-beef-outline.svg";
import iconDeer from "../images/ic-animals-deer.svg";
import iconDefol from "../images/ic-animals-defol.svg";
import iconDairy from "../images/ic-animals-cow.svg";
import iconDairyOutline from "../images/ic-animals-dairy-outline.svg";
import iconPig from "../images/ic-animals-pig.svg";
import iconDairyGoat from "../images/ic-animals-goat.svg";
import iconDairyReplacements from "../images/ic-animals-dairy-replacements.svg";
import iconOther from "../images/ic-animals-other-animals.svg";
import iconMultiAnimal from "../images/ic-animals-variety.svg";
import iconMultiAnimalAlt from "../images/ic-animals-variety-alt.svg";
import iconSheepDeer from "../images/ic-animals-sheepanddeer.svg";
import iconSoilTest from "../images/ic-soil-test.svg";
import iconWetlandDrainage from "../images/ic-wetland-drainage.svg";
import iconHay from "../images/ic-supp-hay.svg";
import iconSilage from "../images/ic-supp-silage.svg";
import iconStorage from "../images/ic-storage.svg";
import iconOffFarm from "../images/ic-exported-1.svg";
import iconBaleage from "../images/ic-supp-baleage.svg";
import iconDirectFeeding from "../images/ic-supp-direct-feed.svg";
import iconSowing1 from "../images/ic-sowing-1.svg";
import iconSowing2 from "../images/ic-sowing-2.svg";
import iconSowing3 from "../images/ic-sowing-3.svg";
import iconSowing4 from "../images/ic-sowing-4.svg";
import iconHarvest1 from "../images/ic-harvesting-1.svg";
import iconHarvest2 from "../images/ic-harvesting-2.svg";
import iconCutAndCarry from "../images/ic-cutandcarry.svg";
import iconSpraying from "../images/ic-spraying.svg";
import iconDelete from "../images/ic-delete.svg";
import iconEdit from "../images/ic-edit.svg";
import iconEllipsis from "../images/ic-ellipsis.svg";
import iconSmallCultivation1 from "../images/ic-sm-cultivation-1.svg";
import iconSmallCultivation2 from "../images/ic-sm-cultivation-2.svg";
import iconSmallCultivation3 from "../images/ic-sm-cultivation-3.svg";
import iconSmallCultivation4 from "../images/ic-sm-cultivation-4.svg";
import iconSmallCutAndCarry from "../images/ic-sm-cutandcarry.svg";
import iconSmallFertProd from "../images/ic-sm-fert-prod.svg";
import iconSmallGrazing1 from "../images/ic-sm-grazing-1.svg";
import iconSmallGrazing2 from "../images/ic-sm-grazing-2.svg";
import iconSmallGrazing3 from "../images/ic-sm-grazing-3.svg";
import iconSmallHarvesting1 from "../images/ic-sm-harvesting-1.svg";
import iconSmallHarvesting2 from "../images/ic-sm-harvesting-2.svg";
import iconSmallSowing1 from "../images/ic-sm-sowing-1.svg";
import iconSmallSowing2 from "../images/ic-sm-sowing-2.svg";
import iconSmallSowing3 from "../images/ic-sm-sowing-3.svg";
import iconSmallSowing4 from "../images/ic-sm-sowing-4.svg";
import iconMilkingShed from "../images/ic-milking-shed.svg";
import iconFeedingPad from "../images/ic-structure-3.svg";
import iconWinteringPad from "../images/ic-structure-1.svg";
import iconStandoffPad from "../images/ic-structure-2.svg";
import iconFirefox from "../images/browser-firefox.png";
import iconChrome from "../images/browser-chrome.png";
import iconEdge from "../images/browser-edge.png";
import iconSafari from "../images/browser-safari.png";

export const milkingShed = iconMilkingShed;
export const feedingPad = iconFeedingPad;
export const winteringPad = iconWinteringPad;
export const standoffPad = iconStandoffPad;

export const farm = iconFarm;
export const fruitBlock = iconBlockFruit;
export const cropBlock = iconBlockCrop;
export const pastureBlock = iconBlockPasture;
export const treesAndScrubBlock = iconBlockTrees;
export const riparianBlock = iconRiparian;
export const structureBlock = iconBlockStructure;
export const help = iconHelp;

export const info = iconInfo;
export const add = iconAdd;
export const success = iconSuccess;
export const error = iconError;
export const cancel = iconCancel;
export const search = iconSearch;
export const searchMedium = iconSearchMedium;
export const clear = iconClear;
export const blocks = iconBlocks;

export const logo = () => {
    switch (process.env.REACT_APP_LOGO) {
        case "ed":
            return iconLogoLongEd;
        case "sc":
            return iconLogoLongSci;
        default:
            return iconLogoLongFm;
    }
};
export const logoSmall = () => {
    switch (process.env.REACT_APP_LOGO) {
        case "ed":
            return iconLogoSmallEd;
        case "sc":
            return iconLogoSmallSci;
        default:
            return iconLogoSmallFm;
    }
};

export const logoPrint = () => {
    switch (process.env.REACT_APP_LOGO) {
        case "ed":
            return iconEdPrint;
        case "sc":
            return iconSciPrint;
        default:
            return iconFmPrint;
    }
};
export const close = iconClose;

export const mapBlock = iconMapBlock;
export const mapSelect = iconMapSelect;

export const unknown = iconUnknown;
export const kiwifruit = iconKiwifruit;
export const apple = iconApple;
export const grapes = iconGrapes;
export const avocado = iconAvocado;
export const peach = iconPeach;
export const pasture = iconPasture;
export const suppBaleage = iconSuppBaleage;

export const priorFallow = iconPriorFallow;
export const priorCrop = iconPriorCrop;
export const grassOnly = iconGrassOnly;
export const fruitPeaches = iconFruitPeaches;
export const fruitKiwifruit = iconFruitKiwifruit;
export const fruitGrapes = iconFruitGrapes;
export const fruitAvocado = iconFruitAvocado;
export const fruitApples = iconFruitApples;
export const cropsZucchini = iconCropsZucchini;
export const cropsWatermelon = iconCropsWatermelon;
export const cropsTurnip = iconCropsTurnip;
export const cropsTurnipLeaf = iconCropsTurnipLeaf;
export const cropsTulip = iconCropsTulip;
export const cropsTomato = iconCropsTomato;
export const cropsSweetcorn = iconCropsSweetcorn;
export const cropsSwede = iconCropsSwede;
export const cropsSunflower = iconCropsSunflower;
export const cropsSquash = iconCropsSquash;
export const cropsSpringonion = iconCropsSpringonion;
export const cropsSpinach = iconCropsSpinach;
export const cropsSilverbeet = iconCropsSilverbeet;
export const cropsSeed = iconCropsSeed;
export const cropsRootVege = iconCropsRootVego;
export const cropsRockmelon = iconCropsRockmelon;
export const cropsRedonion = iconCropsRedonion;
export const cropsPumpkin = iconCropsPumpkin;
export const cropsPermanentPasture = iconCropsPermanentPasture;
export const cropsPotatoe = iconCropsPotatoe;
export const cropsPeas = iconCropsPeas;
export const cropsParsnip = iconCropsParsnip;
export const cropsPakchoi = iconCropsPakchoi;
export const cropsOnion = iconCropsOnion;
export const cropsLupin = iconCropsLupin;
export const cropsLily = iconCropsLily;
export const cropsLentils = iconCropsLentils;
export const cropsLegume = iconCropsLegume;
export const cropsLeek = iconCropsLeek;
export const cropsKumera = iconCropsKumera;
export const cropsHemp = iconCropsHemp;
export const cropsGreenVege = iconCropsGreenVege;
export const cropsGreenManure = iconCropsGreenManure;
export const cropsGrain = iconCropsGrain;
export const cropsGarlic = iconCropsGarlic;
export const cropsForages = iconCropsForages;
export const cropsFodder = iconCropsFodder;
export const cropsCoriander = iconCropsCoriander;
export const cropsCelery = iconCropsCelery;
export const cropsCauliflower = iconCropsCauliflower;
export const cropsCabbage = iconCropsCabbage;
export const cropsBrusselSprouts = iconCropsBrusselSprouts;
export const cropsBroccoli = iconCropsBroccoli;
export const cropsBroadbeans = iconCropsBroadbeans;
export const cropsBeets = iconCropsBeets;
export const cropsBeans = iconCropsBeans;
export const cropsBareground = iconCropsBareGround;
export const cropsRedClover = iconCropsRedClover;
export const cropsRocket = iconCropsRocket;
export const cropsOats = iconCropsOats;
export const cropsHops = iconCropsHops;
export const cropsTriticale = iconCropsTriticale;
export const cropsLotus = iconCropsLotus;
export const cropsLettuce = iconCropsLettuce;
export const cropsPlantain = iconCropsPlantain;
export const cropsSage = iconCropsSage;
export const cropsLucerne = iconCropsLucerne;
export const cropsPhacelia = iconCropsPhacelia;
export const cropsPhalaris = iconCropsPhalaris;
export const cropsTallFescue = iconCropsTallFescue;
export const cropsTimothy = iconCropsTimothy;
export const cropsChickpeas = iconCropsChickpeas;
export const cropsCanola = iconCropsCanola;
export const cropsLinseed = iconCropsLinseed;

export const irrigatorBorder = iconIrrigatorBorder;
export const irrigatorFlood = iconIrrigatorFlood;
export const irrigatorKline = iconIrrigatorKline;
export const irrigatorLinear = iconIrrigatorLinear;
export const irrigatorMicro = iconIrrigatorMicro;
export const irrigatorSet = iconIrrigatorSet;
export const irrigatorSprayline = iconIrrigatorSprayline;
export const irrigatorTravelling = iconIrrigatorTravelling;

export const soil = iconSoil;
export const tempWarm = iconTempWarm;
export const tempMed = iconTempMed;
export const tempCold = iconTempCold;

export const rain = iconRain;
export const sunny = iconSunny;
export const cloudy = iconCloudy;

export const effluentLiquid = iconFertiliserForm;
export const fertiliserLime = iconFertiliserLime;
export const fertiliserOrganic = iconFertiliserOrganic;
export const fertiliserProduct = iconFertiliserProduct;
export const fertiliserSoluble = iconFertiliserSoluble;
export const fertiliserGeneral = iconFertiliserForm;

export const snow = iconSnow;

export const sheep = iconSheep;
export const sheepOutline = iconSheepOutline;
export const pig = iconPig;
export const beef = iconBeef;
export const beefOutline = iconBeefOutline;
export const defol = iconDefol;
export const deer = iconDeer;
export const dairy = iconDairy;
export const dairyReplacements = iconDairyReplacements;
export const dairyOutline = iconDairyOutline;
export const dairyGoat = iconDairyGoat;
export const other = iconOther;
export const multiAnimal = iconMultiAnimal;
export const multiAnimalAlt = iconMultiAnimalAlt;
export const sheepDeer = iconSheepDeer;

export const soilTest = iconSoilTest;
export const wetlandDrainage = iconWetlandDrainage;

export const hay = iconHay;
export const silage = iconSilage;
export const storage = iconStorage;
export const offFarm = iconOffFarm;
export const baleage = iconBaleage;
export const directFeeding = iconDirectFeeding;

export const cultivate1 = iconCultivate1;
export const cultivate2 = iconCultivate2;
export const cultivate3 = iconCultivate3;
export const effluentSolid = iconCultivate4;

export const sowing1 = iconSowing1;
export const sowing2 = iconSowing2;
export const sowing3 = iconSowing3;
export const sowing4 = iconSowing4;

export const harvest1 = iconHarvest1;
export const harvest2 = iconHarvest2;
export const cutAndCarry = iconCutAndCarry;
export const spraying = iconSpraying;

export const deleteIcon = iconDelete;
export const editIcon = iconEdit;
export const ellipsisIcon = iconEllipsis;
export const smallCultivate1 = iconSmallCultivation1;
export const smallCultivate2 = iconSmallCultivation2;
export const smallCultivate3 = iconSmallCultivation3;
export const smallCultivate4 = iconSmallCultivation4;
export const smallCutAndCarry = iconSmallCutAndCarry;
export const smallFertProd = iconSmallFertProd;
export const smallGrazing1 = iconSmallGrazing1;
export const smallGrazing2 = iconSmallGrazing2;
export const smallGrazing3 = iconSmallGrazing3;
export const smallHarvest1 = iconSmallHarvesting1;
export const smallHarvest2 = iconSmallHarvesting2;
export const smallSowing1 = iconSmallSowing1;
export const smallSowing2 = iconSmallSowing2;
export const smallSowing3 = iconSmallSowing3;
export const smallSowing4 = iconSmallSowing4;

export const chrome = iconChrome;
export const edge = iconEdge;
export const firefox = iconFirefox;
export const safari = iconSafari;
export const gotoTop = iconGototop;

export const paystation = iconPaystation;
export const visa = iconVisa;
export const mastercard = iconMastercard;
export const americanExpress = iconAmericanExpress;
export const invoice = iconInvoice;
export const polygon = iconPolygon;
