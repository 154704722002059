import * as utils from "common/utils";

export const toTonnes = (amount, hectares) => {
    const result = {
        unit: {
            measuredIn: 't',
            of: amount?.unit?.of,
            perPeriod: amount?.unit?.perPeriod,
        }
    };

    if (amount?.unit?.per === 'ha') {
        result.value = amount.value * (hectares || 1);
    }

    if (amount?.unit?.measuredIn === 'kg') {
        result.value /= 1000;
    }

    result.value = utils.round(result.value, 1);
    result.unit.notation = getNotation(result.unit);

    return result;
}

export const getNotationWithoutOf = (unit) => {
    const result = {
        measuredIn: unit?.measuredIn,
        per: unit?.per,
        perPeriod: unit?.perPeriod
    };
    return getNotation(result);
}

const getNotation = (unit) => {
    let result = unit?.measuredIn || '';

    if (unit?.of && unit.of.length > 0)
        result += ` ${unit.of}`;

    if (unit?.per && unit.per.length > 0)
        result += `/${unit.per}`;

    if (unit?.perPeriod && unit.perPeriod.length > 0)
        result += `/${unit.perPeriod}`;

    return result;
}
