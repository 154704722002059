import React, { Component } from "react";
import Tile from "components/Tile";
import TileBody from "components/TileBody";
import TileFooter from "components/TileFooter";
import { Button } from "components/Button";
import InputPack from "components/InputPack";
import NumericInputPack from "components/NumericInputPack";
import SelectPack from "components/SelectPack";
import * as validations from "common/validations";
import { Link } from "react-router-dom";
import * as domain from "common/domain";
import * as utils from "common/utils";
import * as _utils from "./_utils";
import Alert from "components/Alert";
import SavePrompt from "components/SavePrompt";
import ActionLink from "components/ActionLink";
import ZendeskLink from "components/Help/ZendeskLink";
import { useRefData } from "common/hooks";
import { useUpdateAnalysisAsync } from "containers/hooks";

/**
 * Functional wrapper to wrap the old class component so we can use hooks
 */
export default function CatchmentDetails({ farm, analysis, block, wetland, isUnfenced, isNew }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis);

    return <CatchmentDetailsClassComponent farm={farm} analysis={analysis} block={block} wetland={wetland} isUnfenced={isUnfenced} isNew={isNew} refData={refData} updateAnalysisAsync={updateAnalysisAsync} />;
}

class CatchmentDetailsClassComponent extends Component {
    constructor(props) {
        super(props);
        const hasBlockPercentages = props.wetland.catchmentPercentages && props.wetland.catchmentPercentages.length > 0 ? true : false;

        if (hasBlockPercentages) {
            /*eslint-disable no-unused-vars*/
            for (const cp of props.wetland.catchmentPercentages) {
                cp.area = utils.truncateDecimal(calcBlockArea(cp, props.wetland), 1);
            }
        }

        this.state = {
            showDistribution: hasBlockPercentages,
            block: props.block || {},
            wetland: props.wetland,
            isEdit: props.wetland.catchmentArea > 0,
            validation: props.wetland.catchmentArea > 0 ? this.validate({}, props.block, props.wetland, props.isUnfenced) : {},
            isUnfenced: props.isUnfenced,
            dirty: false,
            submitting: false,
            submitSucceeded: false,
        };
    }

    toggleDistribution() {
        let wetland = this.state.wetland;
        if (!wetland.catchmentPercentages || wetland.catchmentPercentages.length === 0) {
            wetland.catchmentPercentages = [{ blockId: undefined, percentage: undefined }];
        }
        this.setState({ showDistribution: !this.state.showDistribution, wetland: wetland });
    }

    removeBlock(index) {
        const wetland = this.state.wetland;
        wetland.catchmentPercentages.splice(index, 1);
        this.setState({ wetland: wetland });
    }

    addBlock() {
        const wetland = this.state.wetland;
        wetland.catchmentPercentages.push({});
        this.setState({ wetland: wetland });
    }

    onChange(e, source) {
        const block = this.state.block;
        const wetland = this.state.wetland;
        switch (source.type) {
            case "block":
                block[source.key] = e.target.value;
                break;
            case "wetland":
                wetland[source.key] = e.target.value;
                if (source.key === "catchmentArea") {
                    (wetland.catchmentPercentages || []).forEach((cp) => {
                        cp.area = utils.round(calcBlockArea(cp, wetland), 1);
                    });
                }
                break;
            case "catchmentPercentage":
                wetland.catchmentPercentages[source.index][source.key] = e.target.value;
                if (!isNaN(wetland.catchmentPercentages[source.index][source.key])) {
                    wetland.catchmentPercentages[source.index].area = utils.round(calcBlockArea(wetland.catchmentPercentages[source.index], wetland), 1);
                }
                break;
            case "catchmentArea":
                wetland.catchmentPercentages[source.index][source.key] = e.target.value;
                if (!isNaN(wetland.catchmentPercentages[source.index][source.key])) {
                    const area = utils.truncateDecimal((wetland.catchmentPercentages[source.index][source.key] / wetland.catchmentArea) * 100, 1);
                    wetland.catchmentPercentages[source.index].percentage = isNaN(area) ? 0 : utils.truncateDecimal(area, 0);
                }
                break;
            default:
                console.log("No matching source type");
                break;
        }
        this.isValid(block, wetland, source);
        this.setState({ block, dirty: true });
    }

    validate(currentValidation, block, wetland, isUnfenced, source = undefined) {
        let validation = {};
        let message = undefined;
        let touched = false;

        const name = isUnfenced ? wetland.name : block.name;
        message = validations.required(name);
        message = message || validations.minLength(1)(name);
        message = message || validations.maxLength(50)(name);
        touched = isTouched(currentValidation, source, "name");
        validation.name = { touched: touched, error: message !== undefined, message: message };

        if (!isUnfenced && block.areaInHectares < wetland.effectiveArea) {
            message = `Wetland area must be less than or equal to the block size of ${block.areaInHectares} ha`;
        }
        message = validations.required(wetland.effectiveArea);
        message = message || validations.isNumeric(wetland.effectiveArea);
        message = message || validations.range(1, 40000)(wetland.effectiveArea);
        touched = isTouched(currentValidation, source, "effectiveArea");
        validation.effectiveArea = { touched: touched, error: message !== undefined, message: message };

        message = validations.required(wetland.catchmentArea);
        message = message || validations.isNumeric(wetland.catchmentArea);
        message = message || validations.range(1, 40000)(wetland.catchmentArea);
        message = message || (parseFloat(wetland.catchmentArea) < parseFloat(wetland.effectiveArea) ? "Catchment area cannot be less than the wetland area" : undefined);
        touched = isTouched(currentValidation, source, "catchmentArea");
        validation.catchmentArea = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(wetland.catchmentConvergence);
        touched = isTouched(currentValidation, source, "catchmentConvergence");
        validation.catchmentConvergence = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(wetland.catchmentAquitardDepth);
        touched = isTouched(currentValidation, source, "catchmentAquitardDepth");
        validation.catchmentAquitardDepth = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(wetland.condition);
        touched = isTouched(currentValidation, source, "condition");
        validation.condition = { touched: touched, error: message !== undefined, message: message };

        message = validations.valueNotEmpty(wetland.type);
        touched = isTouched(currentValidation, source, "type");
        validation.type = { touched: touched, error: message !== undefined, message: message };

        let catchmentTotalPercentage = 0;
        if (wetland.catchmentPercentages) {
            catchmentTotalPercentage = wetland.catchmentPercentages.reduce((a, b) => a + parseInt(b.percentage, 10), 0);
            if (catchmentTotalPercentage < 10) {
                validation.distribution = { touched: true, error: true, message: "At least 10% of the flow must come from within the farm" };
            } else if (catchmentTotalPercentage > 100) {
                validation.distribution = { touched: true, error: true, message: "Sum of catchment percentages must be less than or equal to 100" };
            } else if (catchmentTotalPercentage === 100) {
                wetland.wetlandOffFarmType = "Undefined";
                wetland.offfarmPercentage = undefined;
            }
            /*eslint-disable no-unused-vars*/
            for (const cp of wetland.catchmentPercentages) {
                let key = `${cp.blockId}.percentage`;
                message = validations.required(cp.percentage);
                message = message || validations.isNumeric(cp.percentage);
                message = message || validations.range(1, 100)(cp.percentage);
                touched = isTouched(currentValidation, source, key);
                validation[key] = { touched: touched, error: message !== undefined, message: message };

                key = `${cp.blockId}.area`;
                const cpBlock = this.props.analysis.blocks.find((b) => b.id === cp.blockId);
                const remainingBlockArea = _utils.getAvailableBlockArea(this.props.analysis, cpBlock, wetland.id);
                message = cpBlock && cp.area > remainingBlockArea ? "Area cannot be greater than the available block area" : undefined;
                //used touched from percentage
                validation[key] = { touched: touched, error: message !== undefined, message: message };

                key = `${cp.blockId}.blockId`;
                message = validations.valueNotEmpty(cp.blockId);
                touched = isTouched(currentValidation, source, key);
                validation[key] = { touched: touched, error: message !== undefined, message: message };
            }

            if (catchmentTotalPercentage !== 100) {
                message = validations.valueNotEmpty(wetland.wetlandOffFarmType);
                message = message || (wetland.wetlandOffFarmType === "Undefined" ? "Required" : undefined);
                touched = isTouched(currentValidation, source, "wetlandOffFarmType");
                validation.wetlandOffFarmType = { touched: touched, error: message !== undefined, message: message };
            }
        }

        return validation;
    }

    isValid(block, wetland, source = undefined) {
        const validation = this.validate(this.state.validation, block, wetland, this.state.isUnfenced, source);
        this.setState({ validation: validation });
        /*eslint-disable no-unused-vars*/
        for (let key of Object.keys(validation)) {
            if (validation[key].error) {
                return false;
            }
        }
        return true;
    }

    async save() {
        const block = this.state.block;
        const wetland = this.state.wetland;
        if (!this.state.showDistribution) {
            wetland.catchmentPercentages = undefined;
            wetland.wetlandOffFarmType = "Undefined";
            wetland.offfarmPercentage = undefined;
        }
        if (this.isValid(block, wetland)) {
            this.setState({ submitting: true, submitSucceeded: false });

            const analysis = this.props.analysis;
            if (this.state.isUnfenced) {
                if (!analysis.wetlands) {
                    analysis.wetlands = [];
                }
                if (analysis.wetlands.some((w) => w.id === wetland.id)) {
                    analysis.wetlands = analysis.wetlands.map((w) => (w.id === wetland.id ? wetland : w));
                } else {
                    analysis.wetlands.push(wetland);
                }
            } else {
                if (!analysis.blocks) {
                    analysis.blocks = [];
                }
                if (analysis.blocks.some((b) => b.id === block.id)) {
                    analysis.blocks = analysis.blocks.map((b) => (b.id === block.id ? block : b));
                } else {
                    analysis.blocks.push(block);
                }
            }
            await this.props.updateAnalysisAsync(analysis);

            this.setState({ submitting: false, submitSucceeded: true });
        }
    }

    render() {
        const _referrer = `/app/farm/${this.props.farm.id}/analysis/${this.props.analysis.id}/drainage`;
        const distVal = this.state.validation.distribution || {};
        const allConditions = this.props.refData.wetlandConditionType;
        const wetlandConditions = this.state.isUnfenced ? (allConditions && allConditions.filter((w) => domain.WetlandCondition.Natural.key !== w.value)) || [] : (allConditions && allConditions.filter((w) => [domain.WetlandCondition.Natural.key, domain.WetlandCondition.Artificialtype1.key, domain.WetlandCondition.Artificialtype2.key, domain.WetlandCondition.Artificialtype3.key].includes(w.value))) || [];
        const wetlandTypes = this.props.refData.wetlandType || [];
        const wetlandCondition = domain.WetlandCondition[this.state.wetland.condition];
        const wetlandType = domain.WetlandType[this.state.wetland.type];
        const usedBlocks = this.state.wetland.catchmentPercentages ? this.state.wetland.catchmentPercentages.map((b) => b.blockId) : [];
        const blocks = this.props.analysis.blocks.filter((b) => b.id !== this.state.block.id && ![domain.BlockType.NonProductiveWetland, domain.BlockType.FodderCrop].includes(b.type));
        let offfarmArea = 0;
        let offfarmPercentage = 100;
        const totalPercentage = this.state.wetland.catchmentPercentages ? this.state.wetland.catchmentPercentages.reduce((a, b) => a + parseInt(b.percentage, 10), 0) : 0;
        if (this.state.wetland.catchmentPercentages) {
            let blockTotalArea = 0;
            /*eslint-disable no-unused-vars*/
            for (const cp of this.state.wetland.catchmentPercentages) {
                if (!isNaN(cp.percentage)) {
                    blockTotalArea += parseFloat(cp.area);
                    offfarmPercentage -= parseFloat(cp.percentage);
                }
            }
            offfarmArea = this.state.wetland.catchmentArea >= blockTotalArea ? utils.truncateDecimal(this.state.wetland.catchmentArea - blockTotalArea, 1) : 0;
            offfarmPercentage = offfarmPercentage < 0 ? 0 : utils.truncateDecimal(offfarmPercentage, 1);
        }
        const pageTitle = this.state.isUnfenced ? `${this.state.wetland.name ? `${this.state.wetland.name} - ` : ""}Unfenced wetland catchment details` : `${this.state.block.name} - Wetland catchment details`;

        const catchmentTotalPercentage = this.state.wetland.catchmentPercentages ? this.state.wetland.catchmentPercentages.reduce((a, b) => a + parseInt(b.percentage, 10), 0) : 0;

        const addBlockAction = usedBlocks.length < blocks.filter((b) => b.type !== domain.BlockType.NonProductiveRiparian).length && (
            <span id="wetland-catchment-addblock-top" className="IconLink--arrow-plus u-link" onClick={(e) => this.addBlock()}>
                Add another block
            </span>
        );

        const infoText = (
            <div>
                <span>Fenced off wetlands should be entered as a wetland block. If artificial wetlands are used to treat outlets, these should be added to a blocks drainage details. It is important that the same wetland is not included more then once.</span>
                <br />
                <span>
                    {" "}
                    For more detailed information on creating wetlands,{" "}
                    <b>
                        <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000932226" />
                    </b>
                </span>
            </div>
        );

        return (
            <div>
                <SavePrompt blockIf={this.state.dirty && !this.state.submitSucceeded} redirectIf={this.state.submitSucceeded} redirectTo={_referrer} />
                <Tile title={pageTitle} waiting={this.state.submitting} referrer={_referrer}>
                    <Alert type="info" text={infoText} />
                    <TileBody className="u-pt-0">
                        <div className="Grid Grid--withGutter">
                            <div className="Grid-cell">
                                <h3 className="u-mt-0">Wetland details</h3>
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <InputPack id="name" name="name" type="text" label="Wetland name" value={this.state.isUnfenced ? this.state.wetland.name : this.state.block.name} onChange={(e) => this.onChange(e, { type: this.state.isUnfenced ? "wetland" : "block", key: "name" })} meta={{ nrf: true }} val={this.state.validation.name} requiredLabel={true} placeholder="Enter the name for this wetland" />
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <InputPack id="effectiveArea" name="effectiveArea" type="text" label="Wetland area" value={this.state.wetland.effectiveArea} onChange={(e) => this.onChange(e, { type: "wetland", key: "effectiveArea" })} uom="ha" meta={{ nrf: true }} val={this.state.validation.effectiveArea} requiredLabel={true} placeholder="Enter the total wetland area" />
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <SelectPack name="condition" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "wetland", key: "condition" })} value={this.state.wetland.condition} val={this.state.validation.condition} id="condition" label="Condition" requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select a wetland condition
                                    </option>
                                    {this.state.isUnfenced && (
                                        <optgroup label="Natural">
                                            {wetlandConditions
                                                .filter((w) => !w.value.startsWith("Artificial"))
                                                .map((item) => (
                                                    <option value={item.value} key={item.value}>
                                                        {item.text}
                                                    </option>
                                                ))}
                                        </optgroup>
                                    )}
                                    {this.state.isUnfenced && (
                                        <optgroup label="Artificial">
                                            {wetlandConditions
                                                .filter((w) => w.value.startsWith("Artificial"))
                                                .map((item) => (
                                                    <option value={item.value} key={item.value}>
                                                        {item.text}
                                                    </option>
                                                ))}
                                        </optgroup>
                                    )}
                                    {!this.state.isUnfenced &&
                                        wetlandConditions.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                </SelectPack>
                                {wetlandCondition && <Alert type="info" className="u-mb-0" text={wetlandCondition.text} bold={wetlandCondition.bold} />}
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <SelectPack name="type" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "wetland", key: "type" })} value={this.state.wetland.type} val={this.state.validation.type} id="type" label="Wetland type" requiredLabel={true}>
                                    <option value="" disabled={true}>
                                        Select a wetland type
                                    </option>
                                    {wetlandTypes &&
                                        wetlandTypes.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                </SelectPack>
                                {wetlandType && (
                                    <Alert
                                        type="info"
                                        className="u-mb-0"
                                        text={
                                            <div>
                                                <b>Wetness</b> <span>- {wetlandType.wetness}</span>
                                                <br />
                                                <b>Vegetation</b> <span>- {wetlandType.vegetation}</span>
                                                <br />
                                                <b>Stock</b> <span>- {wetlandType.stock}</span>
                                            </div>
                                        }
                                    />
                                )}
                            </div>

                            <div className="Grid-cell u-mt-md">
                                <h3>Catchment details</h3>
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <NumericInputPack id="catchmentArea" name="catchmentArea" type="text" label="Catchment area" value={this.state.wetland.catchmentArea} onChange={(e) => this.onChange(e, { type: "wetland", key: "catchmentArea" })} meta={{ nrf: true }} uom="ha" decimalPlaces={0} val={this.state.validation.catchmentArea} requiredLabel={true} placeholder="Enter the size of the catchment for this wetland" />
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <SelectPack
                                    name="catchmentConvergence"
                                    meta={{ nrf: true }}
                                    onChange={(e) => this.onChange(e, { type: "wetland", key: "catchmentConvergence" })}
                                    value={this.state.wetland.catchmentConvergence}
                                    val={this.state.validation.catchmentConvergence}
                                    id="catchment-convergence"
                                    label="Catchment convergence"
                                    requiredLabel={true}
                                    tip={
                                        <div>
                                            <span>Wetland convergence is a measure of the percentage of shallow runoff (surface and sub-surface drainage) that flows into a wetland. The rest enters the stream directly. This depends on the landscape that drains towards the wetland.</span>
                                            <br />
                                            <span>If the topography converges towards the viewer, then we suggest using a High convergence. In easy country, most flow converges into ephemeral channels and if these are well vegetated and ideally fenced, then convergence can be high.</span>
                                            <br />
                                            <span>If the viewer is in a small depression to which only a small proportion of the property drains AND the other parts of the property do not drain to wetlands, then we suggest using Low convergence.</span>
                                        </div>
                                    }
                                >
                                    <option value="" disabled={true}>
                                        Select a catchment convergence
                                    </option>
                                    {this.props.refData.wetlandConvergence.map((w) => (
                                        <option value={w.value} key={w.value}>
                                            {w.text}
                                        </option>
                                    ))}
                                </SelectPack>
                            </div>
                            <div className="Grid-cell u-lg-width1of2 u-xl-width1of4">
                                <SelectPack
                                    name="catchmentAquitardDepth"
                                    meta={{ nrf: true }}
                                    onChange={(e) => this.onChange(e, { type: "wetland", key: "catchmentAquitardDepth" })}
                                    value={this.state.wetland.catchmentAquitardDepth}
                                    val={this.state.validation.catchmentAquitardDepth}
                                    uom="m"
                                    id="wetland-aquitard"
                                    label="Aquitard depth"
                                    requiredLabel={true}
                                    tip={
                                        <div>
                                            <span>Aquitard depth is the depth down to the soil layer that is impervious to soil water, or where soil drainage is very slow. Seepage from road cuttings, or the depth of a hard layer in post holes may indicate the aquitard depth. In pallic soils, aquitard depth is usual less than 1m</span>
                                        </div>
                                    }
                                >
                                    <option value="" disabled={true}>
                                        Select an aquitard depth
                                    </option>
                                    {this.props.refData.wetlandAquitard.map((w) => (
                                        <option value={w.value} key={w.value}>
                                            {w.text}
                                        </option>
                                    ))}
                                </SelectPack>
                            </div>
                            <div className="Grid-cell">
                                <div className="Field">
                                    <label htmlFor="specify-distribution" className="Checkbox">
                                        <input id="specify-distribution" type="checkbox" className="Checkbox-input" onChange={(e) => this.toggleDistribution()} checked={this.state.showDistribution} />
                                        <span className="Checkbox-label">Specify distribution of catchment area across the blocks on this farm</span>
                                    </label>
                                </div>
                                {this.state.showDistribution && (
                                    <Tile title="Catchment distribution" actions={addBlockAction} indent padTop tertiary>
                                        <Alert type="info" text="Add blocks that are part of the catchment for this wetland. Any remaining area will be attributed to an off farm source." />
                                        <TileBody>
                                            {this.state.wetland.catchmentPercentages.map((p) => {
                                                let index = this.state.wetland.catchmentPercentages.indexOf(p);
                                                return <CatchmentPercentageCard wetland={this.state.wetland} catchmentPercentage={p} key={index} index={index} analysis={this.props.analysis} blocks={blocks} usedBlocks={usedBlocks} showAdd={true} showRemove={this.state.wetland.catchmentPercentages.length > 1} removeBlock={(i) => this.removeBlock(i)} validation={this.state.validation} onChange={(e, source) => this.onChange(e, source)} />;
                                            })}
                                            <div className="Field-group">
                                                <SelectPack name="wetlandOffFarmType" meta={{ nrf: true }} onChange={(e) => this.onChange(e, { type: "wetland", key: "wetlandOffFarmType" })} value={this.state.wetland.wetlandOffFarmType} val={this.state.validation.wetlandOffFarmType} id="wetlandOffFarmType" label="Off farm source" dataWidth="30" disabled={catchmentTotalPercentage >= 100} requiredLabel={true}>
                                                    <option value="Undefined" disabled={true}>
                                                        Select an off farm source
                                                    </option>
                                                    {this.props.refData.wetlandOffFarmType.map((w) => (
                                                        <option value={w.value} key={w.value}>
                                                            {w.text}
                                                        </option>
                                                    ))}
                                                </SelectPack>

                                                <InputPack id="offfarmPercentage" name="offfarmPercentage" type="text" dataWidth="25" uom="%" value={isNaN(offfarmPercentage) ? 0 : offfarmPercentage} meta={{ nrf: true }} disabled={true} />

                                                <InputPack id="offfarmArea" name="offfarmArea" type="text" dataWidth="25" value={offfarmArea} uom="ha" meta={{ nrf: true }} disabled={true} />
                                                <div className="Field" data-width="20"></div>
                                            </div>
                                            <div className="Field-group">
                                                <div className="Field" data-width="30">
                                                    <label className="Field-label">Totals</label>
                                                </div>
                                                <div className="Field" data-width="25">
                                                    <div id="offfarm-percent">
                                                        <span>
                                                            <b>{isNaN(totalPercentage) || totalPercentage < 100 ? 100 : totalPercentage}</b> %
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="Field" data-width="25">
                                                    <div id="offfarm-area">
                                                        <span>
                                                            <b>{this.state.wetland.catchmentArea}</b> ha
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="Field" data-width="20"></div>
                                            </div>
                                            <div className={"Field" + (distVal.touched && distVal.error ? " has-error" : "")}>
                                                <small className="Field-error" id="distribution-error">
                                                    {distVal.message}
                                                </small>
                                            </div>
                                        </TileBody>
                                    </Tile>
                                )}
                            </div>
                        </div>
                    </TileBody>
                    <TileFooter>
                        <div className="ButtonBar ButtonBar--fixed">
                            <div className="ButtonBar-left">
                                <Link to={_referrer} className="Button Button--secondary">
                                    Cancel
                                </Link>
                            </div>
                            <div className="ButtonBar-right">
                                <Button id="fertiliser-details-submit" submit primary waiting={this.state.submitting} onClick={(e) => this.save()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </TileFooter>
                </Tile>
            </div>
        );
    }
}

const isTouched = (validation, source, id) => (validation && validation[id] && validation[id].touched) || source === undefined || source.key === id || source.id === id;
const calcBlockArea = (cp, wetland) => (isNaN(cp.percentage) || isNaN(wetland.catchmentArea) ? 0 : wetland.catchmentArea * (cp.percentage / 100));

const CatchmentPercentageCard = ({ analysis, wetland, catchmentPercentage, blocks, usedBlocks, index, onChange, showRemove, showAdd, removeBlock, validation }) => {
    const blockVal = validation[`${catchmentPercentage.blockId}.blockId`] || {};
    const block = blocks.find((b) => b.id === catchmentPercentage.blockId);
    const blockArea = utils.round(_utils.getAvailableBlockArea(analysis, block, wetland.id), 1);
    return (
        <div className="Field-group" key={index}>
            <div className={"Field" + (blockVal.touched && blockVal.error ? " has-error" : "")} data-width="30">
                <label className="Select Select--block">
                    <span className="Field-label">
                        {index === 0 && "Block"}
                        {index === 0 && <sup className="required">*</sup>}
                    </span>
                    <select className="is-unpopulated" name={`${catchmentPercentage.blockId}.blockId`} id={`${catchmentPercentage.blockId}.blockId`} onChange={(e) => onChange(e, { type: "catchmentPercentage", key: "blockId", index: index, id: `${catchmentPercentage.blockId}.blockId` })} value={catchmentPercentage.blockId || ""}>
                        <option value="" disabled={true}>
                            Select a block
                        </option>
                        {blocks
                            .filter((b) => b.type !== domain.BlockType.ProductiveOutdoorPigs && b.type !== domain.BlockType.NonProductiveRiparian && (!usedBlocks.includes(b.id) || b.id === catchmentPercentage.blockId))
                            .map((w) => (
                                <option value={w.id} key={w.id}>
                                    {w.name}
                                </option>
                            ))}
                    </select>
                </label>
                <small className="Field-error" id={`${catchmentPercentage.blockId}.blockId-error`}>
                    {blockVal.message}
                </small>
            </div>
            <NumericInputPack id={`${catchmentPercentage.blockId}.percentage`} name={`${index}.percentage`} meta={{ nrf: true }} type="text" value={catchmentPercentage.percentage} onChange={(e) => onChange(e, { type: "catchmentPercentage", key: "percentage", index: index })} label={index === 0 && "Catchment percentage"} requiredLabel={index === 0} val={validation[`${catchmentPercentage.blockId}.percentage`]} placeholder="% in this block" decimalPlaces={0} uom="%" dataWidth="25" />
            <NumericInputPack id={`${catchmentPercentage.blockId}.area`} name={`${index}.area`} meta={{ nrf: true }} type="text" value={catchmentPercentage.area} uom="ha" onChange={(e) => onChange(e, { type: "catchmentArea", key: "area", index: index })} label={index === 0 && "Allocated catchment area"} val={validation[`${catchmentPercentage.blockId}.area`]} decimalPlaces={1} requiredLabel={index === 0} placeholder="Area in this block" dataWidth="25" />

            <InputPack id={`${catchmentPercentage.blockId}.blockArea`} name={`${index}.blockArea`} meta={{ nrf: true }} type="text" value={blockArea} disabled={true} uom="ha" label={index === 0 && "Available block area"} placeholder="-" dataWidth="15" />

            <div className="Field" data-width="5">
                {showRemove && (
                    <div className="u-pt-md">
                        <ActionLink className="IconLink--cross-circle" onClick={() => removeBlock(index)}></ActionLink>
                    </div>
                )}
            </div>
        </div>
    );
};
