import { clear } from "common/icons";
import Alert from "components/Alert";
import ActionLink from "components/ActionLink";
import { useFieldHelp } from "./useFieldHelp";

export default function TextField({ input, id, label, placeholder, uom, className, dataWidth, onChange, onBlur, onClick, info, warning, tip, maxLength, autoFocus, readOnly, disabled, required, meta, button, clearable, onClear, hideError }) {
    const [fieldHelpLink, fieldHelp] = useFieldHelp(tip);

    const handleOnChange = (e) => {
        const value = input.type === "number" ? Number(e.target.value) : e.target.value;

        if (input.onChange) input.onChange(value);

        if (onChange) onChange(value);
    };

    const handleOnClick = (e) => {
        if (input.onClick) input.onClick(e);

        if (onClick) onClick(e);
    };

    const handleOnBlur = (e) => {
        const value = input.type === "number" ? Number(e.target.value) : e.target.value;

        if (input.onBlur) input.onBlur(value);

        if (onBlur) onBlur(value);
    };

    const clearValue = () => {
        if (input.onChange) input.onChange(undefined);

        if (onChange) onChange(undefined);

        if (onClear) onClear();
    };

    const error = meta && meta.touched && meta.error;

    const htmlId = id || input.name;

    return (
        <div className={`Field ${error ? "has-error" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            {label && (
                <label htmlFor={htmlId} className={`Field-label ${disabled && !readOnly ? "is-disabled" : ""}`}>
                    {label}
                    {required && <sup className="required">*</sup>}
                    {fieldHelpLink}
                </label>
            )}
            <div className="Input_wrapper">
                <input {...input} id={htmlId} value={input.value || ""} data-test-id={htmlId} type={input.type || "text"} step={input.type === "number" ? "any" : undefined} placeholder={placeholder || label} className={`Input Input--block ${clearable ? "has-value has-clear" : ""}`} maxLength={maxLength} autoFocus={autoFocus} readOnly={readOnly} disabled={disabled} onChange={handleOnChange} onClick={handleOnClick} onBlur={handleOnBlur} />
                {clearable && (
                    <ActionLink id="searchClear" className="Input-clear" title="Clear" onClick={clearValue}>
                        <img src={clear} height="24px" width="24px" className="u-p-5" alt="Clear" />
                    </ActionLink>
                )}
                {uom && <small className="Input-helper">{uom}</small>}
                {button && <span className="Input-button">{button}</span>}
            </div>
            {error && !hideError && (
                <div id={`${htmlId}-error`} data-test-id={`${htmlId}-error`} className="Field-error u-block u-mt-sm">
                    {error}
                </div>
            )}
            {warning && <Alert type="warning" text={warning} className="u-mb-0" />}
            {info && <Alert type="info" text={info} className="u-mb-0" />}
            {fieldHelp}
        </div>
    );
}
