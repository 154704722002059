import { Link } from "react-router-dom";
import { canAddStructure } from "./_utils";
import { requiresOutdoorPigEffluentSystem } from "common/viewModels";
import ZendeskLink from "components/Help/ZendeskLink";
import ScenarioToolLink from "containers/ScenarioTool/ScenarioToolLink";

export default function EffluentActionBar({ farm, analysis, hasDairy, hasCows, hasOutdoorPigs }) {
    const dairyEffluentSystem = hasDairy || analysis.effluentSystem;
    const milkingShed = hasDairy && canAddStructure(analysis, hasDairy, "MilkingShed");
    const feedingPad = hasCows && canAddStructure(analysis, hasDairy, "FeedingPad");
    const coveredWinteringPad = canAddStructure(analysis, hasDairy, "CoveredWinteringPad");
    const uncoveredWinteringPad = canAddStructure(analysis, hasDairy, "UncoveredWinteringPad");
    const standoffPad = hasCows && canAddStructure(analysis, hasDairy, "StandoffPad");
    const showOutdoorPigEffluentSystem = (analysis.structures || []).filter((s) => ["OutdoorPigBarns", "OutdoorPigHuts", "OutdoorPigFarrowingVillages"].includes(s.type)).some((s) => requiresOutdoorPigEffluentSystem(s));
    const showOutdoorPigFarrowingVillage = hasOutdoorPigs && canAddStructure(analysis, hasDairy, "OutdoorPigFarrowingVillages");
    const showOutdoorPigBarn = hasOutdoorPigs && canAddStructure(analysis, hasDairy, "OutdoorPigBarns");
    const hasOptions = dairyEffluentSystem || milkingShed || feedingPad || coveredWinteringPad || uncoveredWinteringPad || standoffPad || hasOutdoorPigs;

    return (
        <div className="ActionsBar ActionsBar--super u-print-none">
            {hasOptions ? (
                <div className="ActionsBar-left">
                    <ul className="ActionsBar-links">
                        {dairyEffluentSystem && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/effluent`} className="IconLink--settings" id="create-effluent-link">
                                    Dairy effluent system
                                </Link>
                            </li>
                        )}
                        {showOutdoorPigEffluentSystem && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigEffluent`} className="IconLink--settings" id="create-pig-effluent-link">
                                    Outdoor pigs effluent system
                                </Link>
                            </li>
                        )}
                        {milkingShed && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=MilkingShed`} className="IconLink--arrow-plus" id="create-milk-link">
                                    Define feeding for milking shed
                                </Link>
                            </li>
                        )}
                        {feedingPad && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=FeedingPad`} className="IconLink--arrow-plus" id="create-feedpad-link">
                                    Add feed pad
                                </Link>
                            </li>
                        )}
                        {(coveredWinteringPad || uncoveredWinteringPad) && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=WinteringPad`} className="IconLink--arrow-plus" id="create-winteringpad-link">
                                    Add wintering pad/shelter
                                </Link>
                            </li>
                        )}
                        {standoffPad && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/structure?type=StandoffPad`} className="IconLink--arrow-plus" id="create-standoff-link">
                                    Add standoff pad
                                </Link>
                            </li>
                        )}
                        {showOutdoorPigFarrowingVillage && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigFarrowingVillages`} className="IconLink--arrow-plus" id="create-farrowingvillage-link">
                                    Add outdoor pig farrowing village
                                </Link>
                            </li>
                        )}
                        {showOutdoorPigBarn && (
                            <li>
                                <Link to={`/app/farm/${farm.id}/analysis/${analysis.id}/structures/outdoorPigBarns`} className="IconLink--arrow-plus" id="create-pigshed-link">
                                    Add outdoor pig shed/barn
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            ) : null}
            <div className="ActionsBar-right">
                <ul className="ActionsBar-links">
                    {process.env.REACT_APP_DISABLE_SCENARIO_TOOL !== "true" && (
                        <li>
                            <ScenarioToolLink farm={farm} analysis={analysis} />
                        </li>
                    )}
                    <li>
                        <ZendeskLink title="Page help" url="https://overseer-helpdesk.zendesk.com/hc/en-us/articles/900000961986" className="IconLink--new-tab u-font-green" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
