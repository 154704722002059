import { HashLink } from "react-router-hash-link";
import { useRefData } from "common/hooks";
import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { useOverseerModelRun } from "containers/BudgetHome";

export default function Blocks({ analysis }) {
    const refData = useRefData();
    const { data: overseerModelRun } = useOverseerModelRun(analysis);

    const netFarmNitrogenLoss = overseerModelRun?.modelResults?.farm?.nitrogen?.netTotalLoss?.value;

    const totalNNotation = overseerModelRun?.modelResults?.blocks?.[0]?.nitrogen?.totalLoss?.unit?.notation;
    const averageNNotation = overseerModelRun?.modelResults?.blocks?.[0]?.nitrogen?.averageLoss?.unit?.notation;
    const drainageNNotation = overseerModelRun?.modelResults?.blocks?.[0]?.nitrogen?.drainage?.unit?.notation;
    const surplusNNotation = overseerModelRun?.modelResults?.blocks?.[0]?.nitrogen?.surplus?.unit?.notation;
    const totalPNotation = overseerModelRun?.modelResults?.blocks?.[0]?.phosphorus?.totalLoss?.unit?.notation;
    const averagePNotation = overseerModelRun?.modelResults?.blocks?.[0]?.phosphorus?.averageLoss?.unit?.notation;

    const blocks = analysis.blocks.reduce((results, block) => {
        var blockResults = overseerModelRun?.modelResults?.blocks?.find(b => b.id === block.id);
        const result = {
            id: block.id,
            icon: utils.getBlockIcon(block.type),
            name: block.name,
            type: block.type,
            typeLabel: utils.getBlockTypeText(refData, block, false),
            isProductive: block.isProductive,
            area: block.type === "FodderCrop" ? block.rotationArea : block.areaInHectares,
            totalNitrogenLoss: blockResults?.nitrogen?.totalLoss?.value,
            averageNitrogenLoss: blockResults?.nitrogen?.averageLoss?.value,
            nitrogenDrainage: blockResults?.nitrogen?.drainage?.value,
            nitrogenSurplus: blockResults?.nitrogen?.surplus?.value,
            totalPhosphorusLoss: blockResults?.phosphorus?.totalLoss?.value,
            averagePhosphorusLoss: blockResults?.phosphorus?.averageLoss?.value,
            areaAsPercentageOfTotalBlockedArea: block.type !== "FodderCrop" ? utils.round((block.areaInHectares / analysis.totalBlockAreaInHectares) * 100, 0) : undefined,
        };
        result.nitrogenLossAsPercentageOfFarmTotal = netFarmNitrogenLoss > 0 ? utils.round((result.totalNitrogenLoss / netFarmNitrogenLoss) * 100, 0) : undefined;
        results.push(result);
        return results;
    }, []);

    const otherSources = {
        id: "other.sources",
        icon: utils.getBlockIcon("other"),
        name: "Other sources",
        typeLabel: "Other",
        totalNitrogenLoss: overseerModelRun?.modelResults?.farm?.nitrogen?.grossLossFromOtherSources?.value,
        totalPhosphorusLoss: overseerModelRun?.modelResults?.farm?.phosphorus?.grossLossFromOtherSources?.value,
    };

    return (
        <div className="Table Table-Compressed">
            <Watermark />
            <table>
                <thead>
                    <tr>
                        <th data-width="25">Name</th>
                        <th>Type</th>
                        <th className="numeric">Area (ha)</th>
                        <th className="numeric">Total N ({totalNNotation})</th>
                        <th className="numeric">Average N ({averageNNotation})</th>
                        <th className="numeric">N in drainage ({drainageNNotation})</th>
                        <th className="numeric">N surplus ({surplusNNotation})</th>
                        <th className="numeric">Total P ({totalPNotation})</th>
                        <th className="numeric">Average P ({averagePNotation})</th>
                        <th className="numeric">Block area (%)</th>
                        <th className="numeric">N farm loss (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {refData?.blockTypes?.map(blockType => {
                        const productiveBlocks = blocks.filter(b => b.isProductive && b.type === blockType.value);
                        if (productiveBlocks) {
                            return productiveBlocks.map(block => <BlockRow key={block.id} block={block} />);
                        }
                        return null;
                    })}
                    {refData?.blockTypes?.map(blockType => {
                        const nonProductiveBlocks = blocks.filter(b => !b.isProductive && b.type === blockType.value);
                        if (nonProductiveBlocks) {
                            return nonProductiveBlocks.map(block => <BlockRow key={block.id} block={block} />);
                        }
                        return null;
                    })}
                    {otherSources ? <BlockRow block={otherSources} /> : null}
                </tbody>
            </table>
        </div>
    )
}

const BlockRow = ({ block }) => {
    const showBlockNameAsHyperlink = block.id !== "other.sources";
    return (
        <tr>
            <td>
                <div className="u-flex">
                    <img className="u-icon-sm u-mr-sm u-p-5" src={block.icon} alt="Block Type" />
                    <div className="u-pt-sm">
                        {showBlockNameAsHyperlink ? <HashLink to={`#${block.id}`}>{block.name}</HashLink> : block.name}
                    </div>
                </div>
            </td>
            <td>{block.typeLabel}</td>
            <td className="numeric">{utils.numeric.format(block.area, 1)}</td>
            <td className="numeric">{utils.numeric.format(block.totalNitrogenLoss)}</td>
            <td className="numeric">{utils.numeric.format(block.averageNitrogenLoss)}</td>
            <td className="numeric">{utils.numeric.format(block.nitrogenDrainage)}</td>
            <td className="numeric">{utils.numeric.format(block.nitrogenSurplus)}</td>
            <td className="numeric">{utils.numeric.format(block.totalPhosphorusLoss)}</td>
            <td className="numeric">{utils.numeric.format(block.averagePhosphorusLoss, 1)}</td>
            <td className="numeric">{utils.numeric.format(block.areaAsPercentageOfTotalBlockedArea)}</td>
            <td className="numeric">{utils.numeric.format(block.nitrogenLossAsPercentageOfFarmTotal)}</td>
        </tr>
    )
}
